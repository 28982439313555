import React from "react";

const InterestCard = ({ data, rev }) => {
  return (
    <div className={`int-card ${rev}`}>
      <div className="int-card-1">
        <div
          className="item1 btnset"
          style={{ backgroundImage: `url(${data.Article})` }}
        >
          {/* <button className="int-btn">article</button> */}
        </div>
        <div
          className="item2 btnset"
          style={{ backgroundImage: `url(${data.Finder})` }}
        >
          {/* <button className="int-btn">trip finder</button> */}
        </div>
      </div>
      <div className="int-card-2">
        <div
          className="item3 btnset"
          style={{ backgroundImage: `url(${data.Guide})` }}
        >
          {/* <button className="int-btn">trip guide</button> */}
        </div>
      </div>
    </div>
  );
};

export default InterestCard;
