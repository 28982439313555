import React, { Suspense, lazy } from "react";
import "./interest.css";
import bannerimg from "../../Images/Interest/Interest-Banner.jpeg";

import { data } from "./interestImports";
import { Link } from "react-router-dom";

const InterestPage = () => {
  const InterestCard = lazy(() => import("./InterestCard"));
  const Interestinfo = lazy(() => import("./Interestinfo"));
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  let arr = [
    "tourguide/winter",
    "tourguide/summer",
    "tourguide/spring",
    "tourguide/snow",
    "tourguide/beaches",
    "tourguide/foodanddrink",
    "tourguide/history",
    "tourguide/solotravel",
    "tourguide/africa",
    "tourguide/asia",
    "tourguide/europe",
    "tourguide/northamerica",
    "tourguide/middleeast",
  ];

  return (
    <div className="interest-container">
      <Suspense
        fallback={
          <div>
            <h1 style={{ fontSize: 50 }}>LOADING..PLEASE WAIT</h1>
          </div>
        }
      >
        <div className="interest-top">
          <img
            src="https://images.unsplash.com/photo-1499591934245-40b55745b905?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2072&q=80"
            alt=""
          />
          <div className="interest-top-content">
            <h1>Best places to visit in 2023</h1>
            <Link
              to="/travelogue/best-places-to-visit-in-2023"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              <button>Read More</button>
            </Link>
          </div>
        </div>
      </Suspense>

      {data.map((e, i) => {
        return (
          <div
            className={`interest-cards-cointainer ${i % 2 === 0 && "int-rev"}`}
          >
            <Suspense
              fallback={
                <div>
                  <h1 style={{ fontSize: 50 }}>LOADING..PLEASE WAIT</h1>
                </div>
              }
            >
              <InterestCard
                data={e}
                key={i}
                rev={i % 2 === 1 ? "int-rev" : ""}
              />
              <Interestinfo link={arr[i]} data={e} key={i + 9} />
            </Suspense>
          </div>
        );
      })}
    </div>
  );
};

export default InterestPage;
