import React from "react";
import "../blog.css";
import blogImage from "../../../Images/Africa.jpg";

const Info = ({ data }) => {
  return (
    <>
      <div
        className={`mountain-info ${data?.reverse ? "mountain-info-rev" : ""} `}
        // style={{ textAlign: data?.reverse ? "start" : "end" }}
      >
        <div className="mountain-info-left">
          <h2>{data?.title}</h2>
          <p>{data?.para}</p>
        </div>
        <div className="mountain-info-right">
          <img src={data.img ? data.img : blogImage} alt="blog_image" />
        </div>
      </div>
    </>
  );
};

export default Info;
