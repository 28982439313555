import React from "react";
import { Link } from "react-router-dom";
import { data } from "../../blogs/data";
import "./card.css";
import Maldives from "../../blogs/BlogImage/Maldives_Image/Cover.jpeg";

import cardLogo from "../HomeImage/centercardlogo.png";

let allKey = Object.keys(data);

let keys = [];
let Image = [];
var Title = allKey.map((e, k) => {
  keys.push(data[e][0].title);
  if (k > 7) {
    Image.push(data[e][0].bannerImage);
  }
});

function scrolltotop() {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
}

const CardComponent = (props) => {
  return (
    <div className="blog-card">
      <button className="btn-card">Read More</button>
      <p> {props.desc} </p>
    </div>
  );
};
var maldives = "10-most-visited-places-in-maldives";
function Cards() {
  return (
    <div className="main-container">
      <h1>New on Wanderspots</h1>
      <div className="blog-card-container">
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
          to={`/travelogue/${allKey.at(8)}`}
          style={{ backgroundImage: `url(${Image.at(0)})` }}
        >
          <CardComponent desc={keys.at(8)} />
        </Link>
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
          to={`/travelogue/${allKey.at(9)}`}
          className="blog-card-mid"
          style={{ backgroundImage: `url(${Image.at(1)})` }}
        >
          <div className="">
            <button className="btn-card">Read More</button>
            <img src={cardLogo} alt="" height="300px" width="500px" />
            <p> {keys.at(9)} </p>
          </div>
        </Link>
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
          to={`/travelogue/${allKey.at(10)}`}
          style={{ backgroundImage: `url(${Image.at(2)})` }}
        >
          <CardComponent desc={keys.at(10)} />
        </Link>
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
          to={`/travelogue/${allKey.at(11)}`}
          style={{ backgroundImage: `url(${Image.at(3)})` }}
        >
          <CardComponent desc={keys.at(11)} />
        </Link>
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
          to={`/travelogue/${allKey.at(12)}`}
          style={{ backgroundImage: `url(${Image.at(4)})` }}
        >
          <CardComponent desc={keys.at(12)} />
        </Link>
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
          to={`/travelogue/${allKey.at(13)}`}
          style={{ backgroundImage: `url(${Image.at(5)})` }}
        >
          <CardComponent desc={keys.at(13)} />
        </Link>
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
          to={`/travelogue/${allKey.at(14)}`}
          style={{ backgroundImage: `url(${Image.at(6)})` }}
        >
          <CardComponent desc={keys.at(14)} />
        </Link>
        <div className="blog-heading">
          <h1>What's New Trending </h1>
        </div>
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
          to={`/travelogue/${allKey.at(15)}`}
          style={{ backgroundImage: `url(${Image.at(7)})` }}
        >
          <CardComponent desc={keys.at(15)} />
        </Link>
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
          to={`/travelogue/${allKey.at(16)}`}
          style={{ backgroundImage: `url(${Image.at(8)})` }}
        >
          <CardComponent desc={keys.at(16)} />
        </Link>
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
          to={`/travelogue/${allKey.at(17)}`}
          style={{ backgroundImage: `url(${Image.at(9)})` }}
        >
          <CardComponent desc={keys.at(17)} />
        </Link>
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
          to={`/travelogue/${maldives}`}
          style={{ backgroundImage: `url(${Maldives})` }}
        >
          <CardComponent desc={"10 most visited places in Maldives"} />
        </Link>
      </div>
    </div>
  );
}

export default Cards;
