// solotravel

import solotravelbanner from "../../Images/tourguide/solotravel/solotravelbanner.jpeg";
import solotravel1 from "../../Images/tourguide/solotravel/solotravel1.jpeg";
import solotravel2 from "../../Images/tourguide/solotravel/solotravel2.jpeg";
import solotravel3 from "../../Images/tourguide/solotravel/solotravel3.jpeg";
import solotravel4 from "../../Images/tourguide/solotravel/solotravel4.jpeg";

// spring

import springbanner from "../../Images/tourguide/spring/Bannerspring.jpeg";
import spring1 from "../../Images/tourguide/spring/spring1.jpeg";
import spring2 from "../../Images/tourguide/spring/spring2.jpeg";
import spring3 from "../../Images/tourguide/spring/spring3.jpeg";
import spring4 from "../../Images/tourguide/spring/spring4.jpeg";

// summer

import summerbanner from "../../Images/tourguide/Summer/Bannersummer.jpeg";
import summer1 from "../../Images/tourguide/Summer/Summer1.jpeg";
import summer2 from "../../Images/tourguide/Summer/Summer2.jpeg";
import summer3 from "../../Images/tourguide/Summer/Summer3.jpeg";
import summer4 from "../../Images/tourguide/Summer/Summer4.jpeg";

// winter

import winterbanner from "../../Images/tourguide/Winter/Winterbanner.jpeg";
import winter1 from "../../Images/tourguide/Winter/Winter1.jpeg";
import winter2 from "../../Images/tourguide/Winter/Winter2.jpeg";
import winter3 from "../../Images/tourguide/Winter/Winter3.jpeg";
import winter4 from "../../Images/tourguide/Winter/Winter4.jpeg";

// foodanddrink

import foodanddrinkbanner from "../../Images/tourguide/foodanddrink/foodanddrinkbanner.jpeg";
import foodanddrink1 from "../../Images/tourguide/foodanddrink/foodanddrink1.jpeg";
import foodanddrink2 from "../../Images/tourguide/foodanddrink/foodanddrink2.jpeg";
import foodanddrink3 from "../../Images/tourguide/foodanddrink/foodanddrink3.jpeg";
import foodanddrink4 from "../../Images/tourguide/foodanddrink/foodanddrink4.jpeg";
import foodanddrink5 from "../../Images/tourguide/foodanddrink/foodanddrink4.jpeg";

// history

import historybanner from "../../Images/tourguide/History/Historybanner.jpeg";
import history1 from "../../Images/tourguide/History/History1.jpeg";
import history2 from "../../Images/tourguide/History/History2.jpeg";
import history3 from "../../Images/tourguide/History/History3.jpeg";
import history4 from "../../Images/tourguide/History/History4.jpeg";

// snow

import snowbanner from "../../Images/tourguide/Snow/Snowbanner.jpeg";
import snow1 from "../../Images/tourguide/Snow/Snow1.jpeg";
import snow2 from "../../Images/tourguide/Snow/snow2.jpeg";
import snow3 from "../../Images/tourguide/Snow/snow3.jpeg";

// beaches

import beachesbanner from "../../Images/tourguide/Beaches/beachbanner.jpeg";
import beaches1 from "../../Images/tourguide/Beaches/beach1.jpeg";
import beaches2 from "../../Images/tourguide/Beaches/beach2.jpeg";
import beaches3 from "../../Images/tourguide/Beaches/beach3.jpeg";
import beaches4 from "../../Images/tourguide/Beaches/beach4.jpeg";

// africa

import africabanner from "../../Images/tourguide/africa/africabanner.jpeg";
import africa1 from "../../Images/tourguide/africa/africa1.jpeg";
import africa2 from "../../Images/tourguide/africa/africa2.jpeg";
import africa3 from "../../Images/tourguide/africa/africa3.jpeg";
import africa4 from "../../Images/tourguide/africa/africa4.jpeg";

// asia

import asiabanner from "../../Images/tourguide/asia/asiabanner.jpeg";
import asia1 from "../../Images/tourguide/asia/asia1.jpeg";
import asia2 from "../../Images/tourguide/asia/asia2.jpeg";
import asia3 from "../../Images/tourguide/asia/asia3.jpeg";
import asia4 from "../../Images/tourguide/asia/asia4.jpeg";

// europe

import europebanner from "../../Images/tourguide/europe/europebanner.jpeg";
import europe1 from "../../Images/tourguide/europe/europe1.jpeg";
import europe2 from "../../Images/tourguide/europe/europe2.jpeg";
import europe3 from "../../Images/tourguide/europe/europe3.jpeg";
import europe4 from "../../Images/tourguide/europe/europe4.jpeg";

// northamerica

import northamericabanner from "../../Images/tourguide/northamerica/northamericabanner.jpeg";
import northamerica1 from "../../Images/tourguide/northamerica/northamerica1.jpeg";
import northamerica2 from "../../Images/tourguide/northamerica/northamerica2.jpeg";
import northamerica3 from "../../Images/tourguide/northamerica/northamerica3.jpeg";
import northamerica4 from "../../Images/tourguide/northamerica/northamerica4.jpeg";

// middleeast

import middleeastbanner from "../../Images/tourguide/middleeast/middleeastbanner.jpeg";
import middleeast1 from "../../Images/tourguide/middleeast/middleeast1.jpeg";
import middleeast2 from "../../Images/tourguide/middleeast/middleeast2.jpeg";
import middleeast3 from "../../Images/tourguide/middleeast/middleeast3.jpeg";
import middleeast4 from "../../Images/tourguide/middleeast/middleeast4.jpeg";

export const data = {
  solotravel: [
    {
      heading: (
        <>
          <h1>Wander solo, </h1> <h1>and</h1> <h1>find your bliss!!</h1>
        </>
      ),
      banner: solotravelbanner,
    },
    [
      {
        title: `The life-changing experience of your life…`,
        para: (
          <>
            Bali is a popular destination for solo travelers, thanks to its
            stunning beaches, beautiful temples, and laid-back vibe. You can
            explore the island's lush rice paddies, relax on the beach, and
            immerse yourself in the local culture.
            <br />
            <br />
            Thailand is another popular destination for solo travelers, with its
            friendly locals, delicious food, and beautiful scenery. You can
            visit the bustling city of Bangkok, explore the ancient temples of
            Chiang Mai, and relax on the beaches of Phuket.
            <br />
            <br />
            Singapore is a safe and modern city-state that is perfect for solo
            travelers. You can explore the city's beautiful gardens, visit the
            iconic Marina Bay Sands, and indulge in the local cuisine.
            <br />
            <br />
            <h4>Looking to embark on a solo adventure in Asia!</h4>
          </>
        ),
        rev: false,
        sectionimage: solotravel1,
      },
      {
        title: `Being A solo traveler adds a new way of living!`,
        para: (
          <>
            Barcelona, Spain is a beautiful city that is perfect for solo
            travelers. You can admire the unique architecture of Gaudi, relax on
            the beaches of Barceloneta, and indulge in the local cuisine.
            <br />
            <br />
            Amsterdam, Netherlands is a great destination for solo travelers,
            with its picturesque canals, charming architecture, and vibrant
            nightlife.
            <br />
            <br />
            Singapore is a safe and modern city-state that is perfect for solo
            travelers. You can explore the city's beautiful gardens, visit the
            iconic Marina Bay Sands, and indulge in the local cuisine.
            <br />
            <br />
            <h4>Travel not to escape life, but for life not to escape me.</h4>
          </>
        ),
        rev: true,
        sectionimage: solotravel2,
      },
      {
        title: `Freedom to explore the world on your own…`,
        para: (
          <>
            Dubai is a modern and vibrant city that is a great destination for
            solo travelers. With its beautiful beaches, world-class shopping,
            and rich cultural heritage.
            <br />
            <br />
            Petra, Jordan - Petra is an ancient city carved into the rock that
            is a UNESCO World Heritage Site. You can explore the stunning
            temples and tombs, hike through the beautiful landscape, and learn
            about the region's rich history.
            <br />
            <br />
            Muscat, Oman - Muscat is a beautiful city that is known for its
            stunning architecture and rich history. You can visit the Sultan
            Qaboos Grand Mosque, explore the Al Jalali Fort, and relax on the
            beautiful beaches.
            <br />
            <br />
            <h4>Discover yourself through solo travel!!!</h4>
          </>
        ),
        rev: false,
        sectionimage: solotravel3,
      },
      {
        title: `A solo trip is not just a journey, it's an opportunity to rediscover…`,
        para: (
          <>
            Marrakech, Morocco - Marrakech is a city that is full of exotic
            charm and beauty. You can explore the ancient medina, visit the
            stunning Bahia Palace, and indulge in the local cuisine.
            <br />
            <br />
            Victoria Falls, Zambia/Zimbabwe - Victoria Falls is one of the
            world's most stunning natural wonders. You can go bungee jumping,
            take a helicopter ride over the falls, or relax on a sunset cruise
            on the Zambezi River.
            <br />
            <br />
            Essaouira, Morocco - Essaouira is a charming coastal town with a
            laid-back vibe and beautiful beaches. You can wander through the
            winding streets of the medina, enjoy fresh seafood at the local
            restaurants, and take a surfing lesson..
            <br />
            <br />
            <h4>
              With its diverse cultures, stunning landscapes, and rich history,
              Africa offers the Best for every solo traveler.
            </h4>
          </>
        ),
        rev: true,
        sectionimage: solotravel4,
      },
    ],
  ],
  foodanddrink: [
    {
      heading: (
        <>
          <h1>Savor the flavors of the world </h1> <h1>with</h1>{" "}
          <h1>every bite and sip!!!</h1>
        </>
      ),
      banner: foodanddrinkbanner,
    },
    [
      {
        title: ` Travel with your taste buds and discover the world's culinary delights`,
        para: (
          <>
            Ithaa Undersea Restaurant - This restaurant is located 16 feet below
            sea level and offers stunning underwater views of the ocean while
            enjoying contemporary European cuisine.
            <br />
            <br />
            Fashola at Soneva Fushi - This restaurant offers a unique dining
            experience on a sandbank in the middle of the ocean. Guests can
            enjoy a delicious gourmet meal while being surrounded by stunning
            views of the ocean.
            <br />
            <br />
            Blu at Taj Exotica Resort & Spa - This restaurant is located over
            the water and offers stunning views of the ocean while serving fresh
            seafood dishes like grilled lobster, seafood chowder, and yellowfin
            tuna sashimi.
            <br />
            <br />
            <h4> Explore new cultures one dish and drink at a time… </h4>
          </>
        ),
        rev: false,
        sectionimage: foodanddrink1,
      },
      {
        title: `Indulge in the delicious diversity of global cuisine!!!`,
        para: (
          <>
            La Petite Maison - La Petite Maison is a French-Mediterranean
            restaurant that offers fresh and flavorful dishes made with the
            finest ingredients. The restaurant is known for its signature
            dishes, including the warm prawns with olive oil and lemon, and the
            truffle risotto.
            <br />
            <br />
            Tomo - Tomo is a Japanese restaurant that offers stunning views of
            Dubai's skyline. The restaurant offers traditional Japanese dishes,
            including sushi, sashimi, and robatayaki-grilled dishes.
            <br />
            <br />
            Al Hadheerah - Al Hadheerah is a traditional Arabic restaurant that
            offers a unique dining experience in a desert setting. The
            restaurant offers a buffet-style meal with a range of dishes,
            including grilled meats, Arabic mezze, and traditional desserts.
            <br /> <br />
            <h4> Find your perfect slice of paradise!!! </h4>
          </>
        ),
        rev: true,
        sectionimage: foodanddrink2,
      },
      {
        title: `Experience the world through the lens of food and drink!!! `,
        para: (
          <>
            Asador Etxebarri - Asador Etxebarri is a Basque restaurant located
            in the small town of Axpe. The restaurant offers delicious
            wood-fired grilled meats and fish, using the best quality
            ingredients.
            <br />
            <br />
            Tickets Bar - Tickets Bar is a tapas restaurant located in
            Barcelona, run by famous chef Albert Adria. The restaurant offers
            creative and modern tapas dishes with a twist.
            <br />
            <br />
            Casa Botín - Casa Botín is the oldest restaurant in the world,
            located in Madrid. The restaurant is known for its famous roasted
            suckling pig and lamb.
            <br /> <br />
            <h4>
              Let your palate guide you on a culinary journey around the world!
            </h4>
          </>
        ),
        rev: false,
        sectionimage: foodanddrink3,
      },
      {
        title: `Experience the ultimate food guide…`,
        para: (
          <>
            Jay Fai - Jay Fai is a street food stall in Bangkok that has
            received a Michelin star. The restaurant is known for its delicious
            seafood dishes, including crab omelets and drunken noodles.
            <br />
            <br />
            Gaggan - Gaggan is a three-Michelin-starred restaurant located in
            Bangkok. The restaurant offers a unique take on Indian cuisine with
            a modern, molecular twist.
            <br />
            <br />
            Khao Soi Islam - Khao Soi Islam is a famous restaurant in Chiang Mai
            that offers the traditional Northern Thai dish, Khao Soi. This rich
            and flavorful dish consists of coconut milk curry with crispy
            noodles, chicken or beef, and fresh herbs.
            <br /> <br />
            <h4>From street food to fine dining, the world is your oyster!</h4>
          </>
        ),
        rev: true,
        sectionimage: foodanddrink4,
      },
      {
        title: `Indulge in the delicious diversity of global cuisine!!!`,
        para: (
          <>
            La Petite Maison - La Petite Maison is a French-Mediterranean
            restaurant that offers fresh and flavorful dishes made with the
            finest ingredients. The restaurant is known for its signature
            dishes, including the warm prawns with olive oil and lemon, and the
            truffle risotto.
            <br />
            <br />
            Tomo - Tomo is a Japanese restaurant that offers stunning views of
            Dubai's skyline. The restaurant offers traditional Japanese dishes,
            including sushi, sashimi, and robatayaki-grilled dishes.
            <br />
            <br />
            Al Hadheerah - Al Hadheerah is a traditional Arabic restaurant that
            offers a unique dining experience in a desert setting. The
            restaurant offers a buffet-style meal with a range of dishes,
            including grilled meats, Arabic mezze, and traditional desserts.
            <br /> <br />
            <h4> Find your perfect slice of paradise!!! </h4>
          </>
        ),
        rev: false,
        sectionimage: foodanddrink5,
      },
    ],
  ],
  spring: [
    {
      heading: (
        <>
          <h1>Spring is nature's way </h1> <h1>of</h1>
          <h1>saying, Let's party!</h1>
        </>
      ),
      banner: springbanner,
    },
    [
      {
        title: ` Making it a beautiful time to explore Dubai…`,
        para: (
          <>
            Spring is the best time to visit Dubai's many beautiful parks and
            gardens, such as the Dubai Miracle Garden, which features more than
            45 million flowers arranged in stunning designs.
            <br />
            <br />
            Desert Safari: A visit to Dubai is incomplete without experiencing
            the desert safari, and spring is the perfect time to enjoy it.
            <br />
            <br />
            Dubai hosts many exciting events during the spring season. The Dubai
            Food Festival takes place in March and features a range of culinary
            experiences, including food tastings, cooking demonstrations, and
            themed events
            <br /> <br />
            <h4>Ready to plan your Spring adventure in Dubai?</h4>
          </>
        ),
        rev: true,
        sectionimage: spring4,
      },
      {
        title: `A lovely reminder of how beautiful change can indeed be…`,
        para: (
          <>
            Stunning Blooms: Europe's famous gardens and parks come to life
            during the spring season, with beautiful blooms and blossoms in full
            display. Some of the best places to witness the beauty of spring are
            Keukenhof Gardens in the Netherlands, the Chelsea Flower Show in
            London, and the Jardin des Plantes in Paris… <br />
            <br />
            Outdoor Activities: Spring is an excellent time to indulge in
            outdoor activities, such as hiking, biking, and kayaking.
            <br />
            <br />
            Europe has a rich cultural heritage, and there is no shortage of
            festivals during the spring season. Some of the most popular events
            include the Carnival of Venice in Italy, the St. Patrick's Day
            Parade in Dublin, and the Feria de Abril in Seville, Spain.
            <br /> <br />
            <h4>
              {" "}
              It's a great time to explore Europe's cities, towns, and
              countryside.{" "}
            </h4>
          </>
        ),
        rev: false,
        sectionimage: spring1,
      },

      {
        title: `Spring is when you feel like whistling even with a shoe full of slush… `,
        para: (
          <>
            Spring is an excellent time to enjoy the beach in Asia, as the
            weather is warm and the crowds are not as overwhelming as in the
            summer months. Spring is the perfect time to enjoy a beach holiday
            in the Maldives. <br />
            <br />
            The best places to go on a wildlife safari include Yala National
            Park in Sri Lanka, Ranthambore National Park in India, and Kinabalu
            Park in Malaysia.
            <br />
            <br />
            Asia has a rich cultural heritage, and there are many festivals and
            events during spring that showcase the region's traditions and
            customs. The most popular events include the Songkran festival in
            Thailand.
            <br /> <br />
            <h4>
              It's an ideal time to explore the natural beauty, cultural
              heritage, and wildlife of Asia.
            </h4>
          </>
        ),
        rev: true,
        sectionimage: spring3,
      },
      {
        title: `No winter lasts forever, No spring skips its turn…`,
        para: (
          <>
            Spring in Tanzania runs from March to May, and it's the best time to
            witness the Great Migration in the Serengeti <br />
            <br />
            In Kenya, You can witness the Great Migration in the Masai Mara, go
            on a hot air balloon safari, and enjoy other wildlife experiences.
            <br />
            <br />
            It's a great time to explore the country's ancient cities, such as
            Marrakesh, Fez, and Casablanca. The weather is mild, and the crowds
            are smaller, making it the perfect time to experience the culture,
            architecture, and cuisine of Morocco.
            <br /> <br />
            <h4>Africa has a lot to offer. Get ready to explore it.</h4>
          </>
        ),
        rev: false,
        sectionimage: spring2,
      },
    ],
  ],
  summer: [
    {
      heading: (
        <>
          <h1> Experience the world </h1> <h1> in a</h1>{" "}
          <h1> new way with us!!</h1>
        </>
      ),
      banner: summerbanner,
    },
    [
      {
        title: `Escape to paradise this summer…`,
        para: (
          <>
            Kenya’s Diani Beach: A stunning beach destination on Kenya's
            southern coast, featuring crystal-clear waters, white sand beaches,
            and plenty of opportunities for water sports and activities. <br />
            <br />
            Beau Vallon Beach: One of Seychelles' most popular beaches, located
            on Mahé Island and offering a wide range of water sports and
            activities.
            <br />
            <br />
            Zanzibar Island: A tropical paradise off the coast of Tanzania,
            known for its white sand beaches, turquoise waters, and historic
            Stone Town.
            <br /> <br />
            <h4>Ready to plan your summer adventure in Africa?</h4>
          </>
        ),
        rev: false,
        sectionimage: summer1,
      },
      {
        title: `Experience the ultimate summer adventure…`,
        para: (
          <>
            Malaysia’s Langkawi Island: A tropical paradise featuring stunning
            beaches, crystal-clear waters, and dense rainforests. <br />
            <br />
            Maafushi Island: A popular tourist destination in the Maldives,
            featuring a wide range of water sports and activities, as well as
            stunning beaches and turquoise waters.
            <br />
            <br />
            Marina Bay Sands in Singapore is a world-famous luxury hotel and
            casino complex, featuring stunning architecture, rooftop bars, and a
            massive infinity pool.
            <br /> <br />
          </>
        ),
        rev: true,
        sectionimage: summer2,
      },
      {
        title: `Indulge in the summer of your dreams…`,
        para: (
          <>
            Ibiza: An island located in the (Spain) Mediterranean Sea, known for
            its world-famous nightlife, stunning beaches, and crystal-clear
            waters. <br />
            <br />
            In Kenya, You can witness the Great Migration in the Masai Mara, go
            on a hot air balloon safari, and enjoy other wildlife experiences.
            <br />
            <br />
            Lake Geneva: A stunning lake located on the border of Switzerland
            and France, featuring spectacular scenery, historic landmarks, and
            world-class resorts.
            <br /> <br />
            Lake District National Park: A stunning natural park located in
            northern England, known for its stunning scenery, outdoor
            adventures, and quaint towns and villages.
            <br /> <br />
            <h4>Ready for a Sunny paradise.</h4>
          </>
        ),
        rev: false,
        sectionimage: summer3,
      },
      {
        title: `Adventure awaits this summer - are you ready…`,
        para: (
          <>
            Ski Dubai: A massive indoor ski resort located in the Mall of
            Emirates, featuring a wide range of winter sports and activities.{" "}
            <br />
            <br />
            Wadi Rum: A stunning desert landscape located in southern Jordan,
            featuring sand dunes, towering cliffs, and unique rock formations.
            <br />
            <br />
            Pamukkale: A UNESCO World Heritage Site, featuring stunning white
            travertine terraces and natural hot springs in Turkey.
            <br /> <br />
            <h4>Discover your next dream destination with wanderspots</h4>
          </>
        ),
        rev: true,
        sectionimage: summer4,
      },
    ],
  ],
  winter: [
    {
      heading: (
        <>
          <h1>Embrace the magic of winter wonderlands</h1> <h1> with</h1>
          <h1>stunning views and cozy fireside retreats</h1>
        </>
      ),
      banner: winterbanner,
    },
    [
      {
        title: `
        Luxury Europe winter escape…
        `,
        para: (
          <>
            <h4>
              Europe is full of magical destinations that come to life in the
              winter months
            </h4>
            Vienna, Austria- Experience the charm of Vienna in the winter months
            with its stunning architecture, cozy cafes, and world-famous
            Christmas markets. <br />
            <br />
            Rovaniemi, Finland-Get ready for a unique winter experience in
            Rovaniemi, the official hometown of Santa Claus.
            <br />
            <br />
            Zermatt, Switzerland-For ski enthusiasts, Zermatt is a must-visit
            destination. It's the perfect place for a winter sports getaway.
            <br /> <br />
            Bruges, Belgium-Stroll through the cobblestone streets, visit the
            historic churches and indulge in delicious Belgian chocolate.
            <br /> <br />
            <h4>Ready to plan your winter adventure in Europe?</h4>
          </>
        ),
        rev: false,
        sectionimage: winter1,
      },
      {
        title: `Dubai's winter delights…`,
        para: (
          <>
            Explore the vibrant city of Dubai in the winter months. From
            towering skyscrapers to sandy beaches.
            <br />
            <br />
            Burj Khalifa-Take in the breathtaking views of Dubai from the
            world's tallest building, the Burj Khalifa.
            <br />
            <br />
            Dubai Miracle Garden-Experience the wonder of over 45 million
            flowers in bloom at Dubai Miracle Garden, the world's largest flower
            garden.
            <br /> <br />
            Jumeirah Beach-Soak up the warm winter sun on the pristine white
            sand of Jumeirah Beach. <br /> <br />
            <h4>Ready for a Sunny winter paradise.</h4>
          </>
        ),
        rev: true,
        sectionimage: winter2,
      },
      {
        title: `USA's Icy Thrills… `,
        para: (
          <>
            Colorado: Known for its skiing and snowboarding resorts, Colorado
            offers a winter wonderland for outdoor enthusiasts.
            <br />
            <br />
            Tahoe, California: With stunning views of Lake Tahoe, this region
            offers skiing, snowboarding, and other winter sports.
            <br />
            <br />
            Aspen, Colorado: This picturesque town is a favorite destination for
            skiing, snowboarding, and winter sports.
            <br /> <br />
            Jackson Hole, Wyoming: With its dramatic mountains, this area is
            ideal for skiing, snowboarding, and winter adventures.
            <br /> <br />
            Visiting one of these winter destinations to experience the beauty
            of the snowy season in the USA. <br /> <br />
            <h4>
              Pack your bags and escape to the winter wonderland of your dreams
            </h4>
          </>
        ),
        rev: false,
        sectionimage: winter3,
      },
      {
        title: `Asia's winter gems… `,
        para: (
          <>
            Escape the cold and enjoy tropical weather all year round in
            Malaysia..
            <br />
            <br />
            Immerse yourself in the rich cultural heritage of Malaysia by
            visiting historical sites such as the Malacca UNESCO World Heritage
            Site
            <br />
            <br />
            Explore the underwater world by snorkeling or diving in
            crystal-clear waters in the Maldives.
            <br /> <br />
            Singapore's iconic attractions such as Marina Bay Sands, Gardens by
            the Bay, and Sentosa Island.
            <br /> <br />
            Thailand is famous for its stunning beaches of Phuket, Koh Samui,
            and Krabi.. <br /> <br />
          </>
        ),
        rev: true,
        sectionimage: winter4,
      },
    ],
  ],
  snow: [
    {
      heading: (
        <>
          <h1>Embrace the Chill and Explore</h1> <h1>the </h1>{" "}
          <h1>Thrill: Travel Through the Snow</h1>
        </>
      ),
      banner: snowbanner,
    },
    [
      {
        title: `Winter Wonderland Awaits: Discover the Magic of Snow Travel! `,
        para: (
          <>
            The northern parts of Iran experience snowfall during the winter
            season and some of the popular ski resorts in the country are Dizin,
            Shemshak, and Tochal.
            <br />
            <br />
            The Ajloun Forest Reserve, located in northern Jordan, receives
            snowfall during the winter season.
            <br />
            <br />
            The mountainous regions of Syria, such as the Jebel Ansariyah and
            the Mount Hermon ski resort, receive snowfall during the winter
            season.
            <br /> <br />
            Jackson Hole, Wyoming: With its dramatic mountains, this area is
            ideal for skiing, snowboarding, and winter adventures.
            <br /> <br />
            <h4>Travel to New Heights!!</h4>
          </>
        ),
        rev: true,
        sectionimage: snow1,
      },
      {
        title: `Escape to a World of White: Journey Through Snowy Landscapes!`,
        para: (
          <>
            Colorado is a popular destination for skiing and snowboarding, with
            world-class resorts such as Vail, Breckenridge, Aspen, and
            Telluride.
            <br />
            <br />
            Montana's Big Sky Resort, located near Bozeman, is one of the
            largest ski resorts in the United States, with over 5,800 acres of
            ski terrain.
            <br />
            <br />
            New York's Adirondack Mountains offer skiing and snowboarding
            opportunities, with popular resorts such as Whiteface Mountain and
            Gore Mountain.
            <br /> <br />
            <h4>Embark on an Exciting Snow Travel Experience!</h4>
          </>
        ),
        rev: false,
        sectionimage: snow2,
      },
      {
        title: `Chase the Chill: Experience the Beauty of Snowy Destinations!`,
        para: (
          <>
            Spain: If you want to escape the traditional European winter and
            travel somewhere warmer, Malaga is one of the best spots for some
            winter sun.
            <br />
            <br />
            If you’re a fan of thermal baths, you HAVE to visit the neo-baroque
            Szechenyi Baths complex. It sits right in the City Park and is one
            of the main winter tourist attractions in Budapest.
            <br />
            <br />
            Is there a better winter destination than Vienna? Not only in
            Europe, but the entire world. Elegant and opulent, this Austrian
            capital captivates millions of tourists each year with its
            world-class concerts at Vienna’s State Opera House, top-of-notch
            restaurants, guaranteed snow, and self-assured Baroque architecture.
            <br /> <br />
            <h4>Escape to a world of snowy adventure.</h4>
          </>
        ),
        rev: true,
        sectionimage: snow3,
      },
    ],
  ],
  history: [
    {
      heading: (
        <>
          <h1> Experience the rich culture </h1> <h1>and </h1>{" "}
          <h1>heritage of our ancestors!!!</h1>
        </>
      ),
      banner: historybanner,
    },
    [
      {
        title: `Step back in time and uncover the secrets of our past…`,
        para: (
          <>
            Rome, Italy - Rome is known for its ancient history and is home to
            many historic landmarks, including the Colosseum, the Roman Forum,
            and the Pantheon.
            <br />
            <br />
            Paris, France - Paris is known for its rich history and is home to
            many historic landmarks, including the Eiffel Tower, the Louvre
            Museum, and the Palace of Versailles.
            <br />
            <br />
            Berlin, Germany - Berlin is known for its 20th-century history and
            is home to many historic landmarks, including the Berlin Wall, the
            Brandenburg Gate, and Checkpoint Charlie.
            <br /> <br />
            <h4>Journey through history and discover the stories!!!</h4>
          </>
        ),
        rev: false,
        sectionimage: history1,
      },
      {
        title: `Discover the legacy of our forefathers and the impact they had on our present…`,
        para: (
          <>
            The Great Wall of China: This iconic structure is over 13,000 miles
            long and was built over the course of several centuries to protect
            China from invaders.
            <br />
            <br />
            Borobudur Temple, Indonesia: This ancient Buddhist temple is the
            largest in the world and is a UNESCO World Heritage Site
            <br />
            <br />
            The Ayutthaya Historical Park, Thailand: This UNESCO World Heritage
            Site is home to the ruins of the ancient city of Ayutthaya, which
            was once the capital of Thailand.
            <br /> <br />
            <h4>
              Asia is a continent rich in history and heritage, with countless
              sites to explore!!!
            </h4>
          </>
        ),
        rev: true,
        sectionimage: history2,
      },
      {
        title: `Explore the treasures of the past and their significance in our modern lives…`,
        para: (
          <>
            The National Museum of Kenya, Kenya: This museum is home to numerous
            exhibits on the history and culture of Kenya, including exhibits on
            human evolution and traditional Kenyan art
            <br />
            <br />
            Robben Island, South Africa: This island was once used as a prison
            to hold political prisoners, including Nelson Mandela. Today, it is
            a UNESCO World Heritage Site and a museum.
            <br />
            <br />
            <h4>Follow in the footsteps of history's greatest figures!!!</h4>
          </>
        ),
        rev: false,
        sectionimage: history3,
      },
      {
        title: `Witness the wonders of ancient civilizations and marvel at their ingenuity…
        `,
        para: (
          <>
            Ellis Island National Museum of Immigration in New York - This
            museum tells the story of immigration to the United States through
            the experience of those who passed through Ellis Island. Visitors
            can learn about the challenges and triumphs of immigrants as they
            arrived in America and made new lives for themselves.
            <br />
            <br />
            The Golden Gate Bridge in San Francisco - The Golden Gate Bridge is
            one of the most iconic landmarks in the United States and is
            recognized around the world. Visitors can walk or bike across the
            bridge and take in the stunning views of San Francisco Bay.
            <br />
            <br />
            The Kennedy Space Center in Florida - The Kennedy Space Center is
            NASA's primary launch center and has been the site of many historic
            space missions. Visitors can see real spacecraft, meet astronauts,
            and learn about the history of space exploration.
            <br /> <br />
            <h4>
              Experience the magic of history as you walk in the footsteps of
              our ancestors!!!
            </h4>
          </>
        ),
        rev: true,
        sectionimage: history4,
      },
    ],
  ],
  beaches: [
    {
      heading: (
        <>
          <h1>Life's a beach, so grab your</h1> <h1>sunscreen</h1>{" "}
          <h1> and have fun!!!</h1>
        </>
      ),
      banner: beachesbanner,
    },
    [
      {
        title: `Escape to paradise on the sandy shores…`,
        para: (
          <>
            Maldives - The Maldives is a group of islands in the Indian Ocean
            known for its crystal-clear waters, stunning beaches, and luxurious
            resorts.
            <br />
            <br />
            Bali, Indonesia - Bali is known for its stunning beaches,
            crystal-clear waters, and great surf spots. There are many popular
            beaches to visit, such as Kuta Beach and Seminyak Beach, as well as
            quieter ones like Jimbaran Bay and Lovina Beach.
            <br />
            <br />
            Langkawi, Malaysia - Langkawi is a beautiful archipelago of 99
            islands with stunning beaches and clear waters. Pantai Cenang and
            Tanjung Rhu Beach are among the most popular beaches in Langkawi.
            <br /> <br />
            <h4>Sun, surf, and relaxation await you….</h4>
          </>
        ),
        rev: true,
        sectionimage: beaches1,
      },
      {
        title: `Take a break from the hustle and bustle with a beach getaway…  `,
        para: (
          <>
            Costa Brava, Spain - Costa Brava is a beautiful coastline in
            northeastern Spain with many sandy beaches, rocky coves, and clear
            waters. Popular beaches include Platja d'Aro, Lloret de Mar, and
            Tossa de Mar.
            <br />
            <br />
            Amalfi Coast, Italy - The Amalfi Coast is a stunning coastal region
            in southern Italy with beautiful beaches, crystal-clear waters, and
            scenic views. Some of the best beaches include Furore Beach, Fiordo
            di Furore, and Praiano Beach.
            <br />
            <br />
            French Riviera, France - The French Riviera is a glamorous coastline
            in the south of France with many beautiful beaches, clear waters,
            and luxurious resorts. Popular beaches include Plage de la Garoupe,
            Plage de la Salis, and Plage du Midi.
            <br /> <br />
            <h4>Find your perfect slice of paradise!!!</h4>
          </>
        ),
        rev: false,
        sectionimage: beaches2,
      },
      {
        title: `Discover the beauty of the ocean and the beach…
        `,
        para: (
          <>
            Dubai, United Arab Emirates - Dubai is known for its stunning
            beaches, luxury resorts, and warm waters. Popular beaches include
            Jumeirah Beach, Kite Beach, and The Beach at JBR.
            <br />
            <br />
            Aqaba, Jordan - Aqaba is a beautiful coastal city in Jordan with
            many beautiful beaches, clear waters, and historic landmarks.
            Popular beaches include South Beach, Tala Bay Beach, and Coral
            Beach.
            <br />
            <br />
            Doha, Qatar - Doha is a beautiful coastal city in Qatar with many
            stunning beaches, crystal-clear waters, and luxury resorts. Popular
            beaches include Katara Beach, Sealine Beach, and The Pearl-Qatar.
            <br /> <br />
            <h4>Enjoy endless days of sun, sand, and sea!!! .</h4>
          </>
        ),
        rev: true,
        sectionimage: beaches3,
      },
      {
        title: `Experience the ultimate beach vacation…
        `,
        para: (
          <>
            Zanzibar, Tanzania - Zanzibar is a beautiful island off the coast of
            Tanzania with many stunning beaches, clear waters, and historic
            landmarks. Popular beaches include Nungwi Beach, Kendwa Beach, and
            Paje Beach.
            <br />
            <br />
            Essaouira, Morocco - Essaouira is a beautiful coastal city in
            Morocco with many stunning beaches, clear waters, and historic
            landmarks. Popular beaches include Essaouira Beach, Sidi Kaouki
            Beach, and Moulay Bouzerktoun Beach.
            <br />
            <br />
            Dakar, Senegal - Dakar is a beautiful coastal city in Senegal with
            many beautiful beaches, clear waters, and historic landmarks.
            Popular beaches include Yoff Beach, N'Gor Island Beach, and Virage
            Beach.
            <br /> <br />
            <h4>
              Relax, unwind, and let the waves wash your worries away!!! .
            </h4>
          </>
        ),
        rev: false,
        sectionimage: beaches4,
      },
    ],
  ],
  africa: [
    {
      heading: (
        <>
          <h1>Travel Freely, Travel Adventurously</h1>
          <h1> Welcome to your one-stop destination for a </h1>{" "}
          <h1>journey that will change your life.</h1>
        </>
      ),
      banner: africabanner,
    },
    [
      {
        title: `Embrace the unknown and watch your life unfold into an adventure: Through an Adventurous Journey…`,
        para: (
          <>
            There are many amazing safari locations in Africa. You have a lot of
            options, from Jinja in Uganda, Down to the Garden Route in South
            Africa, and then back up through Swakopmund in Namibia.
            <br /> <br /> One of those experiences that everyone must have at
            least once in their lifetime is an African safari in Tanzania,
            Zimbabwe, and Botswana.
            <br /> <br />
            <h4> Make this Adventurous yours!!</h4>
          </>
        ),
        rev: true,
        sectionimage: africa1,
      },
      {
        title: `
        Step Back in Time and Discover the Ancients' Marvels…`,
        para: (
          <>
            Africa's ancient civilizations have left behind remarkable historic
            sites over the centuries. Africa is home to some incredible historic
            sites that are regrettably all too frequently disregarded, including
            ancient pyramids, centuries-old rock-hewn churches, castles,
            mosques, and palaces.
            <br /> <br />
            Without Egypt, Ethiopia, Ghana, Mali, Senegal, Sudan, and Tunisia no
            list of amazing historical places in Africa will ever be complete
            <br /> <br />
            <h4> Starting with the oldest, let's move forward!!</h4>
          </>
        ),
        rev: false,
        sectionimage: africa2,
      },
      {
        title: `Join Wanderspot on a food tour of the world's flavors.        `,
        para: (
          <>
            Africa is experiencing far of an able-to-be-consumed renaissance,
            with established and modern flavors joining in a delicious fusion.
            Africa has a lot to offer to foodies on their travels.
            <br /> <br /> There are the top 6 countries in Africa, and in each
            country, you will find outstanding restaurants providing incredible
            food..
            <br /> <br />
            Starting with a recent best restaurant. Lusaka, (Zambia) Marrakesh,
            (Morocco) Kigali, (Rwanda) Gondar, (Ethiopia) Stone Town, (Zanzibar)
            and Chamarel, (Mauritius).
            <br /> <br />
            <h4>Enjoy the flavor one bite at a time!!</h4>
          </>
        ),
        rev: true,
        sectionimage: africa3,
      },
      {
        title: `From Africa with Love: The Most Enchanting Romantic Getaways…
        `,
        para: (
          <>
            Searching for romantic places to visit in Africa? All around Africa,
            you may find some of the most beautiful and perfect places, perfect
            for a proposal or a honeymoon.
            <br /> <br />
            The best part is that despite the fact that each of these places is
            in Africa, they are all reasonably priced, leave you with
            unforgettable memories, and are all accessible.
            <br /> <br />
            Here are the top 6 most romantic spots for a truly unforgettable
            proposal or honeymoon on the continent.
            <br /> <br />
            Starting with the perfect destinations, Okavango Delta (Botswana),
            Masai Mara (Kenya), Nosey Be (Madagascar), Victoria Falls (Zambia),
            Mahe Island (Seychelles), and Maloti (Drakensberg Mou).
            <br /> <br />
            <h4>
              {" "}
              Discover the hidden gems of the loveliest place in the world!
            </h4>
          </>
        ),
        rev: false,
        sectionimage: africa4,
      },
    ],
  ],
  asia: [
    {
      heading: (
        <>
          <h1> Travel beyond borders and </h1> <h1>experience </h1>{" "}
          <h1> the beauty of diversity!!!</h1>
        </>
      ),
      banner: asiabanner,
    },
    [
      {
        title: `
        Explore the world with us and make memories to last a lifetime….  `,
        para: (
          <>
            Maldives - This island nation in the Indian Ocean is famous for its
            white sandy beaches, turquoise waters, and luxurious overwater
            bungalows. Whether you want to go scuba diving, relax on the beach,
            or indulge in a spa treatment, the Maldives is the perfect
            destination for a romantic getaway.
            <br /> <br />
            Kuala Lumpur, Malaysia - This vibrant city is a melting pot of
            cultures and offers a mix of old-world charm and modern amenities.
            Visit the iconic Petronas Twin Towers, explore the historic Batu
            Caves, or shop for souvenirs at the bustling street markets.
            <br /> <br />
            Singapore - This island city-state is a global hub of finance,
            commerce, and culture. Visit the stunning Gardens by the Bay,
            explore the vibrant neighborhoods of Chinatown and Little India, or
            indulge in some delicious street food at the hawker centers.
            <br /> <br />
            <h4>Find your paradise in a world of adventure and discovery…</h4>
          </>
        ),
        rev: true,
        sectionimage: asia1,
      },
      {
        title: `
          Journey to new horizons and discover your true self!!! `,
        para: (
          <>
            Ayutthaya, Thailand - This ancient city was once the capital of the
            Ayutthaya Kingdom and is now a UNESCO World Heritage Site. It is
            known for its impressive temples and historical ruins.
            <br /> <br />
            Borobudur, Indonesia - This ancient Buddhist temple complex is
            located in central Java and is one of the largest and most
            impressive in the world.
            <br /> <br />
            Angkor Wat, Cambodia - One of the most famous and impressive
            archaeological sites in the world, Angkor Wat is a massive temple
            complex built in the 12th century.
            <br /> <br />
            <h4>Asia’s remarkable historical places…</h4>
          </>
        ),
        rev: false,
        sectionimage: asia2,
      },
      {
        title: `
        Explore Asia's food scene with Wanderspot… `,
        para: (
          <>
            Bangkok, Thailand: Bangkok is known for its street food, which is
            some of the best in the world. Be sure to check out places like Or
            Tor Kor Market, Chatuchak Weekend Market, and Yaowarat Road for some
            delicious Thai food.
            <br /> <br />
            Singapore: Singapore is a melting pot of different cultures, so
            you'll find a wide variety of cuisines here. Some must-try dishes
            include laksa, chili crab, and Hainanese chicken rice.
            <br /> <br />
            Taipei, Taiwan: Taipei is known for its night markets, which are
            filled with street food vendors selling everything from stinky tofu
            to bubble tea. Some of the most popular night markets include Shilin
            Night Market and Raohe Street Night Market.
            <br /> <br />
            Hanoi, Vietnam: Hanoi is known for its pho, which is a noodle soup
            that is a staple of Vietnamese cuisine. Some popular spots to try
            include Pho Gia Truyen and Pho Thin.
            <br /> <br />
            <h4>
              Discover new cultures and make new friends along the way!!! …
            </h4>
          </>
        ),
        rev: true,
        sectionimage: asia3,
      },
      {
        title: `
        The gems of Asia's adventure...`,
        para: (
          <>
            Mount Kinabalu, Malaysia: Mount Kinabalu is the highest peak in
            Malaysia and is a popular destination for climbers. The climb is
            challenging but rewarding, with stunning views of the surrounding
            landscape.
            <br /> <br />
            Bali, Indonesia: Bali is known for its beautiful beaches and the
            world-class surfing. There are also many other activities to enjoy
            here, including white water rafting, volcano trekking, and
            paragliding.
            <br /> <br />
            Ha Long Bay, Vietnam: This UNESCO World Heritage site is known for
            its towering limestone cliffs and clear blue waters. There are many
            activities to enjoy here, including kayaking, rock climbing, and
            scuba diving.
            <br /> <br />
            <h4>Discover your next dream destination with wanderspots!!!</h4>
          </>
        ),
        rev: false,
        sectionimage: asia4,
      },
    ],
  ],
  europe: [
    {
      heading: (
        <>
          <h1> Discover the Beauty of Europe</h1>{" "}
          <h1>A Journey Through History, </h1> <h1>, Culture, and Cuisine!!</h1>
        </>
      ),
      banner: europebanner,
    },
    [
      {
        title: `Echoes of the Past: Exploring Europe's Cultural Gems `,
        para: (
          <>
            The history of Europe is filled with fantastical tales and
            narratives that even the most inventive writers might have
            concocted: fallen empires, invading tribes, paganism, and strong
            monarchs <br /> <br /> Discover it for yourself by visiting the
            locations where it was created! Here are the top six historical and
            cultural destinations, Rome (Italy), Paris (France), Barcelona
            (Spain), Munich (Germany), and London (England).
            <br /> <br />
            <h4>
              {" "}
              Discover the Treasures of Europe's Rich Heritage and Timeless
              Beauty!!
            </h4>
          </>
        ),
        rev: true,
        sectionimage: europe1,
      },
      {
        title: `Enchanting Escapes: Discovering the Most Beautiful Places in Europe
        `,
        para: (
          <>
            There is no denying that Europe has many beautiful areas to explore.
            There are countless beautiful sites to discover, including luxurious
            cities, picture-perfect towns,
            <br /> <br /> huge mountain ranges, and beautiful beaches. Here are
            7 beautiful places, Greece, Norway, Portugal, Ireland, Netherlands,
            Germany, and Scotland.
            <br /> <br />
            <h4>Discover the beauty that Europe beholds!!</h4>
          </>
        ),
        rev: false,
        sectionimage: europe2,
      },
      {
        title: `Island-hopping Adventure: Discovering Europe's Most Famous Isles
        `,
        para: (
          <>
            Even though Europe is one of the smallest continents in the world,
            it has one of the richest cultural and historical legacies. So, a
            summertime trip around Europe is an adventure that may include a
            wide range of activities, such as swimming and relaxing at the beach
            <br /> <br /> Discover it for yourself by visiting the beach , going
            out at night, as well as visiting countless historical sites, and
            getting to know the locals. Here's a list of the 5 most attractive
            islands, Capri, Corsica, Crete, Hvar, and Ibiza where you can spend
            a holiday filled with color.
            <br /> <br />
            <h4>Let's discover the beauty of famous Islands!!</h4>
          </>
        ),
        rev: true,
        sectionimage: europe3,
      },
      {
        title: `Feast Your Senses: Indulging in the Best Eateries Across Europe   `,
        para: (
          <>
            Europe is known around the world for its unique urban environments
            and charming architectural wonders. The walled structures, historic
            castles, and cobblestone streets that line the streets of European
            cities serve as magnets for a large crowd of visitors each year.
            <br /> <br /> Here are some restaurants, Michelin star, Noma, and
            Olo where many people might consider stewed beef and Hungarian
            potato dishes, cabbage meat buns, or Polish dumplings.
            <br /> <br />
            <h4>Explore the complete delicious food guide!!</h4>
          </>
        ),
        rev: false,
        sectionimage: europe4,
      },
    ],
  ],
  northamerica: [
    {
      heading: (
        <>
          <h1>Embrace the spirit of adventure </h1>{" "}
          <h1> and create unforgettable </h1>{" "}
          <h1> memories in North America…</h1>
        </>
      ),
      banner: northamericabanner,
    },
    [
      {
        title: `North America is home to a rich history and diverse cultural heritage…
        `,
        para: (
          <>
            Statue of Liberty, New York: A symbol of freedom and democracy, the
            Statue of Liberty is one of the most recognizable landmarks in the
            world.
            <br /> <br /> Ellis Island, New York: Once the gateway for millions
            of immigrants to the United States, Ellis Island is now a museum
            that tells the stories of those who came to America seeking a better
            life.
            <br /> <br />
            Niagara Falls, Ontario: This natural wonder of the world straddles
            the border between Canada and the United States and is a popular
            tourist attraction.
            <br /> <br />
            <h4>Discover yourself in this culture!!</h4>
          </>
        ),
        rev: false,
        sectionimage: northamerica1,
      },
      {
        title: `North America offers numerous romantic destinations for couples…   `,
        para: (
          <>
            Paris, France: Known as the "City of Love," Paris is a romantic
            destination with its charming cafes, beautiful architecture, and
            stunning views from the Eiffel Tower.
            <br /> <br />
            Napa Valley, California: With its scenic vineyards, luxury resorts,
            and world-class restaurants, Napa Valley is a romantic destination
            for wine lovers.
            <br /> <br />
            Maui, Hawaii: This Hawaiian island is known for its beautiful
            beaches, breathtaking sunsets, and romantic atmosphere, making it a
            popular honeymoon destination.
            <br /> <br />
            <h4>Discover the Beauty That Lies Beyond Your Imagination!!</h4>
          </>
        ),
        rev: true,
        sectionimage: northamerica2,
      },
      {
        title: `Paradise Found: The Best Beaches for Your Ultimate Escape
        `,
        para: (
          <>
            Siesta Beach, Florida: Located in Sarasota, Florida, Siesta Beach is
            known for its crystal-clear waters, powdery white sand, and stunning
            sunsets.
            <br /> <br /> Kaanapali Beach, Hawaii: This Maui beach is known for
            its clear turquoise water, soft sand, and stunning views of the
            Pacific Ocean.
            <br /> <br />
            Coronado Beach, California: Located near San Diego, this beach is
            famous for its wide, sandy beaches, gentle surf, and iconic Hotel
            del Coronado.
            <br /> <br />
            <h4>
              Let Your Worries Drift Away by Exploring Our Coastal Paradise!!
            </h4>
          </>
        ),
        rev: false,
        sectionimage: northamerica3,
      },
      {
        title: `The  places where each bite is a step toward culinary bliss
        `,
        para: (
          <>
            New Orleans, Louisiana: Known for its spicy Creole and Cajun
            cuisine, New Orleans is a foodie's paradise with classic dishes like
            gumbo, jambalaya, and beignets.
            <br /> <br /> Montreal, Quebec: This Canadian city is known for its
            delicious bagels, poutine, and smoked meat sandwiches, among other
            culinary delights.
            <br /> <br />
            Oaxaca, Mexico: This southern Mexican city is known for its rich,
            flavorful mole sauce and other traditional dishes like tlayudas and
            quesillo.
            <br /> <br />
            Portland, Oregon: With its innovative food scene, Portland has
            become a foodie destination, with a variety of food carts,
            restaurants, and breweries to explore.
            <br /> <br />
            <h4>
              Eat, Savor, Repeat: Embark on a Culinary Adventure with Us!!
            </h4>
          </>
        ),
        rev: true,
        sectionimage: northamerica4,
      },
    ],
  ],
  middleeast: [
    {
      heading: (
        <>
          <h1> Discover the magic of the Middle East </h1>{" "}
          <h1> A journey through ancient </h1>{" "}
          <h1>history and modern marvels!!!</h1>
        </>
      ),
      banner: middleeastbanner,
    },
    [
      {
        title: `Journeys through Time: Discovering the Riches of Our Heritage

        `,
        para: (
          <>
            In the Middle East, paradoxes exist. The area, which sits at the
            nexus of east and west culture, is currently known for all the
            different reasons, but it was also one of the major cradles of human
            civilization.
            <br /> <br />
            These are the most gorgeous places in the Middle East,
            Istanbul(Turkey), Jerusalem(Israel), Petra(Jordan), Islamic
            Cairo(Egypt), and Byblos (Lebanon) spanning from the depths of
            antiquity to the futuristic skyscrapers of the modern Gulf. .
            <br /> <br />
            <h4>Discover yourself in this culture!!</h4>
          </>
        ),
        rev: false,
        sectionimage: middleeast1,
      },
      {
        title: `Nature's Masterpieces: Breathtaking Scenes That Will Leave You Speechless

        `,
        para: (
          <>
            These are 5 stunning locations to visit in the Middle East like
            Dubai, Dhoa, Petra, Cairo, and Manama for an unforgettable vacation,
            yet there are some specific areas where people can genuinely indulge
            their love.
            <br /> <br /> These locations offer contemporary conveniences and
            interests placed within an excellent and beautiful backdrop. There
            are many places in the middle east where you can visit historic
            sites while still having time to enjoy the sun, surf, the beach, or
            a cocktail by the pool at a five-star hotel.
            <br /> <br />
            <h4>Discover the Beauty That Lies Beyond Your Imagination!!</h4>
          </>
        ),
        rev: true,
        sectionimage: middleeast2,
      },
      {
        title: `Paradise Found: The Best Beaches for Your Ultimate Escape


        `,
        para: (
          <>
            Imagine yourself sipping a cold glass of lemonade while suppressing
            a hammock on a beach with the sunlight bright dislike very strongly
            you and a simple breeze murmuring through your hair
            <br /> <br /> Throw away all your anxiety and relax. The Middle East
            offers marvelous types of beaches like Al Kabir Beach, Al Mughsail
            Beach, Cleopatra Beach, and the Dead Sea that’ll leave you wanting
            more.
            <br /> <br />
            <h4>
              Let Your Worries Drift Away by Exploring Our Coastal Paradise!!
            </h4>
          </>
        ),
        rev: false,
        sectionimage: middleeast3,
      },
      {
        title: `The  places where each bite is a step toward culinary bliss


        `,
        para: (
          <>
            Middle East cuisine is known for its bold and flavorful dishes that
            are rich in spices and herbs which are influenced by various regions
            including the Mediterranean, North Africa, and the Arabian
            Peninsula, Middle Eastern food offers a diverse range of flavors and
            textures
            <br /> <br /> From the tangy taste of hummus and tabbouleh to the
            savory aroma of shawarma and falafel, Middle Eastern cuisine is a
            delicious and vibrant part of the global food scene.
            <br /> <br />
            <h4>
              Eat, Savor, Repeat: Embark on a Culinary Adventure with Us!!
            </h4>
          </>
        ),
        rev: true,
        sectionimage: middleeast4,
      },
    ],
  ],
};
