import React from "react";
import "./blog.css";
import Blog from "./blog-components/Blog";
import Info from "./blog-components/Info";
import { data } from "./data";
import { useParams } from "react-router-dom";

const MainBlog = () => {
  const { country } = useParams();

  return (
    <>
      <div className="mountain">
        <Blog data={data[country][0]} />
        {data[country][1].map((obj, i) => {
          return <Info data={obj} key={i} />;
        })}
        <div className="blog-summary">
          <p>{data ? data[country][0]?.summary : ""}</p>
        </div>
      </div>
    </>
  );
};

export default MainBlog;
