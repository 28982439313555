import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./form.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Form = () => {
  const toastOptions = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: true,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "dark",
  };
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [contact, setContact] = useState();
  const [dest, setDest] = useState();
  const [date, setDate] = useState();
  const [trip, setTrip] = useState();
  const submitHandler = async (event) => {
    event.preventDefault();
    var data = {
      Name: name,
      Email: email,
      Contact: contact,
      Trip: trip,
      Date: date,
      Destination: dest,
    };
    // axios.post("http://localhost:4000/tripfinder", data, {
    //   headers: { "Content-Type": "application/json" },
    // });
    // .then(async (res) => {
    let URL = "https://getform.io/f/a681739a-c6e7-47a8-8304-500339c303a4";
    await axios.post(URL, data, {
      headers: { Accept: "application/json" },
    });
    toast.success("Data Submitted successfully", toastOptions);
    console.log(data);
    // });
  };
  const destination = [
    "Kenya",
    "Seychelles",
    "South Africa",
    "Tanzania",
    "Armenia",
    "Azerbaijan",
    "Indonesia",
    "Malaysia",
    "Maldives",
    "Singapore",
    "Sri Lanka",
    "Austria",
    "Finland",
    "France",
    "Georgia",
    "Germany",
    "Greece",
    "Hungary",
    "Italy",
    "Netherlands",
    "Norway",
    "Sweden",
    "Spain",
    "Switzerland",
    "United Kingdom",
    "Dubai",
    "Jordan",
    "Turkey",
    "United States of America",
  ];
  const tripType = [
    "All Types of Trip",
    "Boat trips",
    "Career breaks and BIG trips",
    "Cycling & mountain biking",
    "Diving & snorkelling",
    "Family travel",
    "Festivals and music",
    "Frontier and expedition",
    "History & Heritage",
    "Honeymoons",
    "Horse riding",
    "Learning trips",
    "Meet the locals",
    "Outdoor Activities",
    "Rail journeys",
    "Short breaks",
    "Solo travel",
    "Travel icons",
    "Visiting the Poles",
    "Volunteer and conservation",
    "Wildlife and safaris",
    "Walking and trekking",
  ];
  tripType.sort();
  destination.sort();
  return (
    <>
      <form className="form-cantainer" onSubmit={submitHandler} method="POST">
        <h3>Find your Ultimate Trip </h3>
        <div className="select-container">
          <div>
            <label htmlFor="">Destination</label>
            <select
              name="dest"
              id=""
              className="selection"
              onChange={(e) => setDest(e.target.value)}
            >
              {destination.map((event) => {
                return (
                  <>
                    <option value={event}>{event}</option>
                  </>
                );
              })}
            </select>
          </div>
          <div>
            <label htmlFor="">Departure Date</label>
            <input
              type="date"
              name="date"
              required
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="">Trip Type</label>
            <select
              name="trip"
              id=""
              className="selection"
              onChange={(e) => setTrip(e.target.value)}
            >
              {tripType.map((event) => {
                return (
                  <>
                    <option value={event}>{event}</option>
                  </>
                );
              })}
            </select>
          </div>
        </div>
        <div className="input-field">
          <div>
            <label htmlFor=""> Enter your name </label>
            <input
              type="text"
              placeholder="Full name "
              name="name"
              required
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="">Enter your Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="contact">
            <label htmlFor="">Contact No.</label>
            <input
              type="number"
              name="contact"
              placeholder="Contact no."
              required
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
        </div>
        <button type="submit"> Inspire me</button>
      </form>
    </>
  );
};
export default Form;
