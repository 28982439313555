const Experience = [
    {
      summary: ` I stumbled upon this blog and it has completely changed the way I travel. The
      articles are insightful and packed with useful tips, and the stunning photography
      always leaves me in awe. Thanks to them, I've discovered hidden gems I never
      would have found on my own. Highly recommended!`,
      author: "Salim Sheikh",
      star: "4",
    },
    {
      summary: `
       Their articles are not only informative but also engaging and inspiring, making me want
        to pack my bags and explore the world. I'm grateful for their expertise and highly
         recommend their services to anyone looking for travel inspiration.
       `,
       author:"Wasim Wahid",
       star:"5"
    },
    {
     summary:`I've learned so much about different cultures and destinations through the articles and videos.
      They have a wealth of knowledge and experience and I always come away with new ideas and inspiration
       for my next trip.` ,
       author:"Serif Rashmi",
       star:"4"
    },
    {
      summary :`The team at Wanderspots is incredibly passionate about what they do and it
      shows in every article and post. They have a genuine love for travel and a desire
      to share it with others. I always look forward to their latest updates and
      recommendations.`,
      author:"Khalid al-mulhim",
      star:"5"
    },
    {
      summary :`Wandorspots has been my go-to source of travel inspiration for years. Their
      beautifully written articles and stunning photography always make me feel like I'm
      right there with them, experiencing the destinations firsthand`,
      author:"Moddaz al-suliman",
      star:"4"
    },
  ];
  export default Experience;