import React from "react";
import "./sunshyamresort.css";
import vid from "../../Images/sunshyam/firepit.mp4";
import { Link } from "react-router-dom";

const Sunshyamresort = () => {
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  return (
    <div className="sunresortcontainer">
      <div className="sunresortheading">
        <h1>Sun Siyam Resorts</h1>
      </div>
      <div className="sun-resort">
        <div className="sunresort1">
          <video src={vid} autoPlay muted loop></video>
        </div>
        <div className="sunresort2">
          <img
            src="https://images.unsplash.com/photo-1591074688601-9fca7714536a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80"
            alt="resort picture"
          />
          <Link
            to={"/travelogue/sunsiyam-resort"}
            onClick={() => {
              window.scrollTo(0, 0);
              scrolltotop();
            }}
          >
            <button>Explore</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sunshyamresort;
