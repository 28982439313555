import React from "react";
import "./discover.css";
import Info from "../blogs/blog-components/Info";
import discoverData from "./Data/discoverData";
import DiscoverCards from "./Countries/DiscoverCards";
//C:\Users\ftt\Desktop\Wanderspots-Features\wandersports\src\components\blogs\blog-components\Info.jsx
export default function DiscoverPage() {
  return (
    <div className="discover-container">
      <div className="discover-banner">
        <h1>Explore the World with Our Exclusive Tour Packages. </h1>
      </div>
      <div className="content">
        <p>
          Our tour packages are specially curated to offer you the best travel
          experience possible. We take pride in our attention to detail and our
          commitment to ensuring that every aspect of your trip is taken care
          of. Whether you're looking for adventure, relaxation, or cultural
          immersion, we have a package that will suit your needs. From the
          stunning beaches of Bali to the majestic mountains of Switzerland, our
          tour packages cover some of the most beautiful destinations in the
          world. Our experienced tour guides will take you on a journey that you
          will never forget, offering insights into the local culture and
          history along the way. So, why wait? Explore the world with us and
          create unforgettable memories that will last a lifetime. Browse
          through our packages and find the perfect one for you today!
        </p>
      </div>
      <div className="discover-country">
        <DiscoverCards />
      </div>
    </div>
  );
}
