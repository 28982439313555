import React from "react";
import { Link } from "react-router-dom";
import discoverData from "../Data/discoverData";
export default function DiscoverCards() {
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  return (
    <div className="countries">
      {discoverData.map((data) => {
        return (
          <div className={`card-map ${data.rev && "reverse"}`}>
            <div className="countries-image">
              <img src={data.bannerImage} alt="" />
            </div>
            <div className="countries-content">
              <h3>{data.heading}</h3>
              <p>{data.content}</p>
              <Link
                to={`/country/${data.url}`}
                onClick={() => {
                  window.scrollTo(0, 0);
                  scrolltotop();
                }}>
                Read More
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
}
