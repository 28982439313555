import { HashRouter as Router, Routes, Route } from "react-router-dom";
import NewHomePage from "./components/Home/NewHomePage";
import Destination from "./components/destination-page/Destination";
import InterestPage from "./components/Interest/InterestPage";
import Event from "./components/event/Event";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import TripFinder from "./components/trip-finder/NewTripFinder";
import TourGuide from "./components/tour-guide/TourGuide";
import MainBlog from "./components/blogs/MainBlog";
import Terms from "./components/footer/terms&conditions/Terms";
import Error from "./components/error page/Error";
import MainBlogPage from "./components/MainBlogPage/Categories";
import Privacy from "./components/footer/privacy/Privacy";
import React from "react";
import { ToastContainer } from "react-toastify";
import Contactus from "./components/contact us/Contactus";
import Faq from "./components/footer/FAQ/FAQ/Faq";
import Popup from "./components/popup/Popup";
import Sunshyamresortblog from "./components/blogs/sunshyamresortblog/Sunshyamresortblog";
import DiscoverPage from "./components/Discover/DiscoverPage";
import Africa from "../src/components/Discover/Countries-Pages/Africa"
function App() {
  
  return (
    <div className="App">
      <Router>
        <Popup />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<NewHomePage />} />
          <Route exact path="/travelogue" element={<MainBlogPage />} />
          <Route exact path="/travelogue/:country" element={<MainBlog />} />
          <Route
            exact
            path="/travelogue/sunsiyam-resort"
            element={<Sunshyamresortblog />}
          />
          <Route exact path="/tourguide/:tour" element={<TourGuide />} />
          <Route exact path="/destination" element={<Destination />} />
          <Route exact path="/interest" element={<InterestPage />} />
          <Route exact path="/event" element={<Event />} />
          <Route exact path="/tripplanner" element={<TripFinder />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/contactus" element={<Contactus />} />
          <Route exact path="*" element={<Error />} />
          <Route  exact path="/discover" element={<DiscoverPage/>}/>
          <Route exact path="/country/:conts" element={ <Africa/> } />
        </Routes>
        <Footer />
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
