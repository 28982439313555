import React, { useState } from "react";
import "./newHome.css";
import TopDestination from "./TopDestination";
import WorkIcon from "@mui/icons-material/Work";
import PeopleIcon from "@mui/icons-material/People";
import TourCard from "./TourCard";
import Image2 from "./CountriesImage/3.jpeg";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import CarouselExperience from "./HomeCard";
import Carousel from "../simpleSlider/newSlider";
import vid from "../../Images/motion.mp4";
import travelVid from "../../Images/travelVid.mp4";
import reviews from "./clientReviews";
import { data } from "../blogs/data";
import Rating from "@mui/material/Rating";
import Experience from "./clientReviews";
import Sunshyamresort from "../sunshyamresort/Sunshyamresort";
// import footerVid from "../../Images/footerVid.mp4";

// Section 2 Image
import DubaiImage from "./HomeImage/Section-2-Dubai.jpeg";
import MalasiyaImage from "./HomeImage/Section-2-Malasiya.jpeg";
import MaldivesImage from "./HomeImage/Section-2-Maldives.jpeg";
import ThailandImage from "./HomeImage/Section-2-Singapore.jpeg";
import SingaporeImage from "./HomeImage/Section-2-Thailand.jpeg";
// Section 4 image
import DubaiTourPage from "./HomeImage/Section-4-Dubai.jpeg";
import FranceTourPage from "./HomeImage/Section-4-France.jpeg";
import MalaysiaTourPage from "./HomeImage/Section-4-Malasiya.jpeg";
import MaldivesTourPage from "./HomeImage/Section-4-Maldives.jpeg";
import SpainTourPage from "./HomeImage/Section-4-Dubai.jpeg";
import ThailandTourPage from "./HomeImage/Section-4-Thailand.jpeg";
import SwitzerlandTourPage from "./HomeImage/Section-4-Switzerland.jpeg";
import SingaporeTourPage from "./HomeImage/Section-4-Singapore.jpeg";
//Section 5 Image
import Section5Image from "./HomeImage/Section-5.jpeg";
import InterestCard from "../Interest/InterestCard";

import { data as interestImports } from "../Interest/interestImports";
import Interestinfo from "../Interest/Interestinfo";

import Cards from "../Home/Cards/Cards";
import NewCarousel from "../newCarousel/NewCarousel";
import HomeCarousel from "./homecarousel/HomeCarousel";

export default function NewHomePage() {

  const [email, setEmail] = useState();
  // console.log(email);
  const handleClick = async (e) => {
    e.preventDefault();
    var URL = "http://localhost:4000/subscribe";
    var option = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    };
    await fetch(URL, option)
      .then(() => {
        alert("Subscribed ");
        setEmail("");
      })
      .catch(function () {
        alert("Failed to Subscribe");
      });
  };

  const MyCard = (props) => {
    return (
      <>
        <div className="rating-card">
          <p> {props.summary} </p>
          <Rating value={props.star} />
          <h3> {props.author} </h3>
        </div>
      </>
    );
  };
  let keys = Object.keys(data);
  return (
    <div>
    
      <HomeCarousel />

      <Sunshyamresort />
      <Cards />
      <div className="best-destination">
        <div className="destination-countries">
          <div className="destination-title">
            <h1>Unlock Wonders of the World</h1>
            <p>
              Showcasing the most incredible places in the world that are sure
              to leave you breathless. From the vibrant cityscapes of Tokyo and
              <br />
              New York to the pristine beaches of the Maldives and Bali, we have
              scoured the globe to bring you the very best in travel. Whether is
              <br />
              you're seeking adventure, culture, or simply a place to relax and
              unwind, we have something for everyone. Browse our collection of
              <br />
              destinations and get ready to start on the journey of a lifetime.
            </p>
          </div>
          <div className="destination-images">
            <TopDestination
              Image={DubaiImage}
              countries="Dubai"
              URL="/travelogue/dubai-a-dream-destination"
            />
            <TopDestination
              Image={MalasiyaImage}
              countries="Malaysia"
              URL="/travelogue/best-destination-to-visit-in-malasiya"
            />
            <TopDestination
              Image={MaldivesImage}
              countries="Maldives"
              URL="/travelogue/10-most-visited-places-in-maldives"
            />
            <TopDestination
              Image={SingaporeImage}
              countries="Thailand"
              URL="/travelogue/best-attractive-beaches-in-thailand"
            />
            <TopDestination
              Image={ThailandImage}
              countries="Singapore"
              URL="/travelogue/most-visited-places-in-songapore"
            />
          </div>
        </div>
      </div>
      <div className="start-trip">
        <div className="trip-text">
          <div className="heading">
            <h2> Travel with Wanderspots!!</h2>
          </div>
          <div className="option-1">
            <div className="icons">
              <CardTravelIcon color="primary" />
            </div>
            <div className="option-text">
              <h4>Expertise and Experience</h4>
              <p>
                We have personally visited and vetted every destination that we
                feature on our blog, ensuring that we provide our readers with
                accurate and reliable information.
              </p>
            </div>
          </div>
          <div className="option-1">
            <div className="icons">
              <WorkIcon color="success" />
            </div>
            <div className="option-text">
              <h4>Inspiration and Creativity </h4>
              <p>
                Our blog is filled with unique and creative content that goes
                beyond the typical travel guide. We share our personal stories,
                photos, and videos to give you a taste of what each destination
                has to offer.
              </p>
            </div>
          </div>
          <div className="option-1">
            <div className="icons">
              <PeopleIcon />
            </div>
            <div className="option-text">
              <h4>Community and Engagement </h4>
              <p>
                We encourage our readers to share their own travel experiences
                and tips, and we actively engage with our audience through
                social media and comments on our blog
              </p>
            </div>
          </div>
        </div>
       
        <div className="trip-vid">
          <video src={travelVid} autoPlay loop muted></video>
        </div>
      </div>
      {/* interest card */}
      <div className="top-tour">
        <div className="tour-text">
          <h1>
            Best of the best tours, attractions, and activities <br /> you won’t
            want to miss!
          </h1>
          <br />
          <p>
            Top destinations for your next holiday, Here's where your fellow
            travelers are headed…
          </p>
        </div>
        <div className="tour-container">
          <TourCard
            URL={`/travelogue/${keys.at(0)}`}
            Image={DubaiTourPage}
            Title="Dubai "
            Description="Where Luxury and Ambition Meet"
          />
          <TourCard
            URL={`/travelogue/${keys.at(1)}`}
            Image={MaldivesTourPage}
            Title="Maldives"
            Description="Escape to a Tropical Wonderland"
          />
          <TourCard
            URL={`/travelogue/${keys.at(2)}`}
            Image={MalaysiaTourPage}
            Title="Malaysia"
            Description="A Fusion of Culture and Nature"
          />
          <TourCard
            URL={`/travelogue/${keys.at(3)}`}
            Image={SingaporeTourPage}
            Title="Singapore"
            Description="A City of Spectacular Contrasts"
          />
          <TourCard
            URL={`/travelogue/${keys.at(4)}`}
            Image={ThailandTourPage}
            Title="Thailand"
            Description="The Land of beautiful Smiles"
          />
          <TourCard
            URL={`/travelogue/${keys.at(5)}`}
            Image={SwitzerlandTourPage}
            Title="Switzerland"
            Description="A Country of Timeless Beauty"
          />
          <TourCard
            URL={`/travelogue/${keys.at(6)}`}
            Image={FranceTourPage}
            Title="France"
            Description=" A Country of Elegance and Romance"
          />
          <TourCard
            URL={`/travelogue/${keys.at(7)}`}
            Image={SpainTourPage}
            Title="Spain"
            Description="The Land of Sun and Sangria"
          />
        </div>
      </div>
      <h1 className="keepexploring">Keep Exploring</h1>
      <div className="interest-cards-cointainer">
        <InterestCard data={interestImports[4]} />
        <Interestinfo data={interestImports[4]} link={"tourguide/beaches"} />
      </div>
      <div className="svis-tour">
        <h1>Experience the world in a new way with Wanderspots!</h1>
        <div className="svis">
          <div className="svis-img">
            <img src={Section5Image} alt="" />
          </div>
          <div className="svis-text">
            As world citizens, we want everyone to experience the wonders of
            traveling. There’s so much out there that’s worth discovering and
            it’s our mission to make sure you get top-of-the-line experiences
            wherever your journey takes you. Don’t let any place go unexplored;
            join us in this exhilarating adventure around the globe!
          </div>
        </div>
      </div>
      <br /> <br />
      <h1 className="clientsays">What Our Client Say</h1>
      <div className="feedback">
        <NewCarousel data={CarouselExperience} />
      </div>
    </div>
  );
}
