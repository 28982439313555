import React from "react";
import Rating from "@mui/material/Rating";
import Qoutes from "../../Images/Qoutes.png";
import reviews from "./clientReviews";

const CarouselExperience = reviews.map((e) => {
  return (
    <div className="reviewcard">
      <div className="quoteimg">
        <img src={Qoutes} />
      </div>
      <p>{e.summary}</p>
      <Rating value={e.star} readOnly />
      <div className="reviewername">
        <h5>{e.author}</h5>
      </div>
    </div>
  );
});

export default CarouselExperience;
