import React, { useState, useEffect } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import mainLogo from "../../Images/mainLogo.png";
import navimg from "../../Images/footerlogo.png";

export default function Navbar() {
  const [nav, setNav] = useState(false);
  const [change, setChange] = useState(false);
  const [drop, setdrop] = useState(false);

  const changeBackground = () => {
    if (window.scrollY < 10) {
      setChange(false);
    }
    if (window.scrollY >= 66) {
      setChange(true);
    } else {
      setChange(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  return (
    <>
      <div className="navtopimg">
        <img src={navimg}></img>
      </div>
      <div className={`navbar-container ${change ? "scroll-color" : ""}`}>
        <Link
          to="/"
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
        >
          <div className="leftlogonavbar">
            <img
              src={mainLogo}
              alt=""
              className="logo"
              style={{ display: change ? "initial" : "none" }}
            />
          </div>
        </Link>
        <div 
          className={`dropdownnav`}
          style={{
            transform: drop
              ? "scaleY(1) translateX(-50%)"
              : "scaleY(0) translateX(-50%)",
          }}
        >
          <ul style={{ opacity: drop ? 1 : 0 }}>
            <li> <a onClick={()=> setdrop(false) }> Bucket list Destinations </a></li>        
                <li> <Link to="/discover" onClick={()=> setdrop(false) }> Bespoke Tours  </Link></li>
          </ul>
        </div>
        <ul>
          <li
            onClick={() => {
              setdrop(!drop);
            }}
          >
            <i class="ri-menu-line dropnavicon"></i>
          </li>
          <li>
            <Link
              to="/"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/destination"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              Destination
            </Link>
          </li>
          <li>
            <Link
              to="/interest"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              Interest
            </Link>
          </li>
          <li>
            <Link
              to="/tripplanner"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              Trip Planner
            </Link>
          </li>
          <li>
            <Link
              to="/event"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              Events
            </Link>
          </li>
          <li>
            <Link
              to="/travelogue"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              Travelogue
            </Link>
          </li>
        </ul>
      </div>
      <i className="ri-menu-line mobile-menu" onClick={() => setNav(!nav)}></i>
      <div className={"mobile-nav"} style={{ display: nav ? "flex" : "none" }}>
        <ul>
          <li>
            <Link
              to="/"
              onClick={() => {
                window.scrollTo(0, 0);
                setNav(!nav);
              }}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/destination"
              onClick={() => {
                window.scrollTo(0, 0);
                setNav(!nav);
              }}
            >
              Destination
            </Link>
          </li>
          <li>
            <Link
              to="/interest"
              onClick={() => {
                window.scrollTo(0, 0);
                setNav(!nav);
              }}
            >
              Interest
            </Link>
          </li>
          <li>
            <Link
              to="/tripplanner"
              onClick={() => {
                window.scrollTo(0, 0);
                setNav(!nav);
              }}
            >
              Trip Planner
            </Link>
          </li>
          <li>
            <Link
              to="/event"
              onClick={() => {
                window.scrollTo(0, 0);
                setNav(!nav);
              }}
            >
              Events
            </Link>
          </li>
          <li>
            <Link
              to="/travelogue"
              onClick={() => {
                window.scrollTo(0, 0);
                setNav(!nav);
              }}
            >
              Travelogue
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
