import Banner from "../../BlogImage/Dubai_Image/Marina8.jpg";
import AfricaImage from "../Images/AfricaImage.webp";
import AsiaImage from "../Images/AsiaImage.jpg";
import NorthImage from "../Images/NorthImage.jpg";
import MiddleImage from "../Images/MiddleEastImage.jpg";
import Europe from "../Images/EuropeImage.jpg";
// Africa Country Image
import kenyaImage from "../Images/Destination/Africa-Kenya.jpeg";
import seychellesImage from "../Images/Destination/Africa-Seychelles.jpeg";
import southImage from "../Images/Destination/Africa-South-Africa.jpeg";
import tanzaniaImage from "../Images/Destination/Africa-Tanzania.jpeg";
// Asia Country Image
import armeniaImage from "../Images/Destination/Asia-Armenia.jpeg";
import indonesiaImage from "../Images/Destination/Asia-Indonesia.jpeg";
import malaysiaImage from "../Images/Destination/Asia-Malaysia.jpeg";
import singaporeImage from "../Images/Destination/Asia-Singapore.jpeg";
import srilankaImage from "../Images/Destination/Asia-SriLanka.jpeg";
import maldivesImage from "../Images/Destination/Asia-Maldives.jpeg";
import thailandImage from "../Images/Destination/Asia-Thailand.jpeg";
import azerImage from "../Images/Destination/Asia-Azerbaijan.jpeg";

//Europe Country Image
import austriaImage from "../Images/Destination/Europe-Austria.jpeg";
import finlandImage from "../Images/Destination/Europe-Finland.jpeg";
import georiaImage from "../Images/Destination/Europe-Georgia.jpeg";
import germanyImage from "../Images/Destination/Europe-Germany.jpeg";
import greeceImage from "../Images/Destination/Europe-Greece.jpeg";
import hungaryImage from "../Images/Destination/Europe-Hungary.jpeg";
import italyImage from "../Images/Destination/Europe-Italy.jpeg";
import switezerImage from "../Images/Destination/Europe-Switzerland.jpeg";
import netherlandImage from "../Images/Destination/Europe-Netherlands.jpeg";
import norwayImage from "../Images/Destination/Europe-Norway.jpeg";
import spainImage from "../Images/Destination/Europe-Spain.jpeg";
import swedenImage from "../Images/Destination/Europe-Sweden.jpeg";
import ukImage from "../Images/Destination/Europe-UnitedKingdom.jpeg";
import franceImage from "../Images/Destination/Europe-France.jpeg";

//Middle East Country Image
import dubaiImage from "../Images/Destination/MiddleEast-Dubai.jpeg";
import jordanImage from "../Images/Destination/MiddleEast-Jordan.jpeg";
import turkeyImage from "../Images/Destination/MiddleEast-Turkey.jpeg";

//North america country  Image
import usaImage from "../Images/Destination/NorthAmerica-UnitedStatesofAmerica.jpeg";
const CountryData = {
  images: [
    {
      head: "Africa",
      africaBanner: AfricaImage,
      africa: AfricaImage,
      europe: Europe,
      middleeast: MiddleImage,
      northamerica: NorthImage,
      asia: AsiaImage,
    },
  ],
  contsName: {
    africa: "Africa",
    asia: "Asia",
    middleeast: "Middle East",
    europe: "Europe",
    northamerica: "North America",
  },
  africa: [
    {
      name: "Kenya",
      content: `Located in East Africa, Kenya is known for its wildlife reserves, national parks, and scenic beaches. The country is home to various ethnic groups and boasts a rich cultural heritage.`,
      img: kenyaImage,
      rev: true,
   package:"kenya",
      night3: (
        <>
          <h3>
            Here is a suggested itinerary for a 3-night 4-day trip to Kenya
          </h3>
          <h4> Arrival in Nairobi</h4>
          <ul>
            <li> Arrive at Jomo Kenyatta International Airport. </li>
            <li> Check into your hotel and take some time to settle in </li>
            <li>
              Visit the Nairobi National Museum, where you can learn about the
              history and culture of Kenya.
            </li>
            <li>
              Take a stroll through the nearby Uhuru Park and enjoy the tranquil
              surroundings.
            </li>
          </ul>
          <h4> Visit the Maasai Mara National Reserve</h4>
          <ul>
            <li> Take a scenic flight to the Maasai Mara National Reserve. </li>
            <li>
              Spend the day on a game drive, exploring the vast savannas and
              observing the diverse wildlife, including lions, elephants,
              giraffes, and zebras.
            </li>
            <li>
              In the evening, relax at your lodge and take in the peaceful
              sounds of the African wilderness.
            </li>
          </ul>
          <h4> Maasai Village Visi</h4>
          <ul>
            <li>
              Visit a Maasai village and learn about the traditional way of life
              of this indigenous people.
            </li>
            <li>
              Watch a traditional dance performance and purchase souvenirs from
              the local market
            </li>
            <li> Return to your lodge for the evening </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li> Spend the morning at leisure before departing Kenya. </li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to go on a hot air
            balloon ride, visit the Amboseli National Park, or go on a beach
            holiday, Kenya offers a wide range of options for travelers.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>
            Here is a suggested itinerary for a 5-night 6-day trip to Kenya
          </h3>
          <h4>Arrival in Nairobi</h4>
          <ul>
            <li>Arrive at Jomo Kenyatta International Airport </li>

            <li>Check into your hotel and take some time to settle in </li>
            <li>
              Visit the Nairobi National Museum, where you can learn about the
              history and culture of Kenya
            </li>
            <li>
              Take a stroll through the nearby Uhuru Park and enjoy the tranquil
              surroundings
            </li>
          </ul>
          <h4> Visit the Maasai Mara National Reserve</h4>
          <ul>
            {" "}
            <li>Take a scenic flight to the Maasai Mara National Reserve</li>
            <li>
              Spend the day on a game drive, exploring the vast savannas and
              observing the diverse wildlife, including lions, elephants,
              giraffes, and zebras.
            </li>
            <li>
              In the evening, relax at your lodge and take in the peaceful
              sounds of the African wilderness.
            </li>
          </ul>
          <h4> Maasai Village Visit</h4>
          <ul>
            <li>
              Visit a Maasai village and learn about the traditional way of life
              of these indigenous people.
            </li>
            <li>
              Watch a traditional dance performance and purchase souvenirs from
              the local market
            </li>
            <li> Return to your lodge for the evening</li>
          </ul>
          <h4> Amboseli National Park</h4>
          <ul>
            <li>
              Take a scenic drive to Amboseli National Park, located at the foot
              of Mount Kilimanjaro.
            </li>
            <li>
              Spend the day on a game drive, observing the elephants and other
              wildlife that call this park home.
            </li>
            <li>
              In the evening, relax at your lodge and enjoy the peaceful
              surroundings.
            </li>
          </ul>
          <h3>Lake Nakuru National Park</h3>
          <ul>
            <li>
              Take a scenic drive to Lake Nakuru National Park, known for its
              stunning pink flamingos and diverse wildlife.
            </li>
            <li>
              Spend the day on a game drive, observing the abundant birdlife and
              other wildlife that call this park home.
            </li>
            <li>
              In the evening, relax at your lodge and take in the peaceful
              sounds of the African wilderness.
            </li>
          </ul>
          <h3> Departure</h3>
          <ul>
            <li>Spend the morning at leisure before departing Kenya.</li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to go on a hot air
            balloon ride, visit the Tsavo National Park, or go on a beach
            holiday, Kenya offers travelers a wide range of options.
          </p>
        </>
      ),
      night6: (
        <>
          <h3>
            Here is a suggested itinerary for a 6-night 7-day trip to Kenya:
          </h3>
          <h4> Arrival in Nairobi</h4>
          <ul>
            <li>Arrive at Jomo Kenyatta International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Visit the Nairobi National Museum, where you can learn about the
              history and culture of Kenya
            </li>
            <li>
              Take a stroll through the nearby Uhuru Park and enjoy the tranquil
              surroundings
            </li>
          </ul>
          <h4>Visit the Maasai Mara National Reserve</h4>
          <ul>
            <li>Take a scenic flight to the Maasai Mara National Reserve</li>
            <li>
              Spend the day on a game drive, exploring the vast savannas and
              observing the diverse wildlife, including lions, elephants,
              giraffes, and zebras.
            </li>
            <li>
              In the evening, relax at your lodge and take in the peaceful
              sounds of the African wilderness.
            </li>
          </ul>
          <h4>Maasai Village Visit</h4>
          <ul>
            <li>
              Visit a Maasai village and learn about the traditional way of life
              of this indigenous people.
            </li>
            <li>
              Watch a traditional dance performance and purchase souvenirs from
              the local market
            </li>
            <li>Return to your lodge for the evening</li>
          </ul>
          <h4>Amboseli National Park</h4>
          <ul>
            <li>
              Take a scenic drive to Amboseli National Park, located at the foot
              of Mount Kilimanjaro.
            </li>
            <li>
              Spend the day on a game drive, observing the elephants and other
              wildlife that call this park home.
            </li>
            <li>
              In the evening, relax at your lodge and enjoy the peaceful
              surroundings.
            </li>
          </ul>
          <h4>Lake Nakuru National Park</h4>
          <ul>
            <li>
              Take a scenic drive to Lake Nakuru National Park, known for its
              stunning pink flamingos and diverse wildlife.
            </li>
            <li>
              Spend the day on a game drive, observing the abundant birdlife and
              other wildlife that call this park home.
            </li>
            <li>
              In the evening, relax at your lodge and take in the peaceful
              sounds of the African wilderness.
            </li>
          </ul>
          <h4>Tsavo West National Park</h4>
          <ul>
            <li>
              Take a scenic drive to Tsavo West National Park, known for its
              diverse landscapes and abundant wildlife.
            </li>
            <li>
              Spend the day on a game drive, observing the diverse wildlife that
              call this park home.
            </li>
            <li>
              In the evening, relax at your lodge and take in the peaceful
              sounds of the African wilderness.
            </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>Spend the morning at leisure before departing Kenya.</li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to go on a hot air
            balloon ride, visit the Samburu National Reserve, or go on a beach
            holiday, Kenya offers a wide range of options for travelers.
          </p>
        </>
      ),
      /**
     * 
This itinerary is just a suggestion and can be modified to suit your interests and preferences. Whether you want to go on a hot air balloon ride, visit the Tsavo National Park, or go on a beach holiday, Kenya offers travelers a wide range of options.

     */
    },
    {
      name: "Seychelles",
      content: ` This small island nation in the Indian Ocean is famous for its pristine beaches, turquoise waters, and abundant marine life. It is a popular tourist destination, and its economy heavily relies on tourism.`,
      img: seychellesImage,
      rev: false,
      package:"seychelles",
      night3: (
        <>
          <p>
            Seychelles is a beautiful island nation located in the Indian Ocean.
            With its pristine beaches, lush tropical forests, and abundant
            marine life, Seychelles is the perfect destination for a relaxing
            and rejuvenating getaway. Here is a suggested itinerary for your
            3-night 4-day trip to Seychelles:
          </p>
          <h4>Arrival and Exploring Victoria</h4>
          <ul>
            <li>Arrive at the Seychelles International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Visit Victoria, the capital of Seychelles and one of the smallest
              capital cities in the world. Take a stroll through the city and
              admire its colonial architecture, visit the Natural History
              Museum, and stop by the bustling markets to pick up some
              souvenirs.
            </li>
          </ul>
          <h4>Beach Day at Beau Vallon</h4>
          <ul>
            <li>
              Spend the day at Beau Vallon, one of the most popular beaches in
              Seychelles. Relax on the soft white sands, swim in the
              crystal-clear waters, and enjoy the vibrant atmosphere of this
              bustling beach.
            </li>
            <li>
              In the evening, take a sunset cruise and watch the sky turn a
              brilliant shade of orange and red as the sun dips below the
              horizon.
            </li>
          </ul>
          <h4> Discover the Island of La Digue</h4>
          <ul>
            <li>
              Take a ferry to La Digue, one of the most picturesque islands in
              Seychelles.
            </li>
            <li>
              Rent a bicycle and explore the island, visiting its charming
              villages, lush coconut groves, and pristine beaches. Don't miss
              the famous Anse Source d'Argent, considered one of the most
              beautiful beaches in the world.
            </li>
          </ul>
          <h4> Departure</h4>
          <ul>
            <li>
              Spend the morning relaxing at your hotel or take one last stroll
              along the beach before departing Seychelles.
            </li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to relax on the beach,
            go snorkeling, or explore the island's rich cultural heritage,
            Seychelles has something for everyone.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>
            Here is a suggested itinerary for a 5-night 6-day trip to
            Seychelles:
          </h3>
          <h4> Arrival and Exploring Victoria</h4>
          <ul>
            <li>Arrive at the Seychelles International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Visit Victoria, the capital of Seychelles and one of the smallest
              capital cities in the world. Take a stroll through the city and
              admire its colonial architecture, visit the Natural History
              Museum, and stop by the bustling markets to pick up some
              souvenirs.
            </li>
          </ul>
          <h4>Praslin Island</h4>
          <ul>
            <li>
              Take a ferry to Praslin Island, one of the largest islands in
              Seychelles.
            </li>
            <li>
              Visit the Vallee de Mai Nature Reserve, a UNESCO World Heritage
              Site and home to the endemic Coco de Mer palm, the largest seed in
              the world.
            </li>
            <li>
              Spend the rest of the day relaxing on the beautiful Anse Lazio
              Beach, known for its turquoise waters and stunning scenery.
            </li>
          </ul>
          <h4>Beach Day at Beau Vallon</h4>
          <ul>
            <li>
              Return to Mahé Island and spend the day at Beau Vallon, one of the
              most popular beaches in Seychelles. Relax on the soft white sands,
              swim in the crystal-clear waters, and enjoy the vibrant atmosphere
              of this bustling beach.
            </li>
            <li>
              In the evening, take a sunset cruise and watch the sky turn a
              brilliant shade of orange and red as the sun dips below the
              horizon.
            </li>
          </ul>
          <h4>Discover the Island of La Digue</h4>
          <ul>
            <li>
              Take a ferry to La Digue, one of the most picturesque islands in
              Seychelles.
            </li>
            <li>
              Rent a bicycle and explore the island, visiting its charming
              villages, lush coconut groves, and pristine beaches. Don't miss
              the famous Anse Source d'Argent, considered one of the most
              beautiful beaches in the world.
            </li>
          </ul>
          <h4> Marine Activities and Relaxation</h4>
          <ul>
            <li>
              Spend the day exploring Seychelles' underwater world by going
              snorkeling or diving. With its vibrant coral reefs and abundant
              marine life, Seychelles is a haven for marine enthusiasts.
            </li>
            <li>
              In the evening, relax at your hotel or take a walk along the
              beach.
            </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Spend the morning relaxing at your hotel or take one last stroll
              along the beach before departing Seychelles.
            </li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to relax on the beach,
            go snorkeling, or explore the island's rich cultural heritage,
            Seychelles has something for everyone.
          </p>
        </>
      ),
      night6: (
        <>
          <h3>
            Here is a suggested itinerary for a 6-night 7-day trip to
            Seychelles:
          </h3>
          <h4> Arrival and Exploring Victoria</h4>
          <ul>
            <li>Arrive at the Seychelles International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Visit Victoria, the capital of Seychelles and one of the smallest
              capital cities in the world. Take a stroll through the city and
              admire its colonial architecture, visit the Natural History
              Museum, and stop by the bustling markets to pick up some
              souvenirs.
            </li>
          </ul>
          <h4> Praslin Island</h4>
          <ul>
            <li>
              Take a ferry to Praslin Island, one of the largest islands in
              Seychelles.
            </li>
            <li>
              Visit the Vallee de Mai Nature Reserve, a UNESCO World Heritage
              Site and home to the endemic Coco de Mer palm, the largest seed in
              the world.
            </li>
            <li>
              Spend the rest of the day relaxing on the beautiful Anse Lazio
              Beach, known for its turquoise waters and stunning scenery.
            </li>
          </ul>
          <h4>Beach Day at Beau Vallon</h4>
          <ul>
            <li>
              Return to Mahé Island and spend the day at Beau Vallon, one of the
              most popular beaches in Seychelles. Relax on the soft white sands,
              swim in the crystal-clear waters, and enjoy the vibrant atmosphere
              of this bustling beach.
            </li>
            <li>
              In the evening, take a sunset cruise and watch the sky turn a
              brilliant shade of orange and red as the sun dips below the
              horizon.
            </li>
          </ul>
          <h4>Discover the Island of La Digue</h4>
          <ul>
            <li>
              Take a ferry to La Digue, one of the most picturesque islands in
              Seychelles.
            </li>
            <li>
              Rent a bicycle and explore the island, visiting its charming
              villages, lush coconut groves, and pristine beaches. Don't miss
              the famous Anse Source d'Argent, considered one of the most
              beautiful beaches in the world.
            </li>
          </ul>
          <h4>Marine Activities and Relaxation</h4>
          <ul>
            <li>
              Spend the day exploring Seychelles' underwater world by going
              snorkeling or diving. With its vibrant coral reefs and abundant
              marine life, Seychelles is a haven for marine enthusiasts.
            </li>
            <li>
              In the evening, relax at your hotel or take a walk along the
              beach.
            </li>
          </ul>
          <h4>Visit Sainte Anne Marine National Park</h4>
          <ul>
            <li>
              Take a boat trip to Sainte Anne Marine National Park, located just
              off the coast of Mahé Island.
            </li>
            <li>
              Enjoy a day of snorkeling, swimming, and exploring the park's
              coral reefs and marine life.
            </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Spend the morning relaxing at your hotel or take one last stroll
              along the beach before departing Seychelles.
            </li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to relax on the beach,
            go snorkeling, or explore the island's rich cultural heritage,
            Seychelles has something for everyone.
          </p>
        </>
      ),
    },
    {
      name: "South Africa",
      content: `Known for its diverse cultures, wildlife, and natural beauty, South Africa is a country located at the southern tip of Africa. It has a turbulent history of apartheid but has made significant strides toward reconciliation and democracy in recent years.
      `,
      package:"southafrica",
      img: southImage,
      rev: true,
      night3: (
        <>
          <h3>
            Here is a suggested itinerary for a 3-night 4-day trip to South
            Africa:
          </h3>
          <h4> Arrival in Cape Town</h4>
          <ul>
            <li>Arrive at Cape Town International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Explore the city center, visit the vibrant Green Market Square,
              and take a stroll through the nearby Company's Garden.
            </li>
            <li>
              In the evening, enjoy a scenic cable car ride up Table Mountain
              for stunning views of the city.
            </li>
          </ul>
          <h4>Cape Town Tour</h4>
          <ul>
            <li>
              Take a full-day tour of Cape Town, including visits to the famous
              landmarks such as the Table Mountain, the Cape of Good Hope, and
              Boulders Beach to observe the penguin colony.
            </li>
            <li>
              In the evening, enjoy a delicious seafood dinner in one of the
              many restaurants in the harbor.
            </li>
          </ul>
          <h4> Winelands Tour</h4>
          <ul>
            <li>
              Take a half-day tour of the nearby Winelands, including visits to
              the famous Stellenbosch and Franschhoek valleys.
            </li>
            <li>
              Sample some of South Africa's finest wines, and enjoy a delicious
              lunch at one of the wineries.
            </li>
            <li>
              In the evening, return to Cape Town and relax at your hotel.
            </li>
          </ul>
          <h4> Departure</h4>
          <ul>
            <li>Spend the morning at leisure before departing South Africa.</li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. If you have more time, consider visiting
            nearby attractions such as the Kirstenbosch National Botanical
            Garden, Robben Island, or the Victoria & Alfred Waterfront.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>
            Here is a suggested itinerary for a 5-night 6-day trip to South
            Africa:
          </h3>
          <h4> Arrival in Cape Town</h4>
          <ul>
            <li>Arrive at Cape Town International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Explore the city center, visit the vibrant Green Market Square,
              and take a stroll through the nearby Company's Garden.
            </li>
            <li>
              In the evening, enjoy a scenic cable car ride up Table Mountain
              for stunning views of the city.
            </li>
          </ul>
          <h4>Cape Peninsula Tour</h4>
          <ul>
            <li>
              Take a full-day tour of the Cape Peninsula region, including a
              visit to the Cape of Good Hope, where the Atlantic and Indian
              Oceans meet.
            </li>
            <li>Stop at Boulders Beach to observe the penguin colony</li>
            <li>
              Visit Simon's Town and the naval museum, and admire the colonial
              architecture.
            </li>
            <li>
              In the evening, enjoy a delicious seafood dinner in one of the
              many restaurants in the harbor.
            </li>
          </ul>
          <h4>Winelands Tour</h4>
          <ul>
            <li>
              Take a full-day tour of the nearby winelands, including visits to
              the famous Stellenbosch and Franschhoek valleys.
            </li>
            <li>
              Sample some of South Africa's finest wines, and enjoy a delicious
              lunch at one of the wineries.
            </li>
            <li>
              In the evening, return to Cape Town and relax at your hotel.
            </li>
          </ul>
          <h4>Cape Town to Kruger National Park</h4>
          <ul>
            <li>
              Take a flight to Kruger National Park, one of the largest game
              reserves in Africa.
            </li>
            <li>Check into your lodge and take some time to settle in</li>
            <li>
              In the evening, go on a night game drive to observe the nocturnal
              wildlife.
            </li>
          </ul>
          <h4> Kruger National Park</h4>
          <ul>
            <li>
              Spend the day on game drives, observing the diverse wildlife that
              call this park home.
            </li>
            <li>In the evening, return to your lodge and relax.</li>
          </ul>
          <h4> Departure</h4>
          <ul>
            <li>Spend the morning at leisure before departing South Africa.</li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to go on a shark-cage
            diving excursion, visit the nearby Victoria Falls, or explore the
            vibrant culture and history of Johannesburg, South Africa offers a
            wide range of options for travelers.{" "}
          </p>
        </>
      ),
      night6: (
        <>
          <h3>
            Here is a suggested itinerary for a 6-night 7-day trip to South
            Africa:
          </h3>
          <h4> Arrival in Cape Town</h4>
          <ul>
            <li>Arrive at Cape Town International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Explore the city center, visit the vibrant Green Market Square,
              and take a stroll through the nearby Company's Garden.
            </li>
            <li>
              In the evening, enjoy a scenic cable car ride up Table Mountain
              for stunning views of the city.
            </li>
          </ul>
          <h4>Cape Peninsula Tour</h4>
          <ul>
            <li>
              Take a full-day tour of the Cape Peninsula Peninsula region,
              including a visit to the Cape of Good Hope, where the Atlantic and
              Indian Oceans meet.
            </li>
            <li>Stop at Boulders Beach to observe the penguin colony</li>
            <li>
              Visit Simon's Town and the naval museum, and admire the colonial
              architecture.
            </li>
            <li>
              In the evening, enjoy a delicious seafood dinner in one of the
              many restaurants in the harbor.
            </li>
          </ul>
          <h4> Winelands Tour</h4>
          <ul>
            <li>
              Take a full-day tour of the nearby winelands, including visits to
              the famous Stellenbosch and Franschhoek valleys.
            </li>
            <li>
              Sample some of South Africa's finest wines, and enjoy a delicious
              lunch at one of the wineries.
            </li>
            <li>
              In the evening, return to Cape Town and relax at your hotel.
            </li>
          </ul>
          <h4> Cape Town to Kruger National Park</h4>
          <ul>
            <li>
              Take a flight to Kruger National Park, one of the largest game
              reserves in Africa.
            </li>
            <li>Check into your lodge and take some time to settle in</li>
            <li>
              In the evening, go on a night game drive to observe the nocturnal
              wildlife.
            </li>
          </ul>
          <h4>Kruger National Park</h4>
          <ul>
            <li>
              Spend the day on game drives, observing the diverse wildlife that
              call this park home.
            </li>
            <li>In the evening, return to your lodge and relax.</li>
          </ul>
          <h4>Panorama Route</h4>
          <ul>
            <li>
              Take a scenic drive along the Panorama Route, passing by the Blyde
              River Canyon, the Three Rondavels, and other stunning landscapes.
            </li>
            <li>In the evening, return to your lodge and relax.</li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>Spend the morning at leisure before departing South Africa.</li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to go on a shark-cage
            diving excursion, visit the nearby Victoria Falls, or explore the
            vibrant culture and history of Johannesburg, South Africa offers a
            wide range of options for travelers.
          </p>
        </>
      ),
    },
    {
      name: "Tanzania",
      content: `Home to Africa's highest mountain, Mount Kilimanjaro, Tanzania is a popular destination for adventure seekers and wildlife enthusiasts. The country is known for its rich cultural heritage and diverse wildlife.`,
      img: tanzaniaImage,
      package:"tanzania",
      rev: false,
      night3: (
        <>
          <h3>
            Here is a sample 3 nights, 4 days itinerary for a trip to Tanzania:
          </h3>
          <h4> Arrival in Tanzania</h4>
          <ul>
            <li>Arrive in Tanzania, meet and greet at the airport</li>
            <li>Transfer to your hotel in Arusha</li>
            <li>Relax and settle into your hotel</li>
            <li>Overnight stay in Arusha</li>
          </ul>
          <h4>: Arusha National Park</h4>
          <ul>
            <li>
              After breakfast, set out on a morning game drive in Arusha
              National Park
            </li>
            <li>
              Explore the park's diverse habitats including forests, savanna,
              and hot springs
            </li>
            <li>Enjoy a picnic lunch in the park</li>
            <li>Return to the hotel for dinner and overnight stay in Arusha</li>
          </ul>
          <h4>Serengeti National Park</h4>
          <ul>
            <li>After breakfast, depart for Serengeti National Park</li>
            <li>
              On the way, stop at Olduvai Gorge, a significant archaeological
              site where human evolution has been traced back to the earliest
              human ancestors
            </li>
            <li>
              Arrive at the park in the late afternoon, check into your lodge
            </li>
            <li>Overnight stay in Serengeti National Park</li>
          </ul>
          <h4> Serengeti National Park</h4>
          <ul>
            <li>Wake up early for a full-day game drive in the Serengeti</li>
            <li>
              Search for the famous Big Five (lion, elephant, leopard, buffalo,
              and rhino) and other wildlife
            </li>
            <li>
              Return to the lodge for dinner and overnight stay in Serengeti
              National Park
            </li>
          </ul>
        </>
      ),
      night5: (
        <>
          <h3>
            Here's a sample itinerary for a 5-night, 6-day trip to Tanzania:
          </h3>
          <h4>Arrival in Dar es Salaam</h4>
          <ul>
            <li>
              Arrive at Julius Nyerere International Airport in Dar es Salaam
            </li>
            <li>Transfer to your hotel</li>
            <li>Overnight in Dar es Salaam</li>
          </ul>
          <h4>Dar es Salaam to Serengeti National Park</h4>
          <ul>
            <li>After breakfast, depart for Serengeti National Park</li>
            <li>Stop en route at the Olduvai Gorge Museum</li>
            <li>Arrive in the Serengeti in the late afternoon</li>
            <li>Overnight in the Serengeti</li>
          </ul>
          <h4>Full day in Serengeti National Park</h4>
          <ul>
            <li>Early morning game drive in the park</li>
            <li>Return to the lodge for breakfast</li>
            <li>Mid-morning game drive in the park</li>
            <li>Lunch at the lodge</li>
            <li>Afternoon game drive in the park</li>
            <li>Return to the lodge for dinner and overnight</li>
          </ul>
          <h4>Serengeti to Ngorongoro Conservation Area</h4>
          <ul>
            <li>After breakfast, depart for Ngorongoro Conservation Area</li>
            <li>Arrive in Ngorongoro in the late afternoon</li>
            <li>Overnight in Ngorongoro</li>
          </ul>
          <h4> Full day in Ngorongoro Conservation Area</h4>
          <ul>
            <li>Early morning game drive in the Ngorongoro Crater</li>
            <li>Return to the lodge for breakfast</li>
            <li>Mid-morning game drive in the crater</li>
            <li>Lunch at the lodge</li>
            <li>Afternoon game drive in the crater</li>
            <li>Return to the lodge for dinner and overnight</li>
          </ul>
          <h4>Ngorongoro to Dar es Salaam</h4>
          <ul>
            <li>After breakfast, depart for Dar es Salaam</li>
            <li>Stop en route for a visit to a cultural village</li>
            <li>Arrive in Dar es Salaam in the late afternoon</li>
            <li>
              Transfer to Julius Nyerere International Airport for your flight
              back home
            </li>
          </ul>
          <p>
            <b>Note:</b> This itinerary is just a sample and can be customized
            based on your preferences and interests. Also, wildlife sightings
            cannot be guaranteed.
          </p>
        </>
      ),
      night6: (
        <>
          <h3>
            Here's a sample itinerary for a 6-night, 7-day trip to Tanzania:
          </h3>
          <h4>Arrival in Dar es Salaam</h4>
          <ul>
            <li>
              Arrive at Julius Nyerere International Airport in Dar es Salaam
            </li>
            <li>Transfer to your hotel</li>
            <li>Overnight in Dar es Salaam</li>
          </ul>
          <h4>Dar es Salaam to Selous Game Reserve</h4>
          <ul>
            <li>After breakfast, depart for Selous Game Reserve</li>
            <li>Arrive in the Selous in the late afternoon</li>
            <li>Overnight in the Selous</li>
          </ul>
          <h4> Full day in Selous Game Reserve</h4>
          <ul>
            <li>Early morning game drive in the reserve</li>
            <li>Return to the lodge for breakfast</li>
            <li>Mid-morning boat safari on the Rufiji River</li>
            <li>Lunch at the lodge</li>
            <li>Afternoon game drive in the reserve</li>
            <li>Return to the lodge for dinner and overnight</li>
          </ul>
          <h4> Selous to Mikumi National Park</h4>
          <ul>
            <li>After breakfast, depart for Mikumi National Park</li>
            <li>Arrive in Mikumi in the late afternoon</li>
            <li>Overnight in Mikumi</li>
          </ul>
          <h4> Full day in Mikumi National Park</h4>
          <ul>
            <li>Early morning game drive in the park</li>
            <li>Return to the lodge for breakfast</li>
            <li>Mid-morning game drive in the park</li>
            <li>Lunch at the lodge</li>
            <li>Afternoon game drive in the park</li>
            <li>Return to the lodge for dinner and overnight</li>
          </ul>
          <h4>Mikumi to Tarangire National Park</h4>
          <ul>
            <li>After breakfast, depart for Tarangire National Park</li>
            <li>Arrive in Tarangire in the late afternoon</li>
            <li>Overnight in Tarangire</li>
          </ul>
          <h4>Full day in Tarangire National Park</h4>
          <ul>
            <li>Early morning game drive in the park</li>
            <li>Return to the lodge for breakfast</li>
            <li>Mid-morning game drive in the park</li>
            <li>Lunch at the lodge</li>
            <li>Afternoon game drive in the park</li>
            <li>Return to the lodge for dinner and overnight</li>
          </ul>
          <h4>Tarangire to Dar es Salaam</h4>
          <ul>
            <li>After breakfast, depart for Dar es Salaam</li>
            <li>Stop en route for a visit to a cultural village</li>
            <li>Arrive in Dar es Salaam in the late afternoon</li>
            <li>
              Transfer to Julius Nyerere International Airport for your flight
              back home
            </li>
          </ul>
          <p>
            <b>Note:</b> This itinerary is just a sample and can be customized
            based on your preferences and interests. Also, wildlife sightings
            cannot be guaranteed.
          </p>
        </>
      ),
    },
  ],
  asia: [
    {
      name: "Armenia",
      content: `Located in the South Caucasus region of Eurasia, Armenia is a country with a rich cultural heritage, including ancient monasteries, churches, and historical landmarks. It is also known for its delicious cuisine and beautiful landscapes.`,
      img: armeniaImage,
      rev: false,
      package:"armernia",
      night3: (
        <>
          <h3>Here is a sample 3-night, 4-day trip plan to Armenia:</h3>
          <h4>Arrival in Yerevan</h4>
          <ul>
            <li>
              Upon arrival at the airport, transfer to your hotel in the city
              center.
            </li>
            <li>
              Explore the city and visit some of its landmarks such as the
              Republic Square, the Opera House, and the Cascade Complex.
            </li>
            <li>
              In the evening, visit a traditional Armenian restaurant for dinner
              and experience the local cuisine.
            </li>
          </ul>
          <h4>Yerevan - Garni - Geghard - Yerevan</h4>
          <ul>
            <li>
              Drive to the ancient pagan temple of Garni, the only standing
              temple of pre-Christian Armenia.
            </li>
            <li>
              Next, visit the Geghard Monastery, a UNESCO World Heritage Site
              carved out of a mountain and surrounded by breathtaking scenery.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4> Yerevan - Lake Sevan - Dilijan - Yerevan</h4>
          <ul>
            <li>
              Drive to the picturesque Lake Sevan, the largest lake in the
              Caucasus region.
            </li>
            <li>
              Continue to the forested spa town of Dilijan, known for its
              healing mineral waters and charming architecture.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4> Departure from Yerevan</h4>
          <ul>
            <li>Transfer to the airport for your departure flight.</li>
          </ul>
          <p>
            <b>Note:</b> This is a basic itinerary and can be adjusted to suit
            your personal preferences and schedule.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>Here is a sample 5-night, 6-day trip plan to Armenia:</h3>
          <h4>Arrival in Yerevan</h4>
          <ul>
            <li>
              Upon arrival at the airport, transfer to your hotel in the city
              center.
            </li>
            <li>
              Explore the city and visit some of its landmarks such as the
              Republic Square, the Opera House, and the Cascade Complex.
            </li>
            <li>
              In the evening, visit a traditional Armenian restaurant for dinner
              and experience the local cuisine.
            </li>
          </ul>
          <h4>Yerevan - Garni - Geghard - Yerevan</h4>
          <ul>
            <li>
              Drive to the ancient pagan temple of Garni, the only standing
              temple of pre-Christian Armenia.
            </li>
            <li>
              Next, visit the Geghard Monastery, a UNESCO World Heritage Site
              carved out of a mountain and surrounded by breathtaking scenery.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Lake Sevan - Dilijan - Yerevan</h4>
          <ul>
            <li>
              Drive to the picturesque Lake Sevan, the largest lake in the
              Caucasus region.
            </li>
            <li>
              Continue to the forested spa town of Dilijan, known for its
              healing mineral waters and charming architecture.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Khor Virap - Noravank - Yerevan</h4>
          <ul>
            <li>
              Drive to the Khor Virap Monastery, one of the most important
              pilgrimage sites in Armenia.
            </li>
            <li>
              Continue to the stunning Noravank Monastery, nestled in a red-rock
              canyon.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Echmiadzin - Zvartnots - Yerevan</h4>
          <ul>
            <li>
              Drive to the religious capital of Armenia, Echmiadzin, to visit
              the Mother Cathedral of Holy Etchmiadzin, the oldest state-built
              church in the world.
            </li>
            <li>
              Next, visit the ruins of the 7th-century Zvartnots Temple, a
              UNESCO World Heritage Site. Return to Yerevan for overnight.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Departure from Yerevan</h4>
          <ul>
            <li>Transfer to the airport for your departure flight.</li>
          </ul>
          <p>
            <b>Note:</b> This is a basic itinerary and can be adjusted to suit
            your personal preferences and schedule.
          </p>
        </>
      ),
      night6: (
        <>
          <h3>Here is a sample 6-night, 7-day trip plan to Armenia:</h3>
          <h4>Arrival in Yerevan</h4>
          <ul>
            <li>
              Upon arrival at the airport, transfer to your hotel in the city
              center.
            </li>
            <li>
              Explore the city and visit some of its landmarks such as the
              Republic Square, the Opera House, and the Cascade Complex.
            </li>
            <li>
              In the evening, visit a traditional Armenian restaurant for dinner
              and experience the local cuisine.
            </li>
          </ul>
          <h4>Yerevan - Garni - Geghard - Yerevan</h4>
          <ul>
            <li>
              Drive to the ancient pagan temple of Garni, the only standing
              temple of pre-Christian Armenia.
            </li>
            <li>
              Next, visit the Geghard Monastery, a UNESCO World Heritage Site
              carved out of a mountain and surrounded by breathtaking scenery.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Lake Sevan - Dilijan - Yerevan</h4>
          <ul>
            <li>
              Drive to the picturesque Lake Sevan, the largest lake in the
              Caucasus region.
            </li>
            <li>
              Continue to the forested spa town of Dilijan, known for its
              healing mineral waters and charming architecture.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Khor Virap - Noravank - Yerevan</h4>
          <ul>
            <li>
              Drive to the Khor Virap Monastery, one of the most important
              pilgrimage sites in Armenia.
            </li>
            <li>
              Continue to the stunning Noravank Monastery, nestled in a red-rock
              canyon.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Echmiadzin - Zvartnots - Yerevan</h4>
          <ul>
            <li>
              Drive to the religious capital of Armenia, Echmiadzin, to visit
              the Mother Cathedral of Holy Etchmiadzin, the oldest state-built
              church in the world.
            </li>
            <li>
              {" "}
              Next, visit the ruins of the 7th-century Zvartnots Temple, a
              UNESCO World Heritage Site.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Saghmosavank - Haghpat - Sanahin</h4>
          <ul>
            <li>
              Drive to the Saghmosavank Monastery, perched on the edge of a deep
              gorge.
            </li>
            <li>
              Continue to the Haghpat Monastery, another UNESCO World Heritage
              Site known for its intricate stone carvings.
            </li>
            <li>
              Visit the Sanahin Monastery, a complex of monastic buildings from
              the 10th to 13th centuries.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Departure from Yerevan</h4>
          <ul>
            <li>Transfer to the airport for your departure flight.</li>
          </ul>
          <p>
            <b>Note:</b>This is a basic itinerary and can be adjusted to suit
            your personal preferences and schedule.
          </p>
        </>
      ),
    },
    {
      name: "Azerbaijan",
      content: `This country in the South Caucasus region is known for its rich culture, including music, dance, and art. It is also home to stunning natural beauty, including the Caucasus Mountains and the Caspian Sea.`,
      img: azerImage,
      rev: true,
      night3: (
        <>
          <h3>Here is a sample 3-night, 4-day trip plan to Azerbaijan:</h3>
          <h4>Arrival in Baku</h4>
          <ul>
            <li>
              Upon arrival at the airport, transfer to your hotel in the city
              center.
            </li>
            <li>
              Explore the city and visit some of its landmarks such as the
              Icheri Sheher (Old City), the Palace of the Shirvanshahs, and the
              Maiden Tower.
            </li>
            <li>
              In the evening, visit a traditional Azerbaijani restaurant for
              dinner and experience the local cuisine.
            </li>
          </ul>
          <h4>Baku - Gobustan - Baku</h4>
          <ul>
            <li>
              Drive to the Gobustan National Park, a UNESCO World Heritage Site
              known for its unique mud volcanoes, rock formations, and ancient
              petroglyphs.
            </li>
            <li>Return to Baku for overnight.</li>
          </ul>
          <h4>Baku - Absheron Peninsula - Baku</h4>
          <ul>
            <li>
              Drive to the Absheron Peninsula, a coastal region known for its
              natural gas fields, mud volcanoes, and stunning beaches.
            </li>
            <li>Return to Baku for overnight.</li>
          </ul>
          <h4>Departure from Baku</h4>
          <ul>
            <li>Transfer to the airport for your departure flight.</li>
          </ul>
          <p>
            <b>Note:</b>This is a basic itinerary and can be adjusted to suit
            your personal preferences and schedule.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>Here is a sample 5-night, 6-day trip plan to Azerbaijan:</h3>
          <h4>Arrival in Baku</h4>
          <ul>
            <li>
              Upon arrival at the airport, transfer to your hotel in the city
              center.
            </li>
            <li>
              Explore the city and visit some of its landmarks such as the
              Icheri Sheher (Old City), the Palace of the Shirvanshahs, and the
              Maiden Tower.
            </li>
            <li>
              In the evening, visit a traditional Azerbaijani restaurant for
              dinner and experience the local cuisine.
            </li>
          </ul>
          <h4>Baku - Gobustan - Baku</h4>
          <ul>
            <li>
              Drive to the Gobustan National Park, a UNESCO World Heritage Site
              known for its unique mud volcanoes, rock formations, and ancient
              petroglyphs.
            </li>
            <li>Return to Baku for overnight.</li>
          </ul>
          <h4>Baku - Absheron Peninsula - Baku</h4>
          <ul>
            <li>
              {" "}
              Drive to the Absheron Peninsula, a coastal region known for its
              natural gas fields, mud volcanoes, and stunning beaches.
            </li>
            <li>Return to Baku for overnight.</li>
          </ul>
          <h4>Baku - Sheki - Baku</h4>
          <ul>
            <li>
              Drive to the city of Sheki, known for its charming old town and
              beautiful wooden architecture.
            </li>
            <li>
              Explore the city, including the Sheki Khan's Palace, the
              Caravanserai, and the Juma Mosque.
            </li>
            <li>Return to Baku for overnight.</li>
          </ul>
          <h4>Baku - Quba - Baku</h4>
          <ul>
            <li>
              Drive to the city of Quba, located in the foothills of the
              Caucasus Mountains.
            </li>
            <li>
              Explore the city, including the Juma Mosque, the Qırmızı Qəsəbə
              (Red Town), and the Qırx Qala (Yellow Fortress).
            </li>
            <li>Return to Baku for overnight.</li>
          </ul>
          <h4>Departure from Baku</h4>
          <ul>
            <li>Transfer to the airport for your departure flight.</li>
          </ul>
          <p>
            <b>Note:</b>This is a basic itinerary and can be adjusted to suit
            your personal preferences and schedule.{" "}
          </p>
        </>
      ),
      night6: (
        <>
          <h3>Here is a sample 6-night, 7-day trip plan to Azerbaijan:</h3>
          <h4>Arrival in Baku</h4>
          <ul>
            <li>
              Upon arrival at the airport, transfer to your hotel in the city
              center.
            </li>
            <li>
              Explore the city and visit some of its landmarks such as the
              Icheri Sheher (Old City), the Palace of the Shirvanshahs, and the
              Maiden Tower.
            </li>
            <li>
              In the evening, visit a traditional Azerbaijani restaurant for
              dinner and experience the local cuisine.
            </li>
          </ul>
          <h4>Baku - Gobustan - Baku</h4>
          <ul>
            <li>
              Drive to the Gobustan National Park, a UNESCO World Heritage Site
              known for its unique mud volcanoes, rock formations, and ancient
              petroglyphs.
            </li>
            <li>Return to Baku for overnight.</li>
          </ul>
          <h4>Baku - Absheron Peninsula - Baku</h4>
          <ul>
            <li>
              Drive to the Absheron Peninsula, a coastal region known for its
              natural gas fields, mud volcanoes, and stunning beaches.
            </li>
            <li>Return to Baku for overnight.</li>
          </ul>
          <h4> Baku - Sheki - Baku</h4>
          <ul>
            <li>
              Drive to the city of Sheki, known for its charming old town and
              beautiful wooden architecture.
            </li>
            <li>
              Explore the city, including the Sheki Khan's Palace, the
              Caravanserai, and the Juma Mosque.
            </li>
            <li>Return to Baku for overnight.</li>
          </ul>
          <h4>Baku - Quba - Baku</h4>
          <ul>
            <li>
              Drive to the city of Quba, located in the foothills of the
              Caucasus Mountains.
            </li>
            <li>
              Explore the city, including the Juma Mosque, the Qırmızı Qəsəbə
              (Red Town), and the Qırx Qala (Yellow Fortress).
            </li>
            <li>Return to Baku for overnight.</li>
          </ul>
          <h4>Baku - Lahij - Baku</h4>
          <ul>
            <li>
              Drive to the small mountain village of Lahij, known for its
              well-preserved traditional architecture and craftsmanship.
            </li>
            <li>
              Explore the village, including its old bazaar, cobbled streets,
              and workshops producing copper and metal goods.
            </li>
            <li>Return to Baku for overnight.</li>
          </ul>
          <h4>Departure from Baku</h4>
          <ul>
            <li>Transfer to the airport for your departure flight.</li>
          </ul>
          <p>
            <b>Note:</b>This is a basic itinerary and can be adjusted to suit
            your personal preferences and schedule.
          </p>
        </>
      ),
    },
    {
      name: "Indonesia",
      content: `With over 17,000 islands, Indonesia is the largest archipelago in the world. It is known for its stunning natural beauty, including pristine beaches, volcanoes, and tropical forests. It is also home to diverse ethnic groups and cultures.`,
      img: indonesiaImage,
      rev: false,
      night3: (
        <>
          <h4>Arrive in Bali</h4>
          <ul>
            <li>Arrive at Ngurah Rai International Airport in Bali</li>
            <li>Check into your hotel and rest for a while</li>
            <li>
              In the afternoon, visit Tanah Lot Temple, one of the most iconic
              landmarks in Bali
            </li>
            <li>Enjoy a sunset dinner at a beachside restaurant in Seminyak</li>
          </ul>
          <h4> Explore Ubud</h4>
          <ul>
            <li>
              Start the day with a visit to the famous Tegalalang Rice Terrace
            </li>
            <li>
              Visit Ubud Monkey Forest and interact with the playful monkeys
            </li>
            <li>
              Take a dip in the Tirta Empul Temple, a Hindu water temple that is
              believed to have healing powers
            </li>
            <li>Visit the Ubud Art Market to pick up some souvenirs</li>
          </ul>
          <h4>Discover Nusa Penida</h4>
          <ul>
            <li>
              Take an early morning boat ride to Nusa Penida, a beautiful island
              located off the southeast coast of Bali
            </li>
            <li>
              Visit Kelingking Beach, one of the most picturesque spots on the
              island
            </li>
            <li>
              Explore Angel's Billabong and Broken Beach, two stunning natural
              attractions
            </li>
            <li>Return to Bali in the evening and enjoy a farewell dinner</li>
          </ul>
          <h4>Departure</h4>
          <p>
            Check out of your hotel and transfer to Ngurah Rai International
            Airport for your flight home
          </p>
        </>
      ),
      night5: (
        <>
          <h4> Arrive in Bali</h4>
          <ul>
            <li>Arrive at Ngurah Rai International Airport in Bali</li>
            <li>Check into your hotel and relax</li>
            <li>
              In the afternoon, explore Seminyak and its beautiful beaches
            </li>
            <li>Enjoy a sunset dinner at a beachside restaurant</li>
          </ul>
          <h4> Discover Ubud</h4>
          <ul>
            <li>
              Visit the Tegalalang Rice Terraces, a stunning natural attraction
              that is a must-see when in Bali
            </li>
            <li>
              Explore the Ubud Monkey Forest and interact with the playful
              monkeys
            </li>
            <li>
              Visit the Tirta Empul Temple, a Hindu water temple that is
              believed to have healing powers
            </li>
            <li>
              Enjoy a traditional Balinese dance performance in the evening
            </li>
          </ul>
          <h4>Explore Nusa Penida</h4>
          <ul>
            <li>
              Take a boat to Nusa Penida, a beautiful island located off the
              southeast coast of Bali
            </li>
            <li>
              Visit Kelingking Beach, one of the most picturesque spots on the
              island
            </li>
            <li>
              Explore Angel's Billabong and Broken Beach, two stunning natural
              attractions
            </li>
            <li>
              Visit Crystal Bay, a beautiful beach with crystal-clear water
              perfect for snorkeling and swimming
            </li>
          </ul>
          <h4> Discover Uluwatu</h4>
          <ul>
            <li>
              Visit the Uluwatu Temple, a Hindu temple located on the edge of a
              cliff with breathtaking views of the Indian Ocean
            </li>
            <li>
              Enjoy a Kecak dance performance at the temple in the evening
            </li>
            <li>
              Visit Padang Padang Beach, a beautiful beach located near the
              temple
            </li>
          </ul>
          <h4>Visit Gili Trawangan</h4>
          <ul>
            <li>
              Take a speedboat to Gili Trawangan, a beautiful island located off
              the northwest coast of Lombok
            </li>
            <li>
              Explore the island by bicycle and visit the famous Gili T swings
            </li>
            <li>
              Snorkel or dive at one of the many dive sites around the island
            </li>
            <li>Enjoy a beautiful sunset on the beach</li>
          </ul>
          <h4> Departure</h4>
          <p>
            Check out of your hotel and transfer to Lombok International Airport
            for your flight home
          </p>
        </>
      ),
      night6: (
        <>
          <h4>Arrival in Bali</h4>
          <ul>
            <li>
              Upon arrival at Ngurah Rai International Airport in Bali, check-in
              at your hotel and spend the day relaxing and exploring your
              surroundings.
            </li>
          </ul>
          <h4> Ubud</h4>
          <ul>
            <li>
              Begin the day with a visit to Tegalalang Rice Terrace, one of
              Bali's most famous rice terraces.
            </li>
            <li>
              Next, head to Ubud, Bali's cultural hub. Visit the Ubud Palace and
              the Monkey Forest.
            </li>
            <li>
              In the evening, attend a traditional Balinese dance performance.
            </li>
          </ul>
          <h4>Mount Batur Sunrise Trek</h4>
          <ul>
            <li>
              Wake up early and embark on a trek to the summit of Mount Batur to
              witness a stunning sunrise.
            </li>
            <li>
              After the trek, visit the natural hot springs in the area to
              relax.
            </li>
          </ul>
          <h4> Gili Islands</h4>
          <ul>
            <li>
              Take a fast boat from Bali to Gili Trawangan, one of the three
              Gili Islands known for its beautiful beaches and clear waters.
            </li>
            <li>
              Spend the day snorkeling, diving, or simply relaxing on the beach.
            </li>
          </ul>
          <h4> Lombok</h4>
          <ul>
            <li>
              Take a boat to Lombok and visit the traditional Sasak village of
              Sade to learn about the local culture.
            </li>
            <li>
              Visit the stunning beaches of Lombok, such as Kuta Beach and
              Tanjung Aan Beach.
            </li>
          </ul>
          <h4>Komodo Island</h4>
          <ul>
            <li>
              Fly to Labuan Bajo and take a boat to Komodo Island to see the
              Komodo dragons, the largest lizards in the world.
            </li>
            <li>
              Snorkel in Pink Beach, one of the most beautiful beaches in Komodo
              National Park.
            </li>
          </ul>
          <h4>Labuan Bajo</h4>
          <ul>
            <li>
              Spend the day exploring Labuan Bajo, a charming fishing town on
              the western tip of Flores Island.
            </li>
            <li>Visit the local markets and sample the delicious seafood.</li>
          </ul>
          <h4> Departure</h4>
          <p>
            Enjoy your last day in Indonesia and transfer to the airport for
            your flight back home. Note: This itinerary is just a suggestion and
            can be customized according to your preferences and interests.
          </p>
        </>
      ),
    },
    {
      name: "Malaysia",
      content: ` Located in Southeast Asia, Malaysia is a country known for its beautiful beaches, diverse wildlife, and bustling cities. It is a melting pot of different cultures, including Malay, Chinese, and Indian.`,
      img: malaysiaImage,
      rev: true,
      night3: (
        <>
          <h4> Kuala Lumpur</h4>
          <ul>
            <li>Arrive at Kuala Lumpur International Airport</li>
            <li>Check in to your hotel in the city center</li>
            <li>
              Visit the iconic Petronas Twin Towers and take a walk in the KLCC
              Park
            </li>
            <li>
              Explore the historic and cultural landmarks in the city such as
              the Merdeka Square, Sultan Abdul Samad Building, and National
              Museum
            </li>
            <li>
              Enjoy the vibrant nightlife in Bukit Bintang, home to a variety of
              restaurants, bars, and entertainment venues.
            </li>
          </ul>
          <h4>Malacca</h4>
          <ul>
            <li>Travel to Malacca (approximately 2 hours by car)</li>
            <li>
              Visit the UNESCO-listed Malacca Historic City Centre and explore
              the charming colonial architecture and colorful Peranakan culture
            </li>
            <li>
              Visit the famous Jonker Street Night Market, where you can sample
              local street food and shop for souvenirs
            </li>
            <li>Return to Kuala Lumpur in the evening</li>
          </ul>
          <h4>Batu Caves and Genting Highlands</h4>
          <ul>
            <li>
              Take a half-day trip to the Batu Caves, a series of caves and cave
              temples located just outside Kuala Lumpur
            </li>
            <li>
              After visiting the caves, drive up to Genting Highlands, a
              mountain resort with a cool climate and numerous attractions
              including the Genting Skyway cable car, indoor and outdoor theme
              parks, and casinos
            </li>
            <li>Return to Kuala Lumpur in the evening</li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Enjoy some last-minute shopping or sightseeing in Kuala Lumpur
            </li>
            <li>Depart from Kuala Lumpur International Airport</li>
            <li>
              This itinerary offers a mix of cultural, historical, and natural
              attractions in Malaysia, as well as a taste of its vibrant
              nightlife and culinary scene.{" "}
            </li>
          </ul>
        </>
      ),
      night5: (
        <>
          <h4>Kuala Lumpur</h4>
          <ul>
            <li>Arrive at Kuala Lumpur International Airport</li>
            <li>Check in to your hotel in the city center</li>
            <li>
              Visit the iconic Petronas Twin Towers and take a walk in the KLCC
              Park
            </li>
            <li>
              Explore the historic and cultural landmarks in the city such as
              the Merdeka Square, Sultan Abdul Samad Building, and National
              Museum
            </li>
            <li>
              Enjoy the vibrant nightlife in Bukit Bintang, home to a variety of
              restaurants, bars, and entertainment venues.
            </li>
          </ul>
          <h4> Malacca</h4>
          <ul>
            <li>Travel to Malacca (approximately 2 hours by car)</li>
            <li>
              Visit the UNESCO-listed Malacca Historic City Centre and explore
              the charming colonial architecture and colorful Peranakan culture
            </li>
            <li>
              Visit the famous Jonker Street Night Market, where you can sample
              local street food and shop for souvenirs
            </li>
            <li>Return to Kuala Lumpur in the evening</li>
          </ul>
          <h4>Cameron Highlands</h4>
          <ul>
            <li>
              Take a scenic drive to Cameron Highlands (approximately 3.5 hours
              by car)
            </li>
            <li>Visit tea plantations, strawberry farms, and flower gardens</li>
            <li>
              Explore the Mossy Forest and take in the stunning views of the
              surrounding hills
            </li>
            <li>
              Enjoy a relaxing evening at your hotel in the cool, mountain
              climate.
            </li>
          </ul>
          <h4>Penang</h4>
          <ul>
            <li>Travel to Penang (approximately 4 hours by car)</li>
            <li>
              Visit the UNESCO-listed George Town, a historic city with a mix of
              colonial and traditional architecture
            </li>
            <li>Sample local street food at the various hawker centers</li>
            <li>
              Visit the Kek Lok Si Temple, one of the largest Buddhist temples
              in Southeast Asia
            </li>
            <li>Return to Cameron Highlands in the evening</li>
          </ul>
          <h4>Langkawi</h4>
          <ul>
            <li>Take a flight or ferry to Langkawi</li>
            <li>
              Enjoy a day at the beach, soaking up the sun and taking part in
              water sports such as snorkeling and kayaking
            </li>
            <li>
              Visit the Langkawi Sky Bridge for stunning views of the
              surrounding islands
            </li>
            <li>
              Enjoy a sunset dinner on the beach before returning to your hotel.
            </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>Enjoy some last-minute shopping or sightseeing in Langkawi</li>
            <li>Depart from Langkawi International Airport.</li>
            <li>
              This itinerary offers a diverse range of attractions and
              experiences, from cultural and historical landmarks to natural
              scenery and beach activities.{" "}
            </li>
          </ul>
        </>
      ),
      night6: (
        <>
          <h4>Kuala Lumpur</h4>
          <ul>
            <li>Arrive at Kuala Lumpur International Airport</li>
            <li>Check in to your hotel in the city center</li>
            <li>
              Visit the iconic Petronas Twin Towers and take a walk in the KLCC
              Park
            </li>
            <li>
              Explore the historic and cultural landmarks in the city such as
              the Merdeka Square, Sultan Abdul Samad Building, and National
              Museum
            </li>
            <li>
              Enjoy the vibrant nightlife in Bukit Bintang, home to a variety of
              restaurants, bars, and entertainment venues.
            </li>
          </ul>
          <h4>Malacca</h4>
          <ul>
            <li>Travel to Malacca (approximately 2 hours by car)</li>
            <li>
              Visit the UNESCO-listed Malacca Historic City Centre and explore
              the charming colonial architecture and colorful Peranakan culture
            </li>
            <li>
              Visit the famous Jonker Street Night Market, where you can sample
              local street food and shop for souvenirs
            </li>
            <li>Return to Kuala Lumpur in the evening</li>
          </ul>
          <h4>Cameron Highlands</h4>
          <ul>
            <li>
              Take a scenic drive to Cameron Highlands (approximately 3.5 hours
              by car)
            </li>
            <li>Visit tea plantations, strawberry farms, and flower gardens</li>
            <li>
              Explore the Mossy Forest and take in the stunning views of the
              surrounding hills
            </li>
            <li>
              Enjoy a relaxing evening at your hotel in the cool, mountain
              climate.
            </li>
          </ul>
          <h4>Penang</h4>
          <ul>
            <li>Travel to Penang (approximately 4 hours by car)</li>
            <li>
              Visit the UNESCO-listed George Town, a historic city with a mix of
              colonial and traditional architecture
            </li>
            <li>Sample local street food at the various hawker centers</li>
            <li>
              Visit the Kek Lok Si Temple, one of the largest Buddhist temples
              in Southeast Asia
            </li>
            <li>Return to Cameron Highlands in the evening</li>
          </ul>
          <h4>Langkawi</h4>
          <ul>
            <li>Take a flight or ferry to Langkawi</li>
            <li>
              Enjoy a day at the beach, soaking up the sun and taking part in
              water sports such as snorkeling and kayaking
            </li>
            <li>
              Visit the Langkawi Sky Bridge for stunning views of the
              surrounding islands
            </li>
            <li>
              Enjoy a sunset dinner on the beach before returning to your hotel.
            </li>
          </ul>
          <h4>Kota Kinabalu</h4>
          <ul>
            <li>Take a flight to Kota Kinabalu, a coastal city in Sabah</li>
            <li>
              Visit the Kota Kinabalu City Mosque, one of the most beautiful
              mosques in Malaysia
            </li>
            <li>
              Explore the Tunku Abdul Rahman Marine Park, home to beautiful
              coral reefs and marine life
            </li>
            <li>
              Enjoy a seafood dinner at the famous Night Market in Kota
              Kinabalu.
            </li>
          </ul>
          <h4>Mount Kinabalu</h4>
          <ul>
            <li>
              Take a day trip to Mount Kinabalu, the tallest mountain in
              Malaysia
            </li>
            <li>
              Hike up to the summit for stunning views of the surrounding
              landscape
            </li>
            <li>
              Explore Kinabalu Park, a UNESCO World Heritage Site known for its
              biodiversity
            </li>
            <li>Return to Kota Kinabalu in the evening.</li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Enjoy some last-minute shopping or sightseeing in Kota Kinabalu
            </li>
            <li>Depart from Kota Kinabalu International Airport.</li>
            <li>
              This itinerary offers a diverse range of attractions and
              experiences, from cultural and historical landmarks to natural
              scenery and outdoor adventures.
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "Maldives",
      content: `An island nation located in the Indian Ocean, the Maldives is famous for its crystal-clear waters, white-sand beaches, and luxury resorts. It is a popular honeymoon destination and attracts tourists from all over the world.`,
      img: maldivesImage,
      rev: false,
      night3: (
        <>
          <p>
            Upon arrival at Velana International Airport in Maldives, you will
            be greeted by a representative who will transfer you to your resort
            by speedboat. Check into your room and enjoy the rest of the day at
            leisure, exploring the resort's facilities and soaking up the sun on
            the pristine beaches. In the evening, enjoy a romantic dinner by the
            beach under the stars.
          </p>
          <br />
          <p>
            After breakfast, spend the day indulging in various water activities
            such as snorkeling, scuba diving, or simply lounging on the beach.
            You can also opt for a spa treatment at the resort's spa for a
            rejuvenating experience. In the evening, go for a sunset cruise and
            enjoy the breathtaking views of the Indian Ocean.
          </p>
          <br />
          <p>
            Today, you can explore the nearby islands on a day trip, where you
            can enjoy a picnic lunch on a secluded island and engage in water
            activities such as swimming, snorkeling, and fishing. In the
            evening, return to the resort and enjoy a sumptuous dinner at one of
            the resort's restaurants.
          </p>
          <br />
          <p>
            After breakfast, check out of your room and transfer back to Velana
            International Airport for your flight back home. If time permits,
            you can explore the capital city of Maldives, Male, and indulge in
            some last-minute souvenir shopping before your departure flight.
          </p>
          <br />
          <p>
            This 3 night 4 day trip to Maldives will offer you a perfect
            combination of relaxation, adventure, and exploration.
          </p>
        </>
      ),
      night5: (
        <>
          <p>
            Upon arrival at Velana International Airport in Maldives, you will
            be greeted by a representative who will transfer you to your resort
            by speedboat or seaplane. Check into your room and spend the rest of
            the day at leisure, exploring the resort's facilities and enjoying
            the beautiful surroundings. In the evening, enjoy a romantic dinner
            by the beach under the stars.
          </p>
          <br />
          <p>
            After breakfast, spend the day indulging in various water activities
            such as snorkelling, scuba diving, or simply lounging on the beach.
            You can also opt for a spa treatment at the resort's spa for a
            rejuvenating experience. In the evening, go for a sunset cruise and
            enjoy the breathtaking views of the Indian Ocean.
          </p>
          <br />
          <p>
            Today, you can explore the nearby islands on a day trip, where you
            can enjoy a picnic lunch on a secluded island and engage in water
            activities such as swimming, snorkelling, and fishing. In the
            evening, return to the resort and enjoy a sumptuous dinner at one of
            the resort's restaurants.
          </p>
          <br />
          <p>
            After breakfast, go for a dolphin-watching tour where you can spot
            playful dolphins jumping and swimming in the crystal-clear waters of
            Maldives. In the afternoon, indulge in some water sports such as
            parasailing or jet skiing. In the evening, enjoy a romantic dinner
            on a private beach.
          </p>
          <br />
          <p>
            Spend the day at leisure, relaxing on the beach or exploring the
            resort's facilities. You can also go for a guided tour of the local
            island to experience the Maldivian way of life and culture. In the
            evening, enjoy a farewell dinner at the resort's restaurant.
          </p>
          <br />
          <p>
            After breakfast, check out of your room and transfer back to Velana
            International Airport for your flight back home.
          </p>
          <br />
          <p>
            This 5 night 6 day trip to Maldives will offer you a perfect blend
            of relaxation, adventure, and exploration, giving you a memorable
            vacation experience.
          </p>
        </>
      ),
      night6: (
        <>
          <p>
            Upon arrival at Velana International Airport in Maldives, you will
            be greeted by a representative who will transfer you to your resort
            by speedboat or seaplane. Check into your room and spend the rest of
            the day at leisure, exploring the resort's facilities and enjoying
            the beautiful surroundings. In the evening, enjoy a romantic dinner
            by the beach under the stars.
          </p>
          <br />
          <p>
            After breakfast, spend the day indulging in various water activities
            such as snorkelling, scuba diving, or simply lounging on the beach.
            You can also opt for a spa treatment at the resort's spa for a
            rejuvenating experience. In the evening, go for a sunset cruise and
            enjoy the breathtaking views of the Indian Ocean.
          </p>
          <br />
          <p>
            Today, you can explore the nearby islands on a day trip, where you
            can enjoy a picnic lunch on a secluded island and engage in water
            activities such as swimming, snorkelling, and fishing. In the
            evening, return to the resort and enjoy a sumptuous dinner at one of
            the resort's restaurants.
          </p>
          <br />
          <p>
            After breakfast, go for a dolphin watching tour where you can spot
            playful dolphins jumping and swimming in the crystal-clear waters of
            Maldives. In the afternoon, indulge in some water sports such as
            parasailing or jet skiing. In the evening, enjoy a romantic dinner
            on a private beach.
          </p>
          <br />
          <p>
            Spend the day at leisure, relaxing on the beach or exploring the
            resort's facilities. You can also go for a guided tour of the local
            island to experience the Maldivian way of life and culture. In the
            evening, enjoy a farewell dinner at the resort's restaurant.
          </p>
          <br />
          <p>
            Today, you can embark on a full-day adventure tour that includes
            activities such as island hopping, snorkelling, and a BBQ lunch on a
            deserted island. You can also go for a sunset fishing trip and try
            your luck at catching some fish. In the evening, return to the
            resort and enjoy a romantic dinner.
          </p>
          <br />
          <p>
            After breakfast, check out of your room and transfer back to Velana
            International Airport for your flight back home.
          </p>
          <br />
          <p>
            This 6 night 7 day trip to Maldives will offer you a perfect blend
            of relaxation, adventure, and exploration, giving you a memorable
            vacation experience.
          </p>
        </>
      ),
    },
    {
      name: "Singapore",
      content: ` A small island city-state in Southeast Asia, Singapore is known for its clean streets, diverse food scene, and modern architecture. It is also a major financial center and a popular tourist destination.`,
      img: singaporeImage,
      rev: true,
      night3: (
        <>
          <h4>Singapore City Tour</h4>
          <ul>
            <li>
              Arrive at Changi Airport and check in to your hotel in the city
              center
            </li>
            <li>
              Visit the iconic Merlion statue and take in the view of Marina Bay
            </li>
            <li>
              Explore the Gardens by the Bay, a stunning nature park with unique
              supertrees and conservatories
            </li>
            <li>
              Visit the historic Chinatown and experience the local culture and
              cuisine
            </li>
            <li>
              End the day with the spectacular light and water show at Marina
              Bay Sands.
            </li>
          </ul>
          <h4>Sentosa Island</h4>
          <ul>
            <li>
              Take a cable car ride to Sentosa Island, a popular resort island
              in Singapore
            </li>
            <li>
              Visit the Universal Studios theme park and enjoy the various rides
              and attractions
            </li>
            <li>
              Visit the S.E.A. Aquarium, one of the largest aquariums in the
              world
            </li>
            <li>
              Enjoy a relaxing evening at one of the island's many beaches.
            </li>
          </ul>
          <h4>Cultural and Historical Sites</h4>
          <ul>
            <li>
              Visit the National Museum of Singapore, showcasing the country's
              history and culture
            </li>
            <li>
              Explore the vibrant and colorful streets of Little India and
              experience the local Indian culture and cuisine
            </li>
            <li>
              Visit the iconic Sri Mariamman Temple, the oldest Hindu temple in
              Singapore
            </li>
            <li>
              Enjoy the nightlife at Clarke Quay, a riverside area with a
              variety of restaurants, bars, and clubs.
            </li>
          </ul>
          <h4>Shopping and Departure</h4>
          <ul>
            <li>
              Visit Orchard Road, a famous shopping district with a variety of
              luxury boutiques and malls
            </li>
            <li>
              Shop for souvenirs and local snacks at Bugis Street, one of
              Singapore's largest street markets
            </li>
            <li>
              Enjoy a last-minute meal of local food before departing from
              Changi Airport.
            </li>
            <li>
              This itinerary offers a mix of attractions and experiences, from
              cultural and historical landmarks to modern theme parks and
              shopping districts.
            </li>
          </ul>
        </>
      ),
      night5: (
        <>
          <h4>Arrival and City Tour</h4>
          <ul>
            <li>
              Arrive at Changi Airport and check in to your hotel in the city
              center
            </li>
            <li>
              Visit the iconic Merlion statue and take in the view of Marina Bay
            </li>
            <li>
              Explore the Gardens by the Bay, a stunning nature park with unique
              supertrees and conservatories
            </li>
            <li>
              Visit the historic Chinatown and experience the local culture and
              cuisine
            </li>
            <li>
              End the day with the spectacular light and water show at Marina
              Bay Sands.
            </li>
          </ul>
          <h4>Sentosa Island and Universal Studios</h4>
          <ul>
            <li>
              Take a cable car ride to Sentosa Island, a popular resort island
              in Singapore
            </li>
            <li>
              Visit the Universal Studios theme park and enjoy the various rides
              and attractions
            </li>
            <li>
              Visit the S.E.A. Aquarium, one of the largest aquariums in the
              world
            </li>
            <li>
              Enjoy a relaxing evening at one of the island's many beaches.
            </li>
          </ul>
          <h4>Singapore Zoo and Night Safari</h4>
          <ul>
            <li>
              Visit the Singapore Zoo, known for its open and natural habitats
              for animals
            </li>
            <li>
              Enjoy the zoo's various shows and exhibits, including the
              Rainforest Kidzworld and the Great Rift Valley of Africa
            </li>
            <li>
              In the evening, take the Night Safari tour, where you can see
              nocturnal animals up close in their natural habitats.
            </li>
          </ul>
          <h4>Clarke Quay and River Cruise</h4>
          <ul>
            <li>
              Visit Clarke Quay, a riverside area with a variety of restaurants,
              bars, and clubs
            </li>
            <li>
              Take a river cruise along the Singapore River and learn about the
              city's history and culture
            </li>
            <li>
              Visit the iconic Marina Bay Sands and enjoy the views from the
              observation deck
            </li>
            <li>
              End the day with a luxurious dinner at one of the restaurants in
              Marina Bay Sands.
            </li>
          </ul>
          <h4>Orchard Road and Little India</h4>
          <ul>
            <li>
              Visit Orchard Road, a famous shopping district with a variety of
              luxury boutiques and malls
            </li>
            <li>
              Shop for souvenirs and local snacks at Bugis Street, one of
              Singapore's largest street markets
            </li>
            <li>
              Explore the vibrant and colorful streets of Little India and
              experience the local Indian culture and cuisine
            </li>
            <li>
              Visit the iconic Sri Mariamman Temple, the oldest Hindu temple in
              Singapore.
            </li>
          </ul>
          <h4>Jurong Bird Park and Singapore Flyer</h4>
          <ul>
            <li>
              Visit the Jurong Bird Park, home to a variety of bird species from
              around the world
            </li>
            <li>
              Watch the bird shows and enjoy the park's various exhibits and
              attractions
            </li>
            <li>
              In the evening, take a ride on the Singapore Flyer, a giant Ferris
              wheel that offers stunning views of the city skyline
            </li>
            <li>
              End the day with a relaxing dinner at the waterfront at the
              Esplanade.
            </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>Enjoy some last-minute shopping or sightseeing in Singapore</li>
            <li>Depart from Changi Airport.</li>
            <li>
              This itinerary offers a mix of attractions and experiences, from
              nature parks and zoos to cultural landmarks and shopping
              districts.{" "}
            </li>
          </ul>
        </>
      ),
      night6: (
        <>
          <h4>Arrival and Marina Bay Sands</h4>
          <ul>
            <li>
              Arrive at Changi Airport and check in to your hotel in the city
              center
            </li>
            <li>
              Visit the iconic Marina Bay Sands and enjoy the views from the
              observation deck
            </li>
            <li>
              Explore the Gardens by the Bay, a stunning nature park with unique
              supertrees and conservatories
            </li>
            <li>
              End the day with the spectacular light and water show at Marina
              Bay Sands.
            </li>
          </ul>
          <h4>Sentosa Island and Universal Studios</h4>
          <ul>
            <li>
              Take a cable car ride to Sentosa Island, a popular resort island
              in Singapore
            </li>
            <li>
              Visit the Universal Studios theme park and enjoy the various rides
              and attractions
            </li>
            <li>
              Visit the S.E.A. Aquarium, one of the largest aquariums in the
              world
            </li>
            <li>
              Enjoy a relaxing evening at one of the island's many beaches.
            </li>
          </ul>
          <h4> Singapore Zoo and Night Safari</h4>
          <ul>
            <li>
              Visit the Singapore Zoo, known for its open and natural habitats
              for animals
            </li>
            <li>
              Enjoy the zoo's various shows and exhibits, including the
              Rainforest Kidzworld and the Great Rift Valley of Africa
            </li>
            <li>
              In the evening, take the Night Safari tour, where you can see
              nocturnal animals up close in their natural habitats.
            </li>
          </ul>
          <h4> Cultural and Historical Sites</h4>
          <ul>
            <li>
              Visit the National Museum of Singapore, showcasing the country's
              history and culture
            </li>
            <li>
              Explore the vibrant and colorful streets of Little India and
              experience the local Indian culture and cuisine
            </li>
            <li>
              Visit the iconic Sri Mariamman Temple, the oldest Hindu temple in
              Singapore
            </li>
            <li>
              Enjoy the nightlife at Clarke Quay, a riverside area with a
              variety of restaurants, bars, and clubs.
            </li>
          </ul>
          <h4>Jurong Bird Park and Singapore Flyer</h4>
          <ul>
            <li>
              Visit the Jurong Bird Park, home to a variety of bird species from
              around the world
            </li>
            <li>
              Watch the bird shows and enjoy the park's various exhibits and
              attractions
            </li>
            <li>
              In the evening, take a ride on the Singapore Flyer, a giant Ferris
              wheel that offers stunning views of the city skyline
            </li>
            <li>
              End the day with a relaxing dinner at the waterfront at the
              Esplanade.
            </li>
          </ul>
          <h4>Orchard Road and Gardens by the Bay</h4>
          <ul>
            <li>
              Visit Orchard Road, a famous shopping district with a variety of
              luxury boutiques and malls
            </li>
            <li>
              Shop for souvenirs and local snacks at Bugis Street, one of
              Singapore's largest street markets
            </li>
            <li>
              Explore the Gardens by the Bay and its various conservatories,
              including the Cloud Forest and Flower Dome
            </li>
            <li>
              In the evening, enjoy the Garden Rhapsody light and sound show
              among the supertrees.
            </li>
          </ul>

          <h4>Hawker Centers and Chinatown</h4>
          <ul>
            <li>
              Visit one of Singapore's many hawker centers and indulge in local
              food and delicacies
            </li>
            <li>
              Explore the historic Chinatown and experience the local culture
              and cuisine
            </li>
            <li>
              Visit the Buddha Tooth Relic Temple and Museum, a stunning
              four-story temple with various exhibits on Buddhism
            </li>
            <li>
              End the day with a visit to the Haw Par Villa, a park with various
              Chinese mythological sculptures and exhibits.
            </li>
          </ul>
          <h4> Departure</h4>
          <ul>
            <li>Enjoy some last-minute shopping or sightseeing in Singapore</li>
            <li>Depart from Changi Airport.</li>
            <li>
              This itinerary offers a mix of attractions and experiences, from
              nature parks and zoos to cultural landmarks and shopping
              districts.
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "Sri Lanka",
      content: `Located in the Indian Ocean, Sri Lanka is known for its stunning natural beauty, including ancient temples, tropical forests, and beautiful beaches. It is also famous for its tea plantations and delicious cuisine.`,
      img: srilankaImage,
      rev: false,
      night3: (
        <>
          <h4>Arrival and Sigiriya</h4>
          <ul>
            <li>
              Arrive at Bandaranaike International Airport in Colombo and travel
              to Sigiriya, a UNESCO World Heritage site
            </li>
            <li>
              Visit the Sigiriya Rock Fortress, an ancient rock fortress with
              stunning views and rich history
            </li>
            <li>
              Explore the surrounding gardens and water features of the fortress
            </li>
            <li>Stay overnight in Sigiriya.</li>
          </ul>
          <h4> Kandy and Temple of the Tooth</h4>
          <ul>
            <li>Travel to Kandy, a scenic city in central Sri Lanka</li>
            <li>
              Visit the Temple of the Tooth, a sacred Buddhist temple, and
              UNESCO World Heritage site
            </li>
            <li>Explore Kandy Lake and its surrounding gardens and parks</li>
            <li>Enjoy a cultural dance performance in the evening</li>
            <li>Stay overnight in Kandy.</li>
          </ul>
          <h4>Nuwara Eliya and Tea Plantations</h4>
          <ul>
            <li>
              Travel to Nuwara Eliya, a picturesque town known for its tea
              plantations and scenic landscapes
            </li>
            <li>
              Visit a tea factory and plantation and learn about the tea-making
              process
            </li>
            <li>Enjoy a scenic drive through the tea fields and hills</li>
            <li>Visit the Gregory Lake and the Hakgala Botanical Gardens</li>
            <li>Stay overnight in Nuwara Eliya.</li>
          </ul>
          <h4> Departure and Colombo</h4>
          <ul>
            <li>Travel back to Colombo</li>
            <li>
              Explore the city's landmarks, such as the Colombo National Museum,
              Independence Square, and the Gangaramaya Temple
            </li>
            <li>
              Visit local markets and shopping areas for souvenirs and gifts
            </li>
            <li>Depart from Bandaranaike International Airport.</li>
          </ul>
          <p>
            This itinerary offers a mix of history, culture, nature, and scenic
            landscapes, showcasing some of Sri Lanka's most iconic attractions.{" "}
          </p>
        </>
      ),
      night5: (
        <>
          <h4>Arrival and Colombo</h4>
          <ul>
            <li>
              Arrive at Bandaranaike International Airport in Colombo and check
              into your hotel
            </li>
            <li>
              Explore the city's landmarks, such as the Colombo National Museum,
              Independence Square, and the Gangaramaya Temple
            </li>
            <li>
              Visit local markets and shopping areas for souvenirs and gifts
            </li>
            <li>Stay overnight in Colombo.</li>
          </ul>
          <h4>Sigiriya and Dambulla</h4>
          <ul>
            <li>
              Travel to Sigiriya, a UNESCO World Heritage site, and visit the
              Sigiriya Rock Fortress, an ancient rock fortress with stunning
              views and rich history
            </li>
            <li>
              Explore the surrounding gardens and water features of the fortress
            </li>
            <li>
              Visit the Dambulla Cave Temple, a series of cave temples featuring
              ancient murals and Buddha statues
            </li>
            <li>Stay overnight in Sigiriya or Dambulla.</li>
          </ul>
          <h4>Kandy and Temple of the Tooth</h4>
          <ul>
            <li>Travel to Kandy, a scenic city in central Sri Lanka</li>
            <li>
              Visit the Temple of the Tooth, a sacred Buddhist temple, and
              UNESCO World Heritage site
            </li>
            <li>Explore Kandy Lake and its surrounding gardens and parks</li>
            <li>Enjoy a cultural dance performance in the evening</li>
            <li>Stay overnight in Kandy.</li>
          </ul>
          <h4>Nuwara Eliya and Tea Plantations</h4>
          <ul>
            <li>
              Travel to Nuwara Eliya, a picturesque town known for its tea
              plantations and scenic landscapes
            </li>
            <li>
              Visit a tea factory and plantation and learn about the tea-making
              process
            </li>
            <li>Enjoy a scenic drive through the tea fields and hills</li>
            <li>Visit the Gregory Lake and the Hakgala Botanical Gardens</li>
            <li>Stay overnight in Nuwara Eliya.</li>
          </ul>
          <h4> Yala National Park</h4>
          <ul>
            <li>
              Travel to Yala National Park, known for its diverse wildlife and
              scenic landscapes
            </li>
            <li>
              Take a safari tour to see leopards, elephants, deer, and other
              animals in their natural habitat
            </li>
            <li>
              Visit the Sithulpawwa Temple, an ancient Buddhist temple in the
              park
            </li>
            <li>Stay overnight in Yala or a nearby town.</li>
          </ul>
          <h4> Departure</h4>
          <ul>
            <li>
              Travel back to Colombo or Bandaranaike International Airport
            </li>
            <li>Depart from Sri Lanka.</li>
            <li>
              This itinerary offers a mix of history, culture, nature, and
              wildlife, showcasing some of Sri Lanka's most iconic attractions.
            </li>
          </ul>
        </>
      ),
      night6: (
        <>
          <h4>Arrival and Negombo</h4>
          <ul>
            <li>
              Arrive at Bandaranaike International Airport in Colombo and check
              into your hotel in Negombo
            </li>
            <li>
              Explore the town's beaches, seafood restaurants, and fishing
              villages
            </li>
            <li>Visit the Dutch Fort and the St. Mary's Church</li>
            <li>Stay overnight in Negombo.</li>
          </ul>
          <h4>Anuradhapura and Mihintale</h4>
          <ul>
            <li>
              Travel to Anuradhapura, one of the ancient capitals of Sri Lanka
              and a UNESCO World Heritage site
            </li>
            <li>Visit the ancient temples, stupas, and ruins of the city</li>
            <li>
              Travel to Mihintale, the birthplace of Buddhism in Sri Lanka, and
              visit the ancient monasteries and temples
            </li>
            <li>Stay overnight in Anuradhapura or a nearby town.</li>
          </ul>
          <h4>Sigiriya and Polonnaruwa</h4>
          <ul>
            <li>
              Travel to Sigiriya, a UNESCO World Heritage site, and visit the
              Sigiriya Rock Fortress, an ancient rock fortress with stunning
              views and rich history
            </li>
            <li>
              Explore the surrounding gardens and water features of the fortress
            </li>
            <li>
              Visit the ancient city of Polonnaruwa, another UNESCO World
              Heritage site, and see the ancient ruins, temples, and statues
            </li>
            <li>Stay overnight in Sigiriya or a nearby town.</li>
          </ul>
          <h1>Kandy and Temple of the Tooth</h1>
          <ul>
            <li>Travel to Kandy, a scenic city in central Sri Lanka</li>
            <li>
              Visit the Temple of the Tooth, a sacred Buddhist temple, and
              UNESCO World Heritage site
            </li>
            <li>Explore Kandy Lake and its surrounding gardens and parks</li>
            <li>Enjoy a cultural dance performance in the evening</li>
            <li>Stay overnight in Kandy.</li>
          </ul>
          <h4>Nuwara Eliya and Tea Plantations</h4>
          <ul>
            <li>
              Travel to Nuwara Eliya, a picturesque town known for its tea
              plantations and scenic landscapes
            </li>
            <li>
              Visit a tea factory and plantation and learn about the tea-making
              process
            </li>
            <li>Enjoy a scenic drive through the tea fields and hills</li>
            <li>Visit the Gregory Lake and the Hakgala Botanical Gardens</li>
            <li>Stay overnight in Nuwara Eliya.</li>
          </ul>
          <h4>Ella and Horton Plains National Park</h4>
          <ul>
            <li>
              Travel to Ella, a scenic town surrounded by tea plantations and
              scenic landscapes
            </li>
            <li>
              Visit the Nine Arch Bridge, a historic railway bridge, and the
              Ravana Falls
            </li>
            <li>
              Travel to Horton Plains National Park and take a hike to see the
              World's End viewpoint and the Baker's Falls
            </li>
            <li>Stay overnight in Ella.</li>
          </ul>
          <h4>Galle and Bentota</h4>
          <ul>
            <li>
              Travel to Galle, a historic port city and UNESCO World Heritage
              site
            </li>
            <li>
              Explore the Galle Fort, the lighthouse, and the Dutch Reformed
              Church
            </li>
            <li>
              Visit the Turtle Hatchery in Bentota and learn about conservation
              efforts
            </li>
            <li>
              Relax on Bentota Beach or take a boat ride on the Madu River
            </li>
            <li>Stay overnight in Bentota.</li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Travel back to Colombo or Bandaranaike International Airport
            </li>
            <li>Depart from Sri Lanka.</li>
            <li>
              This itinerary offers a mix of history, culture, nature, and
              scenic landscapes, showcasing some of Sri Lanka's most iconic
              attractions.{" "}
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "Thailand",
      content: `Known for its vibrant culture, beautiful beaches, and delicious food, Thailand is a popular tourist destination in Southeast Asia. It is also known for its ancient temples, bustling cities, and friendly people.`,
      img: thailandImage,
      rev: true,
      night3: (
        <>
          <h4>Bangkok</h4>
          <ul>
            <li>
              Arrive at Suvarnabhumi Airport in Bangkok and check into your
              hotel
            </li>
            <li>
              Explore the city's temples, such as the Grand Palace and Wat Pho,
              which houses the famous reclining Buddha statue
            </li>
            <li>
              Visit the vibrant street markets, such as Chatuchak Weekend Market
              or Chinatown
            </li>
            <li>Enjoy Thai street food and nightlife in the evening</li>
            <li>Stay overnight in Bangkok.</li>
          </ul>
          <h4>Bangkok</h4>
          <ul>
            <li>
              Take a boat ride along the Chao Phraya River and visit the famous
              Temple of Dawn (Wat Arun)
            </li>
            <li>
              Visit the Jim Thompson House, a museum showcasing traditional Thai
              architecture and art
            </li>
            <li>
              Shop for souvenirs at one of Bangkok's many malls, such as Siam
              Paragon or Central World
            </li>
            <li>Stay overnight in Bangkok.</li>
          </ul>
          <h4>Ayutthaya</h4>
          <ul>
            <li>
              Take a day trip to the ancient city of Ayutthaya, a UNESCO World
              Heritage site and the former capital of Siam
            </li>
            <li>
              Explore the ancient ruins, temples, and palaces, such as Wat
              Mahathat, Wat Phra Sri Sanphet, and the Bang Pa-In Palace
            </li>
            <li>
              Enjoy the scenic views along the Chao Phraya River and the local
              markets and food stalls
            </li>
            <li>Return to Bangkok and stay overnight.</li>
          </ul>
          <h4> Departure</h4>
          <ul>
            <li>
              Enjoy your last day in Bangkok by visiting any sites or
              attractions you missed
            </li>
            <li>Transfer to Suvarnabhumi Airport for your departure flight.</li>
            <li>
              This itinerary allows you to experience Bangkok's vibrant city
              life, as well as the historical and cultural landmarks of
              Ayutthaya, all in a short amount of time.{" "}
            </li>
          </ul>
        </>
      ),
      night5: (
        <>
          <h3>
            Thailand has a lot to offer, from beautiful beaches to ancient
            temples and bustling cities. Here's a suggested itinerary for a
            5-night, 6-day trip to Thailand:
          </h3>
          <h4>Bangkok</h4>
          <ul>
            <li>Arrive in Bangkok and check into your hotel</li>
            <li>
              Visit the Grand Palace and Wat Phra Kaew (Temple of the Emerald
              Buddha)
            </li>
            <li>
              Take a boat ride along the Chao Phraya River and explore the local
              markets
            </li>
            <li>Enjoy some delicious Thai street food for dinner</li>
          </ul>
          <h4> Bangkok</h4>
          <ul>
            <li>
              Visit Wat Pho (Temple of the Reclining Buddha) and see the giant
              golden statue
            </li>
            <li>
              Take a tuk-tuk ride through the city and explore the different
              neighborhoods
            </li>
            <li>
              Visit the Jim Thompson House to learn about Thai silk and
              architecture
            </li>
            <li>Enjoy a relaxing Thai massage to end the day</li>
          </ul>
          <h4>Chiang Mai</h4>
          <ul>
            <li>Take a flight to Chiang Mai and check into your hotel</li>
            <li>
              Visit Doi Suthep temple and enjoy panoramic views of the city
            </li>
            <li>
              Explore the historic old town and its temples, such as Wat Chedi
              Luang and Wat Phra Singh
            </li>
            <li>
              Walk through the night market and try some northern Thai cuisine
            </li>
          </ul>
          <h4> Chiang Mai</h4>
          <ul>
            <li>
              Visit an elephant sanctuary and spend the day learning about and
              interacting with the gentle giants
            </li>
            <li>
              Take a cooking class and learn how to make some delicious Thai
              dishes
            </li>
            <li>
              Visit the Baan Kang Wat artist village and see local craftsmen at
              work
            </li>
          </ul>
          <h4>Phuket</h4>
          <ul>
            <li>Take a flight to Phuket and check into your hotel</li>
            <li>Relax on the beach and enjoy the turquoise waters</li>
            <li>
              Visit Phuket Old Town and explore its colorful streets and shops
            </li>
            <li>
              Take a sunset boat ride and enjoy a seafood dinner on the water
            </li>
          </ul>
          <h4> Phuket</h4>
          <ul>
            <li>
              Take a day trip to Phi Phi Islands and enjoy the stunning scenery
              and beaches
            </li>
            <li>
              Go snorkeling or scuba diving to see the colorful marine life
            </li>
            <li>Visit a local market and try some traditional Thai snacks</li>
            <li>End the day with a relaxing massage or spa treatment</li>
          </ul>
          <p>
            This itinerary should give you a good taste of Thailand's diverse
            culture, history, and natural beauty. Of course, there are many
            other places and activities to explore in Thailand, so feel free to
            adjust this itinerary to your preferences and interests.
          </p>
        </>
      ),
      night6: (
        <>
          <h4>Bangkok</h4>
          <ul>
            <li>Arrive in Bangkok and check into your hotel</li>
            <li>
              Visit the Grand Palace and Wat Phra Kaew (Temple of the Emerald
              Buddha)
            </li>
            <li>
              Take a boat ride along the Chao Phraya River and explore the local
              markets
            </li>
            <li>Enjoy some delicious Thai street food for dinner</li>
          </ul>
          <h4> Bangkok</h4>
          <ul>
            <li>
              Visit Wat Pho (Temple of the Reclining Buddha) and see the giant
              golden statue
            </li>
            <li>
              Take a tuk-tuk ride through the city and explore the different
              neighborhoods
            </li>
            <li>
              Visit the Jim Thompson House to learn about Thai silk and
              architecture
            </li>
            <li>Enjoy a relaxing Thai massage to end the day</li>
          </ul>
          <h4> Ayutthaya</h4>
          <ul>
            <li>
              Take a day trip to Ayutthaya, the former capital of Thailand
            </li>
            <li>
              Visit the ancient temples and ruins, including Wat Mahathat and
              Wat Chaiwatthanaram
            </li>
            <li>
              Take a boat ride along the river and see the floating markets
            </li>
            <li>Return to Bangkok for the night</li>
          </ul>
          <h4>Chiang Mai</h4>
          <ul>
            <li>Take a flight to Chiang Mai and check into your hotel</li>
            <li>
              Visit Doi Suthep temple and enjoy panoramic views of the city
            </li>
            <li>
              Explore the historic old town and its temples, such as Wat Chedi
              Luang and Wat Phra Singh
            </li>
            <li>
              Walk through the night market and try some northern Thai cuisine
            </li>
          </ul>
          <h4>Chiang Mai</h4>
          <ul>
            <li>
              Visit an elephant sanctuary and spend the day learning about and
              interacting with the gentle giants
            </li>
            <li>
              Take a cooking class and learn how to make some delicious Thai
              dishes
            </li>
            <li>
              Visit the Baan Kang Wat artist village and see local craftsmen at
              work
            </li>
          </ul>
          <h4>Phuket</h4>
          <ul>
            <li>Take a flight to Phuket and check into your hotel</li>
            <li>Relax on the beach and enjoy the turquoise waters</li>
            <li>
              Visit Phuket Old Town and explore its colorful streets and shops
            </li>
            <li>
              Take a sunset boat ride and enjoy a seafood dinner on the water
            </li>
          </ul>
          <h4>Phi Phi Islands</h4>
          <ul>
            <li>
              Take a day trip to Phi Phi Islands and enjoy the stunning scenery
              and beaches
            </li>
            <li>
              Go snorkeling or scuba diving to see the colorful marine life
            </li>
            <li>Visit a local market and try some traditional Thai snacks</li>
            <li>End the day with a relaxing massage or spa treatment</li>
          </ul>
          <h4> Bangkok</h4>
          <ul>
            <li>
              Take a flight back to Bangkok and spend the day shopping and
              exploring the city
            </li>
            <li>
              Visit the Chatuchak Weekend Market for some unique souvenirs
            </li>
            <li>Depart from Bangkok for your onward journey</li>
          </ul>
          <p>
            This itinerary should give you a great overview of Thailand's
            diverse culture, history, and natural beauty. Of course, there are
            many other places and activities to explore in Thailand, so feel
            free to adjust this itinerary to your preferences and interests.
          </p>
        </>
      ),
    },
  ],
  europe: [
    {
      name: "Austria",
      content: `Located in Central Europe, Austria is known for its stunning Alpine scenery, rich cultural heritage, and delicious food. It is home to historical landmarks, including the Hofburg Palace and the Schönbrunn Palace.`,
      img: austriaImage,
      rev: true,
      night3: (
        <>
          <h4>Arrival in Vienna</h4>
          <ul>
            <li>Arrive in Vienna and check into your hotel</li>
            <li>
              Explore the city center on foot, starting at St. Stephen's
              Cathedral
            </li>
            <li>
              Walk through Graben and Kohlmarkt, two famous shopping streets
            </li>
            <li>Enjoy a traditional Austrian dinner at a local restaurant</li>
            <li>
              Optional: Attend a classical music concert at one of Vienna's
              famous concert halls
            </li>
          </ul>
          <h4>Vienna</h4>
          <ul>
            <li>
              Start the day with a Viennese breakfast at a traditional
              coffeehouse
            </li>
            <li>
              Visit Schönbrunn Palace, the former imperial summer residence and
              a UNESCO World Heritage site
            </li>
            <li>
              Take a walk through the palace gardens and see the Gloriette, a
              magnificent viewpoint
            </li>
            <li>
              Visit the Belvedere Palace, another impressive Baroque palace with
              a beautiful garden
            </li>
            <li>
              End the day with dinner at a Heuriger, a traditional Austrian wine
              tavern on the outskirts of Vienna
            </li>
          </ul>
          <h4>Salzburg</h4>
          <ul>
            <li>
              Take a train to Salzburg, the birthplace of Wolfgang Amadeus
              Mozart and also a UNESCO World Heritage site
            </li>
            <li>
              Visit Mirabell Palace and its gardens, which were featured in the
              movie "The Sound of Music"
            </li>
            <li>
              Explore the old town of Salzburg, including Mozart's birthplace
              and the Salzburg Cathedral
            </li>
            <li>
              Take a funicular to Hohensalzburg Fortress, one of the largest
              medieval castles in Europe with stunning views of the city
            </li>
            <li>Enjoy dinner at a local restaurant in Salzburg</li>
          </ul>
          <h4>Vienna and Departure</h4>
          <ul>
            <li>
              Return to Vienna by train and spend the morning exploring the
              Naschmarkt, a large outdoor market with a wide variety of food and
              goods
            </li>
            <li>
              Visit the Hofburg Palace, the former imperial palace and the
              current residence of the President of Austria
            </li>
            <li>
              Take a stroll through the Volksgarten and see the famous statue of
              Johann Strauss II
            </li>
            <li>
              Depart from Vienna and head home, or continue your travels in
              Europe.
            </li>
          </ul>
        </>
      ),
      night5: (
        <>
          <h4>Arrival in Vienna</h4>
          <ul>
            <li>Arrive in Vienna and check into your hotel</li>
            <li>
              Explore the city center on foot, starting at St. Stephen's
              Cathedral
            </li>
            <li>
              Walk through Graben and Kohlmarkt, two famous shopping streets
            </li>
            <li>Enjoy a traditional Austrian dinner at a local restaurant</li>
            <li>
              Optional: Attend a classical music concert at one of Vienna's
              famous concert halls
            </li>
          </ul>
          <h4>Vienna</h4>
          <ul>
            <li>
              Start the day with a Viennese breakfast at a traditional
              coffeehouse
            </li>
            <li>
              Visit Schönbrunn Palace, the former imperial summer residence and
              a UNESCO World Heritage site
            </li>
            <li>
              Take a walk through the palace gardens and see the Gloriette, a
              magnificent viewpoint
            </li>
            <li>
              Visit the Belvedere Palace, another impressive Baroque palace with
              a beautiful garden
            </li>
            <li>
              End the day with dinner at a Heuriger, a traditional Austrian wine
              tavern on the outskirts of Vienna
            </li>
          </ul>
          <h4>Salzburg</h4>
          <ul>
            <li>
              Take a train to Salzburg, the birthplace of Wolfgang Amadeus
              Mozart and also a UNESCO World Heritage site
            </li>
            <li>
              Visit Mirabell Palace and its gardens, which were featured in the
              movie "The Sound of Music"
            </li>
            <li>
              Explore the old town of Salzburg, including Mozart's birthplace
              and the Salzburg Cathedral
            </li>
            <li>
              Take a funicular to Hohensalzburg Fortress, one of the largest
              medieval castles in Europe with stunning views of the city
            </li>
            <li>Enjoy dinner at a local restaurant in Salzburg</li>
          </ul>
          <h4> Innsbruck</h4>
          <ul>
            <li>
              Take a train to Innsbruck, a picturesque city in the Austrian Alps
            </li>
            <li>Visit the Golden Roof, a famous landmark in the old town</li>
            <li>
              Take the cable car to Nordkette, a mountain range with
              breathtaking views of Innsbruck and the surrounding Alps
            </li>
            <li>
              Explore the Hofburg Imperial Palace and the Tyrolean Folk Art
              Museum
            </li>
            <li>Enjoy dinner at a local restaurant in Innsbruck</li>
          </ul>
          <h4>Hallstatt</h4>
          <ul>
            <li>
              Take a train to Hallstatt, a charming lakeside village nestled in
              the Austrian Alps
            </li>
            <li>Explore the town and take a boat tour of the lake</li>
            <li>
              Visit the Hallstatt Salt Mine, an essential part of the village's
              history and culture
            </li>
            <li>
              Take the cable car to the Hallstatt Skywalk, a viewpoint with
              stunning panoramic views of the lake and mountains
            </li>
            <li>Return to Vienna in the evening</li>
          </ul>
          <h4>Vienna</h4>
          <ul>
            <li>
              Visit the Kunsthistorisches Museum, one of the largest and most
              important art museums in the world
            </li>
            <li>
              Take a walk through the Naschmarkt, a large outdoor market with a
              wide variety of food and goods
            </li>
            <li>
              Visit the Vienna State Opera, one of the most famous opera houses
              in the world
            </li>
            <li>Enjoy dinner at a local restaurant in Vienna</li>
          </ul>
          <h4>Vienna and Departure</h4>
          <ul>
            <li>
              Visit the Hofburg Palace, the former imperial palace and the
              current residence of the President of Austria
            </li>
            <li>
              Take a stroll through the Volksgarten and see the famous statue of
              Johann Strauss II
            </li>
            <li>
              Depart from Vienna and head home, or continue your travels in
              Europe.
            </li>
          </ul>
        </>
      ),
      night6: (
        <>
          <h4>Arrival in Vienna</h4>
          <ul>
            <li>Arrive in Vienna and check into your hotel</li>
            <li>
              Explore the city center on foot, starting at St. Stephen's
              Cathedral
            </li>
            <li>
              Walk through Graben and Kohlmarkt, two famous shopping streets
            </li>
            <li>Enjoy a traditional Austrian dinner at a local restaurant</li>
            <li>
              Optional: Attend a classical music concert at one of Vienna's
              famous concert halls
            </li>
          </ul>
          <h4>Vienna</h4>
          <ul>
            <li>
              Start the day with a Viennese breakfast at a traditional
              coffeehouse
            </li>
            <li>
              Visit Schönbrunn Palace, the former imperial summer residence and
              a UNESCO World Heritage site
            </li>
            <li>
              Take a walk through the palace gardens and see the Gloriette, a
              magnificent viewpoint
            </li>
            <li>
              Visit the Belvedere Palace, another impressive Baroque palace with
              a beautiful garden
            </li>
            <li>
              End the day with dinner at a Heuriger, a traditional Austrian wine
              tavern on the outskirts of Vienna
            </li>
          </ul>
          <h4>Wachau Valley</h4>
          <ul>
            <li>
              Take a day trip to the Wachau Valley, a scenic area along the
              Danube River known for its vineyards and charming villages
            </li>
            <li>
              Visit the Melk Abbey, a magnificent Baroque abbey perched on a
              hill overlooking the Danube
            </li>
            <li>
              Take a boat tour of the Danube and enjoy the views of the
              vineyards and villages
            </li>
            <li>
              Visit the charming village of Dürnstein, with its castle ruins and
              picturesque streets
            </li>
            <li>Return to Vienna in the evening</li>
          </ul>
          <h4>Salzburg</h4>
          <ul>
            <li>
              Take a train to Salzburg, the birthplace of Wolfgang Amadeus
              Mozart and also a UNESCO World Heritage site
            </li>
            <li>
              Visit Mirabell Palace and its gardens, which were featured in the
              movie "The Sound of Music"
            </li>
            <li>
              Explore the old town of Salzburg, including Mozart's birthplace
              and the Salzburg Cathedral
            </li>
            <li>
              Take a funicular to Hohensalzburg Fortress, one of the largest
              medieval castles in Europe with stunning views of the city
            </li>
            <li>Enjoy dinner at a local restaurant in Salzburg</li>
          </ul>
          <h4>Innsbruck</h4>
          <ul>
            <li>
              Take a train to Innsbruck, a picturesque city in the Austrian Alps
            </li>
            <li>Visit the Golden Roof, a famous landmark in the old town</li>
            <li>
              Take the cable car to Nordkette, a mountain range with
              breathtaking views of Innsbruck and the surrounding Alps
            </li>
            <li>
              Explore the Hofburg Imperial Palace and the Tyrolean Folk Art
              Museum
            </li>
            <li>Enjoy dinner at a local restaurant in Innsbruck</li>
          </ul>
          <h4> Hallstatt</h4>
          <ul>
            <li>
              Take a train to Hallstatt, a charming lakeside village nestled in
              the Austrian Alps
            </li>
            <li>Explore the village and take a boat tour of the lake</li>
            <li>
              Visit the Hallstatt Salt Mine, an important part of the village's
              history and culture
            </li>
            <li>
              Take the cable car to the Hallstatt Skywalk, a viewpoint with
              stunning panoramic views of the lake and mountains
            </li>
            <li>Return to Vienna in the evening</li>
          </ul>
          <h4>Vienna</h4>
          <ul>
            <li>
              Visit the Kunsthistorisches Museum, one of the largest and most
              important art museums in the world
            </li>
            <li>
              Take a walk through the Naschmarkt, a large outdoor market with a
              wide variety of food and goods
            </li>
            <li>
              Visit the Vienna State Opera, one of the most famous opera houses
              in the world
            </li>
            <li>Enjoy dinner at a local restaurant in Vienna</li>
          </ul>
          <h4>Vienna and Departure</h4>
          <ul>
            <li>
              Visit the Hofburg Palace, the former imperial palace and the
              current residence of the President of Austria
            </li>
            <li>
              Take a stroll through the Volksgarten and see the famous statue of
              Johann Strauss II
            </li>
            <li>
              Depart from Vienna and head home, or continue your travels in
              Europe.
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "Finland",
      content: `Known for its stunning natural beauty, including the Northern Lights, Finland is a country located in Northern Europe. It is also famous for its saunas, which are an important part of Finnish culture.`,
      img: finlandImage,
      rev: false,
      night3: (
        <>
          <h4>Helsinki</h4>
          <ul>
            <li>Arrive in Helsinki and check into your hotel</li>
            <li>
              Take a stroll through the city center and visit some of Helsinki's
              top attractions, such as the Helsinki Cathedral and Senate Square
            </li>
            <li>
              In the evening, head to a local restaurant to try some traditional
              Finnish cuisine
            </li>
          </ul>
          <h4>Helsinki</h4>
          <ul>
            <li>
              Start your day with a visit to the Temppeliaukio Church, a unique
              church built directly into a solid rock
            </li>
            <li>
              Head to the Design District to explore Helsinki's vibrant design
              scene and boutique shops
            </li>
            <li>
              Spend the afternoon at the Helsinki Market Square, where you can
              browse local handicrafts and try some Finnish street food
            </li>
          </ul>
          <h4> Suomenlinna Fortress and Seurasaari Open-Air Museum</h4>
          <ul>
            <li>
              Take a ferry to Suomenlinna Fortress, a UNESCO World Heritage site
              that was built in the 18th century to protect Helsinki from
              Russian attacks
            </li>
            <li>
              After exploring the fortress, take another ferry to Seurasaari
              Open-Air Museum, an outdoor museum that showcases traditional
              Finnish architecture and way of life
            </li>
            <li>In the evening, head back to Helsinki for a farewell dinner</li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Depending on your flight schedule, you may have some free time in
              the morning to do some last-minute souvenir shopping or explore
              more of Helsinki
            </li>
            <li>
              Check out of your hotel and head to the airport for your departure
              flight
            </li>
          </ul>
        </>
      ),
      night5: (
        <>
          <h4>Helsinki</h4>
          <ul>
            <li>Arrive in Helsinki and check into your accommodation</li>
            <li>
              Spend the day exploring the city, starting with a walking tour of
              the historic center and its landmarks, such as the Helsinki
              Cathedral and the Senate Square
            </li>
            <li>
              Visit the Helsinki Market Square and try some local food, such as
              grilled salmon or reindeer meatballs
            </li>
            <li>In the evening, relax at a traditional Finnish sauna</li>
          </ul>
          <h4>Helsinki</h4>
          <ul>
            <li>
              Take a ferry to Suomenlinna, a UNESCO World Heritage Site and sea
              fortress, located on an island just a short boat ride from
              Helsinki
            </li>
            <li>
              Spend the day exploring the fortress, its museums, and the
              beautiful surrounding nature
            </li>
            <li>
              In the evening, head back to Helsinki for dinner and a night out
              in the city's vibrant nightlife scene
            </li>
          </ul>
          <h4>Rovaniemi</h4>
          <ul>
            <li>
              Fly to Rovaniemi, the capital of Finnish Lapland, known as the
              official home town of Santa Claus
            </li>
            <li>
              Visit Santa Claus Village and meet Santa Claus himself, explore
              the Santa Claus Post Office, and send some postcards with special
              Arctic Circle postmarks
            </li>
            <li>
              Enjoy a reindeer sleigh ride or snowmobile tour in the Arctic
              wilderness
            </li>
            <li>
              Spend the night in Rovaniemi and, if lucky, watch out for the
              Northern Lights
            </li>
          </ul>
          <h4>Rovaniemi</h4>
          <ul>
            <li>
              Visit the Ranua Wildlife Park, the northernmost zoo in the world,
              where you can see over 50 species of Arctic animals, including
              polar bears, wolves, and lynx
            </li>
            <li>
              In the afternoon, visit the Arktikum museum, where you can learn
              about the history, culture, and nature of Lapland and the Arctic
              region
            </li>
            <li>
              Return to Rovaniemi for dinner and enjoy the local delicacies,
              such as smoked reindeer or sautéed reindeer with mashed potatoes
            </li>
          </ul>
          <h4>Levi</h4>
          <ul>
            <li>
              Travel to Levi, one of the most popular ski resorts in Finland,
              located in the Finnish Lapland region
            </li>
            <li>
              Enjoy a day of skiing or snowboarding on the slopes, or if it's
              summertime, try some other outdoor activities, such as hiking,
              mountain biking, or canoeing
            </li>
            <li>
              In the evening, relax in the Levi Spa or one of the local
              restaurants and bars
            </li>
          </ul>
          <h4>Helsinki</h4>
          <ul>
            <li>
              Return to Helsinki and spend your last day shopping for souvenirs
              and gifts, such as Finnish design and handicrafts, or visit one of
              the city's museums or galleries
            </li>
            <li>
              Have a farewell dinner and toast to your memorable trip to Finland
            </li>
            <li>Depart for home or your next destination.</li>
          </ul>
        </>
      ),
      night6: (
        <>
          <h4>Helsinki</h4>
          <ul>
            <li>Arrive in Helsinki and check into your accommodation</li>
            <li>
              Spend the day exploring the city, starting with a walking tour of
              the historic center and its landmarks, such as the Helsinki
              Cathedral and the Senate Square
            </li>
            <li>
              Visit the Helsinki Market Square and try some local food, such as
              grilled salmon or reindeer meatballs
            </li>
            <li>In the evening, relax at a traditional Finnish sauna</li>
          </ul>
          <h4>Helsinki</h4>
          <ul>
            <li>
              Take a ferry to Suomenlinna, a UNESCO World Heritage Site and sea
              fortress, located on an island just a short boat ride from
              Helsinki
            </li>
            <li>
              Spend the day exploring the fortress, its museums, and the
              beautiful surrounding nature
            </li>
            <li>
              In the evening, head back to Helsinki for dinner and a night out
              in the city's vibrant nightlife scene
            </li>
          </ul>
          <h4>Porvoo and Kotka</h4>
          <ul>
            <li>
              Visit the charming town of Porvoo, the second oldest town in
              Finland, known for its beautiful old town with its narrow streets,
              wooden houses, and picturesque riverfront
            </li>
            <li>
              Continue to Kotka, a coastal town known for its beautiful parks
              and gardens, such as the Sapokka Water Garden and the Kotka
              Maretarium aquarium
            </li>
            <li>Spend the night in Kotka</li>
          </ul>
          <h4>Koli National Park</h4>
          <ul>
            <li>
              Travel to Koli National Park, a stunning natural wonderland of
              forests, lakes, hills, and cliffs, located in eastern Finland near
              the Russian border
            </li>
            <li>
              Hike or bike the park's trails, which offer breathtaking views of
              Lake Pielinen and the surrounding landscapes
            </li>
            <li>
              Spend the night in one of the park's cabins or nearby hotels
            </li>
          </ul>
          <h4>Savonlinna and Lake Saimaa</h4>
          <ul>
            <li>
              Visit the town of Savonlinna, located on the shores of Lake
              Saimaa, the largest lake in Finland and the fourth largest in
              Europe
            </li>
            <li>
              Explore the historic Olavinlinna Castle, which dates back to the
              15th century and hosts the annual Savonlinna Opera Festival
            </li>
            <li>
              Take a cruise on Lake Saimaa and enjoy the beautiful scenery and
              wildlife, such as ospreys, eagles, and beavers
            </li>
            <li>Spend the night in Savonlinna or nearby</li>
          </ul>
          <h4> Kuopio and Tahko</h4>
          <ul>
            <li>
              Visit the town of Kuopio, located on the shores of Lake Kallavesi,
              and enjoy the local specialties, such as kalakukko (a Finnish fish
              pie) and traditional Finnish pastries
            </li>
            <li>
              Continue to Tahko, a popular ski resort in winter and a year-round
              destination for outdoor activities, such as hiking, mountain
              biking, and water sports
            </li>
            <li>Spend the night in Tahko</li>
          </ul>
          <h4>Rovaniemi</h4>
          <ul>
            <li>
              Fly to Rovaniemi, the capital of Finnish Lapland, known as the
              official hometown of Santa Claus
            </li>
            <li>
              Visit Santa Claus Village and meet Santa Claus himself, explore
              the Santa Claus Post Office, and send some postcards with special
              Arctic Circle postmarks
            </li>
            <li>
              Enjoy a reindeer sleigh ride or snowmobile tour in the Arctic
              wilderness
            </li>
            <li>
              Spend the night in Rovaniemi and, if lucky, watch out for the
              Northern Lights
            </li>
          </ul>
          <h4>Helsinki</h4>
          <ul>
            <li>
              Return to Helsinki and spend your last day shopping for souvenirs
              and gifts, such as Finnish design and handicrafts, or visit one of
              the city's museums or galleries
            </li>
            <li>
              Have a farewell dinner and toast to your memorable trip to Finland
            </li>
            <li>Depart for home or your next destination.</li>
          </ul>
        </>
      ),
    },
    {
      name: "France",
      content: ` Home to some of the world's most iconic landmarks, including the Eiffel Tower and the Louvre Museum, France is a country known for its rich cultural heritage, beautiful landscapes, and delicious cuisine.`,
      img: franceImage,
      rev: true,
      night3: (
        <>
          <h4>Paris</h4>
          <ul>
            <li>Arrive in Paris and check into your accommodation</li>
            <li>
              Explore the city's iconic landmarks, such as the Eiffel Tower,
              Notre-Dame Cathedral, the Louvre Museum, and the Champs-Élysées
            </li>
            <li>
              Enjoy a delicious French dinner at a local bistro or brasserie
            </li>
          </ul>
          <h4>Paris</h4>
          <ul>
            <li>
              Visit the Musée d'Orsay, home to an impressive collection of
              Impressionist and Post-Impressionist art, including works by
              Monet, Van Gogh, and Degas
            </li>
            <li>
              Take a stroll through the Marais neighborhood, known for its
              charming narrow streets, trendy boutiques, and historic
              architecture
            </li>
            <li>
              In the evening, enjoy a Seine River dinner cruise to see the
              city's sights from a different perspective
            </li>
          </ul>
          <h4>Versailles</h4>
          <ul>
            <li>
              Take a day trip to the Palace of Versailles, a UNESCO World
              Heritage Site and former residence of French royalty
            </li>
            <li>
              Explore the palace's ornate rooms, hall of mirrors, and stunning
              gardens
            </li>
            <li>
              Learn about the history of the French monarchy and the French
              Revolution
            </li>
            <li>Return to Paris for the night</li>
          </ul>
          <h4>Departure</h4>
          <p>
            Depart from Paris for your flight home. Alternatively, you can
            explore more of the city or take a day trip to nearby destinations
            like Normandy or the Loire Valley before departing.
          </p>
        </>
      ),

      night5: (
        <>
          <h4>Paris</h4>
          <ul>
            <li>Arrive in Paris and check into your accommodation</li>
            <li>
              Explore the city's iconic landmarks, such as the Eiffel Tower,
              Notre-Dame Cathedral, the Louvre Museum, and the Champs-Élysées
            </li>
            <li>
              Enjoy a delicious French dinner at a local bistro or brasserie
            </li>
          </ul>
          <h4>Paris</h4>
          <ul>
            <li>
              Visit the Musée d'Orsay, home to an impressive collection of
              Impressionist and Post-Impressionist art, including works by
              Monet, Van Gogh, and Degas
            </li>
            <li>
              Take a stroll through the Marais neighborhood, known for its
              charming narrow streets, trendy boutiques, and historic
              architecture
            </li>
            <li>
              In the evening, enjoy a Seine River dinner cruise to see the
              city's sights from a different perspective
            </li>
          </ul>
          <h4>Normandy</h4>
          <ul>
            <li>
              Take a day trip to the region of Normandy and visit the D-Day
              Landing Beaches, such as Omaha Beach and Utah Beach, where Allied
              forces landed during World War II
            </li>
            <li>
              Visit the American Cemetery and Memorial in Colleville-sur-Mer,
              which honors the soldiers who lost their lives during the Normandy
              landings
            </li>
            <li>
              Learn about the region's rich history and sample some of its
              famous products, such as cider and Camembert cheese
            </li>
            <li>Return to Paris for the night</li>
          </ul>
          <h4>Versailles</h4>
          <ul>
            <li>
              Take a day trip to the Palace of Versailles, a UNESCO World
              Heritage Site and former residence of French royalty
            </li>
            <li>
              Explore the palace's ornate rooms, hall of mirrors, and stunning
              gardens
            </li>
            <li>
              Learn about the history of the French monarchy and the French
              Revolution
            </li>
            <li>Return to Paris for the night</li>
          </ul>
          <h4>Lyon</h4>
          <ul>
            <li>
              Take a train to Lyon, a historic city known for its excellent
              cuisine and beautiful architecture
            </li>
            <li>
              Visit the Vieux Lyon neighborhood, known for its narrow
              cobblestone streets, Renaissance-era buildings, and local shops
              and restaurants
            </li>
            <li>
              Explore the city's many museums, such as the Musée des Beaux-Arts
              and the Gallo-Roman Museum
            </li>
            <li>
              Sample some of the city's famous dishes, such as coq au vin and
              quenelles
            </li>
            <li>Spend the night in Lyon</li>
          </ul>
          <h4>Nice and the French Riviera</h4>
          <ul>
            <li>
              Take a train to the French Riviera and visit the charming city of
              Nice, known for its sunny beaches, palm trees, and beautiful
              architecture
            </li>
            <li>
              Explore the old town and its colorful markets and restaurants
            </li>
            <li>
              Take a stroll along the Promenade des Anglais and enjoy the views
              of the Mediterranean Sea
            </li>
            <li>
              Visit the nearby towns of Cannes and Antibes, known for their
              luxury yachts, casinos, and festivals
            </li>
            <li>Spend the night in Nice</li>
          </ul>
          <h4> Departure</h4>
          <p>Depart from Nice or return to Paris for your flight home.</p>
        </>
      ),

      night6: (
        <>
          <h4>Paris</h4>
          <ul>
            <li>Arrive in Paris and check into your accommodation</li>
            <li>
              Explore the city's iconic landmarks, such as the Eiffel Tower,
              Notre-Dame Cathedral, the Louvre Museum, and the Champs-Élysées
            </li>
            <li>
              Enjoy a delicious French dinner at a local bistro or brasserie
            </li>
          </ul>
          <h4>Paris</h4>
          <ul>
            <li>
              Visit the Musée d'Orsay, home to an impressive collection of
              Impressionist and Post-Impressionist art, including works by
              Monet, Van Gogh, and Degas
            </li>
            <li>
              Take a stroll through the Marais neighborhood, known for its
              charming narrow streets, trendy boutiques, and historic
              architecture
            </li>
            <li>
              In the evening, enjoy a Seine River dinner cruise to see the
              city's sights from a different perspective
            </li>
          </ul>
          <h4> Normandy</h4>
          <ul>
            <li>
              Take a day trip to the region of Normandy and visit the D-Day
              Landing Beaches, such as Omaha Beach and Utah Beach, where Allied
              forces landed during World War II
            </li>
            <li>
              Visit the American Cemetery and Memorial in Colleville-sur-Mer,
              which honors the soldiers who lost their lives during the Normandy
              landings
            </li>
            <li>
              Learn about the region's rich history and sample some of its
              famous products, such as cider and Camembert cheese
            </li>
            <li>Return to Paris for the night</li>
          </ul>
          <h4>Loire Valley</h4>
          <ul>
            <li>
              Travel to the Loire Valley, known for its picturesque landscapes,
              charming towns, and historic castles and palaces
            </li>
            <li>
              Visit the Château de Chambord, the largest and most impressive
              castle in the region, known for its Renaissance architecture and
              stunning gardens
            </li>
            <li>
              Take a wine tour and sample some of the Loire Valley's famous
              wines, such as Sancerre and Chinon
            </li>
            <li>Spend the night in the Loire Valley</li>
          </ul>
          <h4>Lyon</h4>
          <ul>
            <li>
              Travel to Lyon, a historic city known for its excellent cuisine
              and beautiful architecture
            </li>
            <li>
              Visit the Vieux Lyon neighborhood, known for its narrow
              cobblestone streets, Renaissance-era buildings, and local shops
              and restaurants
            </li>
            <li>
              Explore the city's many museums, such as the Musée des Beaux-Arts
              and the Gallo-Roman Museum
            </li>
            <li>
              Sample some of the city's famous dishes, such as coq au vin and
              quenelles
            </li>
            <li>Spend the night in Lyon</li>
          </ul>
          <h4>Provence</h4>
          <ul>
            <li>
              Travel to the region of Provence, known for its beautiful
              landscapes, charming villages, and delicious cuisine
            </li>
            <li>
              Visit the city of Avignon, known for its UNESCO-listed historic
              center and its famous Palais des Papes
            </li>
            <li>
              Explore the picturesque town of Saint-Rémy-de-Provence, where Van
              Gogh once lived, and visit its many galleries and boutiques
            </li>
            <li>
              Take a tour of a lavender field and sample some of the region's
              famous wines and cheeses
            </li>
            <li>Spend the night in Provence</li>
          </ul>
          <h4>Nice and the French Riviera</h4>
          <ul>
            <li>
              Travel to the French Riviera and visit the charming city of Nice,
              known for its sunny beaches, palm trees, and beautiful
              architecture
            </li>
            <li>
              Explore the old town and its colorful markets and restaurants
            </li>
            <li>
              Take a stroll along the Promenade des Anglais and enjoy the views
              of the Mediterranean Sea
            </li>
            <li>
              Visit the nearby towns of Cannes and Antibes, known for their
              luxury yachts, casinos, and festivals
            </li>
            <li>Spend the night in Nice</li>
          </ul>
          <h4>Paris</h4>
          <ul>
            <li>
              Return to Paris and spend your last day shopping for souvenirs and
              gifts, such as French perfumes, chocolates, and fashion
            </li>
            <li>
              Visit one of the city's many parks or gardens, such as the
              Luxembourg Gardens or the Tuileries Garden
            </li>
            <li>Have a farewell</li>
          </ul>
        </>
      ),
    },
    {
      name: "Georgia",
      content: `Located in the South Caucasus region, Georgia is a country with a rich cultural heritage, including ancient churches, monasteries, and historical landmarks. It is also known for its wine and delicious cuisine.`,
      img: georiaImage,
      rev: false,
      night3: (
        <>
          <ul>
            <li>Arrive in Tbilisi, the capital city of Georgia</li>
            <li>Check into your hotel and freshen up</li>
            <li>
              Take a walking tour of Tbilisi to get acquainted with the city and
              its culture
            </li>
            <li>
              Visit the Narikala Fortress for a panoramic view of the city and
              take a cable car ride
            </li>
            <li>Enjoy Georgian cuisine at a local restaurant</li>
            <li>Stroll through the Old Town and visit the Sulfur Baths</li>
          </ul>
          <br />
          <ul>
            <li>
              Head out to Mtskheta, a UNESCO World Heritage site, and the
              ancient capital of Georgia
            </li>
            <li>
              Visit the Svetitskhoveli Cathedral, one of the most important
              religious sites in Georgia
            </li>
            <li>
              Continue to Jvari Monastery, located on a hilltop overlooking the
              city and offering breathtaking views of the surrounding landscape
            </li>
            <li>Return to Tbilisi for a leisurely evening</li>
          </ul>
          <br />
          <ul>
            <li>Take a day trip to the wine region of Kakheti</li>
            <li>
              Visit the 17th-century fortified town of Sighnaghi, known for its
              picturesque views of the Alazani Valley and the Caucasus Mountains
            </li>
            <li>
              Stop at local wineries for tastings and learn about the
              traditional Georgian winemaking process
            </li>
            <li>Return to Tbilisi for a farewell dinner.</li>
          </ul>
          <br />
          <ul>
            <li>
              Check out of your hotel and transfer to the airport for your
              departure flight.
            </li>
          </ul>
          <p>
            This itinerary covers some of the must-visit sites in Georgia,
            including its capital city, historical landmarks, and scenic wine
            regions.
          </p>
        </>
      ),
      night5: (
        <>
          <ul>
            <li>Arrive in Tbilisi, the capital city of Georgia</li>
            <li>Check into your hotel and freshen up</li>
            <li>
              Take a walking tour of Tbilisi to get acquainted with the city and
              its culture
            </li>
            <li>
              Visit the Narikala Fortress for a panoramic view of the city and
              take a cable car ride
            </li>
            <li>Enjoy Georgian cuisine at a local restaurant</li>
            <li>Stroll through the Old Town and visit the Sulfur Baths</li>
          </ul>
          <br />
          <ul>
            <li>
              Head out to Mtskheta, a UNESCO World Heritage site, and the
              ancient capital of Georgia
            </li>
            <li>
              Visit the Svetitskhoveli Cathedral, one of the most important
              religious sites in Georgia
            </li>
            <li>
              Continue to Jvari Monastery, located on a hilltop overlooking the
              city and offering breathtaking views of the surrounding landscape
            </li>
            <li>Return to Tbilisi for a leisurely evening</li>
          </ul>
          <br />
          <ul>
            <li>Take a day trip to the wine region of Kakheti</li>
            <li>
              Visit the 17th-century fortified town of Sighnaghi, known for its
              picturesque views of the Alazani Valley and the Caucasus Mountains
            </li>
            <li>
              Stop at local wineries for tastings and learn about the
              traditional Georgian winemaking process
            </li>
            <li>Return to Tbilisi for the night</li>
          </ul>
          <br />
          <ul>
            <li>
              Travel to the mountainous region of Kazbegi, located in the north
              of Georgia
            </li>
            <li>
              Visit the Gergeti Trinity Church, located on the top of a mountain
              and offering stunning views of the surrounding landscape
            </li>
            <li>
              Take a 4x4 ride to the Gveleti Waterfall and enjoy a picnic lunch
              in the scenic surroundings
            </li>
            <li>Return to Tbilisi in the evening</li>
          </ul>
          <br />
          <ul>
            <li>
              Visit the ancient cave city of Uplistsikhe, located in the eastern
              part of Georgia
            </li>
            <li>
              Continue to the medieval city of Gori and visit the Stalin Museum
            </li>
            <li>Return to Tbilisi for the night</li>
          </ul>
          <br />
          <ul>
            <li>Take a day trip to the Black Sea region of Georgia</li>
            <li>
              Visit the city of Batumi and stroll along its famous seaside
              promenade
            </li>
            <li>
              See the Batumi Botanical Garden, known for its beautiful and
              diverse collection of plants
            </li>
            <li>Return to Tbilisi for a farewell dinner.</li>
          </ul>
          <br />
          <ul>
            <li>
              Check out of your hotel and transfer to the airport for your
              departure flight.
            </li>
          </ul>
          <p>
            This itinerary covers some of the must-visit sites in Georgia,
            including its capital city, historical landmarks, scenic wine
            regions, and breathtaking mountainous landscapes.{" "}
          </p>
        </>
      ),
      night6: (
        <>
          <ul>
            <li>Arrive in Tbilisi, the capital city of Georgia</li>
            <li>Check into your hotel and freshen up</li>
            <li>
              Take a walking tour of Tbilisi to get acquainted with the city and
              its culture
            </li>
            <li>
              Visit the Narikala Fortress for a panoramic view of the city and
              take a cable car ride
            </li>
            <li>Enjoy Georgian cuisine at a local restaurant</li>
            <li>Stroll through the Old Town and visit the Sulfur Baths</li>
          </ul>
          <br />
          <ul>
            <li>
              Head out to Mtskheta, a UNESCO World Heritage site, and the
              ancient capital of Georgia
            </li>
            <li>
              Visit the Svetitskhoveli Cathedral, one of the most important
              religious sites in Georgia
            </li>
            <li>
              Continue to Jvari Monastery, located on a hilltop overlooking the
              city and offering breathtaking views of the surrounding landscape
            </li>
            <li>Return to Tbilisi for a leisurely evening</li>
          </ul>
          <br />
          <ul>
            <li>Take a day trip to the wine region of Kakheti</li>
            <li>
              Visit the 17th-century fortified town of Sighnaghi, known for its
              picturesque views of the Alazani Valley and the Caucasus Mountains
            </li>
            <li>
              Stop at local wineries for tastings and learn about the
              traditional Georgian winemaking process
            </li>
            <li>Return to Tbilisi for the night</li>
          </ul>
          <br />
          <ul>
            <li>
              Travel to the mountainous region of Kazbegi, located in the north
              of Georgia
            </li>
            <li>
              Visit the Gergeti Trinity Church, located on the top of a mountain
              and offering stunning views of the surrounding landscape
            </li>
            <li>
              Take a 4x4 ride to the Gveleti Waterfall and enjoy a picnic lunch
              in the scenic surroundings
            </li>
            <li>Return to Tbilisi in the evening</li>
          </ul>
          <br />
          <ul>
            <li>
              Visit the ancient cave city of Uplistsikhe, located in the eastern
              part of Georgia
            </li>
            <li>
              Continue to the medieval city of Gori and visit the Stalin Museum
            </li>
            <li>Return to Tbilisi for the night</li>
          </ul>
          <br />
          <ul>
            <li>Take a day trip to the Black Sea region of Georgia</li>
            <li>
              Visit the city of Batumi and stroll along its famous seaside
              promenade
            </li>
            <li>
              See the Batumi Botanical Garden, known for its beautiful and
              diverse collection of plants
            </li>
            <li>Return to Tbilisi in the evening</li>
          </ul>
          <br />
          <ul>
            <li>
              Visit the Davit Gareja Monastery Complex, located in the
              southeastern part of Georgia and known for its unique frescoes and
              rock-cut architecture
            </li>
            <li>
              Stop at the Bodbe Monastery, located near the town of Sighnaghi
              and the final resting place of Saint Nino, the patron saint of
              Georgia
            </li>
            <li>Return to Tbilisi for a farewell dinner</li>
          </ul>
          <br />
          <ul>
            <li>
              Check out of your hotel and transfer to the airport for your
              departure flight.
            </li>
            <li>
              This itinerary covers some of the must-visit sites in Georgia,
              including its capital city, historical landmarks, scenic wine
              regions, breathtaking mountainous landscapes, and the Black Sea
              coast.{" "}
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "Germany",
      content: `Known for its rich history, beautiful architecture, and delicious beer, Germany is a country located in Central Europe. It is home to many historical landmarks, including the Berlin Wall and the Brandenburg Gate.`,
      img: germanyImage,
      rev: true,
      night3: (
        <>
          <ul>
            <li>Arrive in Berlin and check into your hotel</li>
            <li>
              Start your sightseeing by visiting the Brandenburg Gate and taking
              a stroll through the Tiergarten park
            </li>
            <li>
              Have lunch at a local restaurant and sample some traditional
              German cuisine
            </li>
            <li>
              Spend the afternoon exploring the Berlin Wall, including the East
              Side Gallery and Checkpoint Charlie
            </li>
            <li>
              In the evening, head to the trendy neighborhood of Kreuzberg for
              dinner and drinks
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to Potsdam, a beautiful city just outside of
              Berlin
            </li>
            <li>
              Visit the stunning Sanssouci Palace and Gardens, which was the
              summer residence of Frederick the Great
            </li>
            <li>
              Enjoy lunch in Potsdam's charming Old Town and explore its
              historic streets
            </li>
            <li>
              Head back to Berlin in the afternoon and visit the Reichstag
              building and its impressive glass dome
            </li>
            <li>End the day with a delicious dinner at a local brewery</li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Catch an early morning train to Munich, one of Germany's most
              picturesque cities
            </li>
            <li>
              Check into your hotel and take a stroll through the city's
              historic old town, including the Marienplatz and Frauenkirche
            </li>
            <li>
              Have lunch at a traditional Bavarian beer hall and enjoy some
              local specialties like bratwurst and pretzels
            </li>
            <li>
              Spend the afternoon exploring the city's world-renowned museums
              and art galleries, such as the Pinakothek der Moderne and the
              Deutsches Museum
            </li>
            <li>
              In the evening, head to a beer garden for some live music and a
              cold brew
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to Neuschwanstein Castle, the fairy tale castle
              that inspired the design of Disneyland's Sleeping Beauty Castle
            </li>
            <li>
              Take a guided tour of the castle and learn about its history and
              architecture
            </li>
            <li>
              Afterward, stop by the nearby village of Hohenschwangau for lunch
              and some souvenir shopping
            </li>
            <li>
              Return to Munich in the late afternoon and spend your final
              evening enjoying a delicious meal and some German beer before
              departing the next day.
            </li>
            <li>
              This itinerary will give you a taste of Germany's rich history,
              beautiful architecture, and delicious cuisine, while also allowing
              you to experience the country's unique culture and traditions.
            </li>
          </ul>
        </>
      ),
      night5: (
        <>
          <h4>Berlin</h4>
          <ul>
            <li>Arrive in Berlin and check into your hotel</li>
            <li>
              Start your sightseeing by visiting the Brandenburg Gate and taking
              a stroll through the Tiergarten park
            </li>
            <li>
              Have lunch at a local restaurant and sample some traditional
              German cuisine
            </li>
            <li>
              Spend the afternoon exploring the Berlin Wall, including the East
              Side Gallery and Checkpoint Charlie
            </li>
            <li>
              In the evening, head to the trendy neighborhood of Kreuzberg for
              dinner and drinks
            </li>
          </ul>
          <h4>Berlin</h4>
          <ul>
            <li>
              Take a guided tour of the Reichstag building and its impressive
              glass dome
            </li>
            <li>Visit the Berlin Cathedral and Museum Island</li>
            <li>
              Have lunch in the trendy neighborhood of Prenzlauer Berg and spend
              the afternoon shopping in the boutique stores and vintage shops
            </li>
            <li>
              In the evening, take a river cruise on the Spree River and enjoy
              dinner on board
            </li>
          </ul>
          <h4>Munich</h4>
          <ul>
            <li>
              Catch an early morning train to Munich, one of Germany's most
              picturesque cities
            </li>
            <li>
              Check into your hotel and take a stroll through the city's
              historic old town, including the Marienplatz and Frauenkirche
            </li>
            <li>
              Have lunch at a traditional Bavarian beer hall and enjoy some
              local specialties like bratwurst and pretzels
            </li>
            <li>
              Spend the afternoon exploring the city's world-renowned museums
              and art galleries, such as the Pinakothek der Moderne and the
              Deutsches Museum
            </li>
            <li>
              In the evening, head to a beer garden for some live music and a
              cold brew
            </li>
          </ul>
          <h4>Munich</h4>
          <ul>
            <li>
              Take a day trip to Neuschwanstein Castle, the fairy tale castle
              that inspired the design of Disneyland's Sleeping Beauty Castle
            </li>
            <li>
              Take a guided tour of the castle and learn about its history and
              architecture
            </li>
            <li>
              Afterward, stop by the nearby village of Hohenschwangau for lunch
              and some souvenir shopping
            </li>
            <li>
              Return to Munich in the late afternoon and spend your final
              evening enjoying a delicious meal and some German beer
            </li>
          </ul>
          <h4>Frankfurt</h4>
          <ul>
            <li>
              Catch a morning train to Frankfurt, a vibrant city known for its
              modern architecture and high-rise skyline
            </li>
            <li>
              Check into your hotel and visit the historic Römerberg square and
              the Frankfurt Cathedral
            </li>
            <li>
              Have lunch at a local restaurant and explore the city's many
              museums and art galleries, such as the Städel Museum and the
              Museum of Modern Art
            </li>
            <li>
              In the evening, head to the Sachsenhausen neighborhood for dinner
              and drinks in its lively bars and clubs
            </li>
          </ul>
          <h4>Frankfurt</h4>
          <ul>
            <li>
              Visit the nearby town of Heidelberg, famous for its stunning
              castle and historic university
            </li>
            <li>
              Take a guided tour of the castle and explore the town's charming
              old town and pedestrianized shopping streets
            </li>
            <li>
              Return to Frankfurt in the late afternoon and spend your final
              evening enjoying a delicious meal and some German beer before
              departing the next day.
            </li>
            <li>
              This itinerary will give you a taste of Germany's rich history,
              beautiful architecture, and delicious cuisine, while also allowing
              you to experience the country's unique culture and traditions.
            </li>
          </ul>
        </>
      ),
      night6: (
        <>
          <h4>Arrival in Berlin</h4>
          <p>
            Upon your arrival in Berlin, check-in at your hotel and spend the
            afternoon exploring the city's historical landmarks. Visit the
            iconic Brandenburg Gate, Checkpoint Charlie, and the Berlin Wall
            Memorial. Later in the evening, enjoy a dinner at a traditional
            German restaurant.
          </p>

          <h4> Berlin City Tour</h4>
          <p>
            Begin your day with a guided tour of Berlin, visiting the famous
            Berlin Cathedral, Reichstag Building, and Museum Island. After
            lunch, visit the Pergamon Museum to see some of the world's most
            impressive collections of ancient artifacts. Spend the evening at
            your leisure exploring the city's nightlife.
          </p>

          <h4>Frankfurt</h4>
          <p>
            Take a train to Frankfurt in the morning and explore the city's
            financial district. Visit the Römerberg Square and Frankfurt
            Cathedral before enjoying lunch at a local restaurant. Later, visit
            the Senckenberg Museum, which boasts one of the largest collections
            of natural history specimens in Europe.
          </p>

          <h4>Munich</h4>
          <p>
            Travel to Munich, the capital of Bavaria, in the morning. Visit the
            famous Marienplatz, the heart of the city, and enjoy lunch at a
            traditional Bavarian beer hall. In the afternoon, explore the city's
            museums and galleries, such as the Munich Residenz and the Alte
            Pinakothek.
          </p>

          <h4>Neuschwanstein Castle</h4>
          <p>
            Take a day trip from Munich to the Neuschwanstein Castle, one of the
            most iconic castles in the world. The castle is located in the
            Bavarian Alps and offers breathtaking views of the surrounding
            landscape. After your tour of the castle, take a leisurely walk
            through the castle gardens.
          </p>

          <h4>Heidelberg</h4>
          <p>
            Travel to Heidelberg, a charming university town located in the
            southwestern region of Germany. Visit the Heidelberg Castle, the
            town's most famous landmark, and take a stroll through the
            picturesque Old Town. In the evening, enjoy a traditional German
            dinner at a local restaurant.
          </p>

          <h4>Departure</h4>
          <p>
            On your final day, spend the morning exploring any remaining sights
            in Heidelberg before traveling back to Berlin for your departure
            flight. If time permits, visit the Berlin Zoo or the Berlin
            Philharmonic before heading to the airport.
          </p>
        </>
      ),
    },
    {
      name: "Greece",
      content: ` Home to ancient ruins, beautiful beaches, and delicious food, Greece is a country located in Southeast Europe and is known for its rich cultural heritage, including landmarks such as the Parthenon and the Acropolis`,
      img: greeceImage,
      rev: false,
      night3: (
        <>
          <ul>
            <li>Arrive in Athens and check into your hotel</li>
            <li>
              Visit the Acropolis, including the Parthenon, the Propylaea, and
              the Temple of Athena Nike
            </li>
            <li>
              Explore the Acropolis Museum to learn more about the history of
              the site
            </li>
            <li>
              Have dinner in the Plaka neighborhood, known for its charming
              streets and traditional tavernas
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to Delphi, home to the famous Oracle of Apollo and
              ancient ruins{" "}
            </li>
            <li>
              Visit the Temple of Apollo, the ancient theater, and the Sanctuary
              of Athena
            </li>
            <li>
              Enjoy lunch in the nearby town of Arachova, famous for its
              handmade textiles and local cuisine
            </li>
            <li>
              Return to Athens in the evening and have dinner at a rooftop
              restaurant with a view of the Acropolis
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the island of Aegina, known for its pistachios
              and ancient temple of Aphaia
            </li>
            <li>
              Visit the Temple of Aphaia, a well-preserved ancient Greek temple
              with stunning views of the surrounding countryside
            </li>
            <li>
              Explore the island's charming villages, beaches, and markets
            </li>
            <li>
              Return to Athens in the evening and have dinner at a local
              restaurant
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a morning stroll around the neighborhoods of Plaka and
              Monastiraki, known for their traditional architecture and markets
            </li>
            <li>
              Visit the National Archaeological Museum, which houses some of
              Greece's most important ancient artifacts
            </li>
            <li>
              Have lunch at a local souvlaki restaurant, and spend the afternoon
              shopping for souvenirs or relaxing at a cafe
            </li>
            <li>Depart from Athens in the evening</li>
          </ul>
          <p>
            Of course, this is just one possible itinerary and there are many
            other places to explore in Greece, such as the islands of Santorini
            or Mykonos, the ancient city of Olympia, or the stunning beaches of
            Crete.
          </p>
        </>
      ),
      night5: (
        <>
          <ul>
            <li>Arrive in Athens and check into your hotel</li>
            <li>
              Visit the Acropolis, including the Parthenon, the Propylaea, and
              the Temple of Athena Nike
            </li>
            <li>
              Explore the Acropolis Museum to learn more about the history of
              the site
            </li>
            <li>
              Have dinner in the Plaka neighborhood, known for its charming
              streets and traditional tavernas
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to Delphi, home to the famous Oracle of Apollo and
              ancient ruins{" "}
            </li>
            <li>
              Visit the Temple of Apollo, the ancient theater, and the Sanctuary
              of Athena
            </li>
            <li>
              Enjoy lunch in the nearby town of Arachova, famous for its
              handmade textiles and local cuisine
            </li>
            <li>
              Return to Athens in the evening and have dinner at a rooftop
              restaurant with a view of the Acropolis
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the island of Aegina, known for its pistachios
              and ancient temple of Aphaia
            </li>
            <li>
              Visit the Temple of Aphaia, a well-preserved ancient Greek temple
              with stunning views of the surrounding countryside
            </li>
            <li>
              Explore the island's charming villages, beaches, and markets
            </li>
            <li>
              Return to Athens in the evening and have dinner at a local
              restaurant
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a morning flight to the island of Santorini, known for its
              stunning caldera views and romantic sunsets
            </li>
            <li>
              Check into your hotel and explore the island's iconic white-washed
              villages, such as Oia and Fira
            </li>
            <li>
              Visit the ancient city of Akrotiri, a well-preserved Minoan
              settlement from the Bronze Age
            </li>
            <li>
              Have dinner at a seaside restaurant, watching the sunset over the
              Aegean Sea
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a boat tour around the caldera, visiting landmarks such as
              the volcanic island of Nea Kameni and the hot springs of Palia
              Kameni
            </li>
            <li>
              Explore the island's beaches and wineries, sampling local wines
              such as Assyrtiko and Vinsanto
            </li>
            <li>
              Have lunch at a seaside taverna, and spend the afternoon shopping
              for souvenirs or relaxing at a spa
            </li>
            <li>
              Watch the sunset from a rooftop bar or from the village of Oia
            </li>
            <li>
              Have a farewell dinner at a local restaurant, enjoying traditional
              Greek cuisine and live music
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Depart from Santorini, with memories of stunning views and island
              charm.
            </li>
          </ul>
          <p>
            Of course, this is just one possible itinerary and there are many
            other places to explore in Greece, such as the island of Mykonos,
            the ancient city of Olympia, or the stunning beaches of Crete.
          </p>
        </>
      ),
      night6: (
        <>
          <ul>
            <li>Arrive in Athens and check into your hotel</li>
            <li>
              Visit the Acropolis, including the Parthenon, the Propylaea, and
              the Temple of Athena Nike
            </li>
            <li>
              Explore the Acropolis Museum to learn more about the history of
              the site
            </li>
            <li>
              Have dinner in the Plaka neighborhood, known for its charming
              streets and traditional tavernas
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to Delphi, home to the famous Oracle of Apollo and
              ancient ruins{" "}
            </li>
            <li>
              Visit the Temple of Apollo, the ancient theater, and the Sanctuary
              of Athena
            </li>
            <li>
              Enjoy lunch in the nearby town of Arachova, famous for its
              handmade textiles and local cuisine
            </li>
            <li>
              Return to Athens in the evening and have dinner at a rooftop
              restaurant with a view of the Acropolis
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the island of Aegina, known for its pistachios
              and ancient temple of Aphaia
            </li>
            <li>
              Visit the Temple of Aphaia, a well-preserved ancient Greek temple
              with stunning views of the surrounding countryside
            </li>
            <li>
              Explore the island's charming villages, beaches, and markets
            </li>
            <li>
              Return to Athens in the evening and have dinner at a local
              restaurant
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a morning flight to the island of Crete, the largest island
              in Greece known for its beaches and rugged landscapes.
            </li>
            <li>
              Check into your hotel and spend the day exploring the city of
              Heraklion or nearby villages such as Archanes or Fodele.
            </li>
            <li>
              Visit the archaeological site of Knossos, the largest and most
              impressive Bronze Age archaeological site on the island.
            </li>
            <li>
              Have dinner at a local restaurant, enjoying traditional Cretan
              cuisine and live music.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the charming town of Chania, known for its
              Venetian harbor and old town.
            </li>
            <li>
              Explore the narrow streets of the old town, visiting landmarks
              such as the Agora and the Venetian lighthouse.
            </li>
            <li>
              Visit the beaches of Balos and Elafonisi, known for their
              crystal-clear waters and pink sand.
            </li>
            <li>
              Have dinner at a seaside restaurant, enjoying fresh seafood and
              local wines.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the island of Santorini, known for its stunning
              caldera views and romantic sunsets.
            </li>
            <li>
              Check into your hotel and explore the island's iconic white-washed
              villages, such as Oia and Fira.
            </li>
            <li>
              Visit the ancient city of Akrotiri, a well-preserved Minoan
              settlement from the Bronze Age.
            </li>
            <li>
              Have dinner at a seaside restaurant, watching the sunset over the
              Aegean Sea.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a boat tour around the caldera, visiting landmarks such as
              the volcanic island of Nea Kameni and the hot springs of Palia
              Kameni.
            </li>
            <li>
              Explore the island's beaches and wineries, sampling local wines
              such as Assyrtiko and Vinsanto.
            </li>
            <li>
              Have lunch at a seaside taverna, and spend the afternoon shopping
              for souvenirs or relaxing at a spa.
            </li>
            <li>
              Watch the sunset from a rooftop bar or from the village of Oia.
            </li>
            <li>
              Have a farewell dinner at a local restaurant, enjoying traditional
              Greek cuisine and live music.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Depart from Santorini, with memories of stunning views and island
              charm.
            </li>
          </ul>
          <p>
            Of course, this is just one possible itinerary and there are many
            other places to explore in Greece, such as the island of Mykonos,
            the ancient city of Olympia, or the stunning beaches of Zakynthos.
          </p>
        </>
      ),
    },
    {
      name: "Hungary",
      content: ` A country located in Central Europe, Hungary is known for its stunning architecture, rich history, and delicious food. It is home to the beautiful city of Budapest, which straddles the Danube River and is famous for its thermal baths and beautiful landmarks such as the Parliament Building.`,
      img: hungaryImage,
      rev: true,
      night3: (
        <>
          <ul>
            <li>Arrive in Budapest and check into your hotel</li>
            <li>
              Take a walking tour of the city's main attractions, including St.
              Stephen's Basilica, the Hungarian Parliament Building, and Heroes'
              Square
            </li>
            <li>
              Take a relaxing dip in one of Budapest's famous thermal baths,
              such as the Széchenyi or Gellért Baths
            </li>
            <li>
              Enjoy dinner at a traditional Hungarian restaurant, trying local
              specialties such as goulash or chimney cake
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a morning visit to Buda Castle, a UNESCO World Heritage Site,
              and explore its many attractions, including the Royal Palace, the
              Fisherman's Bastion, and Matthias Church
            </li>
            <li>
              Walk along the Danube Promenade and take in the views of the river
              and the city's famous bridges
            </li>
            <li>
              Visit the Great Market Hall, where you can try Hungarian cuisine
              and shop for souvenirs
            </li>
            <li>
              Take a sunset Danube River cruise, enjoying the beautiful views of
              the city from the water
            </li>
            <li>
              Have dinner at a local restaurant, trying Hungarian wine and
              desserts such as Dobos cake or Somlói Galuska
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the charming town of Eger, known for its
              thermal baths and Baroque architecture
            </li>
            <li>
              Visit the Castle of Eger, a well-preserved medieval fortress that
              played an important role in Hungarian history
            </li>
            <li>
              Visit the Basilica of St. John the Apostle, a stunning Baroque
              church with beautiful frescoes and paintings
            </li>
            <li>
              Take a dip in one of the town's thermal baths, such as the Eger
              Thermal Bath or the Turkish Bath
            </li>
            <li>
              Have dinner at a local restaurant, trying local wines such as Egri
              Bikavér (Bull's Blood of Eger)
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Visit the Hungarian National Museum, one of Budapest's most
              important cultural institutions, to learn more about the country's
              history and culture
            </li>
            <li>
              Explore the Jewish Quarter and visit the Great Synagogue, the
              largest synagogue in Europe, and the Jewish Museum
            </li>
            <li>
              Take a stroll in City Park, the largest park in Budapest, and
              visit attractions such as the Vajdahunyad Castle, the Budapest
              Zoo, and the Széchenyi Thermal Bath
            </li>
            <li>
              Have lunch at a local restaurant, trying Hungarian street food
              such as lángos or chimney cake
            </li>
            <li>
              Depart from Budapest, bringing unforgettable memories of your trip
              to Hungary.
            </li>
          </ul>{" "}
          <br />
        </>
      ),
      night5: (
        <>
          <ul>
            <li>Arrive in Budapest and check into your hotel</li>
            <li>
              Take a walking tour of the city's main attractions, including St.
              Stephen's Basilica, the Hungarian Parliament Building, and Heroes'
              Square
            </li>
            <li>
              Take a relaxing dip in one of Budapest's famous thermal baths,
              such as the Széchenyi or Gellért Baths
            </li>
            <li>
              Enjoy dinner at a traditional Hungarian restaurant, trying local
              specialties such as goulash or chimney cake
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a morning visit to Buda Castle, a UNESCO World Heritage Site,
              and explore its many attractions, including the Royal Palace, the
              Fisherman's Bastion, and Matthias Church
            </li>
            <li>
              Walk along the Danube Promenade and take in the views of the river
              and the city's famous bridges
            </li>
            <li>
              Visit the Great Market Hall, where you can try Hungarian cuisine
              and shop for souvenirs
            </li>
            <li>
              Take a sunset Danube River cruise, enjoying the beautiful views of
              the city from the water
            </li>
            <li>
              Have dinner at a local restaurant, trying Hungarian wine and
              desserts such as Dobos cake or Somlói Galuska
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the charming town of Eger, known for its
              thermal baths and Baroque architecture
            </li>
            <li>
              Visit the Castle of Eger, a well-preserved medieval fortress that
              played an important role in Hungarian history
            </li>
            <li>
              Visit the Basilica of St. John the Apostle, a stunning Baroque
              church with beautiful frescoes and paintings
            </li>
            <li>
              Take a dip in one of the town's thermal baths, such as the Eger
              Thermal Bath or the Turkish Bath
            </li>
            <li>
              Have dinner at a local restaurant, trying local wines such as Egri
              Bikavér (Bull's Blood of Eger)
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Visit the Hungarian National Museum, one of Budapest's most
              important cultural institutions, to learn more about the country's
              history and culture
            </li>
            <li>
              Explore the Jewish Quarter and visit the Great Synagogue, the
              largest synagogue in Europe, and the Jewish Museum
            </li>
            <li>
              Take a stroll in City Park, the largest park in Budapest, and
              visit attractions such as the Vajdahunyad Castle, the Budapest
              Zoo, and the Széchenyi Thermal Bath
            </li>
            <li>
              Have lunch at a local restaurant, trying Hungarian street food
              such as lángos or chimney cake
            </li>
            <li>
              In the evening, attend a performance at the Hungarian State Opera
              House or the Palace of Arts
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the picturesque Danube Bend, where the Danube
              River makes a sharp turn and flows between the Buda Hills and the
              Visegrád Mountains
            </li>
            <li>
              Visit the medieval castle in Visegrád and enjoy panoramic views of
              the Danube River and the surrounding landscape
            </li>
            <li>
              Take a scenic cruise on the Danube River and stop in the charming
              town of Szentendre, known for its colorful architecture,
              galleries, and museums
            </li>
            <li>
              Have dinner at a local restaurant, trying Hungarian dishes such as
              stuffed peppers or chicken paprikash
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Before departing Budapest, visit the House of Terror Museum to
              learn about Hungary's troubled history during the 20th century
            </li>
            <li>
              Explore the Hungarian wine regions, such as Eger or Tokaj, and
              take a tour of the vineyards and wineries
            </li>
            <li>
              Depart from Budapest, taking with you unforgettable memories of
              your trip to Hungary.
            </li>
          </ul>
        </>
      ),
      night6: (
        <>
          <h3>
            Here's a suggested itinerary for a 7-night, 8-day trip to Hungary:
          </h3>
          <ul>
            <li>Arrive in Budapest and check into your hotel</li>
            <li>
              Take a walking tour of the city's main attractions, including St.
              Stephen's Basilica, the Hungarian Parliament Building, and Heroes'
              Square
            </li>
            <li>
              Take a relaxing dip in one of Budapest's famous thermal baths,
              such as the Széchenyi or Gellért Baths
            </li>
            <li>
              Enjoy dinner at a traditional Hungarian restaurant, trying local
              specialties such as goulash or chimney cake
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a morning visit to Buda Castle, a UNESCO World Heritage Site,
              and explore its many attractions, including the Royal Palace, the
              Fisherman's Bastion, and Matthias Church
            </li>
            <li>
              Walk along the Danube Promenade and take in the views of the river
              and the city's famous bridges
            </li>
            <li>
              Visit the Great Market Hall, where you can try Hungarian cuisine
              and shop for souvenirs
            </li>
            <li>
              Take a sunset Danube River cruise, enjoying the beautiful views of
              the city from the water
            </li>
            <li>
              Have dinner at a local restaurant, trying Hungarian wine and
              desserts such as Dobos cake or Somlói Galuska
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the charming town of Eger, known for its
              thermal baths and Baroque architecture
            </li>
            <li>
              Visit the Castle of Eger, a well-preserved medieval fortress that
              played an important role in Hungarian history
            </li>
            <li>
              Visit the Basilica of St. John the Apostle, a stunning Baroque
              church with beautiful frescoes and paintings
            </li>
            <li>
              Take a dip in one of the town's thermal baths, such as the Eger
              Thermal Bath or the Turkish Bath
            </li>
            <li>
              Have dinner at a local restaurant, trying local wines such as Egri
              Bikavér (Bull's Blood of Eger)
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Visit the Hungarian National Museum, one of Budapest's most
              important cultural institutions, to learn more about the country's
              history and culture
            </li>
            <li>
              Explore the Jewish Quarter and visit the Great Synagogue, the
              largest synagogue in Europe, and the Jewish Museum
            </li>
            <li>
              Take a stroll in City Park, the largest park in Budapest, and
              visit attractions such as the Vajdahunyad Castle, the Budapest
              Zoo, and the Széchenyi Thermal Bath
            </li>
            <li>
              Have lunch at a local restaurant, trying Hungarian street food
              such as lángos or chimney cake
            </li>
            <li>
              In the evening, attend a performance at the Hungarian State Opera
              House or the Palace of Arts
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the picturesque Danube Bend, where the Danube
              River makes a sharp turn and flows between the Buda Hills and the
              Visegrád Mountains
            </li>
            <li>
              Visit the medieval castle in Visegrád and enjoy panoramic views of
              the Danube River and the surrounding landscape
            </li>
            <li>
              Take a scenic cruise on the Danube River and stop in the charming
              town of Szentendre, known for its colorful architecture,
              galleries, and museums
            </li>
            <li>
              Have dinner at a local restaurant, trying Hungarian dishes such as
              stuffed peppers or chicken paprikash
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Visit the Tokaj Wine Region, a UNESCO World Heritage Site and one
              of Hungary's most famous wine regions
            </li>
            <li>
              Take a tour of the vineyards and wineries, learning about the
              production of the region's sweet Tokaji wine
            </li>
            <li>
              Have lunch at a local restaurant, trying traditional Hungarian
              dishes paired with local wines
            </li>
            <li>
              Return to Budapest and enjoy a free evening to explore the city on
              your own
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the charming town of Pécs, known for its
              beautiful architecture, cultural heritage, and Mediterranean
              atmosphere
            </li>
            <li>
              Visit the Pécs Cathedral, a beautiful Romanesque cathedral with
              intricate carvings and frescoes Explore the Zsoln
            </li>
            <li>
              Before departing Budapest, visit the House of Terror Museum to
              learn about Hungary's troubled history during the 20th century
            </li>
            <li>
              Explore the Hungarian wine regions, such as Eger or Tokaj, and
              take a tour of the vineyards and wineries
            </li>
            <li>
              Depart from Budapest, taking with you unforgettable memories of
              your trip to Hungary.
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "Italy",
      content: `Located in Southern Europe, Italy is known for its stunning architecture, beautiful landscapes, and delicious cuisine. It is home to famous landmarks such as the Colosseum and the Leaning Tower of Pisa, as well as beautiful cities such as Rome, Florence, and Venice.`,
      img: italyImage,
      rev: false,
      night3: (
        <>
          <ul>
            <li>Arrive in Rome and check into your hotel</li>
            <li>
              Take a walking tour of the city's main attractions, including the
              Colosseum, the Roman Forum, and the Pantheon
            </li>
            <li>
              Visit the Vatican City and tour St. Peter's Basilica and the
              Vatican Museums, including the famous Sistine Chapel
            </li>
            <li>
              Have dinner at a local restaurant, trying Italian cuisines such as
              pasta, pizza, and gelato
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a morning visit to the iconic Trevi Fountain, where you can
              toss a coin over your shoulder for good luck
            </li>
            <li>
              Visit the Spanish Steps, a popular spot for people-watching and
              shopping
            </li>
            <li>
              Explore the Piazza Navona, one of Rome's most beautiful squares,
              with its famous fountains and Baroque architecture
            </li>
            <li>Take a sunset stroll along the Tiber River</li>
            <li>
              Have dinner at a local restaurant, trying Italian wine and
              desserts such as tiramisu or cannoli
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to Florence, the birthplace of the Renaissance and
              one of Italy's most beautiful cities
            </li>
            <li>
              Visit the Uffizi Gallery, one of the world's most famous art
              museums, with its impressive collection of Renaissance
              masterpieces
            </li>
            <li>
              Explore the Piazza del Duomo, home to the magnificent Florence
              Cathedral, with its famous dome designed by Brunelleschi
            </li>
            <li>
              Take a stroll across the Ponte Vecchio, a medieval bridge lined
              with shops selling gold and jewelry
            </li>
            <li>
              Have lunch at a local restaurant, trying Tuscan cuisine such as
              bistecca alla Fiorentina or ribollita soup
            </li>
            <li>
              Return to Rome in the evening and have dinner at a local
              restaurant, trying Italian dishes such as carbonara or cacio e
              pepe
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a morning visit to the Villa Borghese, a beautiful park in
              the heart of Rome with its famous Borghese Gallery, home to many
              masterpieces by Bernini, Caravaggio, and Raphael
            </li>
            <li>
              Visit the ancient Appian Way, one of Rome's oldest and most
              important roads, with its many ancient ruins and tombs
            </li>
            <li>
              Have lunch at a local restaurant, trying Italian street food such
              as supplì or pizza al taglio
            </li>
            <li>
              Visit the Roman Catacombs, a network of underground tunnels and
              tombs used by early Christians
            </li>
            <li>Return to your hotel and depart for your next destination</li>
          </ul>
          <p>
            <b>Note:</b>This itinerary is just a suggestion and can be
            customized to fit your interests and preferences. Keep in mind that
            there are many other beautiful cities and attractions in Italy that
            you may want to include in your itinerary, such as Venice, Milan, or
            the Amalfi Coast.{" "}
          </p>
        </>
      ),

      night5: (
        <>
          <h4>Rome</h4>
          <ul>
            <li>Arrive in Rome and check into your hotel</li>
            <li>
              Take a walking tour of the city's main attractions, including the
              Colosseum, the Roman Forum, and the Pantheon
            </li>
            <li>
              Visit the Vatican City and tour St. Peter's Basilica and the
              Vatican Museums, including the famous Sistine Chapel
            </li>
            <li>
              Have dinner at a local restaurant, trying Italian cuisines such as
              pasta, pizza, and gelato
            </li>
          </ul>
          <h4>Florence</h4>
          <ul>
            <li>Take a morning train to Florence</li>
            <li>
              Visit the Uffizi Gallery, one of the world's most famous art
              museums, with its impressive collection of Renaissance
              masterpieces
            </li>
            <li>
              Explore the Piazza del Duomo, home to the magnificent Florence
              Cathedral, with its famous dome designed by Brunelleschi
            </li>
            <li>
              Take a stroll across the Ponte Vecchio, a medieval bridge lined
              with shops selling gold and jewelry
            </li>
            <li>
              Have lunch at a local restaurant, trying Tuscan cuisine such as
              bistecca alla fiorentina or ribollita soup
            </li>
            <li>
              Return to Rome in the evening and have dinner at a local
              restaurant, trying Italian dishes such as carbonara or cacio e
              pepe
            </li>
          </ul>
          <h4> Amalfi Coast</h4>
          <ul>
            <li>Take a morning train to the Amalfi Coast</li>
            <li>
              Spend the day exploring the picturesque towns of Positano, Amalfi,
              and Ravello, with their colorful houses, winding streets, and
              stunning sea views
            </li>
            <li>
              Have lunch at a local restaurant, trying seafood dishes such as
              spaghetti alle vongole or grilled fish
            </li>
            <li>
              Return to Rome in the evening and have dinner at a local
              restaurant, trying Italian wine and desserts such as tiramisu or
              cannoli
            </li>
          </ul>
          <h4>Tuscany</h4>
          <ul>
            <li>Take a morning train to Tuscany</li>
            <li>
              Visit the medieval town of Siena, with its impressive Gothic
              architecture, including the famous Siena Cathedral and the Piazza
              del Campo
            </li>
            <li>
              Have lunch at a local restaurant, trying Tuscan specialties such
              as pici pasta or ribollita soup
            </li>
            <li>
              Visit a local winery and taste some of the region's famous Chianti
              wines
            </li>
            <li>
              Return to Rome in the evening and have dinner at a local
              restaurant, trying Italian dishes such as spaghetti alla carbonara
              or cacio e pepe
            </li>
          </ul>
          <h4>Pompeii </h4>
          <ul>
            <li>
              Take a morning visit to Pompeii, the ancient Roman city destroyed
              by the eruption of Mount Vesuvius in 79 AD
            </li>
            <li>
              Explore the city's well-preserved ruins, including the Forum, the
              Baths, and the House of the Vettii
            </li>
            <li>
              Have lunch at a local restaurant, trying Italian pizza or street
              food such as arancini or panzerotti
            </li>
            <li>
              Visit Naples in the afternoon, the birthplace of pizza and home to
              many historical landmarks such as the Royal Palace of Naples, the
              Castel dell'Ovo, and the Piazza del Plebiscito
            </li>
            <li>
              Return to Rome in the evening and have dinner at a local
              restaurant, trying Italian dishes such as saltimbocca alla romana
              or osso buco
            </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Enjoy a leisurely morning in Rome, perhaps visiting the Trevi
              Fountain or the Spanish Steps
            </li>
            <li>Depart for your next destination</li>
          </ul>
          <p>
            <b>Note:</b> This itinerary is just a suggestion and can be
            customized to fit your interests and preferences. Keep in mind that
            there are many other beautiful cities and attractions in Italy that
            you may want to include in your itinerary, such as Venice, Milan, or
            the Cinque Terre.
          </p>
        </>
      ),

      night6: (
        <>
          <h4>Rome</h4>
          <ul>
            <li>Arrive in Rome and check into your hotel</li>
            <li>
              Take a walking tour of the city's main attractions, including the
              Colosseum, the Roman Forum, and the Pantheon
            </li>
            <li>
              Visit the Vatican City and tour St. Peter's Basilica and the
              Vatican Museums, including the famous Sistine Chapel
            </li>
            <li>
              Have dinner at a local restaurant, trying Italian cuisines such as
              pasta, pizza, and gelato
            </li>
          </ul>
          <h4>Florence</h4>
          <ul>
            <li>Take a morning train to Florence</li>
            <li>
              Visit the Uffizi Gallery, one of the world's most famous art
              museums, with its impressive collection of Renaissance
              masterpieces
            </li>
            <li>
              Explore the Piazza del Duomo, home to the magnificent Florence
              Cathedral, with its famous dome designed by Brunelleschi
            </li>
            <li>
              Take a stroll across the Ponte Vecchio, a medieval bridge lined
              with shops selling gold and jewelry
            </li>
            <li>
              Have lunch at a local restaurant, trying Tuscan cuisine such as
              bistecca alla fiorentina or ribollita soup
            </li>
            <li>
              Return to Rome in the evening and have dinner at a local
              restaurant, trying Italian dishes such as carbonara or cacio e
              pepe
            </li>
          </ul>
          <h4> Amalfi Coast</h4>
          <ul>
            <li>Take a morning train to the Amalfi Coast</li>
            <li>
              Spend the day exploring the picturesque towns of Positano, Amalfi,
              and Ravello, with their colorful houses, winding streets, and
              stunning sea views
            </li>
            <li>
              Have lunch at a local restaurant, trying seafood dishes such as
              spaghetti alle vongole or grilled fish
            </li>
            <li>
              Return to Rome in the evening and have dinner at a local
              restaurant, trying Italian wine and desserts such as tiramisu or
              cannoli
            </li>
          </ul>
          <h4>Tuscany</h4>
          <ul>
            <li>Take a morning train to Tuscany</li>
            <li>
              Visit the medieval town of Siena, with its impressive Gothic
              architecture, including the famous Siena Cathedral and the Piazza
              del Campo
            </li>
            <li>
              Have lunch at a local restaurant, trying Tuscan specialties such
              as pici pasta or ribollita soup
            </li>
            <li>
              Visit a local winery and taste some of the region's famous Chianti
              wines
            </li>
            <li>
              Return to Rome in the evening and have dinner at a local
              restaurant, trying Italian dishes such as spaghetti alla carbonara
              or cacio e pepe
            </li>
          </ul>
          <h4>Pompeii and Naples</h4>
          <ul>
            <li>
              Take a morning visit to Pompeii, the ancient Roman city destroyed
              by the eruption of Mount Vesuvius in 79 AD
            </li>
            <li>
              Explore the city's well-preserved ruins, including the Forum, the
              Baths, and the House of the Vettii
            </li>
            <li>
              Have lunch at a local restaurant, trying Italian pizza or street
              food such as arancini or panzerotti
            </li>
            <li>
              Visit Naples in the afternoon, the birthplace of pizza and home to
              many historical landmarks such as the Royal Palace of Naples, the
              Castel dell'Ovo, and the Piazza del Plebiscito
            </li>
            <li>
              Return to Rome in the evening and have dinner at a local
              restaurant, trying Italian dishes such as saltimbocca alla romana
              or osso buco
            </li>
          </ul>
          <h4>Venice</h4>
          <ul>
            <li>Take a morning train to Venice</li>
            <li>
              Explore the city's famous canals and landmarks, including the
              Grand Canal, St. Mark's Basilica, and the Rialto Bridge
            </li>
            <li>
              Take a gondola ride through the canals, enjoying the unique
              atmosphere and views of the city
            </li>
            <li>
              Have lunch at a local restaurant, trying Venetian specialties such
              as seafood risotto or cicchetti (small tapas-like dishes)
            </li>
            <li>
              Return to Rome in the evening and have dinner at a local
              restaurant, trying Italian dishes such as spaghetti alle vongole
              or saltimboc
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "Netherlands",
      content: `Located in Northwestern Europe, the Netherlands is known for its picturesque windmills, beautiful tulip fields, and vibrant cities such as Amsterdam and Rotterdam. It is also famous for its cheese, particularly Gouda and Edam, and its delicious stroopwafels.`,
      img: netherlandImage,
      rev: true,
      night3: (
        <>
          <ul>
            <li>
              Arrive at Amsterdam Schiphol Airport and take a train or taxi to
              your hotel in Amsterdam city center.
            </li>
            <li>
              Start exploring Amsterdam by visiting some of the city's most
              famous attractions, such as the Van Gogh Museum, the Rijksmuseum,
              and the Anne Frank House.
            </li>
            <li>
              Take a canal tour to see the city from a different perspective and
              enjoy the picturesque views of Amsterdam's historic buildings.
            </li>
            <li>
              Enjoy dinner in one of the many restaurants in the city center,
              such as De Kas or Breda.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the historic city of Utrecht, just 30 minutes
              by train from Amsterdam. Explore the picturesque canals, visit the
              Dom Tower, and stroll through the historic city center.
            </li>
            <li>
              In the afternoon, return to Amsterdam and visit the Jordaan
              district, known for its charming streets, trendy shops, and cozy
              cafes.
            </li>
            <li>
              In the evening, visit a traditional Dutch pub and try some local
              beers, such as Heineken or Grolsch.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the stunning Keukenhof Gardens, which are only
              open from March to May. These gardens are known for their colorful
              tulip fields, and visitors can also see other types of flowers,
              such as daffodils and hyacinths.
            </li>
            <li>
              After visiting Keukenhof, head to the nearby town of Haarlem and
              explore its charming streets and historic buildings.
            </li>
            <li>
              Return to Amsterdam in the evening and enjoy dinner at a
              Michelin-starred restaurant, such as Ciel Bleu or Librije's Zusje.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              On your last day, take a bike tour of Amsterdam and explore some
              of the city's hidden gems and local neighborhoods.
            </li>
            <li>
              After the bike tour, visit the famous Albert Cuyp Market, where
              you can find everything from fresh produce to clothing and
              souvenirs.
            </li>
            <li>
              In the afternoon, visit the NEMO Science Museum, which is a great
              attraction for families and science enthusiasts.
            </li>
            <li>
              Finally, head back to your hotel to collect your bags and take a
              train or taxi to Amsterdam Schiphol Airport for your flight home.
            </li>
          </ul>
        </>
      ),

      night5: (
        <>
          <ul>
            <li>
              Arrive at Amsterdam Schiphol Airport and take a train or taxi to
              your hotel in Amsterdam city center.
            </li>
            <li>
              Spend the day exploring the city's famous attractions, such as the
              Van Gogh Museum, the Rijksmuseum, and the Anne Frank House.
            </li>
            <li>
              Take a canal tour to see the city from a different perspective and
              enjoy the picturesque views of Amsterdam's historic buildings.
            </li>
            <li>
              Enjoy dinner in one of the many restaurants in the city center,
              such as De Kas or Breda.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the historic city of Utrecht, just 30 minutes
              by train from Amsterdam. Explore the picturesque canals, visit the
              Dom Tower, and stroll through the historic city center.
            </li>
            <li>
              In the afternoon, return to Amsterdam and visit the Jordaan
              district, known for its charming streets, trendy shops, and cozy
              cafes.
            </li>
            <li>
              In the evening, visit a traditional Dutch pub and try some local
              beers, such as Heineken or Grolsch.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the stunning Keukenhof Gardens, which are only
              open from March to May. These gardens are known for their colorful
              tulip fields, and visitors can also see other types of flowers,
              such as daffodils and hyacinths.
            </li>
            <li>
              After visiting Keukenhof, head to the nearby town of Haarlem and
              explore its charming streets and historic buildings.
            </li>
            <li>
              Return to Amsterdam in the evening and enjoy dinner at a
              Michelin-starred restaurant, such as Ciel Bleu or Librije's Zusje.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the city of Rotterdam, known for its modern
              architecture, lively cultural scene, and maritime history. Visit
              attractions such as the Euromast tower, the Markthal food hall,
              and the Museum of Boijmans Van Beuningen.
            </li>
            <li>
              In the evening, return to Amsterdam and enjoy a relaxing dinner at
              a cozy restaurant in the city center.
            </li>
          </ul>
          <br />
          <ul>
            <li>
              Take a day trip to the historic city of Leiden, known for its
              beautiful canals, historic buildings, and prestigious university.
              Visit attractions such as the Rijksmuseum van Oudheden, the Hortus
              Botanicus, and the Pieterskerk.
            </li>
            <li>
              In the evening, return to Amsterdam and enjoy a night out in the
              city, perhaps visiting a nightclub or live music venue.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the city of The Hague, which is home to the
              Dutch government and many international organizations. Visit
              attractions such as the Peace Palace, the Mauritshuis museum, and
              Scheveningen beach.
            </li>
            <li>
              In the evening, return to Amsterdam and enjoy dinner at a
              restaurant in the trendy De Pijp neighborhood.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the charming city of Delft, known for its
              beautiful canals, historic buildings, and famous blue-and-white
              pottery. Visit attractions such as the Royal Delft pottery
              factory, the Old Church, and the Vermeer Center.
            </li>
            <li>
              In the evening, return to Amsterdam and enjoy a farewell dinner at
              a top-rated restaurant, such as Restaurant Vinkeles or De Librije
              Amsterdam.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              On your last day, take a bike tour of Amsterdam and explore some
              of the city's hidden gems and local neighborhoods.
            </li>
            <li>
              After the bike tour, visit the famous Albert Cuyp Market, where
              you can find everything from fresh produce to clothing and
              souvenirs.
            </li>
            <li>
              In the afternoon, visit the NEMO Science Museum, which is a great
              attraction for families and science enthusiasts.
            </li>
            <li>
              Finally, head back to your hotel to collect your bags and take a
              train or taxi to Amsterdam Schiphol Airport for your flight home.
            </li>
          </ul>
        </>
      ),

      night6: (
        <>
          <ul>
            <li>
              Arrive at Amsterdam Schiphol Airport and take a train or taxi to
              your hotel in Amsterdam city center.
            </li>
            <li>
              Spend the day exploring the city's famous attractions, such as the
              Van Gogh Museum, the Rijksmuseum, and the Anne Frank House.
            </li>
            <li>
              Take a canal tour to see the city from a different perspective and
              enjoy the picturesque views of Amsterdam's historic buildings.
            </li>
            <li>
              Enjoy dinner in one of the many restaurants in the city center,
              such as De Kas or Breda.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the historic city of Utrecht, just 30 minutes
              by train from Amsterdam. Explore the picturesque canals, visit the
              Dom Tower, and stroll through the historic city center.
            </li>
            <li>
              In the afternoon, return to Amsterdam and visit the Jordaan
              district, known for its charming streets, trendy shops, and cozy
              cafes.
            </li>
            <li>
              In the evening, visit a traditional Dutch pub and try some local
              beers, such as Heineken or Grolsch.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the stunning Keukenhof Gardens, which are only
              open from March to May. These gardens are known for their colorful
              tulip fields, and visitors can also see other types of flowers,
              such as daffodils and hyacinths.
            </li>
            <li>
              After visiting Keukenhof, head to the nearby town of Haarlem and
              explore its charming streets and historic buildings.
            </li>
            <li>
              Return to Amsterdam in the evening and enjoy dinner at a
              Michelin-starred restaurant, such as Ciel Bleu or Librije's Zusje.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the city of Rotterdam, known for its modern
              architecture, lively cultural scene, and maritime history. Visit
              attractions such as the Euromast tower, the Markthal food hall,
              and the Museum of Boijmans Van Beuningen.
            </li>
            <li>
              In the evening, return to Amsterdam and enjoy a relaxing dinner at
              a cozy restaurant in the city center.
            </li>
          </ul>
          <br />
          <ul>
            <li>
              Take a day trip to the historic city of Leiden, known for its
              beautiful canals, historic buildings, and prestigious university.
              Visit attractions such as the Rijksmuseum van Oudheden, the Hortus
              Botanicus, and the Pieterskerk.
            </li>
            <li>
              In the evening, return to Amsterdam and enjoy a night out in the
              city, perhaps visiting a nightclub or live music venue.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the city of The Hague, which is home to the
              Dutch government and many international organizations. Visit
              attractions such as the Peace Palace, the Mauritshuis museum, and
              Scheveningen beach.
            </li>
            <li>
              In the evening, return to Amsterdam and enjoy dinner at a
              restaurant in the trendy De Pijp neighborhood.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              Take a day trip to the charming city of Delft, known for its
              beautiful canals, historic buildings, and famous blue-and-white
              pottery. Visit attractions such as the Royal Delft pottery
              factory, the Old Church, and the Vermeer Center.
            </li>
            <li>
              In the evening, return to Amsterdam and enjoy a farewell dinner at
              a top-rated restaurant, such as Restaurant Vinkeles or De Librije
              Amsterdam.
            </li>
          </ul>{" "}
          <br />
          <ul>
            <li>
              On your last day, take a bike tour of Amsterdam and explore some
              of the city's hidden gems and local neighborhoods.
            </li>
            <li>
              After the bike tour, visit the famous Albert Cuyp Market, where
              you can find everything from fresh produce to clothing and
              souvenirs.
            </li>
            <li>
              In the afternoon, visit the NEMO Science Museum, which is a great
              attraction for families and science enthusiasts.
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "Norway",
      content: `A country located in Northern Europe, Norway is known for its stunning natural beauty, including fjords, glaciers, and the Northern Lights. It is also home to charming cities such as Oslo and Bergen and is famous for its seafood, particularly salmon and cod.`,
      img: norwayImage,
      rev: false,
      night3: (
        <>
          <h4>Arrival in Oslo</h4>
          <p>
            Upon arrival in Oslo, check-in to your hotel and take a walk around
            the city. Visit the Oslo Opera House, one of the most distinctive
            buildings in the city, and stroll through the Royal Palace Park. In
            the evening, enjoy a dinner at a local restaurant that serves
            traditional Norwegian cuisine.
          </p>

          <h4>Oslo City Tour</h4>
          <p>
            Start your day with a guided city tour of Oslo. Visit the Viking
            Ship Museum to see some of the best-preserved Viking ships in the
            world. After that, explore the Akershus Fortress and the Nobel Peace
            Center. In the afternoon, take a scenic boat ride through the Oslo
            Fjord to get a unique perspective of the city from the water.
          </p>

          <h4>Bergen</h4>
          <p>
            Take an early morning train to Bergen, the second-largest city in
            Norway. Spend the day exploring the city's historic landmarks, such
            as the UNESCO-listed Bryggen Wharf, the Fish Market, and the Mount
            Fløyen Funicular. In the evening, enjoy a seafood dinner at a local
            restaurant and experience Bergen's lively nightlife.
          </p>

          <h4>Departure</h4>
          <p>
            On your final day, take a walk through Bergen's picturesque streets
            before heading back to Oslo. If time permits, stop at one of the
            many scenic viewpoints along the way. Once in Oslo, visit the Munch
            Museum, which is home to the largest collection of works by the
            famous Norwegian artist, Edvard Munch. After that, head to the
            airport for your departure flight.
          </p>
        </>
      ),

      night5: (
        <>
          <h4>Arrival in Oslo</h4>
          <p>
            Upon your arrival in Oslo, check-in at your hotel and spend the
            afternoon exploring the city's historical landmarks. Visit the
            iconic Oslo Opera House, the Royal Palace, and Vigeland Sculpture
            Park. In the evening, enjoy a dinner at a traditional Norwegian
            restaurant.
          </p>

          <h4>Oslo City Tour</h4>
          <p>
            Begin your day with a guided city tour of Oslo. Visit the Viking
            Ship Museum to see some of the best-preserved Viking ships in the
            world. After that, explore the Akershus Fortress and the Nobel Peace
            Center. In the afternoon, take a scenic boat ride through the Oslo
            Fjord to get a unique perspective of the city from the water.
          </p>

          <h4> Bergen</h4>
          <p>
            Take an early morning train to Bergen, the second-largest city in
            Norway. Spend the day exploring the city's historic landmarks, such
            as the UNESCO-listed Bryggen Wharf, the Fish Market, and the Mount
            Fløyen Funicular. In the evening, enjoy a seafood dinner at a local
            restaurant and experience Bergen's lively nightlife.
          </p>

          <h4>Fjord Cruise</h4>
          <p>
            Take a full-day fjord cruise from Bergen to see some of Norway's
            most spectacular scenery. Cruise through the narrow Nærøyfjord, a
            UNESCO World Heritage site, and see the breathtaking Aurlandsfjord.
            During the cruise, you can also visit small villages and farms and
            learn about the local culture.
          </p>

          <h4>Flam and Myrdal</h4>
          <p>
            Take a scenic train ride from Bergen to Flam, a picturesque village
            nestled in the mountains. Spend the day exploring the village's
            natural wonders, such as the Flam Railway and the Stegastein
            Viewpoint. In the afternoon, take a train from Flam to Myrdal, where
            you can enjoy the beautiful landscapes before returning to Oslo.
          </p>

          <h4>Departure</h4>
          <p>
            On your final day, take a walk through Oslo's picturesque streets
            before heading to the airport for your departure flight. If time
            permits, visit the Munch Museum, which is home to the largest
            collection of works by the famous Norwegian artist, Edvard Munch.
          </p>
        </>
      ),

      night6: (
        <>
          <h4>Arrival in Oslo</h4>
          <p>
            Upon your arrival in Oslo, check-in at your hotel and spend the
            afternoon exploring the city's historical landmarks. Visit the
            iconic Oslo Opera House, the Royal Palace, and Vigeland Sculpture
            Park. In the evening, enjoy a dinner at a traditional Norwegian
            restaurant.
          </p>

          <h4>Oslo City Tour</h4>
          <p>
            Begin your day with a guided city tour of Oslo. Visit the Viking
            Ship Museum to see some of the best-preserved Viking ships in the
            world. After that, explore the Akershus Fortress and the Nobel Peace
            Center. In the afternoon, take a scenic boat ride through the Oslo
            Fjord to get a unique perspective of the city from the water.
          </p>

          <h4> Bergen</h4>
          <p>
            Take an early morning train to Bergen, the second-largest city in
            Norway. Spend the day exploring the city's historic landmarks, such
            as the UNESCO-listed Bryggen Wharf, the Fish Market, and the Mount
            Fløyen Funicular. In the evening, enjoy a seafood dinner at a local
            restaurant and experience Bergen's lively nightlife.
          </p>

          <h4>Fjord Cruise</h4>
          <p>
            Take a full-day fjord cruise from Bergen to see some of Norway's
            most spectacular scenery. Cruise through the narrow Nærøyfjord, a
            UNESCO World Heritage site, and see the breathtaking Aurlandsfjord.
            During the cruise, you can also visit small villages and farms and
            learn about the local culture.
          </p>

          <h4>Flam and Myrdal</h4>
          <p>
            Take a scenic train ride from Bergen to Flam, a picturesque village
            nestled in the mountains. Spend the day exploring the village's
            natural wonders, such as the Flam Railway and the Stegastein
            Viewpoint. In the afternoon, take a train from Flam to Myrdal, where
            you can enjoy the beautiful landscapes before returning to Oslo.
          </p>

          <h4>Trondheim</h4>
          <p>
            Take a morning flight to Trondheim, one of Norway's oldest cities.
            Spend the day exploring the city's historical landmarks, such as the
            Nidaros Cathedral and the Archbishop's Palace Museum. In the
            evening, enjoy a dinner at a local restaurant and experience
            Trondheim's vibrant culture.
          </p>

          <h4>Departure</h4>
          <p>
            On your final day, take a walk through Oslo's picturesque streets
            before heading to the airport for your departure flight. If time
            permits, visit the Munch Museum, which is home to the largest
            collection of works by the famous Norwegian artist, Edvard Munch.
          </p>
        </>
      ),
    },
    {
      name: "Spain",
      content: `Located in Southern Europe, Spain is known for its beautiful beaches, vibrant culture, and delicious cuisine. It is home to famous landmarks such as the Sagrada Familia in Barcelona and the Alhambra in Granada, as well as vibrant cities such as Madrid and Seville.`,
      img: spainImage,
      rev: true,
      night3: (
        <>
          <h4>Madrid</h4>
          <ul>
            <li>Arrive in Madrid and check into your hotel.</li>
            <li>
              Start the day by visiting the Royal Palace of Madrid, one of the
              city's most popular tourist attractions.
            </li>
            <li>
              Afterward, head to the Prado Museum, which houses a collection of
              European art from the 12th to 19th centuries.
            </li>
            <li>
              In the evening, wander around the streets of the Malasaña
              district, where you can find plenty of bars and restaurants.
            </li>
          </ul>
          <h4>Toledo and Segovia</h4>
          <ul>
            <li>
              Take a day trip to Toledo, a UNESCO World Heritage Site known for
              its medieval architecture and historic landmarks.
            </li>
            <li>
              After exploring Toledo, head to Segovia to see the iconic Roman
              aqueduct and the Alcázar of Segovia, a medieval castle that's also
              a UNESCO World Heritage Site.
            </li>
            <li>Return to Madrid in the evening.</li>
          </ul>
          <h4>Barcelona</h4>
          <ul>
            <li>Catch a morning flight to Barcelona.</li>
            <li>
              Spend the day exploring the city's iconic landmarks, such as the
              Sagrada Familia, Park Güell, and the Gothic Quarter.
            </li>
            <li>
              In the evening, check out the trendy El Raval or Gracia
              neighborhoods, where you can find a variety of restaurants and
              bars.
            </li>
          </ul>
          <h4> Barcelona</h4>
          <ul>
            <li>
              Spend your last day in Barcelona visiting other popular
              attractions, such as La Rambla, Casa Batlló, and the Picasso
              Museum.
            </li>
            <li>
              If you have time, take a stroll along the beachfront promenade and
              enjoy the Mediterranean Sea views.
            </li>
            <li>
              In the evening, catch a flight back home or to your next
              destination.
            </li>
          </ul>
          <p>
            <b>Note:</b>This itinerary includes visits to six of the most
            popular cities in Spain: Madrid, Seville, Cordoba, Granada, Malaga,
            and Barcelona. It also includes day trips to Toledo and Segovia from
            Madrid and day trips to Cordoba and Granada from Seville. If you
            prefer to spend more time in one city or want to explore other
            destinations in Spain.
          </p>
        </>
      ),

      night5: (
        <>
          <h4>Madrid</h4>
          <ul>
            <li>Arrive in Madrid and check into your hotel.</li>
            <li>
              Start the day by visiting the Royal Palace of Madrid, one of the
              city's most popular tourist attractions.
            </li>
            <li>
              Afterward, head to the Prado Museum, which houses a collection of
              European art from the 12th to 19th centuries.
            </li>
            <li>
              In the evening, wander around the streets of the Malasaña
              district, where you can find plenty of bars and restaurants.
            </li>
          </ul>
          <h4>Toledo and Segovia</h4>
          <ul>
            <li>
              Take a day trip to Toledo, a UNESCO World Heritage Site known for
              its medieval architecture and historic landmarks.
            </li>
            <li>
              After exploring Toledo, head to Segovia to see the iconic Roman
              aqueduct and the Alcázar of Segovia, a medieval castle that's also
              a UNESCO World Heritage Site.
            </li>
            <li>Return to Madrid in the evening.</li>
          </ul>
          <h4>Seville</h4>
          <ul>
            <li>
              Catch a morning flight to Seville, one of the most beautiful and
              culturally rich cities in Spain.
            </li>
            <li>
              Visit the Seville Cathedral, which is the largest Gothic cathedral
              in the world and a UNESCO World Heritage Site.
            </li>
            <li>
              After that, head to the Alcázar of Seville, a stunning royal
              palace with beautiful gardens and courtyards.
            </li>
            <li>
              In the evening, stroll around the lively Santa Cruz neighborhood
              and try some tapas at a local bar.
            </li>
          </ul>
          <h4>Granada</h4>
          <ul>
            <li>
              Take a day trip to Granada, a city famous for the Alhambra, a
              stunning palace and fortress complex that's also a UNESCO World
              Heritage Site.
            </li>
            <li>
              Spend the day exploring the Alhambra and the Generalife Gardens,
              which are considered some of the most beautiful in the world.
            </li>
            <li>In the evening, return to Seville.</li>
          </ul>
          <h4>Barcelona</h4>
          <ul>
            <li>Catch a morning flight to Barcelona.</li>
            <li>
              Spend the day exploring the city's iconic landmarks, such as the
              Sagrada Familia, Park Güell, and the Gothic Quarter.
            </li>
            <li>
              In the evening, check out the trendy El Raval or Gracia
              neighborhoods, where you can find a variety of restaurants and
              bars.
            </li>
          </ul>
          <h4> Barcelona</h4>
          <ul>
            <li>
              Spend your last day in Barcelona visiting other popular
              attractions, such as La Rambla, Casa Batlló, and the Picasso
              Museum.
            </li>
            <li>
              If you have time, take a stroll along the beachfront promenade and
              enjoy the Mediterranean Sea views.
            </li>
            <li>
              In the evening, catch a flight back home or to your next
              destination.
            </li>
          </ul>
          <p>
            <b>Note:</b>This itinerary includes visits to six of the most
            popular cities in Spain: Madrid, Seville, Cordoba, Granada, Malaga,
            and Barcelona. It also includes day trips to Toledo and Segovia from
            Madrid and day trips to Cordoba and Granada from Seville. If you
            prefer to spend more time in one city or want to explore other
            destinations in Spain.
          </p>
        </>
      ),

      night6: (
        <>
          <h4>Madrid</h4>
          <ul>
            <li>Arrive in Madrid and check into your hotel.</li>
            <li>
              Start the day by visiting the Royal Palace of Madrid, one of the
              city's most popular tourist attractions.
            </li>
            <li>
              Afterward, head to the Prado Museum, which houses a collection of
              European art from the 12th to 19th centuries.
            </li>
            <li>
              In the evening, wander around the streets of the Malasaña
              district, where you can find plenty of bars and restaurants.
            </li>
          </ul>
          <h4>Toledo and Segovia</h4>
          <ul>
            <li>
              Take a day trip to Toledo, a UNESCO World Heritage Site known for
              its medieval architecture and historic landmarks.
            </li>
            <li>
              After exploring Toledo, head to Segovia to see the iconic Roman
              aqueduct and the Alcázar of Segovia, a medieval castle that's also
              a UNESCO World Heritage Site.
            </li>
            <li>Return to Madrid in the evening.</li>
          </ul>
          <h4>Seville</h4>
          <ul>
            <li>
              Catch a morning flight to Seville, one of the most beautiful and
              culturally rich cities in Spain.
            </li>
            <li>
              Visit the Seville Cathedral, which is the largest Gothic cathedral
              in the world and a UNESCO World Heritage Site.
            </li>
            <li>
              After that, head to the Alcázar of Seville, a stunning royal
              palace with beautiful gardens and courtyards.
            </li>
            <li>
              In the evening, stroll around the lively Santa Cruz neighborhood
              and try some tapas at a local bar.
            </li>
          </ul>
          <h4>Cordoba</h4>
          <ul>
            <li>
              Take a day trip to Cordoba, a city famous for the Mezquita, a
              stunning mosque-cathedral that's also a UNESCO World Heritage
              Site.
            </li>
            <li>
              Spend the day exploring the Mezquita and the charming streets of
              the Jewish Quarter.
            </li>
            <li>In the evening, return to Seville.</li>
          </ul>
          <h4>Granada</h4>
          <ul>
            <li>
              Take a day trip to Granada, a city famous for the Alhambra, a
              stunning palace and fortress complex that's also a UNESCO World
              Heritage Site.
            </li>
            <li>
              Spend the day exploring the Alhambra and the Generalife Gardens,
              which are considered some of the most beautiful in the world.
            </li>
            <li>In the evening, return to Seville.</li>
          </ul>
          <h4>Malaga</h4>
          <ul>
            <li>
              Catch a morning train to Malaga, a city located on the Costa del
              Sol.
            </li>
            <li>
              Visit the Picasso Museum, which houses a collection of works by
              the famous Spanish artist.
            </li>
            <li>
              After that, head to the Alcazaba of Malaga, a Moorish fortress
              that offers stunning views of the city and the sea.
            </li>
            <li>
              In the evening, stroll along the beachfront promenade and enjoy
              some fresh seafood at a local restaurant.
            </li>
          </ul>
          <h4>Barcelona</h4>
          <ul>
            <li>Catch a morning flight to Barcelona.</li>
            <li>
              Spend the day exploring the city's iconic landmarks, such as the
              Sagrada Familia, Park Güell, and the Gothic Quarter.
            </li>
            <li>
              In the evening, check out the trendy El Raval or Gracia
              neighborhoods, where you can find a variety of restaurants and
              bars.
            </li>
          </ul>
          <h4> Barcelona</h4>
          <ul>
            <li>
              Spend your last day in Barcelona visiting other popular
              attractions, such as La Rambla, Casa Batlló, and the Picasso
              Museum.
            </li>
            <li>
              If you have time, take a stroll along the beachfront promenade and
              enjoy the Mediterranean Sea views.
            </li>
            <li>
              In the evening, catch a flight back home or to your next
              destination.
            </li>
          </ul>
          <p>
            <b>Note:</b>This itinerary includes visits to six of the most
            popular cities in Spain: Madrid, Seville, Cordoba, Granada, Malaga,
            and Barcelona. It also includes day trips to Toledo and Segovia from
            Madrid and day trips to Cordoba and Granada from Seville. If you
            prefer to spend more time in one city or want to explore other
            destinations in Spain.
          </p>
        </>
      ),
    },
    {
      name: "Sweden",
      content: `A country located in Northern Europe, Sweden is known for its stunning natural beauty, including forests, lakes, and mountains. It is also home to charming cities such as Stockholm and Gothenburg and is famous for its meatballs, fish dishes, and delicious cinnamon buns.`,
      img: swedenImage,
      rev: false,
      night3: (
        <>
          <h4>Stockholm</h4>
          <ul>
            <li>Arrive in Stockholm and check into your hotel.</li>
            <li>
              Start your day by visiting Gamla Stan, the Old Town of Stockholm,
              which is known for its colorful buildings, narrow streets, and
              historic landmarks.
            </li>
            <li>
              After that, head to the Vasa Museum, which houses a restored
              17th-century warship that sank on its maiden voyage.
            </li>
            <li>
              In the evening, explore the trendy Södermalm district, which is
              home to a variety of bars, restaurants, and shops.
            </li>
          </ul>
          <h4>Stockholm</h4>
          <ul>
            <li>
              Spend the day exploring some of Stockholm's other popular
              attractions, such as the Royal Palace, the Skansen open-air
              museum, and the ABBA Museum.
            </li>
            <li>
              In the evening, catch a performance at the Royal Swedish Opera or
              visit the Fotografiska photography museum.
            </li>
          </ul>
          <h4>Gothenburg</h4>
          <ul>
            <li>
              Take a morning train or flight to Gothenburg, a charming city
              located on the west coast of Sweden.
            </li>
            <li>
              Start your visit by strolling along the Avenyn, a boulevard lined
              with shops, restaurants, and cafes.
            </li>
            <li>
              After that, head to the Liseberg amusement park, which is one of
              the most popular tourist attractions in Gothenburg.
            </li>
            <li>
              In the evening, check out the Haga neighborhood, which is known
              for its cozy cafes and artisan shops.
            </li>
          </ul>
          <h4>Gothenburg</h4>
          <ul>
            <li>
              Spend your last day in Gothenburg visiting other popular
              attractions, such as the Gothenburg Museum of Art, the Gothenburg
              Botanical Garden, and the fish market at Feskekörka.
            </li>
            <li>
              In the afternoon, catch a flight or train back to Stockholm and
              spend the evening relaxing or doing some last-minute souvenir
              shopping.
            </li>
          </ul>
          <p>
            <b>Note:</b> This itinerary includes visits to two of the most
            popular cities in Sweden: Stockholm and Gothenburg. It also includes
            a mix of cultural attractions, historic landmarks, and fun
            activities like amusement parks and museums. If you have more time,
            consider visiting other destinations in Sweden, such as Malmö,
            Uppsala, or the Swedish countryside.
          </p>
        </>
      ),

      night5: (
        <>
          <h4>Stockholm</h4>
          <ul>
            <li>Arrive in Stockholm and check into your hotel.</li>
            <li>
              Start your visit by exploring Gamla Stan, the historic Old Town of
              Stockholm. Here, you'll find beautiful architecture, narrow
              cobblestone streets, and charming cafes and shops.
            </li>
            <li>
              Visit the Royal Palace and its museum, which houses the Swedish
              Crown Jewels.
            </li>
            <li>
              In the evening, take a stroll along Strandvägen, a picturesque
              boulevard lined with beautiful buildings and cafes.
            </li>
          </ul>
          <h4>Stockholm</h4>
          <ul>
            <li>
              Visit Djurgården, an island in the heart of Stockholm that is home
              to several popular attractions, including the Vasa Museum, Skansen
              open-air museum, and ABBA Museum.
            </li>
            <li>
              In the evening, catch a performance at the Royal Swedish Opera, or
              visit the Fotografiska photography museum.
            </li>
          </ul>
          <h4>Gothenburg</h4>
          <ul>
            <li>
              Take a train or flight to Gothenburg, a charming city on the west
              coast of Sweden.
            </li>
            <li>
              Start your visit by strolling along the Avenyn, a boulevard lined
              with shops, restaurants, and cafes.
            </li>
            <li>
              Visit the Liseberg amusement park, which is one of the most
              popular tourist attractions in Gothenburg.
            </li>
            <li>
              In the evening, check out the Haga neighborhood, which is known
              for its cozy cafes and artisan shops.
            </li>
          </ul>
          <h4>Gothenburg</h4>
          <ul>
            <li>
              Spend your day visiting other popular attractions in Gothenburg,
              such as the Gothenburg Museum of Art, the Gothenburg Botanical
              Garden, and the fish market at Feskekörka.
            </li>
            <li>
              In the evening, head to the trendy neighborhood of Majorna, which
              is known for its cool bars and restaurants.
            </li>
          </ul>
          <h4>Malmo</h4>
          <ul>
            <li>
              Spend your last day in Malmö visiting other popular attractions,
              such as Malmö Castle, the Malmö City Museum, and the Moderna
              Museet Malmö.
            </li>
            <li>
              In the afternoon, catch a flight or train back to Stockholm and
              spend the evening relaxing or doing some last-minute souvenir
              shopping.
            </li>
          </ul>
          <p>
            <b>Note:</b> This itinerary includes visits to three of the most
            popular cities in Sweden: Stockholm, Gothenburg, and Malmö. It also
            includes a mix of cultural attractions, historic landmarks, and fun
            activities like amusement parks and museums. If you have more time,
            consider visiting other destinations in Sweden, such as Uppsala, the
            Swedish countryside, or the archipelago outside Stockholm.
          </p>
        </>
      ),

      night6: (
        <>
          <h4>Stockholm</h4>
          <ul>
            <li>Arrive in Stockholm and check into your hotel.</li>
            <li>
              Start your visit by exploring Gamla Stan, the historic Old Town of
              Stockholm. Here, you'll find beautiful architecture, narrow
              cobblestone streets, and charming cafes and shops.
            </li>
            <li>
              Visit the Royal Palace and its museum, which houses the Swedish
              Crown Jewels.
            </li>
            <li>
              In the evening, take a stroll along Strandvägen, a picturesque
              boulevard lined with beautiful buildings and cafes.
            </li>
          </ul>
          <h4>Stockholm</h4>
          <ul>
            <li>
              Visit Djurgården, an island in the heart of Stockholm that is home
              to several popular attractions, including the Vasa Museum, Skansen
              open-air museum, and ABBA Museum.
            </li>
            <li>
              In the evening, catch a performance at the Royal Swedish Opera, or
              visit the Fotografiska photography museum.
            </li>
          </ul>
          <h4>Uppsala</h4>
          <ul>
            <li>
              Take a train or bus to Uppsala, a charming university town north
              of Stockholm.
            </li>
            <li>
              Visit the Uppsala Cathedral, the largest church in Scandinavia,
              and the Linnaeus Garden, which was once the home of famous
              botanist Carl Linnaeus.
            </li>
            <li>
              In the evening, stroll along the Fyris River or check out the bars
              and restaurants in the student neighborhood of Flogsta.
            </li>
          </ul>
          <h4>Gothenburg</h4>
          <ul>
            <li>
              Take a train or flight to Gothenburg, a charming city on the west
              coast of Sweden.
            </li>
            <li>
              Start your visit by strolling along the Avenyn, a boulevard lined
              with shops, restaurants, and cafes.
            </li>
            <li>
              Visit the Liseberg amusement park, which is one of the most
              popular tourist attractions in Gothenburg.
            </li>
            <li>
              In the evening, check out the Haga neighborhood, which is known
              for its cozy cafes and artisan shops.
            </li>
          </ul>
          <h4>Gothenburg</h4>
          <ul>
            <li>
              Spend your day visiting other popular attractions in Gothenburg,
              such as the Gothenburg Museum of Art, the Gothenburg Botanical
              Garden, and the fish market at Feskekörka.
            </li>
            <li>
              In the evening, head to the trendy neighborhood of Majorna, which
              is known for its cool bars and restaurants.
            </li>
          </ul>
          <h4>Malmö</h4>
          <ul>
            <li>
              Take a train or flight to Malmö, a coastal city in southern
              Sweden.
            </li>
            <li>
              Visit the Turning Torso, a unique skyscraper that is the tallest
              building in Scandinavia.
            </li>
            <li>
              Explore the charming Lilla Torg, a small square in the heart of
              Malmö that is home to several historic buildings, shops, and
              restaurants.
            </li>
            <li>
              In the evening, check out the lively Möllevångstorget square,
              which is known for its street food and nightlife.
            </li>
          </ul>
          <h4>Skåne</h4>
          <ul>
            <li>
              Take a day trip to the region of Skåne, which is known for its
              beautiful countryside, vineyards, and historic castles.
            </li>
            <li>
              Visit the medieval castle of Hovdala, the picturesque village of
              Österlen, and the Kivik Cider House, where you can sample local
              cider.
            </li>
            <li>
              In the evening, return to Malmö for a final night in the city.
            </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Depending on your flight time, you may have some time to explore
              Malmö in the morning before departing.
            </li>
            <li>
              Alternatively, you can catch an early flight or train back to
              Stockholm to spend some more time in the capital before departing.
            </li>
          </ul>
          <p>
            <b>Note;</b> This itinerary includes visits to three of the most
            popular cities in Sweden: Stockholm, Gothenburg, and Malmö, as well
            as a day trip to the Skåne region. It also includes a mix of
            cultural attractions, historic landmarks, and fun activities like
            amusement parks and museums.{" "}
          </p>
        </>
      ),
    },
    {
      name: "Switzerland",
      content: `Known for its stunning Alpine scenery, chocolate, and cheese, Switzerland is a country located in Central Europe. It is also famous for its watchmaking industry and for being a popular destination for winter sports enthusiasts.`,
      img: switezerImage,
      rev: true,
      night3: (
        <>
          <h4>Zurich</h4>
          <ul>
            <li>Arrive in Zurich and check into your hotel.</li>
            <li>
              Start your visit by exploring the historic Old Town of Zurich,
              where you'll find beautiful architecture, narrow cobblestone
              streets, and charming cafes and shops.
            </li>
            <li>
              Visit the Fraumünster church and its famous stained glass windows
              by artist Marc Chagall.
            </li>
            <li>
              In the evening, take a stroll along the Limmat river or enjoy a
              dinner at one of Zurich's many excellent restaurants.
            </li>
          </ul>
          <h4>Lucerne</h4>
          <ul>
            <li>
              Take a train or boat to Lucerne, a picturesque city nestled in the
              Swiss Alps.
            </li>
            <li>
              Visit the Chapel Bridge, a covered wooden footbridge that dates
              back to the 14th century.
            </li>
            <li>
              Explore the historic Old Town of Lucerne, which is home to several
              beautiful squares, fountains, and buildings.
            </li>
            <li>
              In the evening, take a boat ride on Lake Lucerne to enjoy the
              stunning mountain views.
            </li>
          </ul>
          <h4>Interlaken</h4>
          <ul>
            <li>
              Take a train to Interlaken, a popular resort town located between
              two lakes in the Swiss Alps.
            </li>
            <li>
              Take the cogwheel train up to Jungfraujoch, the "Top of Europe"
              and the highest train station in Europe.
            </li>
            <li>
              Enjoy panoramic views of the surrounding mountains, including the
              famous Eiger, Mönch, and Jungfrau peaks.
            </li>
            <li>
              In the evening, return to Interlaken for dinner and some souvenir
              shopping.
            </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Depending on your flight time, you may have some time to explore
              Zurich in the morning before departing.
            </li>
            <li>
              Alternatively, you can catch an early flight or train to your next
              destination.
            </li>
          </ul>
          <p>
            <b>Note:</b>This itinerary includes visits to three of the most
            popular destinations in Switzerland: Zurich, Lucerne, and
            Interlaken. It also includes a mix of cultural attractions, historic
            landmarks, and stunning natural scenery. If you have more time,
            consider extending your trip to explore other destinations in
            Switzerland, such as Geneva, Zermatt, or the Swiss Alps.{" "}
          </p>
        </>
      ),

      night5: (
        <>
          <h4>Arrival in Zurich</h4>
          <ul>
            <li>Arrive in Zurich and check in to your hotel.</li>
            <li>
              Spend the day exploring Zurich's old town, including landmarks
              like Grossmünster and Fraumünster churches, the Bahnhofstrasse
              shopping street, and the Swiss National Museum.
            </li>
            <li>
              In the evening, take a stroll along the Limmat River or enjoy a
              meal at one of Zurich's many restaurants.
            </li>
          </ul>
          <h4>Lucerne and Mount Pilatus</h4>
          <ul>
            <li>
              Take a train to Lucerne, a charming city located on the shores of
              Lake Lucerne.
            </li>
            <li>
              Explore the old town, including the Chapel Bridge, Jesuit Church,
              and the Lion Monument.
            </li>
            <li>
              Take the cogwheel train to Mount Pilatus, one of the most scenic
              peaks in Switzerland. Enjoy panoramic views of the Alps and the
              surrounding countryside.
            </li>
            <li>Return to Lucerne for the night.</li>
          </ul>
          <h4>Interlaken and Jungfraujoch</h4>
          <ul>
            <li>
              Take a train to Interlaken, a resort town located between Lake
              Thun and Lake Brienz.
            </li>
            <li>
              Take a cable car to the top of the Schilthorn mountain and enjoy
              stunning views of the surrounding Alps.
            </li>
            <li>
              Take a train to Jungfraujoch, the highest railway station in
              Europe. Enjoy breathtaking views of the Aletsch Glacier and the
              surrounding mountains.
            </li>
            <li>Return to Interlaken for the night.</li>
          </ul>
          <h4>Bern and Gruyeres</h4>
          <ul>
            <li>Take a train to Bern, the capital of Switzerland.</li>
            <li>
              Explore the historic old town, including the Bern Cathedral, the
              Zytglogge clock tower, and the Einstein House.
            </li>
            <li>
              Take a train to Gruyeres, a picturesque medieval town famous for
              its cheese and chocolate.
            </li>
            <li>
              Visit the Gruyere Castle and the Maison Cailler chocolate factory.
            </li>
            <li>Return to Bern for the night.</li>
          </ul>
          <h4>Montreux and Geneva</h4>
          <ul>
            <li>
              Take a train to Montreux, a beautiful lakeside town located on the
              shores of Lake Geneva.
            </li>
            <li>
              Visit the Chillon Castle, a medieval fortress that inspired Lord
              Byron's poem "The Prisoner of Chillon".
            </li>
            <li>
              Take a train to Geneva, the second largest city in Switzerland and
              home to the United Nations.
            </li>
            <li>
              Visit the Jet d'Eau fountain, the Old Town, and the Palace of
              Nations.
            </li>
            <li>Return to Zurich for the night.</li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Check out of your hotel and depart for your next destination.
            </li>
          </ul>
          <p>
            This itinerary covers some of Switzerland's most iconic
            destinations, including Zurich, Lucerne, Interlaken, Bern, Gruyeres,
            Montreux, and Geneva. However, it is just one example of how you
            could spend your time in Switzerland.{" "}
          </p>
        </>
      ),

      night6: (
        <>
          <h4>Arrival in Zurich</h4>
          <ul>
            <li>Arrive in Zurich and check in to your hotel.</li>
            <li>
              Spend the day exploring Zurich's old town, including landmarks
              like Grossmünster and Fraumünster churches, the Bahnhofstrasse
              shopping street, and the Swiss National Museum.
            </li>
            <li>
              In the evening, take a stroll along the Limmat River or enjoy a
              meal at one of Zurich's many restaurants.
            </li>
          </ul>
          <h4>Lucerne and Mount Pilatus</h4>
          <ul>
            <li>
              Take a train to Lucerne, a charming city located on the shores of
              Lake Lucerne.
            </li>
            <li>
              Explore the old town, including the Chapel Bridge, Jesuit Church,
              and the Lion Monument.
            </li>
            <li>
              Take the cogwheel train to Mount Pilatus, one of the most scenic
              peaks in Switzerland. Enjoy panoramic views of the Alps and the
              surrounding countryside.
            </li>
            <li>Return to Lucerne for the night.</li>
          </ul>
          <h4>Interlaken and Jungfraujoch</h4>
          <ul>
            <li>
              Take a train to Interlaken, a resort town located between Lake
              Thun and Lake Brienz.
            </li>
            <li>
              Take a cable car to the top of the Schilthorn mountain and enjoy
              stunning views of the surrounding Alps.
            </li>
            <li>
              Take a train to Jungfraujoch, the highest railway station in
              Europe. Enjoy breathtaking views of the Aletsch Glacier and the
              surrounding mountains.
            </li>
            <li>Return to Interlaken for the night.</li>
          </ul>
          <h4>Zermatt and Matterhorn</h4>
          <ul>
            <li>
              Take a train to Zermatt, a car-free town located at the foot of
              the Matterhorn.
            </li>
            <li>
              Explore the town and take a cable car to the top of the Klein
              Matterhorn, the highest cable car station in Europe.
            </li>
            <li>
              Enjoy panoramic views of the Matterhorn and the surrounding
              mountains.
            </li>
            <li>Return to Zermatt for the night.</li>
          </ul>
          <h4>St. Moritz and Bernina Express</h4>
          <ul>
            <li>
              Take a train to St. Moritz, a luxury resort town located in the
              Swiss Alps.
            </li>
            <li>
              Board the Bernina Express, one of the most scenic train rides in
              Switzerland, and travel through the UNESCO-listed Albula and
              Bernina landscapes.
            </li>
            <li>
              Admire views of glaciers, alpine forests, and mountain lakes.
            </li>
            <li>Return to St. Moritz for the night.</li>
          </ul>
          <h4>Geneva and Montreux</h4>
          <ul>
            <li>
              Take a train to Geneva, the second largest city in Switzerland and
              home to the United Nations.
            </li>
            <li>
              Visit the Jet d'Eau fountain, the Old Town, and the Palace of
              Nations.
            </li>
            <li>
              Take a train to Montreux, a beautiful lakeside town located on the
              shores of Lake Geneva.
            </li>
            <li>
              Visit the Chillon Castle, a medieval fortress that inspired Lord
              Byron's poem "The Prisoner of Chillon".
            </li>
            <li>Return to Geneva for the night.</li>
          </ul>
          <h4> Lausanne and Lavaux Vineyards</h4>
          <ul>
            <li>
              Take a train to Lausanne, a charming lakeside town located on the
              shores of Lake Geneva.
            </li>
            <li>Explore the old town and visit the Olympic Museum.</li>
            <li>
              Take a scenic train ride through the Lavaux Vineyards, a UNESCO
              World Heritage site known for its terraced vineyards and stunning
              views of Lake Geneva.
            </li>
            <li>Return to Zurich for the night.</li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Check out of your hotel and depart for your next destination.
            </li>
          </ul>
          <p>
            This itinerary covers some of Switzerland's most iconic
            destinations, including Zurich, Lucerne, Interlaken, Zermatt, St.
            Moritz, Geneva, Montreux, Lausanne, and the Lavaux Vineyards.{" "}
          </p>
        </>
      ),
    },
    {
      name: "United Kingdom",
      content: `Made up of England, Scotland, Wales, and Northern Ireland, the United Kingdom is known for its rich history, iconic landmarks, and diverse cultures. It is home to famous landmarks such as Buckingham Palace, Stonehenge, and Edinburgh Castle.`,
      img: ukImage,
      rev: false,
      night3: (
        <>
          <h4>London</h4>
          <ul>
            <li>Arrive in London and check into your hotel.</li>
            <li>
              Spend the day exploring the city's famous landmarks, such as
              Buckingham Palace, the Houses of Parliament, Big Ben, and the
              London Eye.
            </li>
            <li>
              Take a stroll along the Thames River and enjoy the views of the
              city's skyline.
            </li>
            <li>
              In the evening, head to a pub for a traditional British dinner and
              a pint of ale.
            </li>
          </ul>
          <h4>Edinburgh</h4>
          <ul>
            <li>
              Take a train from London to Edinburgh, the capital of Scotland.
            </li>
            <li>
              Explore the city's old town, including Edinburgh Castle, the Royal
              Mile, and St. Giles' Cathedral.
            </li>
            <li>
              Walk up to Arthur's Seat, an extinct volcano with panoramic views
              of the city.
            </li>
            <li>
              In the evening, enjoy a traditional Scottish meal and a glass of
              whisky at a local pub.
            </li>
          </ul>
          <h4>Lake District</h4>
          <ul>
            <li>
              Take a train from Edinburgh to the Lake District, a beautiful
              region of lakes, mountains, and forests in northwest England.
            </li>
            <li>
              Take a boat ride on Lake Windermere and enjoy the stunning
              scenery.
            </li>
            <li>
              Visit Beatrix Potter's Hill Top Farm, the home of the famous
              children's author.
            </li>
            <li>
              In the evening, enjoy a relaxing stay at a cozy bed and breakfast
              in the countryside.
            </li>
          </ul>
          <h4>Bath and Stonehenge</h4>
          <ul>
            <li>
              {" "}
              Take a train from the Lake District to Bath, a historic city known
              for its Roman baths and Georgian architecture.
            </li>
            <li>
              Visit the Roman Baths, the Bath Abbey, and the Royal Crescent.{" "}
            </li>
            <li>
              Take a guided tour to Stonehenge, a mysterious prehistoric
              monument.{" "}
            </li>
            <li>Return to London for your departure. </li>
          </ul>
          <p>
            This itinerary covers some of the most popular destinations in the
            United Kingdom, including London, Edinburgh, the Lake District,
            Bath, and Stonehenge. However, it is just one example of how you
            could spend your time in the country.
          </p>
        </>
      ),

      night5: (
        <>
          <h4>London</h4>
          <ul>
            <li>Arrive in London and check into your hotel.</li>
            <li>
              Spend the day exploring the city's famous landmarks, such as
              Buckingham Palace, the Houses of Parliament, Big Ben, and the
              London Eye.
            </li>
            <li>
              Take a stroll along the Thames River and enjoy the views of the
              city's skyline.
            </li>
            <li>
              In the evening, head to a pub for a traditional British dinner and
              a pint of ale.
            </li>
          </ul>
          <h4>Oxford and Stratford-upon-Avon</h4>
          <ul>
            <li>
              Take a day trip from London to Oxford, home to one of the world's
              oldest and most prestigious universities.
            </li>
            <li>
              Visit Christ Church College, the Bodleian Library, and the
              Ashmolean Museum.
            </li>
            <li>
              In the afternoon, head to Stratford-upon-Avon, the birthplace of
              William Shakespeare.
            </li>
            <li>
              Visit the Shakespeare Birthplace Trust and the Royal Shakespeare
              Company's theaters.
            </li>
            <li>Return to London for the night.</li>
          </ul>
          <h4>Edinburgh</h4>
          <ul>
            <li>
              Take a train from London to Edinburgh, the capital of Scotland.
            </li>
            <li>
              Explore the city's old town, including Edinburgh Castle, the Royal
              Mile, and St. Giles' Cathedral.
            </li>
            <li>
              Walk up to Arthur's Seat, an extinct volcano with panoramic views
              of the city.
            </li>
            <li>
              In the evening, enjoy a traditional Scottish meal and a glass of
              whisky at a local pub.
            </li>
          </ul>
          <h4> Lake District</h4>
          <ul>
            <li>
              Take a train from Edinburgh to the Lake District, a beautiful
              region of lakes, mountains, and forests in northwest England.
            </li>
            <li>
              Take a boat ride on Lake Windermere and enjoy the stunning
              scenery.
            </li>
            <li>
              Visit Beatrix Potter's Hill Top Farm, the home of the famous
              children's author.
            </li>
            <li>
              In the evening, enjoy a relaxing stay at a cozy bed and breakfast
              in the countryside.
            </li>
          </ul>
          <h4>Bath and Stonehenge</h4>
          <ul>
            <li>
              Take a train from the Lake District to Bath, a historic city known
              for its Roman baths and Georgian architecture.
            </li>
            <li>
              Visit the Roman Baths, the Bath Abbey, and the Royal Crescent.
            </li>
            <li>
              Take a guided tour to Stonehenge, a mysterious prehistoric
              monument.
            </li>
            <li>Return to London for your departure.</li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Check out of your hotel and depart for your next destination.
            </li>
          </ul>
          <p>
            This itinerary covers some of the most popular destinations in the
            United Kingdom, including London, Oxford, Stratford-upon-Avon,
            Edinburgh, the Lake District, Bath, and Stonehenge. However, it is
            just one example of how you could spend your time in the country.{" "}
          </p>
        </>
      ),

      night6: (
        <>
          <h4>London</h4>
          <ul>
            <li>Arrive in London and check into your hotel.</li>
            <li>
              Spend the day exploring the city's famous landmarks, such as
              Buckingham Palace, the Houses of Parliament, Big Ben, and the
              London Eye.
            </li>
            <li>
              Take a stroll along the Thames River and enjoy the views of the
              city's skyline.
            </li>
            <li>
              In the evening, head to a pub for a traditional British dinner and
              a pint of ale.
            </li>
          </ul>
          <h4>Oxford and Cotswolds</h4>
          <ul>
            <li>
              Take a day trip from London to Oxford, home to one of the world's
              oldest and most prestigious universities.
            </li>
            <li>
              Visit Christ Church College, the Bodleian Library, and the
              Ashmolean Museum.
            </li>
            <li>
              In the afternoon, head to the Cotswolds, a picturesque region of
              rolling hills and charming villages.
            </li>
            <li>
              Visit the villages of Burford, Bourton-on-the-Water, and
              Stow-on-the-Wold.
            </li>
            <li>Return to London for the night.</li>
          </ul>
          <h4>Bath and Stonehenge</h4>
          <ul>
            <li>
              Take a day trip from London to Bath, a historic city known for its
              Roman baths and Georgian architecture.
            </li>
            <li>
              Visit the Roman Baths, the Bath Abbey, and the Royal Crescent.
            </li>
            <li>
              Take a guided tour to Stonehenge, a mysterious prehistoric
              monument.
            </li>
            <li>Return to London for the night.</li>
          </ul>
          <h4>Windsor and Hampton Court Palace</h4>
          <ul>
            <li>
              Take a day trip from London to Windsor, home to Windsor Castle,
              one of the official residences of the British monarch.
            </li>
            <li>Visit St. George's Chapel and the State Apartments.</li>
            <li>
              In the afternoon, head to Hampton Court Palace, a magnificent
              Tudor palace that was once the home of King Henry VIII.
            </li>
            <li>Explore the palace's gardens and maze.</li>
            <li>Return to London for the night.</li>
          </ul>
          <h4>Edinburgh</h4>
          <ul>
            <li>
              Take a train from London to Edinburgh, the capital of Scotland.
            </li>
            <li>
              Explore the city's old town, including Edinburgh Castle, the Royal
              Mile, and St. Giles' Cathedral.
            </li>
            <li>
              Walk up to Arthur's Seat, an extinct volcano with panoramic views
              of the city.
            </li>
            <li>
              In the evening, enjoy a traditional Scottish meal and a glass of
              whisky at a local pub.
            </li>
          </ul>
          <h4>Loch Ness and the Scottish Highlands</h4>
          <ul>
            <li>
              Take a day trip from Edinburgh to Loch Ness and the Scottish
              Highlands, a region of stunning natural beauty.
            </li>
            <li>
              Take a boat ride on Loch Ness and look for the famous monster.
            </li>
            <li>
              Visit the picturesque town of Fort Augustus and enjoy a
              traditional Scottish lunch.
            </li>
            <li>
              Drive through the Highlands and enjoy the scenic landscapes.
            </li>
            <li>Return to Edinburgh for the night.</li>
          </ul>
          <h4>Liverpool</h4>
          <ul>
            <li>
              Take a train from Edinburgh to Liverpool, the birthplace of the
              Beatles and a city with a rich musical heritage.
            </li>
            <li>
              Visit the Beatles Story museum and take a tour of the city's
              musical landmarks.
            </li>
            <li>Visit the Liverpool Cathedral and the Albert Dock.</li>
            <li>
              In the evening, enjoy a night out in the city's lively nightlife
              scene.
            </li>
            <li>Return to London for the night.</li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Check out of your hotel and depart for your next destination.
            </li>
          </ul>
          <p>
            This itinerary covers some of the most popular destinations in the
            United Kingdom, including London, Oxford, the Cotswolds, Bath,
            Stonehenge, Windsor, Hampton Court Palace, Edinburgh, Loch Ness, the
            Scottish Highlands, and Liverpool.
          </p>
        </>
      ),
    },
  ],
  middleeast: [
    {
      name: "Dubai",
      content: ` Located in the United Arab Emirates, Dubai is a city known for its stunning architecture, luxury shopping, and vibrant nightlife. It is home to iconic landmarks such as the Burj Khalifa, the world's tallest building, and the Palm Jumeirah, an artificial island in the shape of a palm tree.`,
      img: dubaiImage,
      rev: false,

      night3: (
        <>
          <h3>Here's a sample itinerary for a 3-night, 4-day trip to Dubai:</h3>
          <h4>Arrival in Dubai</h4>
          <ul>
            <li>
              Upon arrival, check into your hotel and spend the rest of the day
              exploring the city.
            </li>
            <li>
              Visit the iconic Burj Khalifa, the tallest building in the world.
            </li>
            <li>
              Walk through the Dubai Mall, one of the largest shopping centers
              in the world.
            </li>
            <li>Admire the choreographed fountain show outside the mall.</li>
          </ul>
          <h4>Dubai</h4>
          <ul>
            <li>
              Start the day with a visit to the Dubai Miracle Garden, the
              world's largest flower garden.
            </li>
            <li>
              Take a tour of the Dubai Museum, which showcases the history and
              culture of Dubai.
            </li>
            <li>
              In the afternoon, take a dhow cruise along the Dubai Creek and
              admire the city skyline.
            </li>
            <li>
              Spend the evening at the Dubai Fountain, where you can enjoy
              another stunning fountain show.
            </li>
          </ul>
          <h4>Dubai</h4>
          <ul>
            <li>
              Take a desert safari and experience the beauty of the Arabian
              Desert.
            </li>
            <li>
              Stop by a traditional Bedouin-style camp for a camel ride and a
              delicious barbecue dinner.
            </li>
            <li>
              Enjoy a mesmerizing belly dance performance under the stars.
            </li>
          </ul>
          <h4>Departure from Dubai</h4>
          <ul>
            <li>Transfer to the airport for your flight back home.</li>
          </ul>
          <p>
            This itinerary provides a great introduction to Dubai and its many
            attractions, but there is much more to see and do in this vibrant
            city. You can also consider visiting other attractions such as the
            Dubai Fountain, the Palm Jumeirah, and the Dubai Marina, or take a
            scenic drive along the Sheikh Zayed Road to admire the city's
            towering skyscrapers.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>Here's a sample itinerary for a 5-night, 6-day trip to Dubai:</h3>
          <h4>Arrival in Dubai</h4>
          <ul>
            <li>
              Upon arrival, check into your hotel and spend the rest of the day
              exploring the city.
            </li>
            <li>
              Visit the iconic Burj Khalifa, the tallest building in the world.
            </li>
            <li>
              Walk through the Dubai Mall, one of the largest shopping centers
              in the world.
            </li>
            <li>Admire the choreographed fountain show outside the mall.</li>
          </ul>
          <h4>Dubai</h4>
          <ul>
            <li>
              Start the day with a visit to the Dubai Miracle Garden, the
              world's largest flower garden.
            </li>
            <li>
              Take a tour of the Dubai Museum, which showcases the history and
              culture of Dubai.
            </li>
            <li>
              In the afternoon, take a dhow cruise along the Dubai Creek and
              admire the city skyline.
            </li>
            <li>
              Spend the evening at the Dubai Fountain, where you can enjoy
              another stunning fountain show.
            </li>
          </ul>
          <h4>Dubai</h4>
          <ul>
            <li>
              Take a desert safari and experience the beauty of the Arabian
              Desert.
            </li>
            <li>
              Stop by a traditional Bedouin-style camp for a camel ride and a
              delicious barbecue dinner.
            </li>
            <li>
              Enjoy a mesmerizing belly dance performance under the stars.
            </li>
          </ul>
          <h4>Dubai</h4>
          <ul>
            <li>
              Visit the Palm Jumeirah, an artificial island shaped like a palm
              tree.
            </li>
            <li>
              Take a walk along the boardwalk and admire the beautiful homes and
              resorts.
            </li>
            <li>
              Spend the afternoon at the Atlantis, The Palm, a luxurious resort
              with a water park and marine habitats.
            </li>
          </ul>
          <h4>Dubai</h4>
          <ul>
            <li>
              Take a scenic drive along the Sheikh Zayed Road to admire the
              city's towering skyscrapers.
            </li>
            <li>
              Visit the Dubai Fountain, one of the world's largest choreographed
              fountain systems.
            </li>
            <li>
              Spend the evening at the Dubai Marina, a lively waterfront
              development with restaurants, cafes, and shopping.
            </li>
          </ul>
          <h4> Departure from Dubai</h4>
          <ul>
            <li>Transfer to the airport for your flight back home.</li>
          </ul>
          <p>
            This itinerary provides a well-rounded experience of Dubai and its
            many attractions, but there is still much more to see and do in this
            vibrant city. You can also consider visiting other attractions such
            as the Dubai Miracle Garden, the Dubai Museum, and the Dubai Creek,
            or take a helicopter tour to admire the city from above.
          </p>
        </>
      ),
      night6: (
        <>
          <h3>
            {" "}
            Here's a sample itinerary for a 6-night, 7-day trip to Dubai:
          </h3>
          <h4>Arrival in Dubai</h4>
          <ul>
            <li>
              Upon arrival, check into your hotel and spend the rest of the day
              exploring the city.
            </li>
            <li>
              Visit the iconic Burj Khalifa, the tallest building in the world.
            </li>
            <li>
              Walk through the Dubai Mall, one of the largest shopping centers
              in the world.
            </li>
            <li>Admire the choreographed fountain show outside the mall.</li>
          </ul>
          <h4> Dubai</h4>
          <ul>
            <li>
              Start the day with a visit to the Dubai Miracle Garden, the
              world's largest flower garden.
            </li>
            <li>
              Take a tour of the Dubai Museum, which showcases the history and
              culture of Dubai.
            </li>
            <li>
              In the afternoon, take a dhow cruise along the Dubai Creek and
              admire the city skyline.
            </li>
            <li>
              Spend the evening at the Dubai Fountain, where you can enjoy
              another stunning fountain show.
            </li>
          </ul>
          <h4>Dubai</h4>
          <ul>
            <li>
              Take a desert safari and experience the beauty of the Arabian
              Desert.
            </li>
            <li>
              Stop by a traditional Bedouin-style camp for a camel ride and a
              delicious barbecue dinner.
            </li>
            <li>
              Enjoy a mesmerizing belly dance performance under the stars.
            </li>
          </ul>
          <h4>Dubai</h4>
          <ul>
            <li>
              Visit the Palm Jumeirah, an artificial island shaped like a palm
              tree.
            </li>
            <li>
              Take a walk along the boardwalk and admire the beautiful homes and
              resorts.
            </li>
            <li>
              Spend the afternoon at the Atlantis, The Palm, a luxurious resort
              with a water park and marine habitats.
            </li>
          </ul>
          <h4>Dubai</h4>
          <ul>
            <li>
              Take a scenic drive along the Sheikh Zayed Road to admire the
              city's towering skyscrapers.
            </li>
            <li>
              Visit the Dubai Fountain, one of the world's largest choreographed
              fountain systems.
            </li>
            <li>
              Spend the evening at the Dubai Marina, a lively waterfront
              development with restaurants, cafes, and shopping.
            </li>
          </ul>
          <h4>Dubai</h4>
          <ul>
            <li>Visit the Ski Dubai, an indoor ski resort with real snow.</li>
            <li>
              Take a ride on the ski lift and enjoy the stunning views of the
              ski slopes.
            </li>
            <li>
              In the afternoon, visit the Mall of the Emirates, another large
              shopping center with an indoor ski slope.
            </li>
          </ul>
          <h4>Departure from Dubai</h4>
          <ul>
            <li>Transfer to the airport for your flight back home.</li>
          </ul>
          <p>
            This itinerary provides a comprehensive experience of Dubai and its
            many attractions, but there is still much more to see and do in this
            vibrant city. You can also consider visiting other attractions such
            as the Dubai Miracle Garden, the Dubai Museum, and the Dubai Creek,
            or take a helicopter tour to admire the city from above.
          </p>
        </>
      ),
    },
    {
      name: "Jordan",
      content: `A country located in the Middle East, Jordan is known for its ancient ruins, including the famous city of Petra, which is carved out of sandstone cliffs. It is also home to the Dead Sea, a salt lake known for its therapeutic properties.`,
      img: jordanImage,
      rev: true,
      night3: (
        <>
          <h3>
            Here's a sample itinerary for a 3-night, 4-day trip to Jordan:
          </h3>
          <h4>Arrival in Amman</h4>
          <ul>
            <li>Upon arrival in Amman, transfer to your hotel.</li>
            <li>
              In the evening, explore the city and its vibrant culture,
              including its bustling markets and street food.
            </li>
          </ul>
          <h4>Amman & Jerash</h4>
          <ul>
            <li>
              In the morning, visit the ancient Roman city of Jerash, one of the
              largest and most well-preserved Roman ruins outside of Italy.
            </li>
            <li>
              Explore the Hadrian's Arch, the Temple of Artemis, and the South
              Theatre, among other ancient ruins.
            </li>
            <li>
              Return to Amman in the evening and continue to explore the city.
            </li>
          </ul>
          <h4>Petra</h4>
          <ul>
            <li>
              Drive to Petra, one of the Seven Wonders of the World, and one of
              Jordan's most popular tourist attractions.
            </li>
            <li>
              Start your visit with the Treasury, one of Petra's most iconic
              monuments, and continue exploring the site on foot or by
              horseback.
            </li>
            <li>
              Visit the Monastery, the High Place of Sacrifice, and the Royal
              Tombs, among other highlights.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Departure from Jordan</h4>
          <ul>
            <li>Transfer to the airport for your flight back home.</li>
          </ul>
          <p>
            This itinerary provides a comprehensive experience of Jordan and its
            many attractions, but there is still much more to see and do in this
            fascinating country. You can also consider visiting other
            attractions such as the Wadi Rum desert, the Dead Sea, and the Red
            Sea, or take a dip in the hot springs of Ma'in.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>
            {" "}
            Here's a sample itinerary for a 5-night, 6-day trip to Jordan:
          </h3>
          <h4>Arrival in Amman</h4>
          <ul>
            <li>Upon arrival in Amman, transfer to your hotel.</li>
            <li>
              In the evening, explore the city and its vibrant culture,
              including its bustling markets and street food.
            </li>
          </ul>
          <h4>Amman & Jerash</h4>
          <ul>
            <li>
              In the morning, visit the ancient Roman city of Jerash, one of the
              largest and most well-preserved Roman ruins outside of Italy.
            </li>
            <li>
              Explore the Hadrian's Arch, the Temple of Artemis, and the South
              Theatre, among other ancient ruins.
            </li>
            <li>
              Return to Amman in the evening and continue to explore the city.
            </li>
          </ul>
          <h4>Petra</h4>
          <ul>
            <li>
              Drive to Petra, one of the Seven Wonders of the World, and one of
              Jordan's most popular tourist attractions.
            </li>
            <li>
              Start your visit with the Treasury, one of Petra's most iconic
              monuments, and continue exploring the site on foot or by
              horseback.
            </li>
            <li>
              Visit the Monastery, the High Place of Sacrifice, and the Royal
              Tombs, among other highlights.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Wadi Rum</h4>
          <ul>
            <li>
              Drive to Wadi Rum, a vast desert landscape known for its dramatic
              rock formations and Bedouin culture.
            </li>
            <li>
              Take a jeep tour of the desert and visit some of its iconic sites,
              including the Lawrence's Spring, the Seven Pillars of Wisdom, and
              the Anfishiyyeh Inscriptions.
            </li>
            <li>
              Spend the night in a traditional Bedouin camp, experiencing their
              way of life and hospitality.
            </li>
          </ul>
          <h4>Dead Sea</h4>
          <ul>
            <li>
              Drive to the Dead Sea, the lowest point on the earth's surface and
              known for its therapeutic mud and saltwater.
            </li>
            <li>Spend the day relaxing on the beach and taking a mud bath.</li>
            <li>In the evening, return to your hotel.</li>
          </ul>
          <h4>Departure from Jordan</h4>
          <ul>
            <li>Transfer to the airport for your flight back home.</li>
          </ul>
          <p>
            This itinerary provides a comprehensive experience of Jordan and its
            many attractions, but there is still much more to see and do in this
            fascinating country. You can also consider visiting other
            attractions such as the Red Sea, the Baptism Site, or the Madaba
            Mosaics.
          </p>
        </>
      ),
      night6: (
        <>
          <h3>
            {" "}
            Here's a sample itinerary for a 6-night, 7-day trip to Jordan:
          </h3>
          <h4>Arrival in Amman</h4>
          <ul>
            <li>Upon arrival in Amman, transfer to your hotel.</li>
            <li>
              In the evening, explore the city and its vibrant culture,
              including its bustling markets and street food.
            </li>
          </ul>
          <h4>Amman & Jerash</h4>
          <ul>
            <li>
              In the morning, visit the ancient Roman city of Jerash, one of the
              largest and most well-preserved Roman ruins outside of Italy.
            </li>
            <li>
              Explore the Hadrian's Arch, the Temple of Artemis, and the South
              Theatre, among other ancient ruins.
            </li>
            <li>
              Return to Amman in the evening and continue to explore the city.
            </li>
          </ul>
          <h4>Petra</h4>
          <ul>
            <li>
              Drive to Petra, one of the Seven Wonders of the World, and one of
              Jordan's most popular tourist attractions.
            </li>
            <li>
              Start your visit with the Treasury, one of Petra's most iconic
              monuments, and continue exploring the site on foot or by
              horseback.
            </li>
            <li>
              Visit the Monastery, the High Place of Sacrifice, and the Royal
              Tombs, among other highlights.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Wadi Rum</h4>
          <ul>
            <li>
              Drive to Wadi Rum, a vast desert landscape known for its dramatic
              rock formations and Bedouin culture.
            </li>
            <li>
              Take a jeep tour of the desert and visit some of its iconic sites,
              including the Lawrence's Spring, the Seven Pillars of Wisdom, and
              the Anfishiyyeh Inscriptions.
            </li>
            <li>
              Spend the night in a traditional Bedouin camp, experiencing their
              way of life and hospitality.
            </li>
          </ul>
          <h4>Dead Sea</h4>
          <ul>
            <li>
              Drive to the Dead Sea, the lowest point on the earth's surface and
              known for its therapeutic mud and saltwater.
            </li>
            <li>Spend the day relaxing on the beach and taking a mud bath.</li>
            <li>In the evening, return to your hotel.</li>
          </ul>
          <h4>Aqaba & the Red Sea</h4>
          <ul>
            <li>
              Drive to Aqaba, Jordan's only coastal city, and enjoy a day at the
              Red Sea.
            </li>
            <li>
              Go snorkeling or scuba diving to explore the underwater world of
              the Red Sea, with its abundant coral reefs and marine life.
            </li>
            <li>In the evening, return to your hotel.</li>
          </ul>
          <h4> Departure from Jordan</h4>
          <ul>
            <li>Transfer to the airport for your flight back home.</li>
          </ul>
          <p>
            This itinerary provides a comprehensive experience of Jordan and its
            many attractions, but there is still much more to see and do in this
            fascinating country. You can also consider visiting other
            attractions such as the Baptism Site, the Madaba Mosaics, or Mount
            Nebo.
          </p>
        </>
      ),
    },
    {
      name: "Turkey",
      content: `Located at the crossroads of Europe and Asia, Turkey is known for its rich history, stunning architecture, and delicious cuisine. It is home to famous landmarks such as the Hagia Sophia and the Blue Mosque in Istanbul, as well as beautiful beaches along the Mediterranean coast.`,
      img: turkeyImage,
      rev: false,
      night3: (
        <>
          <h3>
            {" "}
            Here's a sample itinerary for a 3-night, 4-day trip to Turkey:
          </h3>
          <h4>Arrival in Istanbul</h4>
          <ul>
            <li>Upon arrival in Istanbul, transfer to your hotel.</li>
            <li>
              In the evening, explore the city's historic district of
              Sultanahmet, including the Blue Mosque, Hagia Sophia, and Topkapi
              Palace.
            </li>
          </ul>
          <h4>Istanbul</h4>
          <ul>
            <li>
              In the morning, take a boat tour of the Bosphorus, the strait that
              separates Europe and Asia in Istanbul.
            </li>
            <li>
              Visit the Grand Bazaar, one of the largest and oldest covered
              markets in the world, to explore its maze of shops and stalls
              selling a variety of goods.
            </li>
            <li>
              In the afternoon, visit the famous Sultanahmet Hamam, a
              traditional Turkish bath, to relax and experience the local
              culture.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Cappadocia</h4>
          <ul>
            <li>
              Fly to Cappadocia, a unique landscape characterized by its fairy
              chimneys, cave dwellings, and hot air balloons.
            </li>
            <li>
              Take a guided tour of the Goreme Open Air Museum, a UNESCO World
              Heritage site, to see its rock-cut churches and monasteries.
            </li>
            <li>
              Visit the nearby Uchisar Castle, a natural fortress carved into a
              volcanic rock.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Departure from Turkey</h4>
          <ul>
            <li>Transfer to the airport for your flight back home.</li>
          </ul>
          <p>
            This itinerary provides a good introduction to Turkey's cultural and
            historical attractions, but there is still much more to see and do
            in this fascinating country. You can also consider visiting other
            destinations such as Ephesus, Pamukkale, or Antalya, depending on
            your interests and time available.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>
            {" "}
            Here's a sample itinerary for a 5-night, 6-day trip to Turkey:
          </h3>
          <h4>Arrival in Istanbul</h4>
          <ul>
            <li>Upon arrival in Istanbul, transfer to your hotel.</li>
            <li>
              In the evening, explore the city's historic district of
              Sultanahmet, including the Blue Mosque, Hagia Sophia, and Topkapi
              Palace.
            </li>
          </ul>
          <h4>Istanbul</h4>
          <ul>
            <li>
              In the morning, take a guided tour of the Topkapi Palace, the
              former residence of the Ottoman Sultans.
            </li>
            <li>
              In the afternoon, visit the famous Sultanahmet Hamam, a
              traditional Turkish bath, to relax and experience the local
              culture.
            </li>
            <li>
              Take a stroll along the historic Istiklal Avenue to explore its
              street life, shops, and restaurants.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Cappadocia</h4>
          <ul>
            <li>
              Fly to Cappadocia, a unique landscape characterized by its fairy
              chimneys, cave dwellings, and hot air balloons.
            </li>
            <li>
              Take a guided tour of the Goreme Open Air Museum, a UNESCO World
              Heritage site, to see its rock-cut churches and monasteries.
            </li>
            <li>
              Visit the nearby Uchisar Castle, a natural fortress carved into a
              volcanic rock.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Cappadocia</h4>
          <ul>
            <li>
              Take a hot air balloon ride to experience Cappadocia from above
              and enjoy its breathtaking views.
            </li>
            <li>
              Visit the nearby Devrent Valley, known for its unique rock
              formations, and the Pasabag, with its fairy chimneys and cave
              dwellings.
            </li>
            <li>
              In the afternoon, visit the town of Avanos, known for its pottery
              and ceramics, to see the local crafts and learn about the
              traditional techniques.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Pamukkale</h4>
          <ul>
            <li>
              Drive to Pamukkale, a UNESCO World Heritage site known for its
              thermal pools and cascading terraces of calcium carbonate.
            </li>
            <li>
              Visit the Hierapolis, an ancient Roman spa city, and see its
              well-preserved ruins, including the Roman theater and the
              Necropolis.
            </li>
            <li>
              In the afternoon, relax in the thermal pools of Pamukkale and soak
              in the natural beauty of the area.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4> Departure from Turkey</h4>
          <ul>
            <li>Transfer to the airport for your flight back home.</li>
          </ul>
          <p>
            This itinerary provides a comprehensive experience of Turkey's
            cultural and historical attractions, as well as its unique
            landscapes and natural wonders. You can also consider visiting other
            destinations such as Ephesus, Antalya, or the Black Sea region,
            depending on your interests and time available.
          </p>
        </>
      ),
      night6: (
        <>
          <h3>
            Here's a sample itinerary for a 6-night, 7-day trip to Turkey:
          </h3>
          <h4>Arrival in Istanbul</h4>
          <ul>
            <li>Upon arrival in Istanbul, transfer to your hotel.</li>
            <li>
              In the evening, explore the city's historic district of
              Sultanahmet, including the Blue Mosque, Hagia Sophia, and Topkapi
              Palace.
            </li>
          </ul>
          <h4>Istanbul</h4>
          <ul>
            <li>
              In the morning, take a guided tour of the Topkapi Palace, the
              former residence of the Ottoman Sultans.
            </li>
            <li>
              In the afternoon, visit the famous Sultanahmet Hamam, a
              traditional Turkish bath, to relax and experience the local
              culture.
            </li>
            <li>
              Take a stroll along the historic Istiklal Avenue to explore its
              street life, shops, and restaurants.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Cappadocia</h4>
          <ul>
            <li>
              Fly to Cappadocia, a unique landscape characterized by its fairy
              chimneys, cave dwellings, and hot air balloons.
            </li>
            <li>
              Take a guided tour of the Goreme Open Air Museum, a UNESCO World
              Heritage site, to see its rock-cut churches and monasteries.
            </li>
            <li>
              Visit the nearby Uchisar Castle, a natural fortress carved into a
              volcanic rock.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Cappadocia</h4>
          <ul>
            <li>
              Take a hot air balloon ride to experience Cappadocia from above
              and enjoy its breathtaking views.
            </li>
            <li>
              Visit the nearby Devrent Valley, known for its unique rock
              formations, and the Pasabag, with its fairy chimneys and cave
              dwellings.
            </li>
            <li>
              In the afternoon, visit the town of Avanos, known for its pottery
              and ceramics, to see the local crafts and learn about the
              traditional techniques.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Pamukkale</h4>
          <ul>
            <li>
              Drive to Pamukkale, a UNESCO World Heritage site known for its
              thermal pools and cascading terraces of calcium carbonate.
            </li>
            <li>
              Visit the Hierapolis, an ancient Roman spa city, and see its
              well-preserved ruins, including the Roman theater and the
              Necropolis.
            </li>
            <li>
              In the afternoon, relax in the thermal pools of Pamukkale and soak
              in the natural beauty of the area.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Ephesus</h4>
          <ul>
            <li>
              Drive to the ancient city of Ephesus, one of the largest and
              best-preserved Roman cities in the world.
            </li>
            <li>
              Take a guided tour of the city to see its main attractions, such
              as the Library of Celsus, the Temple of Hadrian, and the Great
              Theater.
            </li>
            <li>
              In the afternoon, visit the House of the Virgin Mary, a holy site
              for both Christians and Muslims, believed to be the last residence
              of the Virgin Mary.
            </li>
            <li>Return to your hotel in the evening.</li>
          </ul>
          <h4>Departure from Turkey</h4>
          <ul>
            <li>Transfer to the airport for your flight back home.</li>
          </ul>
          <p>
            This itinerary provides a comprehensive experience of Turkey's
            cultural and historical attractions, as well as its unique
            landscapes and natural wonders. You can also consider visiting other
            destinations such as Antalya, the Black Sea region, or Mount Nemrut,
            depending on your interests and time available.
          </p>
        </>
      ),
    },
  ],
  northamerica: [
    {
      name: "United States of America",
      content: `A country located in North America, the United States is known for its diversity, iconic landmarks, and vibrant culture. It is home to famous landmarks such as the Statue of Liberty, the Grand Canyon, the Golden Gate Bridge, and the Empire State Building, among many others. `,
      img: usaImage,
      rev: false,
      night3: (
        <>
          <h3>
            Here's a sample itinerary for a 7-night, 8-day trip to the USA
          </h3>
          <h4>Arrival in New York City</h4>
          <ul>
            <li>
              Upon arrival, check into your hotel and spend the rest of the day
              exploring the city.
            </li>
            <li>
              Visit iconic landmarks like the Empire State Building and the
              Statue of Liberty.
            </li>
            <li>
              Walk through Central Park and enjoy the sights and sounds of this
              urban oasis.
            </li>
            <li>
              Take a stroll through the bustling neighborhoods of SoHo and
              Greenwich Village.
            </li>
          </ul>
          <h4>New York City</h4>
          <ul>
            <li>
              Start the day with a visit to the Metropolitan Museum of Art.
            </li>
            <li>Stop by the American Museum of Natural History.</li>
            <li>Enjoy a Broadway show in the evening.</li>
          </ul>
          <h4> Washington, D.C.</h4>
          <ul>
            <li>
              Visit the Lincoln Memorial and reflect on the legacy of one of
              America's greatest presidents.
            </li>
            <li>
              See the Washington Monument and learn about the history of the
              capital city.
            </li>
            <li>
              Spend the afternoon at the National Gallery of Art and admire
              works of art by some of the world's greatest artists.
            </li>
          </ul>
          <h4> Washington, D.C. - Las Vegas</h4>
          <ul>
            <li>Take a flight to Las Vegas, Nevada.</li>
            <li>
              Check into your hotel and spend the rest of the day exploring the
              city.
            </li>
            <li>
              Walk the Las Vegas Strip and see the bright lights and towering
              casinos.
            </li>
            <li>Enjoy a show or a concert in the evening.</li>
          </ul>
          <h4> Las Vegas</h4>
          <ul>
            <li>
              Visit the Hoover Dam and learn about the engineering marvel that
              supplies water and power to the Southwest.
            </li>
            <li>
              Take a helicopter tour of the Grand Canyon and admire its stunning
              natural beauty.
            </li>
          </ul>
          <h4> Las Vegas - Los Angeles</h4>
          <ul>
            <li>Take a flight to Los Angeles, California.</li>
            <li>
              Check into your hotel and spend the rest of the day exploring the
              city.
            </li>
            <li>Visit Hollywood and see the famous Hollywood Walk of Fame.</li>
            <li>
              Take a tour of famous studios, such as Universal Studios or Warner
              Bros.
            </li>
          </ul>
          <h4> Departure from Los Angeles</h4>
          <ul>
            <li> Transfer to the airport for your flight back home.</li>
          </ul>
          <p>
            This itinerary can be customized to fit your specific interests and
            preferences. The USA is home to many amazing cities and attractions,
            and there is something for everyone to enjoy.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>
            Here's a sample itinerary for a 10-night, 11-day trip to the USA
          </h3>
          <h4> Arrival in New York City</h4>
          <ul>
            {" "}
            <li>
              Upon arrival, check into your hotel and spend the rest of the day
              exploring the city.
            </li>
            <li>
              Visit iconic landmarks like the Empire State Building and the
              Statue of Liberty.
            </li>
            <li>
              Walk through Central Park and enjoy the sights and sounds of this
              urban oasis.
            </li>
            <li>
              Take a stroll through the bustling neighborhoods of SoHo and
              Greenwich Village.
            </li>
            <li>
              Dine at one of the many world-class restaurants in the city.
            </li>
          </ul>
          <h4> New York City</h4>
          <ul>
            <li>
              {" "}
              Start the day with a visit to the Metropolitan Museum of Art.
            </li>
            <li> Enjoy a Broadway show in the evening.</li>
          </ul>
          <h4> New York City - Washington, D.C.</h4>
          <ul>
            <li> Take an early morning train to Washington, D.C.</li>
            <li>
              Check into your hotel and spend the rest of the day exploring the
              city.
            </li>
            <li>
              Visit the National Mall and see monuments and memorials dedicated
              to American leaders.
            </li>
            <li>
              {" "}
              Take a tour of the White House and see the famous Executive
              Residence.
            </li>
            <li>
              Visit the Smithsonian National Museum of American History and
              learn about the country's rich cultural heritage.
            </li>
          </ul>
          <h4>Washington, D.C.</h4>
          <ul>
            <li>
              Visit the Lincoln Memorial and reflect on the legacy of one of
              America's greatest presidents.
            </li>
            <li>
              See the Washington Monument and learn about the history of the
              capital city.
            </li>
            <li>
              Spend the afternoon at the National Gallery of Art and admire
              works of art by some of the world's greatest artists.
            </li>
          </ul>
          <h4> Washington, D.C. - Las Vegas</h4>
          <ul>
            <li>Take a flight to Las Vegas, Nevada.</li>
            <li>
              Check into your hotel and spend the rest of the day exploring the
              city.
            </li>
            <li>
              Walk the Las Vegas Strip and see the bright lights and towering
              casinos.
            </li>
            <li>Enjoy a show or a concert in the evening.</li>
          </ul>
          <h4> Las Vegas</h4>
          <ul>
            <li>
              Visit the Hoover Dam and learn about the engineering marvel that
              supplies water and power to the Southwest.
            </li>
            <li>
              Take a helicopter tour of the Grand Canyon and admire its stunning
              natural beauty.
            </li>
          </ul>
          <h4> Las Vegas - Los Angeles</h4>
          <ul>
            <li> Take a flight to Los Angeles, California.</li>
            <li>
              Check into your hotel and spend the rest of the day exploring the
              city.
            </li>
            <li>Visit Hollywood and see the famous Hollywood Walk of Fame.</li>
            <li>
              Take a tour of famous studios, such as Universal Studios or Warner
              Bros.
            </li>
          </ul>
          <h4> Los Angeles</h4>
          <ul>
            <li>Visit the Getty Center and admire the art and architecture.</li>
          </ul>
          <h4>Los Angeles</h4>
          <ul>
            <li>Visit the Getty Center and admire the art and architecture.</li>
            <li>
              Spend the day at Disneyland or California Adventure and enjoy the
              rides and attractions.
            </li>
          </ul>
          <h4> Los Angeles - San Francisco </h4>
          <ul>
            <li>Take a flight to San Francisco.</li>
            <li>
              Check into your hotel and spend the rest of the day exploring the
              city.
            </li>
            <li>
              Walk across the Golden Gate Bridge and admire the views of the
              bay.
            </li>
            <li>
              Visit Fisherman's Wharf and enjoy the seafood and street
              performers.
            </li>
          </ul>
          <h4> San Francisco</h4>
          <ul>
            <li>
              Visit Alcatraz Island and learn about the history of the famous
              prison.
            </li>
            <li>
              Take a tour of the famous Haight-Ashbury neighborhood and learn
              about the counterculture movement of the 1960s.{" "}
            </li>
          </ul>
          <h4> Departure from San Francisco </h4>
          <ul>
            <li>Transfer to the airport for your flight back home.</li>
            <li>
              This itinerary can be customized to fit your specific interests
              and preferences. The USA is home to many amazing cities and
              attractions, and there is something for everyone to enjoy.
            </li>
          </ul>
        </>
      ),
    },
  ],
};
const service = CountryData.africa[0].name;
console.log(service);
export default CountryData;

/**
 * United States of America: A country located in North America, the United States is known for its diversity, iconic landmarks, and vibrant culture. It is home to famous landmarks such as the Statue of Liberty, the Grand Canyon, the Golden Gate Bridge, and the Empire State Building, among many others.
 */
