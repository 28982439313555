import React from "react";
import "./contactus.css";
import NewForm from "../form/NewForm";
const Contactus = () => {
  return (
    <>
      <NewForm />
    </>
  );
};

export default Contactus;
