import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./newCarousel.css";

const items = [
  <div className="item" data-value="1">
    1
  </div>,
  <div className="item" data-value="2">
    2
  </div>,
  <div className="item" data-value="3">
    3
  </div>,
  <div className="item" data-value="4">
    4
  </div>,
  <div className="item" data-value="5">
    5
  </div>,
];

function Carousel({ data = items, setnum = 3, setmode }) {
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: setnum ? setnum : 3 },
  };
  return (
    <div className="commonCarousel">
      <AliceCarousel
        animationType={setmode ? setmode : "slide"}
        animationDuration={800}
        mouseTracking
        infinite
        autoPlay={1000}
        items={data}
        paddingLeft={10}
        paddingRight={10}
        responsive={responsive}
        controlsStrategy="alternate"
      />
    </div>
  );
}

export default Carousel;
