import React from "react";
import "./event.css";
import Ecard from "./Ecard";
import { lowersecsliderdata } from "./eventCardData";
import NewCarousel from "../newCarousel/NewCarousel";

let SlideShow = lowersecsliderdata.map((e) => {
  return (
    <div className="slideShow-img">
      <img src={e.img} alt="" />
    </div>
  );
});

const Event = () => {
  return (
    <>
      <div className="event">
        <h1>Stay tuned for updates on </h1>
        <br />
        <h1>upcoming events and</h1>
        <br />
        <h1>their respective details.</h1>
        <p>
          Here are some exciting events happening around the world that you
          won't want to miss! Whether you're into music, food, culture, or
          adventure, there's something for everyone.
        </p>
        <button>Contact now!</button>
      </div>
      <div className="upcoming">
        <div className="slider-nav">
          <h1>Upcoming Events</h1>
        </div>
        {/* <Carousel Card={Ecard} setSlideNum={3} data={data} /> */}
        <NewCarousel data={Ecard} />
      </div>
      <div className="slideShow">
        <h1>Be sure to check out these events and more during your travels!</h1>
        <NewCarousel data={SlideShow} setnum={1} setmode="fadeout" />
      </div>
    </>
  );
};

export default Event;
