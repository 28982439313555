import React from "react";
import "../blog.css";

const Blog = ({ data }) => {
  return (
    <>
      <div
        className="mountain-blog mountain-blog-img"
        style={{
          backgroundImage: `url(${data.bannerImage})`,
        }}
      >
        <div className="film"></div>
        <h1 className="mountain-heading">{data ? data.title : "default"}</h1>
        <p className="mountain-content">
          {data ? data.para : "default"} <br />
        </p>
        <h3 className="blogh3">{data ? data.line : ""}</h3>
      </div>
    </>
  );
};

export default Blog;
