import React from "react";
// import BlogImage from "../../../Images/Country-Image/Finland.jpg"
import BlogImage from "./BlogImages/MainBlog.jpeg";
function BlogCard(props) {
  return (
    <div className="blog-card">
      <img src={props.img} alt="" />
      <h5 style={{ marginTop: "3%" }}>{props.title}</h5>
    </div>
  );
}

export default BlogCard;
