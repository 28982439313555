import React from "react";
import "./destination.css";
import Carousel from "../simpleSlider/newSlider";
// import dpvid from "../../Images/dpvid.webm";
import coverbanner from "../../Images/CoverBanner.png";
// import img from "../../Images/africa/af1.png";
// import img2 from "../../Images/africa/af2.jpg";
// import img3 from "../../Images/africa/af3.jpg";
// import img4 from "../../Images/africa/af4.jpg";
// import aft1 from "../../Images/africa/png1.png";
// import aft2 from "../../Images/africa/png2.png";
// import aft3 from "../../Images/africa/png3.png";
import destinationdata from "./destinationdata";
import NewCarousel from "../newCarousel/NewCarousel";
import { Link } from "react-router-dom";

const newDestination = () => {
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  let arr = ["africa", "asia", "europe", "middleeast", "northamerica"];
  return (
    <>
      <div className="destination-vid">
        <div className="destination-vid-left">
          {/* <video src={dpvid} autoPlay loop muted></video> */}
          <img src={coverbanner} alt="" />
        </div>
        {/* <div className="destination-vid-right">
          <h1>Some weird text</h1>
          <p>life isn't hehe anymore</p>
        </div> */}
      </div>
      <div className="des-heading-text">
        <h1>DESTINATION</h1>
        <p>
          Wanderspots is dedicated to helping you find the best destinations for
          your next adventure!
        </p>
        <p>
          From stunning beaches to bustling cities, we provide insider tips to
          help you plan the perfect trip.
        </p>
        <p>Let's explore the world together!</p>
      </div>
      {destinationdata.map((e, i) => {
        let destinationCard = e.countries.map((e) => {
          return (
            <>
              <div className="destination-card">
                <div className="destination-card-top">
                  <img src={e.img} alt={e.name} />
                </div>
                <div className="destination-card-mid">
                  <p className="destination-card-mid-top">{e.name}</p>
                </div>
              </div>
            </>
          );
        });
        return (
          <>
            <div className="destination-newmid">
              <img src={e.mapimg} alt="" />
              <div className="destination-newmid-text">
                <div className="destination-newmid-text-inner">
                  <h1>{e.imghead}</h1>
                  <h4>{e.imgsubhead}</h4>
                </div>
                <div className="int-links-1">
                  <Link
                    to="/travelogue"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      scrolltotop();
                    }}
                  >
                    <button className="int-btn-1">Article</button>
                  </Link>
                  <Link
                    to="/tripplanner"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      scrolltotop();
                    }}
                  >
                    <button className="int-btn-1">Trip Planner</button>
                  </Link>
                  <Link
                    to={`/tourguide/${arr[i]}`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      scrolltotop();
                    }}
                  >
                    <button className="int-btn-1">Trip Guide</button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="destination-bottom">
              <h1>COUNTRIES</h1>
              <div className="destination-cards">
                <NewCarousel data={destinationCard} />
                {/* <Carousel
                  setAutoPlay={true}
                  Card={destinationCard}
                  setEffect={"coverflow"}
                  data={e.countries}
                /> */}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default newDestination;

{
  /* <div className="destination-top">
        <img src={img} alt="" />
        <div className="destination-top-text">
          <h1>africa's hehe</h1>
          <p>Lorem ipsum dolor sit amet consectetur.</p>
        </div>
      </div> */
}
{
  /* <div className="destination-mid">
        <div className="destination-mid-container">
          <div className="destination-infographic">
            <img src={img2} alt="" />
            <div className="destination-mid-img">
              <img src={aft1} alt="" />
            </div>
          </div>
          <div className="destination-infographic">
            <img src={img3} alt="" />
            <div className="destination-mid-img">
              <img src={aft2} alt="" />
            </div>
          </div>
          <div className="destination-infographic">
            <img src={img4} alt="" />
            <div className="destination-mid-img">
              <img src={aft3} alt="" />
            </div>
          </div>
        </div>
      </div> */
}
