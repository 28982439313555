import React from "react";
import Blog from "../blog-components/Blog";
import Info from "../blog-components/Info";
import "./sunshyamresortblog.css";
import { data } from "../data";
import { Link } from "react-router-dom";
import PasiBanner from "./Sunshyam Image/Pasikudah_Image/PasiBanner.png";
import OhleveliBanner from "./Sunshyam Image/Olheveli_Image/OhleveliBanner.png";
import iruBanner from "./Sunshyam Image/IruVeli_Image/iruBanner.png";
import viluBanner from "./Sunshyam Image/ViluReef_Image/viluBanner.png";
import iruFushiBanner from "./Sunshyam Image/IruFushi_Image/iruFushiBanner.png";
const Sunshyamresortblog = () => {
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  return (
    <>
      <div className="mountain">
        <Blog data={data["sunsiyamresort"][0]} />
        {data["sunsiyamresort"][1].map((obj, i) => {
          return <Info data={obj} key={i} />;
        })}
        <div className="blog-summary">
          <p>{data ? data["sunsiyamresort"][0]?.summary : ""}</p>
        </div>
        <div className="sunresortcards">
          <h1>Explore More Of Our Resorts</h1>
          <div className="sunresortcardsrow1">
            <div className="sunresortcard">
              <div className="sunresortcardimg">
                <img src={viluBanner} />
                <Link
                  to="/travelogue/exploring-the-unmatched-beauty-of-sun-siyam-vilu-reef"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    scrolltotop();
                  }}
                >
                  <button>Read More</button>
                </Link>
              </div>
              <h2> Sun Siyam Vilu Reef</h2>
            </div>
            <div className="sunresortcard">
              <div className="sunresortcardimg">
                <img src={iruFushiBanner} />
                <Link
                  to="/travelogue/sun-siyam-iru-fushi-a-serene-island-getaway-in-the-maldives"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    scrolltotop();
                  }}
                >
                  <button>Read More</button>
                </Link>
              </div>
              <h2>SUN SIYAM IRU FUSHI</h2>
            </div>
          </div>
          <div className="sunresortcardsrow2">
            <div className="sunresortcard">
              <div className="sunresortcardimg">
                <img src={iruBanner} />
                <Link
                  to="/travelogue/escape-to-paradise-a-review-of-the-sun-siyam-iru-veli-resort"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    scrolltotop();
                  }}
                >
                  <button>Read More</button>
                </Link>
              </div>
              <h2>SUN SIYAM IRU VELI</h2>
            </div>
            <div className="sunresortcard">
              <div className="sunresortcardimg">
                <img src={OhleveliBanner} />
                <Link
                  to="/travelogue/unravelling-the-ultimate-maldivian-getaway-at-sun-siyam-olhuveli"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    scrolltotop();
                  }}
                >
                  <button>Read More</button>
                </Link>
              </div>
              <h2>SUN SIYAM OLHUVELI</h2>
            </div>
            <div className="sunresortcard">
              <div className="sunresortcardimg">
                <img src={PasiBanner} />
                <Link
                  to="/travelogue/exploring-the-diverse-marine-life-at-sun-siyam-pasikudah-reef"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    scrolltotop();
                  }}
                >
                  <button>Read More</button>
                </Link>
              </div>
              <h2>SUN SIYAM PASIKIJDAH</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sunshyamresortblog;
