import React from "react";
import { Link } from "react-router-dom";

const Interestinfo = ({ data, link }) => {
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  return (
    <div className="int-info" style={{ backgroundImage: `url(${data.Main})` }}>
      <div className="int-info-film">
        <h1>{data.Name}</h1>
        <p>{data.para}</p>
        <div className="int-links">
          <Link
            to={`/travelogue`}
            onClick={() => {
              window.scrollTo(0, 0);
              scrolltotop();
            }}
          >
            <button className="int-btn">Article</button>
          </Link>
          <Link
            to={`/tripplanner`}
            onClick={() => {
              window.scrollTo(0, 0);
              scrolltotop();
            }}
          >
            <button className="int-btn">Trip Planner</button>
          </Link>
          <a
            href={`/#/${link}`}
            onClick={() => {
              window.scrollTo(0, 0);
              scrolltotop();
            }}
          >
            <button className="int-btn">Trip Guide</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Interestinfo;
