import React from "react";
import { Link } from "react-router-dom";
import { data } from "../blogs/data";
export default function TourCard(props) {
  let keys = Object.keys(data);
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  return (
    <div className="tour-card">
      <div className="tour-img">
        <Link to={props.URL} onClick={scrolltotop}>
          <img src={props.Image} alt="" />
        </Link>
      </div>
      <div className="tour-title">
        <h3> {props.Title} </h3>
        <p>{props.Description}</p>
      </div>
    </div>
  );
}
