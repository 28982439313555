import React, { useState, useRef, useEffect } from "react";
import logo from "../../Images/popupimg.png";
import "./popup.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import googleimg from "../../Images/popup/search.png";
import appleimg from "../../Images/popup/apple.png";
import fbimg from "../../Images/popup/facebook.png";
import { Link } from "react-router-dom";

const Popup = () => {
  let submit = () => {
    axios
      .post("https://getform.io/f/a681739a-c6e7-47a8-8304-500339c303a4", data, {
        headers: { Accept: "application/json" },
      })
      .then((response) => {
        toast.success("We will get back to you soon!", toastOptions);
        setTimeout(() => {
          setVisibility(false);
        }, 2000);
      })
      .catch((error) => {
        toast.error("something went wrong", toastOptions);
      });
  };

  const toastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: false,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: false,
    pauseOnHover: false,
    theme: "colored",
  };

  let handleValidation = (e) => {
    e.preventDefault();
    if (data.email === "") {
      toast.error("please enter your email", toastOptions);
      return;
    }
    if (!data.email.includes("@")) {
      toast.warn("please enter a valid email", toastOptions);
      return;
    }
    submit();
  };

  let [data, setData] = useState({
    email: "",
  });

  let [visibility, setVisibility] = useState(false);

  let popupRef = useRef();

  let handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    document.body.style.overflow = visibility ? "hidden" : "visible";
    popupRef.current.style.display = visibility ? "flex" : "none";
  }, [visibility]);

  useEffect(() => {
    setTimeout(() => {
      setVisibility(true);
    }, 100);
  }, []);

  const handleParentClick = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setVisibility(false);
    }
  };

  return (
    <div className="popupcontainer" ref={popupRef} onClick={handleParentClick}>
      <div className="popup">
        <i
          class="ri-close-circle-fill popupclose"
          onClick={(e) => {
            setVisibility(false);
          }}
        ></i>
        <div className="popuplogo">
          <img src={logo} alt="" srcset="" />
        </div>
        <div className="popuptext">
          <h1>Embark on a journey beyond boundaries...</h1>
          <p>
            Unleash Your Adventurous Spirit and Discover the World with Us!
            Become a member and experience travel like never before
          </p>
        </div>
        <form onSubmit={submit}>
          <input
            type="email"
            name="email"
            onChange={handleChange}
            placeholder="Your email address"
          />
          <button type="submit" onClick={handleValidation}>
            Continue
          </button>
        </form>
        <div className="popuplinks">
          <button className="popupbtn">
            <i class="ri-google-fill"></i> <>Continue with Google</>
          </button>
          <button className="popupbtn">
            <i class="ri-apple-fill"></i>
            <>Continue with Apple ID</>
          </button>
          <button className="popupbtn">
            <i class="ri-facebook-fill"></i> <>Continue with Facebook</>
          </button>
          <p>
            By continuing, you agree to our <Link>Terms & Conditions</Link> and
            &nbsp;<Link>Privacy Policy</Link>, and to subscribe to marketing
            communications for offers, alerts and services.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Popup;
