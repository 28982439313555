import React from "react";
import "./Categories.css";
import BlogImage from "./BlogImages/MainBlog.jpeg";
import { Link } from "react-router-dom";

function CategoryCard(props) {
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  return (
    <>
      <div
        className="cat-card"
        onClick={() => {
          window.scrollTo(0, 0);
          scrolltotop();
        }}
      >
        <Link
          to={`/travelogue/${props.category}`}
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
        >
          {" "}
          <img src={props.image} alt="" />{" "}
        </Link>
        <div className="cat-text">
          <h5 style={{ textAlign: "center" }}>{props.title}</h5>
          <p>{props.desc}</p>
          {/* <a href="" style={{display:'none'}}> {props.category&& props.category} </a> */}
        </div>
      </div>
    </>
  );
}

export default CategoryCard;
