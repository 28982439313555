import React from "react";
import { Link } from "react-router-dom";

export default function TopDestination(props) {
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  return (
    <div className="top-destination">
      <Link to={props.URL} onClick={scrolltotop}>
        <img src={props.Image} alt="" />
      </Link>
      <div className="text-option">
        <p>{props.price}</p>
        <Link to={props.URL} onClick={scrolltotop}>
          {props.countries}
        </Link>
      </div>
    </div>
  );
}
