import React, { useState } from "react";

import "./faq.css";
import { Link } from "react-router-dom";
import { Data, focusTips } from "./Data";
const FAQ = ({ faq, index, toggleFAQ }) => {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <div className="faq-answer">{faq.answer}</div>
    </div>
  );
};

function Faq() {
  const [faqs, setFaqs] = useState(Data);
  const [tip, setTip] = useState(focusTips);
  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
    setTip(
      tip.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  return (
    <div className="faq-container">
      <div className="faq-heading"></div>
      <div className="faq-section">
        <h2>Frequently Asked Questions </h2>
        <div className="faqs">
          {faqs.map((faq, index) => (
            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
          ))}
          <h2 className="focus-heading"> Focusing on travel tips and safety</h2>
          {tip.map((faq, index) => (
            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faq;
