// Dubai Blog Image
import DubaiBanner from "./BlogImage/Dubai_Image/Cover.jpeg";
import BurjKhalifa1 from "./BlogImage/Dubai_Image/BurjKhalifa1.jpg";
import BurjAiarb2 from "./BlogImage/Dubai_Image/BurjArab2.jpg";
import PalmImage3 from "./BlogImage/Dubai_Image/Palm3.jpg";
import MesuemImage4 from "./BlogImage/Dubai_Image/Museum4.jpg";
import Aquarium5 from "./BlogImage/Dubai_Image/Aquarium5.jpg";
import Chamber6 from "./BlogImage/Dubai_Image/Chamber6.jpg";
import SkyImage7 from "./BlogImage/Dubai_Image/SkiDubai7.jpeg";
import Marina8 from "./BlogImage/Dubai_Image/Marina8.jpg";
import Frame9 from "./BlogImage/Dubai_Image/Frame9.jpg";
import Dolphinarium10 from "./BlogImage/Dubai_Image/Dolphinarium10.jpg";
// Maldvies  Blog Image
import MaldviesBanner from "./BlogImage/Maldives_Image/Cover.jpeg";
import MaleCity1 from "./BlogImage/Maldives_Image/MaleCity1.jpg";
import Artificial2 from "./BlogImage/Maldives_Image/Artificial2.jpg";
import National3 from "./BlogImage/Maldives_Image/National3.jpg";
import Addu4 from "./BlogImage/Maldives_Image/Addu4.jpg";
import Maafushi5 from "./BlogImage/Maldives_Image/Maafushi5.jpg";
import Grand6 from "./BlogImage/Maldives_Image/Grand6.jpg";
import Fulhadhoo7 from "./BlogImage/Maldives_Image/Fulhadhoo7.jpg";
import Muraka8 from "./BlogImage/Maldives_Image/Muraka8.webp";
// Malaysia  Blog Image
import MalaysiaBanner from "./BlogImage/Malaysia_Image/Cover.jpeg";
import Petrosan1 from "./BlogImage/Malaysia_Image/Petronas1.jpg";
import Betu2 from "./BlogImage/Malaysia_Image/Batu2.jpg";
import Mount3 from "./BlogImage/Malaysia_Image/Mount3.jpg";
import Island from "./BlogImage/Malaysia_Image/Island4.jpg";
import Sipadan5 from "./BlogImage/Malaysia_Image/Sipadan5.jpg";
// Singapore Blog Image
import SingaporeBanner from "./BlogImage/Singapore_Image/cover.jpeg";
import Marina1 from "./BlogImage/Singapore_Image/Marina1.jpg";
import Botanic3 from "./BlogImage/Singapore_Image/Botanic3.jpg";
import Singapore4 from "./BlogImage/Singapore_Image/Singapore4.jpg";
import Orchard5 from "./BlogImage/Singapore_Image/Orchard5.jpg";
import Garden2 from "./BlogImage/Singapore_Image/Garden2.jpg";
//Thailand Blog Image
import ThailandBanner from "./BlogImage/Thailand_Image/Cover.jpeg";
import Railay1 from "./BlogImage/Thailand_Image/Railay1.jpg";
import Harn2 from "./BlogImage/Thailand_Image/Harn2.jpg";
import HadRin3 from "./BlogImage/Thailand_Image/HadRin3.jpg";
import CaveBeach4 from "./BlogImage/Thailand_Image/CaveBeach4.jpg";
import MayaBay5 from "./BlogImage/Thailand_Image/Mayabay5.jpg";
//Switerzerland Blog Image
import SwiterzerlandBanner from "./BlogImage/Switerzerland_Image/cover.jpeg";
import Lucerne1 from "./BlogImage/Switerzerland_Image/Lucerne1.jpg";
import Zurich2 from "./BlogImage/Switerzerland_Image/Zurich2.jpg";
import Interlaken3 from "./BlogImage/Switerzerland_Image/Interlaken3.jpg";
import Jungfraujoch4 from "./BlogImage/Switerzerland_Image/Jungfraujoch4.jpg";
import Lake5 from "./BlogImage/Switerzerland_Image/Lake5.jpg";
import Matterhorn6 from "./BlogImage/Switerzerland_Image/Matterhorn6.jpg";
import Zermatt7 from "./BlogImage/Switerzerland_Image/Zermatt7.jpg";
import Ticino8 from "./BlogImage/Switerzerland_Image/Ticino8.jpg";
import Laussane9 from "./BlogImage/Switerzerland_Image/Laussane9.jpg";
//France Blog Image
import FranceBanner from "./BlogImage/France_Image/cover.jpeg";
import Border1 from "./BlogImage/France_Image/Bordeaux1.jpg";
import Paris2 from "./BlogImage/France_Image/Paris2.jpg";
import Lyon3 from "./BlogImage/France_Image/Lyon3.jpg";
import Marselles4 from "./BlogImage/France_Image/Marseilles4.jpg";
import Nice5 from "./BlogImage/France_Image/Nice5.jpg";
// Spain Blog Image
import SpainBanner from "./BlogImage/Spain_Image/Cover.png";
import Madrid1 from "./BlogImage/Spain_Image/Madrid1.jpg";
import Barcelona2 from "./BlogImage/Spain_Image/Barcelona2.jpg";
import Sabistion3 from "./BlogImage/Spain_Image/Sabistion3.jpg";
import Valencia4 from "./BlogImage/Spain_Image/Valencia4.jpg";
import Granda5 from "./BlogImage/Spain_Image/Granda5.jpg";
import Lbiza6 from "./BlogImage/Spain_Image/Lbiza6.jpg";
import Selvile7 from "./BlogImage/Spain_Image/Selvile7.jpg";
import Codabo8 from "./BlogImage/Spain_Image/Cordoba7.jpg";

// Discover Blog Image
import DiscoverBanner from "./BlogImage/Discover_Image/Cover.jpeg";
import Islandbeach1 from "./BlogImage/Discover_Image/IslandBeach1.jpg";
import Hulhumale2 from "./BlogImage/Discover_Image/Hulhumale2.jpg";
import Fulhadhoo3 from "./BlogImage/Discover_Image/Fulhadhoo3.jpg";
import Maafushi4 from "./BlogImage/Discover_Image/Maafushi4.jpg";
import Reethi5 from "./BlogImage/Discover_Image/Reethi5.jpg";
//Luxury Blog Image
import Luxury1Banner from "./BlogImage/Luxury_Image1/cover.jpeg";
import Private1 from "./BlogImage/Luxury_Image1/Private1.jpg";
import Hotel2 from "./BlogImage/Luxury_Image1/Hotel2.jpg";
import Villas3 from "./BlogImage/Luxury_Image1/Villas3.jpg";
import Cruise4 from "./BlogImage/Luxury_Image1/Cruise4.jpg";
import Glamping5 from "./BlogImage/Luxury_Image1/Glamping5.jpg";
//Solo Travel 1 Blog  Image
import SoloBanner1 from "./BlogImage/SoloTravel1_Image/Cover.jpeg";
import Reasearch1 from "./BlogImage/SoloTravel1_Image/Reasearch1.jpg";
import Book2 from "./BlogImage/SoloTravel1_Image/Book2.jpg";
import Keep3 from "./BlogImage/SoloTravel1_Image/Keep3.jpg";
import Stay4 from "./BlogImage/SoloTravel1_Image/Stay4.jpg";
import Trust5 from "./BlogImage/SoloTravel1_Image/Trust5.jpg";
import Make6 from "./BlogImage/SoloTravel1_Image/Make6.jpg";
import Learn7 from "./BlogImage/SoloTravel1_Image/Learn7.jpg";
import Be8 from "./BlogImage/SoloTravel1_Image/Be8.jpg";
// Beach 1 Blog  Image
import Beach1Banner from "./BlogImage/Beach_Image1/cover.png";
import White1 from "./BlogImage/Beach_Image1/White1.png";
import Anse2 from "./BlogImage/Beach_Image1/Anse2.png";
import Navagio3 from "./BlogImage/Beach_Image1/Navagio3.png";
import Pink4 from "./BlogImage/Beach_Image1/Pink4.png";
import Matira5 from "./BlogImage/Beach_Image1/Matira5.png";
import Tulum6 from "./BlogImage/Beach_Image1/Tulum6.png";
import Lanikai7 from "./BlogImage/Beach_Image1/Lanikai7.png";
//Solo Travel 2 Blog Image
import Solo2Banner from "./BlogImage/Solo_Travel2_Image/Cover.jpeg";
import Japan1 from "./BlogImage/Solo_Travel2_Image/Japan1.jpg";
import Iceland2 from "./BlogImage/Solo_Travel2_Image/Iceland2.jpg";
import New3 from "./BlogImage/Solo_Travel2_Image/New3.jpg";
import Coasta4 from "./BlogImage/Solo_Travel2_Image/Coasta4.jpg";
import Portugal5 from "./BlogImage/Solo_Travel2_Image/Portugal5.jpg";
import Bali6 from "./BlogImage/Solo_Travel2_Image/Bali6.jpg";
import Canada7 from "./BlogImage/Solo_Travel2_Image/Canada7.jpg";
import Scotland8 from "./BlogImage/Solo_Travel2_Image/Scotland8.jpg";
// Faimily blog Image
import FaimilyBanner from "./BlogImage/Faimily_Image/cover.jpeg";
import Orlando1 from "./BlogImage/Faimily_Image/Orlando1.jpg";
import San2 from "./BlogImage/Faimily_Image/San2.jpg";
import Washington3 from "./BlogImage/Faimily_Image/San2.jpg";
import Yellostone4 from "./BlogImage/Faimily_Image/Yellowstone4.jpg";
import Niagara5 from "./BlogImage/Faimily_Image/Niagara5.jpg";
import London6 from "./BlogImage/Faimily_Image/London6.jpg";
import Tokyo7 from "./BlogImage/Faimily_Image/Tokyo7.jpg";
import Maui8 from "./BlogImage/Faimily_Image/Maui8.jpg";
import Coasta9 from "./BlogImage/Faimily_Image/Costa9.jpg";
import Cap10 from "./BlogImage/Faimily_Image/Cap10.jpg";
// Beach 2 Blog Image
import Beach2Banner from "./BlogImage/Beach_Image2/cover.jpeg";
import Kuta1 from "./BlogImage/Beach_Image2/Kuta1.jpg";
import White2 from "./BlogImage/Beach_Image2/White2.jpg";
import Pink3 from "./BlogImage/Beach_Image2/Pink3.jpg";
import Nusa4 from "./BlogImage/Beach_Image2/Nusa4.jpg";
import Bath5 from "./BlogImage/Beach_Image2/Bath5.jpg";
import Navach6 from "./BlogImage/Beach_Image2/Navach6.jpg";
import Cable7 from "./BlogImage/Beach_Image2/Cable7.jpg";
import Anse8 from "./BlogImage/Beach_Image2/Anse8.jpg";
// Solo Travel 3  Blog Iamge
import Solo3Travel from "./BlogImage/Solo_Travel3_Image/Cover.jpeg";
import Plan1 from "./BlogImage/Solo_Travel3_Image/Plan1.jpg";
import Pack2 from "./BlogImage/Solo_Travel3_Image/Pack2.jpg";
import Understand3 from "./BlogImage/Solo_Travel3_Image/Understand3.jpg";
import Find4 from "./BlogImage/Solo_Travel3_Image/Find4.jpg";
import Tell5 from "./BlogImage/Solo_Travel3_Image/Tell5.jpg";

import Experiment6 from "./BlogImage/Solo_Travel3_Image/Experiment6.jpg";
import HaveFun9 from "./BlogImage/Solo_Travel3_Image/HaveFun9.jpg";
import Edit7 from "./BlogImage/Solo_Travel3_Image/Edit7.jpg";
import Respect8 from "./BlogImage/Solo_Travel3_Image/Respect8.jpg";

// Luxury 2 Image  Blog Image
import Luxurybanner from "./BlogImage/Luxury_Image2/cover.jpeg";
import Paris1 from "./BlogImage/Luxury_Image2/Paris1.jpg";
import Tokyo2 from "./BlogImage/Luxury_Image2/Tokyo2.jpg";
import Maldives3 from "./BlogImage/Luxury_Image2/Maldives3.jpg";
import Network4 from "./BlogImage/Luxury_Image2/Nework4.jpg";
import Dubai5 from "./BlogImage/Luxury_Image2/Dubai5.jpg";
// Hidden game Blog Image
import HiddenBanner from "./BlogImage/Hidden_Image/cover.jpeg";
import Matera1 from "./BlogImage/Hidden_Image/Matera1.jpg";
import Kotor2 from "./BlogImage/Hidden_Image/Kotor2.jpg";
import Rila3 from "./BlogImage/Hidden_Image/Rila3.jpg";
import Austria4 from "./BlogImage/Hidden_Image/Austria4.jpg";
import Sintra5 from "./BlogImage/Hidden_Image/Sintra5.jpg";
import Plitvice6 from "./BlogImage/Hidden_Image/Plitvice6.jpg";
import Guimaraes7 from "./BlogImage/Hidden_Image/Guimaraes7.jpg";
import Sloveni8 from "./BlogImage/Hidden_Image/Slovenia8.jpg";
import Cinque9 from "./BlogImage/Hidden_Image/Cinque9.jpg";
import Ribe10 from "./BlogImage/Hidden_Image/Ribe10.jpg";
// Most Visited Place in 2023 Blog Image
import Banner2023 from "./BlogImage/Place2023_Image/banner2023.jpg";
import Everest1 from "./BlogImage/Place2023_Image/Everest1.jpg";
import Amazon2 from "./BlogImage/Place2023_Image/Amazon2.jpg";
import Cycle3 from "./BlogImage/Place2023_Image/Cycle3.jpg";
import Peace4 from "./BlogImage/Place2023_Image/Peace4.jpg";
import Ubidbali5 from "./BlogImage/Place2023_Image/ubidbali5.jpg";
import Japan6 from "./BlogImage/Place2023_Image/Japa6.jpg";
import Tofino7 from "./BlogImage/Place2023_Image/Tofino7.jpg";
import Vegana8 from "./BlogImage/Place2023_Image/Vegana8.jpg";
import Crab9 from "./BlogImage/Place2023_Image/Crab9.jpg";
import Osteria10 from "./BlogImage/Place2023_Image/Osteria10.jpg";
import Friendship11 from "./BlogImage/Place2023_Image/Friendship11.jpg";
import NewYork12 from "./BlogImage/Place2023_Image/NewYork12.jpg";
import foodBanner from "./BlogImage/Place2023_Image/foodBanner.jpeg";
import Santorini13 from "./BlogImage/Place2023_Image/Santorini13.jpg";
// Siyam Resorts Blog Image
import maldivesBanner from "./sunshyamresortblog/Sunshyam Image/Maldives_Image/bannerImage.png";
import Heading2 from "./sunshyamresortblog/Sunshyam Image/Maldives_Image/Heading1.jpg";
// Iru Fushi Blog Image
import iruFushiBanner from "./sunshyamresortblog/Sunshyam Image/IruFushi_Image/iruFushiBanner.png";
import Accom1 from "./sunshyamresortblog/Sunshyam Image/IruFushi_Image/Accom1.png";
import Dining2 from "./sunshyamresortblog/Sunshyam Image/IruFushi_Image/Dining2.png";
import iruFushiActivity from "./sunshyamresortblog/Sunshyam Image/IruFushi_Image/iruFushiActivity.jpg";
// Vilu Reef  Blog image
import viluBanner from "./sunshyamresortblog/Sunshyam Image/ViluReef_Image/viluBanner.png";
import Accomm1 from "./sunshyamresortblog/Sunshyam Image/ViluReef_Image/Accomm1.png";
import Dinings2 from "./sunshyamresortblog/Sunshyam Image/ViluReef_Image/Dinings2.png";
import Activities3 from "./sunshyamresortblog/Sunshyam Image/ViluReef_Image/Activities.png";
// IruVeli Blog Image
import iruBanner from "./sunshyamresortblog/Sunshyam Image/IruVeli_Image/iruBanner.png";
import IruDining2 from "./sunshyamresortblog/Sunshyam Image/IruVeli_Image/Iru2.png";
import Activity3 from "./sunshyamresortblog/Sunshyam Image/IruVeli_Image/Activity3.png";
import Spa4 from "./sunshyamresortblog/Sunshyam Image/IruVeli_Image/Spa4.png";
import IruAccom from "./sunshyamresortblog/Sunshyam Image/IruVeli_Image/IruAccomdation.jpg";

//Olheveli Blog iMAge

import OhleveliBanner from "./sunshyamresortblog/Sunshyam Image/Olheveli_Image/OhleveliBanner.png";
import Accomndation from "./sunshyamresortblog/Sunshyam Image/Olheveli_Image/Accomdation1.png";
import OhleveliDining2 from "./sunshyamresortblog/Sunshyam Image/Olheveli_Image/OhliDinings2.png";
import OhleveliActivity3 from "./sunshyamresortblog/Sunshyam Image/Olheveli_Image/Active.png";

//Pasiludah Blog Image
import PasiBanner from "./sunshyamresortblog/Sunshyam Image/Pasikudah_Image/PasiBanner.png";
import PasiAccom1 from "./sunshyamresortblog/Sunshyam Image/Pasikudah_Image/PasiAccom1.png";
import PasiDinings from "./sunshyamresortblog/Sunshyam Image/Pasikudah_Image/PasiDinings2.png";
import PasiActivity from "./sunshyamresortblog/Sunshyam Image/Pasikudah_Image/ActivityPasi.png";
export const data = {
  "dubai-a-dream-destination": [
    {
      title: "Dubai- A Dream Destination",
      para: `The city of skyscrapers and shopping malls is a destination that attracts tourists from all over the
            world, for its sunshine, adventure shopping, and family fun. Dubai has an impressive
            infrastructure, including the world's tallest building and some of the most luxurious restaurants
            and hotels.`,
      line: `Here are the top ten must-see things in Dubai.`,
      summary: `Dubai is an excellent destination for a family vacation. It is a world-class
      city that offers exceptional hospitality, thrilling adventures, and a shopping
      experience unlike any other. There are many interesting places to visit in
      Dubai with family that offer a perfect blend of tradition and modernity.
      There are marvels in this city that no family should ever miss on their
      vacation, ranging from natural to man-made, and to help you find those
      marvels, we bring you the top 10 Dubai places to visit with family. Visit all
      of these places with your family to spend some of the best days of your life
      with your loved ones while on a Dubai city tour.`,
      bannerImage: DubaiBanner,
      cont: "middleeast",
      cat: "dubai",
    },
    [
      {
        title: `1. Burj Khalifa`,
        para: `The world's tallest building, the Burj Khalifa, is a must-see in Dubai. The Burj Khalifa, which
            stands 828 meters (2, 716.5 feet) tall, has 160 liveable floors. The Burj Khalifa is a spectacular
            architectural and engineering feat, with two observation decks on the 124th and 148th floors
            and Atmosphere, the world's highest restaurant at 1,450 feet, on the 122nd floor. The Burj
            Khalifa also has the highest outdoor observation deck (1,820 feet), the highest occupied floor
            (1,920 feet), and the longest elevator (1,653 ft).`,
        reverse: false,
        img: BurjKhalifa1,
      },
      {
        title: `2. Burj A1 Arab`,
        para: `The Burj A1 Arab is a well-known Dubai landmark. The Burj A1 Arab is a well-known landmark in
            Dubai. Its a seven-star hotel shaped like a sail. Burj A1 Arab is the world's tallest all-suite hotel,
            standing 321 meters tall. On the 28th floor, there is a helipad and a restaurant that appears to
            be suspended in mid-air. The Burj A1 Arab is built on a man-made island connected to the
            mainland by a private bridge. The hotel has nine restaurants and bars, as well as a waterpark.
            The furniture and art frames have been adorned with 24-carat gold. To experience Arabian
            luxury, tourists can book the 'Inside Burj A1 Arab' show.`,
        reverse: true,
        img: BurjAiarb2,
      },
      {
        title: `3. Palm Jumeirah, The man-made island`,
        para: `The Palm Jumeirah, built on reclaimed land and resembling a series of artificial archipelagos, is another must-see in Dubai. When viewed from above, Palm Jumeirah resembles a palm tree. Some of Dubai's top luxury resorts can be found on the island. No steel or concrete was used in the construction of the Palm Jumeirah, which is made up of 120 million cubic meters of seabed sand. The island provides a variety of activities for visitors. Palm Jumeirah Islands has it all, from shopping malls and hotels to luxury beach resorts. Tourists can sail around Palm Jumeirah in a yacht or speedboat, or take the Palm Monorail, which runs past the Atlantis resort.`,
        reverse: false,
        img: PalmImage3,
      },
      {
        title: `4. Museum of the Future`,
        para: `The Museum of the Future is a new tourist attraction in Dubai that is regarded as one of the world's most beautiful structures. The museum, which is located on Sheikh Zayed Road, the city's main thoroughfare, is a seven-story hollow elliptical structure made of stainless steel and inscribed with Arabic calligraphy quotes. The museum transports visitors to the year 2071, which corresponds to the centennial year of the UAE's founding. The seven-story building is an engineering marvel with no pillars.
        The museum offers a variety of futuristic experiences, including a trip to space, a visit to the Amazon rainforests, and a sensory spa. It makes use of cutting-edge virtual reality and augmented reality technology, as well as big data analysis, AI, and human-machine interaction. It has no sharp edges and is one of the most streamlined structures in the world. It has a height of 77 meters. `,
        reverse: true,
        img: MesuemImage4,
      },
      {
        title: `5. Dubai Aquarium`,
        para: `The Guinness World Record for the 'Largest Acrylic Pane' is held by the Dubai Aquarium and Underwater Zoo. It is one of the world's largest indoor aquariums. Located in The Dubai Mall, it is home to more than 33,000 aquatic animals, comprising over 140 species. In the 10-million-liter tank, there are over 400 sharks. Cage snorkeling is a popular attraction because tourists can snorkel in a 10-million-liter tank filled with diverse marine life. Sailing in a glass-bottom boat that floats on the water's surface is also an amazing experience.`,
        reverse: false,
        img: Aquarium5,
      },
      {
        title: `6. Atlantis Aquaventure and Secret Chamber`,
        para: `The Aquaventure Waterpark features private beaches, children's play areas, and marine activities, and it is adjacent to the Atlantis Aquaventure, Dubai's largest waterpark with iconic rides and attractions. The water park spans 17 hectares and features the most exciting water attractions. The park has 30 water slides for thrill seekers. You can take the Leap of Faith, a 27.5-meter dive through a clear tube surrounded by rays and sharks. The Aquacade is another thrilling attraction. You can travel through the tube at up to 35 kph. Do not miss the Secret Chambers and Atlantis Underwater Zoo, which have 65,000 marine animals.`,
        reverse: true,
        img: Chamber6,
      },
      {
        title: `7. Ski Dubai`,
        para: `Ski Dubai offers a spectacular winter setting in the mountains. This Dubai Mall tourist attraction offers a variety of snow activities such as skiing, tobogganing, and snowboarding. You can also get up close and personal with the resident snow penguins. Ski Dubai is the first indoor ski center in the Middle East. It is a must-see destination with five ski runs (the longest of which spans more than 1,300 feet with a 197-foot vertical drop), a giant ball run, an exciting 10-foot ramp, tube slides, and a relaxing chairlift that allows you to see the sights from a higher vantage point.`,
        reverse: false,
        img: SkyImage7,
      },
      {
        title: `8. Dubai Marina`,
        para: `Dubai Marina, the manmade canal city, is set on a two-mile stretch of sand facing the Arabian Gulf. Tourists can view the high-rise luxury residential and vacation properties from this location. Take a boat tour on a traditional Arabian dhow, yacht, or speed boat to explore the area. The entire waterfront district was built by excavating the desert and allowing the waters of the Gulf of Mexico to flow into the site. Make the most of this waterfront location by sunbathing and swimming at Jumeirah Beach. Dubai Marina Walk is a seven-kilometer-long waterfront walkway lined with palm trees and lush greenery.`,
        reverse: true,
        img: Marina8,
      },
      {
        title: `9. Dubai Frame`,
        para: `The Dubai Frame is the largest picture frame in the world. The massive structure, located in Dubai's Zabeel Park, between old and new Dubai, offers panoramic views of the city from a height of 150 meters. The cutting-edge glass bridge that connects parallel vertical towers to form the shape of a picture frame is an incredible feature. The structure has an abstract appearance and is made of a decorative mix of glass, reinforced concrete, steel, and aluminum. Take the elevator to the top of the frame and walk across the sky bridge to get a 360-degree view of the city.`,
        reverse: false,
        img: Frame9,
      },
      {
        title: `10. Dolphinarium`,
        para: `The Dolphinarium in Dubai is a massive tourist attraction that spans 1.25 acres (approximately) of air-conditioned space. Located in Creek Park, a family entertainment and activity park in Dubai, the Dolphinarium is the home of six bottlenose dolphins and seals. The pool is in the center of this Dolphinarium, which has arena-style seating. The animals' abilities are showcased in a 45-minute indoor interactive extravaganza. Observe the dolphins and seals dancing, singing, juggling, playing ball, and jumping through hoops!`,
        reverse: true,
        img: Dolphinarium10,
      },
    ],
  ],
  "10-most-visited-places-in-maldives": [
    {
      title: `10 most visited places in Maldives`,
      para: `Sitting on a hammock under the beautiful star accompanying a beautiful view of the enormous aqua sea in the front and your favorite swallow help. Doesn’t it sound celestial? If you have happened preparation a trip to Maldives for the most interminable period but couldn’t avoid it, immediately is a moment of truth. If you are a couple or a classification expecting the best choice of places to visit in the Maldives, we’ve caught the perfect list for you!`,
      line: `If you are a couple or a classification expecting the best choice of places to visit in the Maldives, we’ve caught the perfect list for you!`,
      summary: `Maldives   is   a   group   of   reefs   situated   in   the   Indian   Ocean.   These
      enclaves   are   famous   versatile   the   globe   for   their   beautiful
      advantage and clear waters. The silver ammophilous beaches and
      sky waters have tense heaps of crowd done yearly to these calm
      peninsulas.   The   very   attractive   place   is   a   well-known   haunt   of
      photographers   the   one   love   to   capture   the   unique   picturesque
      advantage of the isles.`,
      bannerImage: MaldviesBanner,
      cont: "asia",
      cat: "maldives",
    },
    [
      {
        title: `1. Male City `,
        para: `What heaven to start investigating the top Maldives interests than allure tiny capital, Male? Famed for the allure of old temples, rich constructions, aqua waters, and influencing plan wood, Male is individual of the best choice places to survey in the Maldives.

      Surrounded by traditional constructions faithful and shades of vulgar and green on the added, you cannot give to miss this fascinating city on your Maldives trip. From ambling sold gossiping accompanying busy markets to knowledge about Maldivian records.
      `,
        reverse: false,
        img: MaleCity1,
      },
      {
        title: `2. Artificial Beach`,
        para: `An artificial beach, Artificial Beach does not give the impression affected unrehearsed! Known for its allure and awesome advantage, Artificial Beach in Male entices a lot of shore suitors and foodies. Lounging regions, barbeques, and cafes are perfect for cuisine fans, while experience buffs can hold water endeavors for a significant opportunity. With an excess of ventures convenient for pleasure, Artificial Beach convinces expected individuals of the highest-in-rank places to visit in the Maldives with family.`,
        reverse: true,
        img: Artificial2,
      },
      {
        title: `3. National Museum`,
        para: `With a unique and neat accumulation of artifacts, the National Museum in Maldives offers visitors a glimpse into the past concerning this reef country with its own government. It is individual of the ultimate entertaining places to visit in the Maldives to visualize a large variety of monarchical antiquities varying from the Islamic and Buddhist eras.
      It is a paradise for experienced buffs and art admirers. Costumes and ornaments of the former kings and queens, thrones, palanquins, grand sunshades, manuscripts, weaponry, and shield, skilled plenty of treasure continued to introduce devoted impressions in Maldivian voters.
      `,
        reverse: false,
        img: National3,
      },
      {
        title: `4. Addu Atoll`,
        para: `Blessed accompanying a few of the ultimate beautiful islands, Addu Atoll is also known as Seenu Atoll and is with high-quality sightseer places in the Maldives. On your Maldives trip, survey the exquisite islands at Addu Athe toll including the attractive Gan Island. Meedhoo Island, Hulhudhoo Island, Maradhoo Island, and Feydhoo Island are added primeval islands you can visit accompanying your desired one. Densely occupied accompanying clean local villages and exaggerated suspicious top part of animate body saplings, skilled are differing fun water ventures for feat applicants like diving, underwater swimming, and angling.`,
        reverse: true,
        img: Addu4,
      },
      {
        title: `5. Maafushi Island`,
        para: `If you are preparing a honeymoon trip to the Maldives, Maafushi Island is an individual of the high-quality places to visit in the Maldives for couples. Away from the crowds, it is ideal to give condition occasion to accompany your beloved and enjoy sweet dialogues.

      In addition, Maafushi Island is spotted accompanying warm guesthouses and hostels that are perfect for honeymooners for a warm stay. Besides, skilled are real remembrances, sandbars, outlets, and diving instrumentalities that will form your visit to Maafushi Island in the Maldives value it.

      `,
        reverse: false,
        img: Maafushi5,
      },
      {
        title: `6. Grand Friday Mosque`,
        para: `One of the top conscientious places to visit in the Maldives, the Grand Friday Mosque not only allures otherworldly applicants but to design fans. The unity and the silvery-marble new makeup create this attractiveness to travelers. The temple has a massive beautiful dome that towers over the main square, Jumhoree Maidan. For the best occurrence, prevent pleading periods and visit between 9 searches out 5 pm. And because this is a scrupulous place, it should wear appropriate entire clothes for both people.`,
        reverse: true,
        img: Grand6,
      },
      {
        title: `7. Fulhadhoo Island`,
        para: `Clear lagoons, silver-soil beaches, affecting touch forests, and a calm ambiance are what create Fulhadhoo Island individual of the must-visit places in the Maldives. This clean peninsula is further individual of the slightest investigated and private archipelagos, that hold unseen treasures.

      If you be going to give a kind occasion accompanying your singular, Fulhadhoo Island is with highest in rank place to visit in the Maldives for your honeymoon. To love your period here to the most thorough, savor underwater swimming, diving, and reptile and aquatic mammal marking.
      `,
        reverse: false,
        img: Fulhadhoo7,
      },
      {
        title: `8. The Muraka`,
        para: `The Muraka Conrad understands to support you accompanying a resembling a dream occurrence. For a noteworthy stay, this resort offers excellent comforts like a private cook and servant, professional resort situations, water endeavors, an endless pool, and a ready-to-work appropriateness instructor. Conrad Maldives resort is not just a reconciliation but an individual of ultimate interesting Maldives journey places.`,
        reverse: true,
        img: Muraka8,
      },
    ],
  ],
  "best-destination-to-visit-in-malasiya": [
    {
      title: "Best Destinations to visit in Malaysia",
      para: `Malaysia is an international country accompanying much to offer guests regardless of
      their budget or their plan of fun. Kuala Lumpur, the country's capital, is a worldly-wise city
      attending astonishing buying and marvelous construction-inside blocks, you'll find both the
      up-to-date Petronas Towers and any of the pioneering palaces and constructions.
        Just a short repulse from the capital, skilled are much of agenda-from peninsulas,
         sierras, and record-breaking caves, to innumerable places of worship and a singular
         chance to investigate the animal world-rich wilderness full of plant and animal life of Borneo.
        `,
      line: "For more plans on how to give your opportunity, visualize our list of best sightseer appeals in Malaysia.",
      summary: ` Malaysia is a country of the contrasts-an general join of pioneering design,
       beautiful parks and beaches, existing skyscrapers, and a singular countryside camouflaged
       in beverage orchards. A fusion accompanying influences from adjacent lands, in addition to
        Western nations, Malaysia has an entity for everybody-from the experience-legendary Petrona
         Towers to the enlightening and conscientious difference that form this a goal like no added.`,
      cont: "asia",
      bannerImage: MalaysiaBanner,
      cat: "malaysia",
    },
    [
      {
        title: "Petronas Twin Towers",
        para: `The most exaggerated twin towers in the experience, the Petronas reach a powerful 452
        meters extreme up into the clouds. The towers are 88 floors unreasonable and have an influential total of 76 elevators.
            Built utilizing supported facts, fortify, and mirror, two together towers are affiliated for
            each additional by a double sky bridge on the 41st and 42nd floors. Visitors can create their
            habit up here for marvelous views of KL and the 6.9-hectare KLCC Park beneath the views are specifically powerful after dark.
            While most of the floors on the towers are leased to associations-IBM, Microsoft, and Huawei
             Technologies all have jobs here-below floors of the towers that are unsociable for Suria KLCC, individual of the best buying centers in Malaysia. With over 300 stores, a cunning balcony, and even scope for a Philharmonic Hall, this sales and amusement scope will maintain callers busy for hours.
            `,
        reverse: true,
        img: Petrosan1,
      },
      {
        title: "Batu Caves",
        para: `Located inferior a moment outside Kuala Lumpur, the Batu Caves complex resides of three main caves plus
        a succession of tinier ones, most of the bureaucracy holding images and 100-period-traditional shrines loyal to Hindu gods.
        The main hole in land formation, popular as Cathedral Cave, is favorable of a large vivid molding-manage
        completely up the 272 steps, and you'll find a room beautified accompanying effigies, altars, and lights.
        At the bottom of the stairway,  a 43-beat-unreasonable golden effigy of Lord Murugan welcomes foreigners.
        Visitors are admitted to investigating the caves on their own or can touch a directed tour to determine
        nearly the caves. During the Hindu celebration of Thaipusam in January, thousands of population flock to the cavern for the festivals.
        `,
        reverse: false,
        img: Betu2,
      },
      {
        title: "Mount Kinabalu",
        para: `At just over 4,000 meters extreme, Mount Kinabalu is the most exaggerated elevation in Malaysia. The pile is one Kinabalu Park, individual of the oldest city parks in Malaysia and a UNESCO World Heritage Site. Because of the allure singular environment joining mountaintop pastures, grasslands, and shrublands, Kinabalu is home to a powerful range of both plant and animal varieties, containing the endangered orangutans.
        Mount Kinabalu is a big goal for climbers-but summiting here may be deceptive. Only 185 crawl permits are circulated regularly at apiece park, and guests must form reconciliation stipulations and engage a ridge guide earlier so that grant permission to hit the trails. Although crowds under 16 are admitted to touching crawling groups, skills are limited working.
        Climbers concede the possible plan of being a guest of the Kinabalu National Park before trying the crawl because the park itself is once at an elevation of over 1,800 meters, this will turn over in one's mind adaptation before trying to reach the peak.
        `,
        reverse: true,
        img: Mount3,
      },
      {
        title: "Perhentian Islands",
        para: `Once an end secondhand by merchants touring Southeast Asia, this group of narrow peninsulas is a park for animals and has enhanced a main visitor goal in northeastern Malaysia. Most of the islets may be achieved by either ferry or limited power-driven boats, even though only the two together best enclaves offer accommodations, shops, and comforts-of these two, Pulau Perhentian Besar has more of a hike setting, while Pulau Perhentian Kecil is to some extent more fashionable and offspring-familiarize.
        While you can embark on the vehicle a water taxicab to move from individual coast to the next here, it's still attainable to attend the reef's walking trails alternatively-a much-urged alternative as you disturb trek through wilderness full of plant and animal life courses and take open beautiful views of the water on the way to the destination.
        Scuba diving, underwater swimming, and kayaking are common ventures here, but companies can likewise enlist in reptile preservation programs and receive a singular approach to the extent of the place turtle's equal plan.
        `,
        reverse: false,
        img: Island,
      },
      {
        title: "Sipadan Island",
        para: `Sipadan Island and allure encircling sea waters are unspecified the realm's most flavorful sea residence, home to imperiled hawksbill turtles, porpoise cheat, monitor lizards, and a large group of pink class. The enclave is likewise thought-out individual of best choice diving goals in the realm and is menacingly shielded-visiting demands a permit earlier and only 120 permits are reported per epoch.
        Reaching the archipelago demands a time-long ride on a speed ship. Once here, the isle may be surely surveyed traveling by foot, accompanying various beaches and shoal sites within minutes of each one.
        Since it's not any more likely to persist the reef by way of environmental protection societies (the nearby Mabul Island offers accommodations), callers normally reach here early in the dawn for unspecified underwater swimming and diving tours. All foreigners must leave the islet by 3 pm.
        `,
        reverse: true,
        img: Sipadan5,
      },
    ],
  ],
  "most-visited-places-in-songapore": [
    {
      title: "Most visited Places in Singapore",
      para: `Singapore has been characterized as a park for the rich, and it's real that the little city-state does have the brightness of money. But Singapore offers as well just expensive buying malls, indulgence lodgings, and fine eating (though it's value taking care of in those some if you can). There is likewise colorful annals and various racial dorm to find, in addition to many offspring-intimate attractiveness and beautiful public scopes that form investigating this lightly innovative city helpful.

        Singapore has a wonderful public transportation scheme that creates journeys available and smooth. Once you've received a sense of the train line graph, you'll have easy zipping from individual contained municipality to the next. English is uttered far and wide, and signs are in English also. In fact, Singapore is individual of the smooth and most easy nations to guide along routes, often over water in Southeast Asia. And because you're not equating prices to nearby Thailand or Vietnam, you're in for an agreeable stay`,
      line: ``,
      summary: `A land with modern marvels and sky-osculation constructions, Singapore is up-to-date. With the cleverly illuminated lights that sway about after dark emphasizing the allure of up-to-date architecture, it nearly feels like fate has been achieved on the soil. Singapore is popular for its allure fast-moving progress, influential and new buying malls, intense eating scenes and luxe lodgings accompanying wonderful views originated in the country.`,
      cont: "asia",
      cat: "singapore",
      bannerImage: SingaporeBanner,
    },
    [
      {
        title: "Marina Bay Sands",
        para: `The rich Marina Bay Sands resort complex contains an expensive indulgence inn, a promenade accompanying a waterway running through it, the ArtScience Museum, and the Marina Bay Sands Skypark Observation Deck–an angle for deceiving the whole city. The Skypark's regarding bedeck and endlessness pool are about the transport (agreed, send) that tops the inn. Only tenants are admitted to using the endlessness pool, but one can visit the remark bedeck.

            From the Skypark, you can visualize the creative double loop bridge, the traffic, the Gardens apiece Bay (101 hectares of land convinced into beach flowers), and the powerful horizon.
            
            While top in addition to the city, tenants can snag a morsel or a cappuccino at the rooftop saloon or lift something precious from the gift stand. You can purchase a photograph of yourself green-secluded indicating the position of the large inn as it's all illuminate after dark, but the cost is steep at 50 Singapore dollars–better to request a friend traveler to snap a photograph of you by chance. The beautiful wealth of the Marina Bay Sands demonstrates Singapore's style and rank as an essential worldwide city in Southeast Asia.
            `,
        reverse: false,
        img: Marina1,
      },
      {
        title: "Gardens by the Bay",
        para: `Once you've checked out this gracefully planned green scope (from the very beginning of the Marina Bay Sands, possibly) you achieved able to have or do stay away. Wander through the Bay East Garden, perfect for retaining the throbbing plants of the world and emerging the city bustle for a short time.
            You won't be going to miss Supertree Grove, a place you'll find a cluster of emblematic, innovative makeups planned to act environmentally tenable functions. Then, head to the Cloud Forest Dome to visualize the globe's tallest household cascade and discover some about biodiversity. Check the site for passport deal prices and tour opportunities.
            `,
        reverse: true,
        img: Garden2,
      },
      {
        title: "Botanic Gardens",
        para: `
        Not expected wrong the Gardens on the Bay, the Botanic Gardens are still valuable a visit. Singapore has taken allure's first UNESCO World Heritage recommendation for allure floral flowers and accompanying good reason. The city can constantly feel inclined to an urban area, although a clean and wealthy individual, the relation to plants and flowers continue pieces of Singapore's more rowdy tradition.
        
        Here, a walking trail leads to the flowers' traditional seedlings, which are conserved as constituents and work to safeguard the city's mature wood class. Make sure to visit the powerful National Orchid Garden also.
        
        Another favorite agenda contains visiting the eco-flowers, eco-basin, bonsai flowers, sculptures, and various additional precise flowers.
        `,
        reverse: false,
        img: Botanic3,
      },
      {
        title: "Singapore Zoo",
        para: `
        Billing itself as the experience's best rainforest wild, the Singapore Zoo is a pretty influential place. The ease is clean and alluring, and the mammals perform well doctored, accompanying an abundance of luxuriant plants and residence scope.
        
        The orangutans are specifically powerful, and callers can watch as babies and persons alike swing extremely above their principles and lunch on products. There is too a big ape kin, zebras, meerkats, a komodo monster, blemish rats, silvery tigers, pouched mammals, and many additional beings.
        
        Guests can honor satiation for a few of the mammals. Allow not completely three hours to create your habit about the wild.
        
        If the wild doesn't placate your need for getting nearly being, skilled's further the Night Safari, River Safari (containing a giant panda wood), and the Jurong Bird Park. Park storage passes are free if you plan to visit as well as individual of the parklike area in which animals are housed for exhibition.
        `,
        reverse: true,
        img: Singapore4,
      },
      {
        title: " Orchard Road",
        para: `One may be excused for meeting expectations in Singapore and wait and do nothing but buy, as this is an excellent city for style and creator fashionable. Orchard Road Extension is an excellent place to start a buying celebration, as skilled are exclusive stores in every direction. You'd want nothing less from a community that boasts 22 malls and six area stores. There are more four videotape stages, containing an IMAX movie theater, and a KTV karaoke institution.`,
        reverse: false,
        img: Orchard5,
      },
    ],
  ],
  "best-attractive-beaches-in-thailand": [
    {
      title: "Best attractive beaches in Thailand",
      para: `
        The southern domains of Thailand are home to a few of the ultimate optically beautiful and mysterious beaches in the experience. If you expect quiet and peacefulness, stroll the beaches disregarding the huge rock formed from sediment monoliths and caves in the green water of Krabi Province. For an alive waterfront setting, you can suddenly go over to ordinary Phuket Island. On the additional coast, the Gulf of Thailand is home to islets encircled accompanying gravelly delicate beaches, also. Koh Samui is the moment of truth from what or which place to survey Koh Phangan and Koh Tao.
        
        The southern tip of Thailand is a beachgoer's land nevertheless place you pick to spend money on your coast blanket. Choosing the right coast for your escape depends on how you be going to give your opportunity, whether you expect to experience or completely to understand the beams, be friendly or uncouple.
        `,
      line: `While few beaches are smooth to disturb, and accordingly forever cramped, added secret treasures are hidden on reefs, needing some more work to find. Figure out what suits you accompanying our list of attractive beaches in Thailand.`,
      summary: `
        Thailand has no deficiency of beautiful beaches, accompanying long stretches of the border by the water near both the Andaman Sea and Gulf Coast apart from in addition 1,400 archipelagos and islets. The country with its own government is frequently pronounced to have few of the world’s most magnificent beaches.
        `,
      cont: "asia",
      bannerImage: ThailandBanner,
      cat: "thailand",
    },
    [
      {
        title: "Railay Beach ",
        para: `
            The splendid Railay Beach is individual of the highest in rank beaches in Krabi and individual of the top attractions in Thailand. It is only approachable by longtail raft because it sits on a limited peninsula, but it is well valued the work to catch here. The delicate silvery soil on the coast is singular in some of the occurrences. Railay Beach is between cliffs and caves, rock formed from sediment monoliths, a wilderness full of plant and animal life countrysides, and pool-like swimming fields.
            
            There are various tinier beaches that create Railay Beach, and so forth are inside close closeness. This stretch of coast is perfect for joining the usual coast occasion accompanying a few surveys about the isle.
            `,
        reverse: false,
        img: Railay1,
      },
      {
        title: " Nai Harn Beach",
        para: `Though Nai Harn Beach can receive completely congested, it is still individual of the ultimate favorite beaches in Thailand. Located in Phuket, the waterfront is famous for transparent water, silver soil, and waves that are not arrogant when swimming.

            The beach is one of the ultimate standards by way of allure agreeing environment and weather environments that manage acceptable to visit period-round. Nearby coffee shops and local drink hawkers manage useful to stay an adequate era at the coast. A number of resorts like the Navatara Phuket Resort, The Nai Harn, and Sunsuri Phuket are within walking distance of the beach.
            `,
        reverse: true,
        img: Harn2,
      },
      {
        title: "Head Rin",
        para: `Haad Rin Beach is an individual of the ultimate standard of the almost 30 beaches on the narrow island of Koh Phangan. The long stretch of white sand and sky water among coconut forests and cliffs create an attractive beach day. The beach is mainly an active setting, accompanied by willing entertainment of volleyball, folk swimming, and vacationers experiencing water sports like jet skiing and kayaking.

     Nearby hotel ranges from private villas at Anantara Rasananda Koh Phangan Villas to brimming-aid resorts, like the Santhiya Koh Phangan Resort & Spa, and even hostels on the shore for those the one act on a close budget. The best period to visit is outside weekly entire-moon occurrences when the region can catch completely busy accompanying young coast-goers.
     `,
        reverse: true,
        img: HadRin3,
      },
      {
        title: "Phra Nang Cave Beach",
        para: `For a beach surrounded by beautiful countryside and a rustic experience that few possible choices can equal, visit Phra Nang Cave Beach in Krabi Province in southern Thailand. Characterized by outcroppings of cliffs that are common for mountain climbing, the unintelligent water concerning this waterfront form it a favorite call for social sightseers and kins expect more reliable waters to float. It may be congested at times, but it is individual of the ultimate singular beaches in Thailand.

            For a more private beach experience, stay at a resort like the Rayavadee Resort, which secludes patrons in the woodland environment, accompanying a low-profile pool and waterfront region. Short trails forthcoming the coast take you through caves and reveal the region's countryside. Try a few new products from a buoyant hawker to calm in the heat. The best opportunities to visit for appropriate weather and fewer crowds are May and October. 
            `,
        reverse: false,
        img: CaveBeach4,
      },
      {
        title: "Maya Bay",
        para: `The empty, azure waters of Maya Bay on Koh Phi Phi, individual of the ultimate elegant isles in Krabi Province, are mainly among massive rock formed from sediment monoliths. The comprehensive view of the coast from it smooth to visualize the reason this site was secondhand as the scenery for the Leonardo DiCaprio picture The Beach. The steep cliffs that enclose the water manage to choose a private land, in spite of it is unlike what you visualize in the flick. 

            The coast was enclosed in 2018 for a reinvigoration project of the pink that had existed demolished from uncontrolled travel. The coast only reopened in 2022 and acted so accompanying managing to help maintains it maintained and distinguished.
            `,
        reverse: true,
        img: MayaBay5,
      },
    ],
  ],
  "best-honeymoon-places-in-switzerland": [
    {
      title: "Best Honeymoon Places in Switzerland",
      para: `

        Switzerland assembles ideas and food from Germany, France, and Italy. It is, so, the perfect globe-in-a-scoop travel goal. Besides, Switzerland is sanctified accompanying the new advantage of the Alps. On the other hand, the throbbing city history in places like Zurich is continually an extravagance. The endless stretch of being and fowl sanctuaries in Switzerland that guarantee a complete bundle for some honeymoon celebration on the ground adds ornament to the list.
        `,
      line: `Here is the upper class few of the ultimate idealistic and best honeymoon goals in Switzerland to guarantee that dream honeymoon you have existed in preparation for:`,
      summary: `There take care not to be someplace better than Switzerland Honeymoon place to plan a sentimental and significant trip accompanying your person who is loved by another. Moreover, the infinite alluring interests of the country, containing Zurich, Interlake, Lucerne, Zermatt, Geneva, Ticino,  and miscellaneous remainder of something create this country high-quality establish the face concerning this ground to plan a honeymoon. These appeals not only admit you to understand or be understood by the great open countryside of Switzerland but likewise retain the allure of education in the highest in rank habit attainable`,
      cont: "europe",
      bannerImage: SwiterzerlandBanner,
      cat: "switzerland",
    },
    [
      {
        title: "Lucerne",
        para: `
           Most romantic things to do: Laze about the aqua vulgar waters of the lagoon, traipse about closely associated among the beautiful calm concerning this motor-free city, survey classical ridges, and pose apiece abundant fountains. You can too select to take a cruise ride in Lake Lucerne and receive clicks against the marvelous backdrops of obscure snowstorm-adorned peaks. This is individual of the highest in rank honeymoon places in Switzerland.
           `,
        reverse: true,
        img: Lucerne1,
      },
      {
        title: "Zurich",
        para: `
            Most romantic things to do: Zurich is the enlightening center – survey the many museums, and creativity cafes, and splurge on the local food. You can again take your woman buying at common people buying arcades in this place municipality and buy her few fancy treasures or tainted fashion wear to hasten her off her extremities on your Switzerland honeymoon in December. This is individual of the highest in rank places to visit in Switzerland for a honeymoon proper the number of sentimental places in Zurich.
            `,
        reverse: false,
        img: Zurich2,
      },
      {
        title: "Interlaken",
        para: `
            Most romantic things to do: Indulge in scene sports like skiing, snowboarding, and paragliding to visualize the Swiss Alps from heaven. Lose yourselves in the astounding advantage of the Alps as you board high-quality railways in the realm accompanying your companion. You can go sailing on Lake Thun or Lake Brienz. There is a lot of commotion and plenty of places to visit in Interlaken.
            `,
        reverse: true,
        img: Interlaken3,
      },
      {
        title: "Jungfraujoch",
        para: `Most romantic things to do: Walk closely associated accompanying your love till complete Panorama habit – the pleasant on the west side when facing north facing the first slope of the Alps. If you men are a risk-taking couple, this is the place expected – trek to the crest of the top of Europe. Ride the cable car to feel the wind deadening your face in addition to bestowing you superb views of the Alps, or just play snowstorm-fight accompanying your associate.`,
        reverse: false,
        img: Jungfraujoch4,
      },
      {
        title: "Lake Geneva",
        para: `Most romantic things to do: Laze about in the serenity of Europe’s best Alpine reservoir, purchase in the UN city, and stroll through the many gardens, parks, and promenades. You can survey the miscellaneous limited scale markets and eating intersections on sold over the bank concerning this reservoir accompanying your loved one to hear about the legitimate education of Switzerland and be greeted accompanying laughing faces and affection. This is individual of the most sentimental basins in Switzerland.`,
        reverse: true,
        img: Lake5,
      },
      {
        title: "The Matterhorn",
        para: `
            Most romantic things to do: Hiking, pedaling, and climbing is the ultimate well-known agenda in The Matterhorn. You can again select to explore the district on a horse-fatigued posture and try to solicit your lady love in a few anglo-saxon styles for fear that she may relive this day for the rest of her growth! Don’t overlook taking selfies against the mythical like backdrops.
            `,
        reverse: false,
        img: Matterhorn6,
      },
      {
        title: "Zermatt",
        para: `Most romantic things to do: Zermatt is one of the best choice Switzerland honeymoon places that offers individuals with completely plenty of ventures. For the quiet souls, skilled are chapels and churches that may be surveyed while the adventure applicants can choose hiking and skiing. One can also associate with a vigil the sunrises and sunsets here`,
        reverse: true,
        img: Zermatt7,
      },
      {
        title: "Ticino",
        para: `Most romantic things to do: No honeymoon trip to Switzerland is complete outside a visit to Ticino. One can head to the Castles of Bellinzona here or be enchanted apiece Verzasca Valley. Visit Monte Bre and retain the views of the city.`,
        reverse: false,
        img: Ticino8,
      },
      {
        title: "Laussane",
        para: `Most romantic things to do: If you are preparing for a honeymoon trip to Switzerland, visit the best cathedral skilled, Cathedral Notre Dame. Head to the Lausanne castle and love wide-ranging views of the city from the allure plateau. The agreeable Lake Ouchy is the perfect spot to savor a glimmering sundown and form for an individual of ultimate sentimental places in Switzerland.`,
        reverse: true,
        img: Laussane9,
      },
    ],
  ],
  "best-tourist-places-in-france": [
    {
      title: "8 Best tourist Places in France",
      para: `Believe me, France is much more than Paris! Although Paris is individual of the most exquisite downtowns in the realm but still several additional legendary places in France place you can visit all the while on your vacation. From boulevards to idolized castles and captivating cathedrals to attractive villages, France is blessed with accompanying type bounties popular for its awesome countryside and jaw-abandoning settings.`,
      line: ``,
      summary: `With the list of legendary tourist places in France, you can retain the trip to this fascinating country at allure best. From magnificently trimmed flowers, entertaining museums, throbbing cafes, and active streets to the world’s best skill galleries, France offers a singular happening to all types of guests.`,
      cont: "europe",
      bannerImage: FranceBanner,
      cat: "france",
    },
    [
      {
        title: "Bordeaux",
        para: `Popularly known as the ‘Wine Capital of the World’, Bordeaux is legendary for its allure of sweet drinks and experiences across the planet. Nestled on the bank of the Garonne River, Bordeaux is overflowing accompanying medieval-style structural marvels, active cafes, and appealing museums.

         This is individual of the top capitals to visit in France for record buffs and construction sweethearts as it is home to a few of the ultimate great remembrances on the planet.
         
         With splendid cathedrals to the most protracted pedestrian buying lane of Europe and great stages to spellbinding museums, Bordeaux has an entity for various types of hikers and forms their vacations significant.
         
         Popular Attractions in Bordeaux: Saint-André Cathedral, Grand Théâtre de Bordeaux, Basilica of Saint-Seurin, Museum of Aquitaine, Cité du Vin, Place de la Bourse
         Things to do in Bordeaux: Heritage walk, city tour, buying, and sweet drink judging
         The best opportunity to visit Bordeaux: June to August
         `,
        reverse: false,
        img: Border1,
      },
      {
        title: "Paris",
        para: `Enveloped by a delightful air of romance, luxury, and scene, Paris is individual of the ultimate legendary municipalities in France. The capital city boasts about structural marvels and unaffected wonders, that engage a lot of travelers from everywhere the experience. Dazzle accompanying the indulgence and taste in Paris accompanies your desired one.

            The colorful character of the city forms its land for honeymooners. Popularly popular as the ‘City of Lights’ and ‘Fashion Capital’, Paris is a perfect place to like the inspiring nightlife and take glimpses of the rich educational tradition of the ancient stage.
            
            Popular Attractions in Paris: Notre Dame Cathedral, Louvre Museum, Catacombs, Eiffel Tower, Arc of Triumph, Champs Elysees, Montmartre
            Things to do in Paris: Dinner on the top of the Eiffel Tower, buying, disco show, River Seine cruise
            The best period to visit Paris: June to August
            `,
        reverse: true,
        img: Paris2,
      },
      {
        title: "Lyon",
        para: `Home to a few of the wonderful structural and classical milestones of the country, Lyon is common for uniting fabric. Settled on the bank of the junction of waterways Rhône and Saône, Lyon houses UNESCO World Heritage Sites and legendary remembrances of France. It is a perfect traveler goal in France for a recreational holiday.

           It is also known as the gastronomical capital of France. The Lyonnaise food is the gastronomical delights, that you can savor in the soft bars of Lyon that do delicious and brink-striking bowls accompanying absolutely surpassed dressings. The creative urban countryside and ecologically devised streets of Lyon offer a unique experience during the France trip.
           Popular Attractions in Lyon: The Museum of Fine Arts, The Gallo-Roman Museum, Roman Theatres, Silk Industry in Croix-Rousse, Basilica of Notre-Dame de Fourvière, 
           Things to do in Lyon: Heritage walk, city tour, buying, and savoring usual foods
           The best period to visit Lyon: June to August
           `,
        reverse: false,
        img: Lyon3,
      },
      {
        title: "Marseilles",
        para: `Marseilles is the oldest and best city in France. This socially acceptable traffic city is ahead of the Mediterranean Coast. Owing to the different sophistication and ethics, Marseilles is also known as the ‘European Capital of Culture’. This city is a perfect place to appreciate the enticing cuisines, cruises, and different educations.

            You can begin undertaking a gastronomic tour in Marseille, investigate the domain by taxicab dinghy, continue a fine arts tour, and have vintage automobile rides in the city. There are many agendas in Marseilles to relish distinctive importance and form the trip memorable.
            
            Popular Attractions in Marseilles: The Fort Saint Jean, The Eglise Saint Laurent, La Canebère, The Chateau d’if, Marseilles Fine Art Museum, Museum of the Mediterranean
            Things to do in Marseilles: Boat trip to The Chateau d’if, little sightseer train trip in Notre Dame de la Garde, traditional walk, and city tour
            The best period to visit Marseilles: September to November
            `,
        reverse: true,
        img: Marselles4,
      },
      {
        title: "Nice",
        para: `Boasts colorful streets, marvelous shore parts, old-fashioned charm, and up-to-date behavior, Nice is the ultimate charming place in France. Located on the southeast coast of France on the French Riviera, this domain loves Mediterranean surroundings on account that travelers flock here throughout their old age.

            Nice describes the perfect mixture of French and Italian sophistication. It is a paradise for cuisine suitors and a perfect place for a gastronomical delight. Savor the brink-striking cuisines here at the local diners containing plant-eating cheesecake and recently able noodles.
            
            Popular Attractions in Nice: The Fort Saint-Jean, Museum of the Mediterranean, La Canebère, The Chateau d’if, Marseilles Fine Art Museum,  The Eglise Saint Laurent
            Things to do in Nice: Boat trip to The Chateau d’if, little visitor train trip in Notre Dame de la Garde, traditional walk, and city tour
            The best opportunity to visit Nice: September to November
            `,
        reverse: false,
        img: Nice5,
      },
    ],
  ],
  "most-polular-destinations-in-spain": [
    {
      title: "8 Most Popular Destinations in Spain",
      para: `Few touristry goals on the planet offer a specific radiant array of places to visualize and traveler attractiveness to visit as Spain. This European country is likewise suffused accompanying extraordinary unrefined surroundings and advantages, from the rough Sierra Nevada mountain system to the silvery soil and vulgar bayous of allure Mediterranean archipelagos like Ibiza.`,
      line: `The country likewise shows the crest as a whole belonging related to the sheer enjoyment of growth: pleasing snack; excellent creativity; astonishing sounds that are pleasant, and harmonized; energetic waltz; and warm, companionable folk. Plan your Spanish exploits accompanying our list of high-quality places to visit in Spain.`,
      summary: `The country is too interesting on account of the various education of allure apparent domains. There is very record here, much of that may be surveyed in some cities. Many Spanish municipalities have old Roman ruins and Islamic designs from the Moorish ending, old castles and fortifications, and Castilian- and Hapsburg-stage palaces and mansions. Most also feature many models of new Art Nouveau, Art Deco, and Modernist design`,
      cont: "europe",
      bannerImage: SpainBanner,
      cat: "spain",
    },
    [
      {
        title: "Madrid",
        para: `Spain's abundant capital city showcases the country's marvelous experiences. It's a perfect festivity goal, as skilled are grand palaces, advancing officers, changeful of the guards, and a large group of museums to visit.No visit Madrid is complete outside visiting all three of the city's big museums. 

    The Prado Museum (correctly named the Museo Nacional del Prado) showcases what was already the Spanish monarchical accumulation of cunning, while the nearby Reina Sofia Museum shows new jewels like Picasso's exciting Guernica. Also, the nearby Thyssen-Bornemisza Museum connects Old Masters accompanying the highest rank in existing creativity. 
    
    Madrid is again a classic, European traipsing city, suffused accompanying green rooms to boast like the Buen Retiro Park, in addition to off-course, pedestrian-only boulevards like the emblematic Gran Via. The snack idea too blooms here, from nonchalant tapas trying a food to contemporary, Michelin-star microscopic science of cooking`,
        reverse: true,
        img: Madrid1,
      },
      {
        title: "Barcelona",
        para: `The second best city in Spain offers entirely various travel occurrences distinguished from Madrid. Its marshy area gives it more of a resort feel, completely accompanying warm, bright weather to love on most days of the period. It's on the country's northeast coast, apiece Mediterranean Sea, and again appears to have a much newer, growing vibe than Madrid and additional Spanish metropolises.

             Must-visualize traveler allure in Barcelona contains the city's huge (and enduringly bare) Sagrada Familia new cathedral and the Joan Miro place for viewing artifacts or, created by the apiece emblematic inventor himself before welcome death. Other good places to visualize in Barcelona involve the city's nearly three 5 beaches and La Rambla, an immense, wood-interlined, pedestrian-only avenue. It's a place the whole city appears at hand out for a stroll.
             `,
        reverse: false,
        img: Barcelona2,
      },
      {
        title: "San Sebastian",
        para: `This resort city act on the northward coast of Spain, in the Basque domain. San Sebastian is yes the beautiful Bay of Biscay, which opens to the Atlantic Ocean. It's a well-known waves municipality, accompanied completely by a surfing setting at Playa de Zurriola. A visit determines the time to happen the singular Basque education. 

        Things to do here involve giving a few periods on the arenicolous waterfront of La Concha or possibly hiking up Monte Orgull, the stronghold-surpassed bluff disregarding the city's harborside area extent. It's again fun to associate with because covered by an asphalt neighborhood, named Parte Vieja. The community is suffused accompanying genuinely refined, regionally possessed shops and innumerable places to remain and boast pintxos (the Basque report of tapas).
        `,
        reverse: true,
        img: Sabistion3,
      },
      {
        title: "Valencia",
        para: `Valencia is a traffic city accompanying a long link to the expanse and to Spain's business at an establishment of the realm, particularly accompanying Europe, the Middle East, and Africa. It's in the Mediterranean Sea, on the country's southeastern coast. As a seaside city, skilled are few neat beaches to appreciate. Another rustic place to visualize is Albufera Park, a beachside wetlands reserve accompanying plenty of hiking trails (and a few good beaches). 

        The city has many museums. A climax is the Museum of Fine Arts (Museu de Belle Arts de Valencia), accompanied by an allure wonderful group of Spanish masters by inventors like El Greco and Goya. There's likewise an abundant, Gothic-style cathedral in the old center of the traditional city in addition to shreds of the traditional city obstruction.
        `,
        reverse: false,
        img: Valencia4,
      },
      {
        title: "Granada",
        para: `Home to the impressive Alhambra Moorish small hill dwelling and stronghold, Granada is another colorful goal in the southern domain of Andalusia. It features more marvelous models of Islamic design, containing the Alhambra castle complex. There's a whole maintained Moorish neighborhood extent named The Albaicín, accompanying narrow streets and most constructions dating from the 8th, 9th, and 10th a period of time. It's an excellent visitor goal, as you can knowledge old and up-to-date Spain in the alike place.`,
        reverse: true,
        img: Granda5,
      },
      {
        title: "Ibiza",
        para: `One of the best choice recess spots in Spain for couples is the resort enclave of Ibiza. It's a short departure or a five- to six-time ferry ride continuously, and is situated about 100 5 off the Spanish coast. Ibiza is the best of the Balearic Islands, a group of mainly resort enclaves in the Mediterranean Sea. The added three best reefs, Mallorca, Menorca, and Formentera are too standard places to visualize in Spain.

        Ibiza may be best famous for alternative music, shore bodies, and large waltz clubs, but it's again suffused accompanying plenty of excellent offspring resorts and innumerable fun belongings to visualize and succeed kids. It has an entity for all and is a good goal for singles, couples, and classifications. Resort regions in Ibiza involve Portinatx in the northward, San Antonio in the west, and Santa Eulalia in the orient.
        `,
        reverse: false,
        img: Lbiza6,
      },
      {
        title: "Córdoba",
        para: `This old city in the Andalusian domain on the west side when facing north of Spain admits you to investigate Spain's annals breaking promises from various thousand ages to the moment of truth when it was any of the Roman Empire. Córdoba was a main Roman city (named Corduba), and many ruins and maintained Roman sites wait, containing a 16-arch bridge across the Guadalquivir River.

         Córdoba was an important Islamic center all during the Middle Ages and has a few marvelous constructions, therefore, ending, the focal point is the city's abundant temple. Called La Mezquita, the temple was innate in the 8th century and later convinced into a Christian temple in the 13th century. There are further long Jewish experiences in the city (and so forth over Spain), and a 14th-century church may be bothered in the old Judería or Jewish quarter.
         
         Cordoba has many museums. The Museo Arqueológico de Córdoba presents the city's (and Spain's) annals breaking promises thousands of age. An additional attractiveness is a place for viewing artifacts or acting in the scene of Cordoba's old Roman stage. The theater has existed shoveled and maybe haunted in the place for viewing artifacts or's cellar level.
         `,
        reverse: true,
        img: Codabo8,
      },
      {
        title: "Seville",
        para: `
        A cute and interesting city in southern Spain, Seville is another model of the country's living annals. It's a compact city, excellent for journeys, suffused accompanying Roman ruins, Moorish buildings and palaces, and Gothic churches from the Castilian rule (the ending when Spain was governed by kings and queens). Islamic experiences and education are particularly rich here, as the city was the capital of the ruling Moorish empire for most of the moment of truth it was under their control. 
        
        It too flourished all the while in Spain's Golden Age, from the 15th to 18th a period of time, when it was the only traffic admitted to regularly sustain business from Spanish communities in the Americas.
        `,
        reverse: false,
        img: Selvile7,
      },
    ],
  ],
  "discovering-paradise-the-best-beaches-in-the-maldives": [
    {
      title: "Discovering Paradise: The Best Beaches in the Maldives",
      para: `The Maldives is a dream destination for many travelers, and it’s not hard to see why. With its picturesque scenery, crystal-clear waters, and stunning beaches, it’s the perfect place to unwind and escape the hustle and bustle of everyday life. In this blog post, we’ll take a look at some of the best beaches in the Maldives that you absolutely need to visit.`,
      summary: `In conclusion, the Maldives is home to some of the most beautiful beaches in the world. From the white sandy beaches of Hulhumale to the crystal-clear waters of Fulhadhoo, the Maldives has something to offer for every type of beachgoer. Whether you're looking for a peaceful retreat or an adventure-filled vacation, the Maldives has it all. So, pack your bags and head to the Maldives to experience these stunning beaches.
     The Maldives is a paradise on Earth with its picturesque islands, crystal-clear waters, and stunning beaches. It’s a dream destination for many travelers seeking to unwind and escape from their hectic lives. With so many beaches to choose from, it can be overwhelming to decide which ones to visit during your trip to the Maldives. From tranquil beaches with white sandy shores to beaches perfect for snorkeling and water sports, we've got you covered. So, sit back, relax, and let us take you on a journey through the best beaches in the Maldives.
     `,
      cont: "europe",
      bannerImage: DiscoverBanner,
      cat: "beach",
    },
    [
      {
        title: "Veligandu Island Beach",
        para: `Located on Veligandu Island, this beach is one of the most picturesque and tranquil beaches in the Maldives. The crystal-clear waters make it perfect for swimming, while the white sandy beaches are great for sunbathing. The island is also home to several luxury resorts, making it the perfect spot for a luxurious vacation.`,
        reverse: true,
        img: Islandbeach1,
      },
      {
        title: "Hulhumale Beach",
        para: `If you're looking for a beach that's easily accessible, then Hulhumale Beach is perfect for you. It's located on the island of Hulhumale, which is just a 20-minute speedboat ride from the capital city of Male. The beach is known for its long stretches of white sandy beaches and crystal-clear waters. Visitors can enjoy a range of activities such as snorkeling, swimming, and sunbathing. There are also several cafes and restaurants, making it the perfect spot for a relaxing day out.`,
        reverse: false,
        img: Hulhumale2,
      },
      {
        title: "Fulhadhoo Beach",
        para: `Located on the Fulhadhoo Island, Fulhadhoo Beach is one of the most beautiful and peaceful beaches in the Maldives. The beach is known for its stunning turquoise waters, which are perfect for swimming and snorkeling. Visitors can also take a stroll along the beach and enjoy the beautiful scenery. The island is also home to several guesthouses, making it the perfect spot for a quiet and peaceful vacation.`,
        reverse: true,
        img: Fulhadhoo3,
      },
      {
        title: "Maafushi Island",
        para: `Maafushi Island is a small island located in the Kaafu Atoll of the Maldives. The island is home to several beautiful beaches, including Bikini Beach, which is a popular spot among tourists. Bikini Beach is known for its soft white sand and clear turquoise waters, making it perfect for swimming and sunbathing. The beach is also surrounded by several cafes and restaurants, serving up some of the best local cuisines.`,
        reverse: false,
        img: Maafushi4,
      },
      {
        title: "Reethi Beach",
        para: "Located on the island of Fonimagoodhoo in the Baa Atoll of the Maldives, Reethi Beach is one of the most beautiful and serene beaches in the Maldives. The beach is known for its long stretches of white sandy beaches and clear turquoise waters, making it perfect for swimming and sunbathing. Visitors can also enjoy a range of activities such as snorkeling, diving, and kayaking. The island is also home to several luxury resorts, making it the perfect spot for a luxurious vacation.",
        reverse: true,
        img: Reethi5,
      },
    ],
  ],
  "luxury-travel-and-high-end-accommodations": [
    {
      title: "Luxury travel and high-end accommodations",
      para: `Luxury travel and high-end accommodations are becoming increasingly popular among travelers who are looking for a luxurious and comfortable experience. With the rise of boutique hotels, high-end resorts, and luxury cruises, it’s easier than ever to indulge in a luxurious travel experience. In this article, we’ll explore some of the most luxurious travel options and high-end accommodations in the world.
         `,
      summary: `In conclusion, luxury travel and high-end accommodations are becoming more popular among travelers seeking a luxurious and comfortable experience. From private jets and luxury hotels to private villas and glamping, there are a wide variety of options for those seeking to indulge in a luxurious travel experience. So, if you’re looking for a vacation that is truly extraordinary, consider one of these luxurious travel options and accommodations for a truly unforgettable experience.`,
      cont: "luxury",
      bannerImage: Luxury1Banner,
      cat: "luxury-travel",
    },
    [
      {
        title: "Private Jets",
        para: `Flying on a private jet is the ultimate way to travel in luxury and style. Private jets offer privacy, comfort, and convenience that commercial airlines cannot match. With personalized services and amenities, you can travel in comfort and style without the hassle of long security lines or delayed flights.
        Private jet companies like NetJets and VistaJet offer fractional ownership programs and on-demand charter services that allow travelers to access private jets easily. NetJets offers a fleet of over 700 aircraft, including private jets, turboprops, and helicopters, with access to over 5,000 airports worldwide. VistaJet offers a global private jet service that allows you to fly to over 1,900 airports worldwide `,
        reverse: true,
        img: Private1,
      },
      {
        title: "Luxury Hotels",
        para: `Luxury hotels offer a level of service and comfort that sets them apart from traditional hotels. With personalized services, fine dining, and opulent amenities, luxury hotels like the Four Seasons, Mandarin Oriental, and Ritz Carlton are a traveler’s dream come true.
        These hotels offer spacious rooms and suites, private butler services, and spa treatments, making them perfect for a relaxing vacation. The Four Seasons, for example, offers a range of luxurious accommodations, including villas and suites, that come with breathtaking views and world-class amenities.
        The Mandarin Oriental is renowned for its exceptional service and attention to detail. The hotel chain has locations in some of the world’s most popular destinations, including New York, Tokyo, and Bangkok. The Ritz Carlton is another luxury hotel brand that is known for its world-class service and accommodations. The Ritz Carlton hotels offer a range of luxurious accommodations, including suites, villas, and penthouses.
        `,
        reverse: false,
        img: Hotel2,
      },
      {
        title: "Private Villas",
        para: `Private villas are a great option for travelers seeking privacy and exclusivity. With luxurious amenities like private pools, personal chefs, and 24-hour butler service, private villas offer a level of comfort that is hard to match.
         Companies like Luxury Retreats and Airbnb Luxe offer a wide range of private villas in popular destinations worldwide. Luxury Retreats offers a curated collection of luxury villas, estates, and chalets in some of the world’s most sought-after destinations. Airbnb Luxe is a new offering from Airbnb that offers a selection of luxury homes and villas with personalized services and amenities.
         `,
        img: Villas3,
        reverse: true,
      },
      {
        title: "Luxury Cruises",
        para: `Luxury cruises offer a unique way to travel in comfort and style. With world-class dining, entertainment, and personalized service, luxury cruises like Seabourn, Regent Seven Seas, and Crystal Cruises offer travelers the ultimate experience at sea.
          These cruises feature luxurious accommodations, fine dining, and exciting shore excursions, making them perfect for a luxurious and relaxing vacation. Seabourn, for example, offers a range of luxurious cruises to destinations like the Mediterranean, Caribbean, and Asia. Regent Seven Seas offers all-inclusive cruises to destinations like Alaska, the Mediterranean, and the Caribbean. Crystal Cruises is known for its exceptional service and luxurious accommodations, with options like suites with private balconies and butler service.
          `,
        img: Cruise4,
        reverse: false,
      },
      {
        title: "Glamping",
        para: `Glamping, or glamorous camping, is a trend that has taken the travel industry by storm. With luxury tents and treehouses equipped with all the amenities of a five-star hotel, glamping allows travelers to enjoy the great outdoors without sacrificing comfort.
          Companies like Aman, Four Seasons, and Six Senses offer glamping experiences in some of the world's most beautiful destinations.
          `,
        img: Glamping5,
        reverse: true,
      },
    ],
  ],
  "best-solo-traveling-places-for-female": [
    {
      title: "Best solo traveling places for female",
      para: `Traveling solo can be a liberating experience, and for female travelers, it can also be an empowering one. With the rise of women-only travel groups and female-focused tours, solo female travel has become more accessible and safer than ever before. In this article, we’ll explore some of the best solo travel destinations for female travelers.`,
      summary: `This article explores the best solo traveling places for women. Solo travel can be a daunting but empowering experience for women, and this article provides detailed descriptions of destinations that are safe, welcoming, and enjoyable for solo female travelers. The article covers a range of locations, from bustling cities to serene natural settings, and offers tips and advice for women traveling alone. By highlighting some of the best places for solo female travel, this article aims to encourage women to embark on their own solo travel adventures with confidence and excitement.`,
      cont: "solo-travel",
      bannerImage: Solo2Banner,
      cat: "solo-travel",
    },
    [
      {
        title: "Japan",
        para: `Japan is a safe and welcoming destination for solo female travelers. With a low crime rate and a culture that values hospitality and respect, Japan is an excellent destination for those looking to explore a new culture on their own.`,
        reverse: true,
        img: Japan1,
      },
      {
        title: "Iceland",
        para: `Iceland is a nature lover’s paradise and an ideal destination for solo female travelers. With stunning natural landscapes like glaciers, hot springs, and waterfalls, Iceland offers a unique and unforgettable travel experience.
        Iceland is also one of the safest countries in the world, making it an excellent destination for solo female travelers. Visitors can explore the country’s natural wonders on their own, from the famous Blue Lagoon to the Northern Lights.       `,
        reverse: false,
        img: Iceland2,
      },
      {
        title: "New Zealand",
        para: `New Zealand is a beautiful and welcoming country, perfect for solo female travelers. With its breathtaking natural landscapes, friendly locals, and vibrant cities, New Zealand offers a range of experiences for solo travelers.

        From hiking in the mountains to exploring the beaches and cities, New Zealand has something for everyone. The country is also known for its safety and low crime rates, making it an excellent destination for solo female travelers
        `,
        reverse: true,
        img: New3,
      },
      {
        title: "Costa Rica",
        para: `Costa Rica is a safe and welcoming destination for solo female travelers. With its stunning natural beauty, rich culture, and diverse wildlife, Costa Rica offers a unique and unforgettable travel experience.
        Visitors can explore the country’s beautiful beaches, hike through the rainforest, or try their hand at surfing. Costa Rica is also known for its eco-tourism and sustainability efforts, making it an ideal destination for those who want to travel responsibly. `,
        reverse: false,
        img: Coasta4,
      },
      {
        title: "Portugal",
        para: `Portugal is a beautiful and welcoming country, perfect for solo female travelers. With its rich history, beautiful architecture, and delicious cuisine, Portugal offers a range of experiences for solo travelers.
        Visitors can explore the historic cities of Lisbon and Porto, relax on the beautiful beaches of the Algarve, or sample the country’s famous port wine in the Douro Valley. Portugal is also known for its safety and low crime rates, making it an excellent destination for solo female travelers.
        `,
        reverse: true,
        img: Portugal5,
      },
      {
        title: "Bali, Indonesia",
        para: `Bali, Indonesia, is a popular destination for solo female travelers. With its beautiful beaches, stunning temples, and vibrant culture, Bali offers a unique and unforgettable travel experience.
        Visitors can explore the island’s beautiful beaches, hike through the lush forests, or relax in the local spas. Bali is also known for its safety and friendly locals, making it an excellent destination for solo female travelers.
        `,
        reverse: false,
        img: Bali6,
      },
      {
        title: "Canada",
        para: `Canada is a safe and welcoming destination for solo female travelers. With its stunning natural landscapes, vibrant cities, and friendly locals, Canada offers a range of experiences for solo travelers.
          Visitors can explore the country’s beautiful national parks, like Banff and Jasper, or enjoy the cosmopolitan cities of Toronto and Vancouver. Canada is also known for its safety and low crime rates, making it an excellent destination for solo female travelers.`,
        reverse: true,
        img: Canada7,
      },
      {
        title: "Scotland",
        para: `Scotland is a beautiful and welcoming country, perfect for solo female travelers. With its stunning natural beauty, rich history, and vibrant cities, Scotland offers a range of experiences for solo travelers.`,
        reverse: false,
        img: Scotland8,
      },
    ],
  ],
  "best-beaches-around-the-world-sun-sand-and-sea": [
    {
      title: "Best Beaches Around the World: Sun, Sand, and Sea",
      para: `Beaches are some of the most popular destinations for travelers around the world. From pristine white sand beaches to rocky shorelines, there are countless beaches to explore and enjoy. In this article, we'll take a look at some of the best beaches around the world.`,
      summary: `In conclusion, these are just a few of the many beautiful beaches around the world. Whether you're looking for a secluded paradise or a bustling destination, there's a beach out there for everyone to enjoy. So pack your sunscreen and explore the stunning beaches that the world has to offer.`,
      bannerImage: Beach1Banner,
      cont: "beach",
      cat: "beach",
    },
    [
      {
        title: "Whitehaven Beach, Australia",
        para: `Located in the Whitsunday Islands, Whitehaven Beach is known for its bright white sand that is made up of 98% pure silica. The beach stretches for 7 kilometers and offers stunning views of the turquoise waters of the Coral Sea. With its secluded location and clear waters, Whitehaven Beach is a popular destination for snorkeling and scuba diving.
       `,
        reverse: true,
        img: White1,
      },
      {
        title: "Anse Source d'Argent, Seychelles",
        para: `Anse Source d'Argent is one of the most photographed beaches in the world, with its unique rock formations and crystal-clear waters. The beach is located on La Digue Island and is surrounded by granite boulders that have been shaped by the elements over time. The shallow waters and gentle currents make it a perfect spot for swimming and snorkeling.`,
        reverse: false,
        img: Anse2,
      },
      {
        title: "Navagio Beach, Greece",
        para: `Navagio Beach, also known as Shipwreck Beach, is located on the island of Zakynthos and is famous for its crystal-clear waters and the wreckage of a smuggler's ship that washed ashore in 1980. The beach is surrounded by steep cliffs and can only be accessed by boat, which adds to its secluded and picturesque setting.`,
        reverse: true,
        img: Navagio3,
      },
      {
        title: "Pink Sands Beach, Bahamas ",
        para: `Located on Harbour Island, Pink Sands Beach is named for its unique pink sand that is caused by tiny red organisms that live in the coral reefs surrounding the island. The beach is three miles long and offers clear blue waters, making it a popular destination for swimming, snorkeling, and kayaking.`,
        reverse: false,
        img: Pink4,
      },
      {
        title: "Matira Beach, French Polynesia",
        para: `Matira Beach is located on the island of Bora Bora and is known for its crystal-clear waters and soft white sand. The beach is surrounded by lush vegetation and offers stunning views of Mount Otemanu in the distance. With its calm waters, Matira Beach is a popular destination for swimming, snorkeling, and kayaking.`,
        reverse: true,
        img: Matira5,
      },
      {
        title: "Tulum Beach, Mexico",
        para: `Located on the Yucatan Peninsula, Tulum Beach is known for its white sand beaches and turquoise waters. The beach is surrounded by palm trees and ancient Mayan ruins, making it a unique and picturesque destination. With its calm waters and soft sand, Tulum Beach is a popular spot for swimming, sunbathing, and relaxing.`,
        reverse: false,
        img: Tulum6,
      },
      {
        title: "Lanikai Beach, Hawaii",
        para: `Lanikai Beach is located on the island of Oahu and is known for its clear blue waters and soft white sand. The beach is surrounded by palm trees and offers stunning views of the Mokulua Islands in the distance. With its calm waters and beautiful scenery, Lanikai Beach is a popular destination for swimming, snorkeling, and kayaking.`,
        reverse: true,
        img: Lanikai7,
      },
      {
        title: "Ipanema Beach, Brazil",
        para: `Located in Rio de Janeiro, Ipanema Beach is known for its vibrant culture and beautiful coastline. The beach is surrounded by towering mountains and offers stunning views of the city skyline. With its lively atmosphere and warm waters, Ipanema Beach is a popular spot for swimming, sunbathing, and people-watching.`,
        reverse: false,
        img: Scotland8,
      },
    ],
  ],
  "traveling-solo-tips-and-tricks-for-a-safe-and-memorable-trip": [
    {
      title: "Traveling Solo: Tips and Tricks for a Safe and Memorable Trip",
      para: `Traveling solo can be a truly rewarding experience. It offers an opportunity to explore new places on your own terms, make new friends, and create memories that will last a lifetime. However, it can also be daunting and requires some careful planning and preparation. In this article, we will provide some tips and tricks for a safe and memorable solo trip.
  `,
      line: `Traveling solo can be an incredibly exciting and adventurous experience. It allows you to explore new places on your own terms, take in new sights and cultures, and create memories that will last a lifetime. Solo travel also provides an opportunity for personal growth and self-discovery. It can be a chance to step outside your comfort zone, push your boundaries, and challenge yourself in new ways. While traveling solo can be daunting, with some careful planning and preparation, it can also be a safe and rewarding experience. In this article, we will explore some tips and tricks for solo travel to ensure a safe and memorable trip.`,
      summary: `In conclusion, traveling solo can be an exciting and rewarding experience, but it requires careful planning and preparation. By following these tips and tricks, you can ensure a safe and memorable trip. Remember to research your destination, book your accommodation in advance, keep your valuables safe, stay connected, trust your instincts, make new friends, learn basic phrases, and be flexible. Bon, voyage!`,
      bannerImage: SoloBanner1,
      cont: "solo-travel",
      cat: "solo-travel",
    },
    [
      {
        title: "Research Your Destination",
        para: `Before embarking on any solo trip, it is essential to research your destination. Find out about the culture, customs, and laws of the country you plan to visit. Research the local customs and traditions, dress codes, and behaviors that may be considered disrespectful. Learn about the public transportation system and the areas that are safe for tourists`,
        reverse: true,
        img: Reasearch1,
      },
      {
        title: "Book Your Accommodation in Advance",
        para: `One of the most crucial aspects of solo travel is to ensure your accommodation is safe and secure. Book your accommodation in advance and check reviews from other travelers. Choose a hotel or hostel located in a safe and well-lit area. Always keep your accommodation address and phone number with you.`,
        reverse: false,
        img: Book2,
      },
      {
        title: "Keep Your Valuables Safe       ",
        para: `Keeping your valuables safe is essential when traveling alone. Use a money belt or a neck pouch to keep your cash, passport, and important documents close to your body. Avoid carrying large amounts of cash or valuable items with you.`,
        reverse: true,
        img: Keep3,
      },
      {
        title: "Stay Connected ",
        para: `Staying connected with your loved ones back home is essential when traveling solo. Inform your family or friends of your itinerary, including the places you plan to visit and your accommodation details. Use social media to keep in touch and post updates about your trip.`,
        reverse: false,
        img: Stay4,
      },
      {
        title: "Trust Your Instincts",
        para: `Trust your instincts when traveling solo. If something does not feel right, it probably isn't. Listen to your gut feeling and avoid situations that make you uncomfortable. Be aware of your surroundings and keep an eye on your belongings.`,
        reverse: true,
        img: Trust5,
      },
      {
        title: "Make New Friends",
        para: `One of the best things about traveling alone is the opportunity to meet new people. Join group tours, attend local events, or take part in activities that interest you. Strike up conversations with other travelers, and you might make some lifelong friends.`,
        img: Make6,
        reverse: false,
      },
      {
        title: "Learn Basic Phrases",
        para: `Learning basic phrases in the local language can be helpful when traveling solo. It can help you communicate with locals and make your trip more enjoyable. Learn how to say hello, thank you, and ask for help.`,
        reverse: true,
        img: Learn7,
      },
      {
        title: "Be Flexible",
        para: `Be flexible when traveling solo. Plans may change, and unexpected situations may arise. Be open to new experiences and be willing to adapt to changing circumstances.`,
        reverse: false,
        img: Be8,
      },
    ],
  ],
  "family-friendly-vacations-destinations-that-offer-something-for-everyone": [
    {
      title:
        "Family-Friendly Vacations: Destinations That Offer Something for Everyone",
      summary: `In conclusion, there are plenty of family-friendly vacation destinations that offer something for everyone. Whether you're looking for theme parks, natural beauty, or cultural experiences, there's a destination out there that will suit your family's needs. Consider one of these ten top family vacation destinations for your next family adventure.`,
      line: `Are you looking for a family-friendly vacation destination that offers something for everyone? There are plenty of options to choose from, both in the United States and abroad. In this blog post, we'll explore ten top family vacation destinations and what makes them so great for families.`,
      bannerImage: FaimilyBanner,
      cat: "asia",
      cont: "asia",
    },
    [
      {
        title: "Orlando, Florida",
        para: `First on our list is Orlando, Florida. With Walt Disney World, Universal Studios, and SeaWorld all located in the city, there's no shortage of family-friendly activities to enjoy. From thrilling roller coasters to character meet-and-greets, Orlando offers a magical experience for all ages.`,
        img: Orlando1,
        reverse: true,
      },
      {
        title: "San Diego, California",
        para: `Next up is San Diego, California. With its beautiful beaches, world-famous zoo, and amusement parks like Legoland and SeaWorld, San Diego is a top family destination. The city also offers a variety of cultural and educational experiences, such as visiting the USS Midway Museum or the Cabrillo National Monument.`,
        img: San2,
        reverse: false,
      },
      {
        title: "Washington, D.C.",
        para: `Washington, D.C. is another great destination for families. The nation's capital is rich in history and culture, and offers plenty of free attractions for families to enjoy, such as the Smithsonian museums. The city is also home to iconic landmarks like the White House, the Lincoln Memorial, and the Washington Monument.`,
        img: Washington3,
        reverse: true,
      },
      {
        title: "Yellowstone National Park, USA",
        para: `For families who love the great outdoors, Yellowstone National Park is a must-visit destination. One of America's most iconic national parks, Yellowstone offers breathtaking natural beauty and a variety of outdoor activities for families to enjoy, such as hiking, fishing, and wildlife watching.`,
        img: Yellostone4,
        reverse: false,
      },
      {
        title: "Niagara Falls, Ontario",
        para: `Niagara Falls, Ontario is another family-friendly destination that offers a variety of attractions and activities for families to enjoy. From boat rides to zip lining, there's plenty to see and do at these stunning waterfalls. Families can also explore the nearby town of Niagara-on-the-Lake, which offers charming shops, restaurants, and wineries.`,
        img: Niagara5,
        reverse: true,
      },
      {
        title: "London, England",
        para: `If you're looking to travel abroad, London, England is a great family-friendly destination. The city is full of history and culture, with attractions such as the Tower of London, the London Eye, and Harry Potter tours. London also offers a variety of parks and outdoor spaces, such as Hyde Park and Regent's Park.`,
        img: London6,
        reverse: false,
      },
      {
        title: "Tokyo, Japan",
        para: `Tokyo, Japan is another international destination that offers plenty of family-friendly activities. With its unique blend of modern technology and traditional culture, Tokyo offers something for everyone. Families can visit theme parks like Tokyo Disneyland and DisneySea, take a sushi-making class, or explore the city's many parks and gardens.`,
        img: Tokyo7,
        reverse: true,
      },
      {
        title: "Maui, Hawaii, USA",
        para: `Maui, Hawaii is a top family destination that offers stunning beaches, crystal-clear waters, and a variety of outdoor activities such as snorkeling and surfing. The island also offers plenty of cultural experiences, such as attending a traditional luau or visiting the Maui Ocean Center..`,
        img: Maui8,
        reverse: false,
      },

      {
        title: "Costa Rica, USA",
        para: `Costa Rica is another great destination for families who love the great outdoors. With its diverse wildlife and natural beauty, Costa Rica offers a variety of family-friendly activities such as hiking, zip lining, and wildlife watching. Families can also explore the country's many beaches and national parks.`,
        img: Coasta9,
        reverse: true,
      },
      {
        title: "Cape Town, South Africa",
        para: `Last but not least is Cape Town, South Africa. With its stunning natural scenery, world-class beaches, and unique cultural experiences, Cape Town is a great family-friendly destination. Families can visit iconic landmarks like Table Mountain and Robben Island, or spend a day at the Boulders Beach Penguin Colony..`,
        img: Cap10,
        reverse: false,
      },
    ],
  ],
  "exploring-the-best-beaches-in-the-world-from-bali-to-the-bahamas": [
    {
      title:
        "Exploring the Best Beaches in the World: From Bali to the Bahamas",
      para: `If you're a beach lover, you're in luck because the world is full of beautiful beaches waiting to be explored. From the white sand beaches of Bali to the crystal-clear waters of the Bahamas, we've put together a list of some of the best beaches in the world.`,
      line: ``,
      summary: `In conclusion, the world is full of beautiful beaches waiting to be explored. From Kuta Beach in Bali to Anse Source d'Argent in the Seychelles, these beaches offer stunning scenery and a chance to relax and unwind. So, pack your bags, grab your sunscreen, and get ready for an unforgettable beach vacation.`,
      bannerImage: Beach2Banner,
      cont: "beach",
      cat: "beach",
    },
    [
      {
        title: "Kuta Beach, Bali",
        para: `Kuta Beach is one of the most popular beaches in Bali and is known for its golden sand and clear blue waters. It's an ideal destination for surfers and sunbathers alike. The beach also has plenty of restaurants and bars where you can relax and enjoy the view.`,
        reverse: true,
        img: Kuta1,
      },
      {
        title: "Whitehaven Beach, Australia",
        para: `Whitehaven Beach is located in the Whitsunday Islands of Australia and is known for its pure white silica sand. The crystal-clear waters of the beach offer a great opportunity for snorkelling and swimming. The beach is also surrounded by lush tropical vegetation, making it a beautiful and serene destination.`,
        reverse: false,
        img: White2,
      },
      {
        title: "Pink Sands Beach, Bahamas",
        para: `Pink Sands Beach is located on Harbour Island in the Bahamas and is known for its unique pink sand. The pink colour of the sand is caused by tiny marine creatures that live in the coral reefs offshore. The beach, combined with the crystal-clear waters, creates a stunning and unique beach experience.`,
        reverse: true,
        img: Pink3,
      },
      {
        title: "Nusa Dua Beach, Bali",
        para: `Nusa Dua Beach is located on the southeastern coast of Bali and offers a wide range of activities, including swimming, surfing, and parasailing. The beach is also home to several luxury resorts, making it a popular destination for those looking for a more upscale beach experience.`,
        reverse: false,
        img: Nusa4,
      },
      {
        title: "The Baths, British Virgin Islands",
        para: `The Baths are located on the island of Virgin Gorda in the British Virgin Islands. The beach is known for its giant granite boulders that create natural pools and grottos for visitors to explore. The crystal-clear waters offer a great opportunity for snorkelling and swimming.`,
        reverse: true,
        img: Bath5,
      },
      {
        title: "Navagio Beach, Greece",
        para: `Navagio Beach, also known as Shipwreck Beach, is located on the island of Zakynthos in Greece. The beach is named after a shipwreck that can still be seen on the beach. The crystal-clear waters and white sand make it a popular destination for tourists visiting Greece.`,
        reverse: false,
        img: Navach6,
      },
      {
        title: "Cable Beach, Bahamas",
        para: `Cable Beach is located on the island of New Providence in the Bahamas and is known for its soft white sand and calm waters. The beach is an ideal destination for families with young children as there are plenty of activities nearby, including water sports and beachside restaurants and bars.`,
        reverse: true,
        img: Cable7,
      },

      {
        title: "Anse Source d'Argent, Seychelles",
        para: `Anse Source d'Argent is located on the island of La Digue in the Seychelles. The beach is known for its unique rock formations and crystal-clear waters. The beach also offers great opportunities for snorkelling and swimming.`,
        reverse: false,
        img: Anse8,
      },
    ],
  ],
  "travel-photography-tips-and-tricks-for-capturing-our-adventures": [
    {
      title:
        "Travel Photography: Tips and Tricks for Capturing Your Adventures",
      line: ``,
      para: `Travel photography is a wonderful way to capture the memories and experiences of your adventures. Whether you are exploring a new city, hiking through a beautiful landscape, or discovering a different culture, photography can help you preserve those moments forever. It's not always simple, though, to capture stunning vacation images. In this blog post, we will share some tips and tricks to help you improve your travel photography and capture the essence of your adventures.`,
      summary: `Travel photography is a way to capture the essence of your adventures and to share your experiences with others. To take great travel photos, it's important to research your destination, pack the right gear, learn how to use your camera, plan your shots, pay attention to lighting, and use compositional techniques to create dynamic shots. Be patient and keep practising to improve your skills and capture amazing travel photos.`,
      bannerImage: Solo3Travel,
      cont: "solo-travel",
      cat: "solo-travel",
    },
    [
      {
        title: "Plan Ahead",
        para: `The first step to great travel photography is to plan ahead. Do some research on the destination you are visiting and make a list of the places and things you want to photograph. This will help you make the most of your time and ensure that you capture the images you want.`,
        img: Plan1,
        reverse: true,
      },
      {
        title: "Pack the right gear",
        para: `The gear you choose to bring on your trip can make a big difference in the quality of your photos. Make sure to pack a camera that is appropriate for your needs and the type of photography you want to do. A good camera bag and a sturdy tripod can also be useful, especially if you plan to shoot in low light or at night.`,
        img: Pack2,
        reverse: false,
      },

      {
        title: "Understand light",
        para: `Understanding how to work with light is crucial for good travel photography. Pay attention to the direction and quality of light, as well as the time of day. Sunrise and sunset can offer some of the best light for photography, so plan your shoots accordingly. Also, consider using reflectors or diffusers to control the light and create more dynamic images.`,

        img: Understand3,
        reverse: true,
      },
      {
        title: "Find unique perspectives",
        para: `Finding unique perspectives can help your photos stand out and capture the essence of a place. Experiment with different angles and viewpoints, and don't be afraid to get close to your subject. Shooting from a low angle can make your subject appear more imposing, while shooting from above can give a different perspective on a landscape.`,
        img: Find4,
        reverse: false,
      },
      {
        title: "Tell a story",
        para: `Travel photography is not just about capturing pretty pictures; it is also about telling a story. Try to capture the essence of a place or a moment in your photos, and use them to tell a story about your adventures. Consider including people, landmarks, and other elements that will help convey the sense of place and time..`,
        img: Tell5,
        reverse: true,
      },
      {
        title: "Experiment with different settings",
        para: `Experimenting with different camera settings can help you achieve different effects in your photos. Consider using a shallow depth of field to blur the background and make your subject stand out, or a slow shutter speed to create motion blur in your images. Don't be afraid to try new things and experiment with your camera..`,
        img: Experiment6,
        reverse: false,
      },
      {
        title: "Edit your photos",
        para: `Editing your photos can help you enhance the colors, contrast, and other elements of your images. Consider using photo editing software like Adobe Lightroom or Photoshop to adjust the exposure, color balance, and other settings of your photos. Remember, however, that editing should be used to enhance your photos, not to completely change them.`,
        img: Edit7,
        reverse: true,
      },
      {
        title: "Be respectful",
        para: `When traveling, it is important to be respectful of the local culture and the people you encounter. Ask for permission before taking photos of people, and be mindful of their privacy and feelings. Avoid taking photos of sensitive or sacred places, and follow local laws and customs regarding photography.`,
        img: Respect8,
        reverse: false,
      },
      {
        title: "Have fun",
        para: `Finally, remember to have fun and enjoy the experience of travel photography. Don't worry too much about getting the perfect shot; instead, focus on capturing the essence of your adventures and creating memories that will last a lifetime. Travel photography is a wonderful way to explore the world and capture its beauty, so make the most of it and enjoy the ride..`,
        img: HaveFun9,
        reverse: true,
      },
    ],
  ],
  "luxury-travel-top-destinations-for-a-splurge-worthy-experience": [
    {
      title: "Luxury Travel: Top Destinations for a Splurge-Worthy Experience",
      para: `Luxury travel has always been a popular choice for people looking for a high-end vacation experience. Whether it's the luxurious accommodations, gourmet cuisine, or indulgent spa treatments, luxury travel offers a range of splurge-worthy experiences that can make any trip unforgettable. In this blog post, we will explore some of the top destinations for a luxury travel experience, where you can indulge in opulence and pampering.`,
      line: ``,
      bannerImage: Luxurybanner,
      cont: "luxury",
      cat: "luxury",
    },
    [
      {
        title: "Paris, France",
        para: `Paris is known for its romantic charm, fashion, and sophistication, making it a top destination for luxury travel. It's the perfect place to indulge in high-end shopping, gourmet cuisine, and world-class cultural experiences. The city is home to some of the world's most iconic luxury hotels, such as the Ritz Paris, which has been the epitome of luxury since 1898. It boasts a range of lavish amenities, including a Michelin-starred restaurant and an opulent spa. A trip to Paris is not complete without a visit to the Eiffel Tower, a stroll down the Champs-Elysées, or a visit to the Louvre Museum.`,
        img: Paris1,
        reverse: true,
      },
      {
        title: "Tokyo, Japan",
        para: `Tokyo is a city that combines traditional Japanese culture with modern luxury. The city is known for its innovative design, high-end shopping, and excellent cuisine. Tokyo has some of the most luxurious hotels in the world, such as the Aman Tokyo, which offers a range of lavish amenities, including a spa, fitness centre, and indoor swimming pool. The city is also home to some of the world's best sushi restaurants, offering a unique culinary experience. Other must-see attractions include the Tokyo Tower, the Imperial Palace, and the famous Shibuya Crossing.`,
        img: Tokyo2,
        reverse: false,
      },

      {
        title: "Maldives",
        para: `The Maldives is a tropical paradise and a top destination for luxury travel. It's known for its crystal-clear waters, pristine beaches, and luxurious accommodations. The Maldives is home to some of the world's most exclusive resorts, such as the Soneva Fushi, which offers a range of lavish amenities, including a private beach, spa, and gourmet dining experiences. The Maldives is also a popular destination for water sports, such as scuba diving and snorkelling, and offers an opportunity to see an abundance of marine life, including manta rays and whale sharks.`,
        img: Maldives3,
        reverse: false,
      },
      {
        title: "New York City, USA",
        para: `New York City is a bustling metropolis that offers a range of luxury experiences, from high-end shopping to gourmet cuisine. The city is home to some of the world's most exclusive hotels, such as the Four Seasons New York, which boasts stunning views of the city skyline and a range of lavish amenities, including a spa and fitness centre. New York is also known for its Broadway shows, museums, and iconic landmarks, such as the Statue of Liberty and the Empire State Building..`,
        img: Network4,
        reverse: true,
      },
      {
        title: "Dubai, UAE",
        para: `Dubai is a city of opulence, known for its extravagant architecture, high-end shopping, and luxurious accommodations. The city is home to some of the world's most luxurious hotels, such as the Burj Al Arab, which boasts a range of lavish amenities, including a private beach, spa, and helipad. Dubai is also home to the world's tallest building, the Burj Khalifa, and offers a range of unique experiences, such as indoor skiing and desert safaris.`,
        img: Dubai5,
        reverse: false,
      },
    ],
  ],
  "hidden-gems-in-europe-you-need-to-visit": [
    {
      title: " Hidden Gems in Europe You Need to Visit",
      para: `Europe is filled with incredible destinations that are well-known and popular among tourists. But if you're looking for something a bit more off-the-beaten-path, there are still plenty of hidden gems waiting to be discovered. From picturesque villages to breathtaking natural landscapes, here are 10 hidden gems in Europe that you need to visit.`,
      line: ``,
      bannerImage: HiddenBanner,
      cont: "europe",
      cat: "",
    },
    [
      {
        title: "Matera, Italy",
        para: `Located in the southern region of Basilicata, Matera is one of Italy's most unique and fascinating cities. Known as the "Sassi" or "stone city," Matera is famous for its ancient cave dwellings that have been inhabited for thousands of years. These cave homes were carved into the soft tufa rock and were once home to some of Italy's poorest families. Today, many of these caves have been transformed into charming hotels, restaurants, and shops.`,
        reverse: false,
        img: Matera1,
      },
      {
        title: "Kotor, Montenegro",
        para: `Tucked away in the Balkans, the small town of Kotor is a true hidden gem. Surrounded by towering mountains and situated on the banks of the stunning Bay of Kotor, this town is a must-visit for nature lovers and history buffs alike. The Old Town of Kotor is a UNESCO World Heritage Site and is filled with winding alleyways, beautiful churches, and historic landmarks.`,
        reverse: true,
        img: Kotor2,
      },
      {
        title: "Rila Monastery, Bulgaria",
        para: `Nestled in the heart of the Rila Mountains in Bulgaria, the Rila Monastery is a true hidden gem. Founded in the 10th century, this stunning monastery is a masterpiece of Bulgarian Renaissance architecture. The monastery's main church is filled with incredible frescoes and murals, and the surrounding mountains offer plenty of opportunities for hiking and exploring..`,
        reverse: false,
        img: Rila3,
      },

      {
        title: "Hallstatt, Austria",
        para: `Located in the Salzkammergut region of Austria, the picturesque village of Hallstatt is a true hidden gem. With its charming alpine architecture and stunning lakeside location, Hallstatt is a popular destination for outdoor enthusiasts and history buffs alike. The village is home to some of Austria's oldest salt mines, which have been in operation since the Bronze Age.`,
        reverse: true,
        img: Austria4,
      },
      {
        title: "Sintra, Portugal",
        para: `Just a short drive from Lisbon, the town of Sintra is a true hidden gem. Known for its fairy-tale castles and stunning natural landscapes, Sintra is a must-visit destination for anyone travelling to Portugal. The town is home to several historic landmarks, including the stunning Pena Palace and the Moorish Castle..`,
        reverse: false,
        img: Sintra5,
      },
      {
        title: "Plitvice Lakes National Park, Croatia",
        para: `Located in central Croatia, the Plitvice Lakes National Park is a true hidden gem. This stunning park is home to a series of interconnected lakes and waterfalls that are renowned for their incredible natural beauty. Visitors can hike along the park's numerous trails, or take a boat ride across the park's tranquil waters.`,
        reverse: true,
        img: Plitvice6,
      },
      {
        title: "Guimaraes, Portugal",
        para: `Located in northern Portugal, the city of Guimaraes is a true hidden gem. Known as the "birthplace of Portugal," Guimaraes is filled with historic landmarks and charming streets. The city's castle is a must-visit attraction, and the surrounding countryside is home to some of Portugal's most beautiful natural landscapes.`,
        reverse: false,
        img: Guimaraes7,
      },
      {
        title: "Ljubljana, Slovenia",
        para: `Ljubljana is the capital of Slovenia and is located on the banks of the Ljubljanica River. It is a small and charming city that is famous for its well-preserved baroque architecture, its vibrant cultural scene, and its delicious food. Visitors can explore the city's narrow streets, visit its many museums and galleries, or take a boat tour on the river`,
        reverse: true,
        img: Sloveni8,
      },
      {
        title: "Cinque Terre, Italy",
        para: `Cinque Terre is a picturesque stretch of coastline on the Italian Riviera. It consists of five small villages that are nestled in the hillsides overlooking the Mediterranean Sea. Each of the villages has its own unique character, but they are all linked by a network of hiking trails that offer spectacular views of the coastline. The villages are also famous for their colourful houses, narrow streets, and delicious seafood.`,
        reverse: false,
        img: Cinque9,
      },
      {
        title: "Ribe, Denmark",
        para: `Ribe is Denmark's oldest town and is located on the west coast of Jutland. It is famous for its well-preserved mediaeval architecture, including the 12th-century Ribe Cathedral and the Viking Museum. Visitors can also explore the town's narrow streets, visit the local markets, or take a boat tour on the Ribe River.`,
        reverse: true,
        img: Ribe10,
      },
    ],
  ],
  "best-places-to-visit-in-2023": [
    {
      title: "BEST PLACES TO VISIT IN  2023!!! ",
      para: `Travel is a blessing that provides us with a wealth of advantages. It gives us the opportunity to experience breathtaking views and immerse ourselves in cultures that are very different from our own. It challenges us to go beyond our comfort zones and stirs up a variety of feelings in us. We come back with a wealth of new information, personal development, and fascinating stories to tell. Let Wonders Travels help you with the planning of your 2023 travels. Here is a sneak peek at what to expect...`,
      line: `Let Wonder Travels' experts assist you in discovering your ideal adventure.`,
      summary: ``,
      cont: "asia",
      cat: "asia",
      bannerImage: Banner2023,
    },
    [
      {
        title: "Trek to Everest Base Camp",
        para: `The Trek to Everest Base Camp takes place in the stunning Himalayan region of Nepal, where you will embark on a once-in-a-lifetime adventure through breathtaking landscapes and high-altitude mountain passes. As you make your way towards the base of Mount Everest, the highest mountain in the world, you will have the opportunity to immerse yourself in the rich Sherpa culture and witness firsthand the unique traditions of the Himalayan people.`,
        reverse: false,
        img: Everest1,
      },
      {
        title: "Explore the Amazon Rainforest -",
        para: `The Explore the Amazon Rainforest trip is set in the heart of South America, where you'll journey deep into the lush and diverse ecosystem of the Amazon Rainforest. Here, you will discover an incredible array of biodiversity, with the opportunity to spot exotic wildlife such as monkeys, sloths, and colorful birds. You'll also learn about the indigenous communities that call this region home and gain an appreciation for their unique way of life.`,
        reverse: true,
        img: Amazon2,
      },
      {
        title: "Cycle the Silk Road ",
        para: `The Cycle the Silk Road trip takes you through the rugged and remote landscapes of Central Asia, following in the footsteps of ancient traders who once journeyed along this historic route. Beginning in the fascinating city of Tashkent, Uzbekistan, you'll cycle through rugged mountains, arid deserts, and traditional villages, taking in the stunning scenery and unique cultural experiences along the way. `,
        reverse: false,
        img: Cycle3,
      },
      {
        title: "Escape the chaos and find tranquility in these peaceful havens",
        para: `If you're looking to make lasting memories, seek out the serenity of peaceful places. Studies suggest that experiences of tranquility and calm can be just as powerful as those that give you an adrenaline rush. Take a break from the hustle and bustle of daily life and let the soothing sights and sounds of nature wash over you. These moments of peace and quiet will stay with you long after you've left, and provide a welcome respite from the stresses of everyday life.`,
        reverse: true,
        img: Peace4,
      },
      {
        title: "The Ultimate Destination for Relaxation and Spiritual Renewal",
        para: `Ubud is a peaceful town in Bali, Indonesia, known for its beautiful temples, rice terraces, and lush rainforests. It's the perfect place to relax and unwind, with plenty of yoga studios, meditation centers, and spas.`,
        reverse: false,
        img: Ubidbali5,
      },
      {
        title: "Discover the Timeless Beauty and Peaceful Ambiance",
        para: `Kyoto is a city in Japan that's known for its stunning temples, gardens, and historic sites. The city has a tranquil atmosphere, and visitors can explore the many shrines and temples while enjoying the peaceful surroundings.`,
        reverse: true,
        img: Japan6,
      },
      {
        title: "Discover the Untamed Beauty of Tofino",
        para: `Tofino is a small town on the west coast of Vancouver Island, Canada, known for its rugged coastline, beautiful beaches, and abundant wildlife. Visitors can enjoy hiking, surfing, and kayaking while taking in the stunning natural scenery.`,
        reverse: false,
        img: Tofino7,
      },
      {
        title: "Feast your eyes and satisfy your cravings",
        para: `It’s always a great idea to learn about the culinary traditions of the countries you visit, as it provides insight into their culture and lifestyle. By exploring local foodie places, you can immerse yourself in the flavors and ingredients of the region, and gain a deeper appreciation for the food that you eat.`,
        reverse: true,
        img: foodBanner,
      },
      {
        title: "La Vida Vegana",
        para: `La Vida Vegana is a must-visit for foodies who love plant-based cuisine. Located in the trendy Condesa neighborhood of Mexico City, this vegan restaurant serves up a creative and delicious menu of Mexican-inspired dishes made entirely from plant-based ingredients. From vegan tacos and enchiladas to queso fundido and churros, La Vida Vegana proves that vegan food can be both healthy and indulgent.`,
        reverse: false,
        img: Vegana8,
      },
      {
        title: "The Crab Shack",
        para: `Located on Tybee Island just outside of Savannah, The Crab Shack is a classic seafood shack that's been serving up fresh seafood since 1990. Situated right on the water, the restaurant offers beautiful views of the marshes and waterways of coastal Georgia. Try their famous Low Country Boil, a hearty feast of boiled shrimp, crab, corn, potatoes, and sausage, or sample some of their other seafood dishes like crab cakes, fried shrimp, and oysters Rockefeller.`,
        reverse: true,
        img: Crab9,
      },
      {
        title: "Osteria Francescana ",
        para: `For the ultimate foodie experience, head to Osteria Francescana in Modena, Italy, which has been named the world's best restaurant multiple times. Led by chef Massimo Bottura, the restaurant offers a multi-course tasting menu that showcases the best of Italian cuisine with a modern twist. `,
        reverse: false,
        img: Osteria10,
      },
      {
        title: "Make Lifelong Friendship",
        para: `Travel can broaden your social circle as well as broaden your intellect. Traveling in a small group with like-minded individuals can frequently result in new friendships. Wonders travelers have provided extremely positive feedback on this front: spending time with the same people and sharing new and unique experiences can be extremely bonding, building memories and friendships for life...        `,
        reverse: true,
        img: Friendship11,
      },
      {
        title: "Experience the Best of New York City",
        para: `Explore the vibrant city that never sleeps, see iconic landmarks like the Statue of Liberty and the Empire State Building, visit world-class museums and art galleries, and enjoy the eclectic food scene.`,
        reverse: false,
        img: NewYork12,
      },
      {
        title: "The Enchanting Island of Santorini",
        para: `Experience the breathtaking beauty of this picturesque island with its whitewashed houses, stunning sunsets, and crystal-clear waters. Take a boat tour, try local cuisine, and hike the volcanic terrain.`,
        reverse: true,
        img: Santorini13,
      },
      {
        title: "Discover the World's Wonders: Your Ultimate Travel Companion",
        para: `Wonders are adventure travel specialists. The business has won numerous awards, including Best Tour Operator at the British Travel Awards, and has been designated a Which? recommended provider for a few years. Its specialists understand that life-enriching, horizon-expanding adventure travel requires exceptional planning, first-rate organization, and insider knowledge of unique locations, peoples, and cultures. Wonder is extremely proud of the amazing experiences it has created around the world, as well as the efforts it has made to protect the environment and support the people it meets. So, why not accompany Wonderspot on their award-winning adventures this year?`,
        reverse: false,
        img: "",
      },
    ],
  ],
  sunsiyamresort: [
    {
      title: `Escape to Paradise: Experience Luxury and Serenity at Sun Siyam Resorts `,
      para: `The Maldives is known for its stunning blue waters, white sand beaches, and luxurious resorts. Sun Siyam Resorts is one of this island nation's most exquisite resort companies. These magnificent resorts allow visitors to elope to a paradise filled with unparalleled luxury and breathtaking natural beauty. Sun Siyam Resorts is a collection of five-star properties in the Maldives that offer a 
      range of accommodation options, from beach villas to floating bungalows. .`,
      line: ``,
      bannerImage: maldivesBanner,
      cont: "sunsiyam",
      cat: "sunsiyam",
      summary: `Overall, Sun Siyam Resorts is an exceptional resort chain that offers guests an opportunity to escape to paradise. With their stunning natural beauty, luxurious amenities, and range of activities and experiences, these resorts are the perfect destination for those seeking a true Maldivian experience.`,
    },
    [
      {
        title: ``,
        para: `
        Sun Siyam Resorts is a collection of five-star properties in the Maldives that offer a 
        range of accommodation options, from beach villas to floating bungalows. Each resort is tastefully designed with traditional Maldivian architecture and contemporary amenities. The resorts are equipped with private balconies or terraces, allowing guests to soak in the stunning views of the Indian Ocean.
      
        The Sun Siyam Vilu Reef, which is situated on the serene southern island of the Maldives, is the company's signature building. The dining choices at this resort range from international flavours to traditional Maldivian food. Visit The Aqua, the resort's signature eatery, for a fine dining experience. The Aqua serves modern cuisine with an emphasis on fresh seafood. The Well Done, the resort's beachfront eatery, offers 
        a more relaxed dining experience where you can chow down on grilled meats and seafood while taking in the sounds of the ocean.
          `,
        reverse: true,
        img: Heading2,
      },
      {
        title: ``,
        para: `But what truly sets Sun Siyam Resort World apart is its range of activities and experiences. The resort offers a range of water sports, from diving and snorkelling to jet skiing and parasailing. Guests can also take a sunset cruise, go fishing, or explore the nearby islands. If relaxation is what you're after, the resort's spa offers a range of treatments, from massages to facials, using natural and organic products.

        Sun Siyam Resort World also caters to families, with a range of activities for children, including a kids' club and a dedicated pool. For couples looking for a romantic getaway, the resort offers private dinners on the beach and sunset cocktails on the rooftop bar.
        `,
        reverse: false,
        img: "",
      },
      {
        title: (
          <>
            <>
              Overview of Sun Siyam Resort Properties in Maldives and Sri Lanka
            </>
            <br /> <br /> <br />
            Sun Siyam Iru Fushi Resort
          </>
        ),
        para: `Sun Siyam Iru Fushi is another stunning property in the Maldives in the northern atoll. This resort is perfect for those seeking a peaceful and serene vacation. The resort offers a range of water sports, from diving and snorkelling to jet skiing and parasailing. Guests can also take a sunset cruise, go fishing, or explore the nearby islands. If relaxation is what you're after, the resort's spa offers a range of treatments, from massages to facials, using natural and organic products.`,
        reverse: true,
        img: iruFushiBanner,
      },
      {
        title: `Sun Siyam Olhuveli Resort`,
        para: `Sun Siyam Olhuveli is another beautiful resort located in the South Malé Atoll. This resort is perfect for those looking for an active vacation, as it offers a range of activities such as surfing, windsurfing, and kayaking. Guests can also explore the vibrant marine life surrounding the resort, with snorkelling and diving excursions available.`,
        reverse: false,
        img: OhleveliBanner,
      },
      {
        title: `The Sun Siyam Iru Veli Resort `,
        para: `The Sun Siyam Iru Veli is a luxurious resort with a range of overwater and beach villas in the Dhaalu Atoll. The resort features a range of dining options, from local Maldivian cuisine to international flavours. Guests can also indulge in a range of activities, such as island hopping, fishing, and diving.
      `,
        reverse: true,
        img: iruBanner,
      },
      {
        title: `Sun Siyam Vilu Reef  Resort`,
        para: `   Lastly, the Sun Siyam Vilu Reef also has a sister property in Sri Lanka, the Sun Aqua Pasikudah. This resort is located on the east coast of Sri Lanka and offers guests a chance to experience the beauty of Sri Lanka and the Maldives. The resort boasts a range of activities, from snorkelling and diving to beach volleyball and tennis`,
        reverse: false,
        img: viluBanner,
      },
      {
        title: "Sun Siyam Pasikudah Resort",
        para: `Sun Siyam Pasikudah Resort is a luxurious, isolated retreat on Sri Lanka's pristine Pasikudah Bay. This resort has everything you need for a relaxing and pleasant vacation, including spacious and well-appointed rooms, exceptional dining choices, and a variety of activities and amenities. The staff's exceptional service adds an additional touch of luxury and makes every guest feel welcome and cared for. Sun Siyam Pasikudah Resort is an excellent option for those looking for a relaxing and luxurious getaway in Sri Lanka.`,
        reverse: true,
        img: PasiBanner,
      },
    ],
  ],
  "sun-siyam-iru-fushi-a-serene-island-getaway-in-the-maldives": [
    {
      title: `Sun Siyam Iru Fushi: A Serene Island Getaway in the Maldives`,
      line: `The Sun Siyam Iru Fushi resort in the Maldives should be at the top of your list if you're searching for a vacation that is genuinely relaxing. To guarantee a hassle-free and comfortable stay, this resort provides various services and amenities. This allows you to fully enjoy everything the Maldives has to offer.`,
      para: `The Maldives, with its crystal-clear waters and pristine white sand beaches, is a dream destination for many travellers. And for those seeking a luxurious and peaceful retreat, the Sun Siyam Iru Fushi resort is an excellent choice. Located in the Noonu Atoll, a 45-minute seaplane ride from Velana International Airport, this resort is an oasis of tranquillity amidst the turquoise waters of the Indian Ocean.`,
      summary: `Sun Siyam Iru Fushi is a perfect destination for those seeking a peaceful and luxurious island getaway in the Maldives. With its stunning natural beauty, diverse dining options, and numerous activities and facilities, the resort offers something for everyone. Whether you're travelling with family or friends, or looking for a romantic escape, this resort is sure to provide an unforgettable experience.
      `,
      bannerImage: iruFushiBanner,
      cont: "sunsiyam",
      cat: "sunsiyam",
    },
    [
      {
        title: `Accommodations at Sun Siyam Iru Fushi
        `,
        para: `The resort provides a variety of lodging choices to accommodate various spending limits and tastes. All of the accommodations, from beachfront villas to overwater bungalows, are roomy and luxuriously furnished, with contemporary conveniences like air conditioning, flat-screen TVs, and free Wi-Fi. The villas' architecture is modeled after the local Maldivian style, with thatched roofs, raw wood, and gentle colors that harmoniously complement the island's natural splendor.`,
        reverse: true,
        img: Accom1,
      },
      {
        title: `Dining Options`,
        para: `The resort has seven restaurants and bars, offering a diverse range of cuisines and dining experiences. The Islander's Grill is a must-visit for seafood lovers, with its fresh catch of the day and grilled specialties. The Trio is an excellent option for Italian cuisine, while the Flavours serves international buffet-style meals throughout the day. For a romantic evening, the Reflections restaurant offers a gourmet dining experience with a private beachfront setting.`,
        reverse: false,
        img: Dining2,
      },
      {
        title: `Activities and Facilities`,
        para: `Sun Siyam Iru Fushi offers a plethora of activities and facilities to keep guests entertained throughout their stay. Water sports enthusiasts can indulge in snorkelling, diving, kayaking, or take a dolphin spotting excursion. The resort also has a PADI-certified dive centre for those who want to explore the vibrant marine life of the Maldives. For those seeking relaxation, the resort's spa offers a range of treatments and therapies, including traditional Maldivian massages and Ayurvedic treatments.

        For families, the resort has a kids' club with a dedicated play area and daily activities for children. The resort also has a tennis court, a fitness centre, and a swimming pool, making it an ideal destination for those who want to stay active during their vacation.
        `,
        reverse: true,
        img: iruFushiActivity,
      },
    ],
  ],
  "exploring-the-unmatched-beauty-of-sun-siyam-vilu-reef": [
    {
      title: `Exploring- The Unmatched Beauty of sun siyam vilu reef
  `,
      line: ``,
      cont: "sunsiyam",
      cat: "sunsiyam",
      para: `Sun Siyam VILU REEF Resort, which is situated in the southern Maldives, provides a quiet retreat from the hustle and bustle of daily living. The resort guarantees visitors an unforgettable vacation thanks to its proximity to turquoise seas, white sand beaches, and lush vegetation. We'll explore what makes Sun Siyam VILU REEF Resort unique in this blog article and why you should think about staying there on your next vacation.`,
      bannerImage: viluBanner,
      summary: `Sun Siyam VILU REEF Resort is a true paradise in the Maldives, offering a perfect blend of luxury, natural beauty, and sustainability. With its range of villas and suites, dining options, activities, and facilities, the resort caters to a wide range of guests, including couples, families, and solo travellers. Its commitment to sustainable tourism practices and community development makes it a responsible and ethical choice for those who want to enjoy a luxurious holiday while minimising its environmental impact. So, if you are looking for a tranquil and unforgettable vacation in the Maldives, Sun Siyam VILU REEF Resort is the perfect destination.
  `,
    },
    [
      {
        title: `Accommodation`,
        para: `Sun Siyam VILU REEF Resort offers 128 luxurious villas and suites that cater to a wide range of preferences and budgets. The villas are categorized into several types: Beach Villas, Deluxe Beach Villas, Water Villas, Pool Villas, and Presidential Suites. All the villas are well-appointed with modern amenities, such as air conditioning, flat-screen TVs, minibars, and private outdoor spaces with sun loungers and direct beach or ocean access. 
        If you prefer to be near the ocean and the beach, the Beach Villas and Deluxe Beach Villas are ideal. These houses provide unimpeded views of the Indian Ocean and are surrounded by tropical gardens. The Water Villas, on the other hand, offers visitors a distinctive and all-encompassing experience of staying on the water because they are perched on stilts over the lagoon. The Pool Villas are perfect for newlyweds or couples looking for a romantic getaway because they have a private plunge pool. The Presidential Suite, which has two bedrooms, a private infinity pool, a Jacuzzi, a large sitting room, and a dining pavilion, is the pinnacle of luxury.
        `,
        reverse: true,
        img: Accomm1,
      },
      {
        title: `Dining`,
        para: `Sun Siyam VILU REEF Resort offers various dining options catering to different tastes and preferences.
        The resort has four restaurants and two bars, each with its unique ambiance and menu.
        The resort's primary dining establishment is the Aqua Restaurant, which offers a buffet for breakfast, lunch, and supper along with a variety of dishes from around the world. In a beachfront location, the specialty eatery The Well Done Restaurant serves grilled meats and seafood. The Sunset Restaurant provides fine dining with an emphasis on seafood and regional flavours, while The Island Pizza offers authentic Italian pizzas.

        Two bars, the Beach Bar and the Pool Bar, are also available at the resort. They offer a variety of cocktails, mocktails, and other alcoholic beverages.
         `,
        reverse: false,
        img: Dinings2,
      },
      {
        title: `Activities and Facilities`,
        para: `The Sun Siyam VILU REEF Resort provides a range of amenities and activities to keep visitors amused while they are there. Several of the Maldives' most pristine reefs and dive sites are accessible through the resort's PADI-certified dive centre, which also provides diving and snorkelling excursions. Activities like windsurfing, stand-up paddleboarding, paddling, and catamaran sailing are available at the resort's water sports facility. The resort has a fitness centre, tennis court, and beach volleyball court for guests who would rather remain on dry land. For those who want to unwind and revitalise their body and mind, the resort also provides yoga and meditation workshops.

        The Araamu Spa at the retreat provides a range of therapies and treatments that are based on conventional Maldivian medical practices. Six therapy rooms, two overwater treatment pavilions, a steam room, a sauna, and a Jacuzzi are available at the spa.
        
        The resort also provides excursions to nearby islands and fishing communities so visitors can experience a bit of Maldivian culture and way of life.
        `,
        reverse: true,
        img: Activities3,
      },
    ],
  ],
  "escape-to-paradise-a-review-of-the-sun-siyam-iru-veli-resort": [
    {
      title: `Escape to Paradise: A Review of the Sun Siyam Iru Veli Resort`,
      para: `
    If you're looking for the ultimate getaway in paradise, look no further than the Sun Siyam Iru Veli Resort. This luxurious resort is located in the stunning Noonu Atoll in the Maldives, surrounded by crystal-clear waters and white sandy beaches. You'll feel like you've stepped into a dream from the moment you arrive.
    `,
      line: ``,
      summary: `Sun Siyam Iru Veli is the ultimate island escape, offering luxury accommodations, world-class dining, and a range of activities and experiences to suit all interests. Whether you want to relax on the beach, explore the underwater world, or simply unwind in the spa, this resort has something for everyone.

    With its commitment to sustainability and environmental conservation, Sun Siyam Iru Veli is also a responsible choice for travellers who want to make a positive impact on the world. Book your stay today and experience the beauty and tranquillity of the Maldives at its best.
    `,
      bannerImage: iruBanner,
      cont: "sunsiyam",

      cat: "sunsiyam",
    },
    [
      {
        title: `Accommodations`,
        para: `Sun Siyam Iru Veli offers a range of luxurious villas, each one designed to provide maximum comfort and privacy. You can choose from overwater villas, beach villas, and family villas, all of which come equipped with modern amenities such as air conditioning, Wi-Fi, and flat-screen TVs.

        The overwater villas are particularly breathtaking, with their private sundecks and direct access to the lagoon. You can wake up to the sight of colourful fish swimming beneath your villa and watch the sunset over the ocean from your infinity pool.
        
        The beach villas offer direct access to the soft white sands of the beach and come with private gardens and plunge pools. These villas are perfect for couples seeking a romantic getaway or families with children who want to be close to the beach.
        
        For larger groups or families, the family villas offer spacious accommodations with multiple bedrooms and living areas. These villas are also conveniently located close to the resort's family-friendly amenities.
        `,
        reverse: true,
        img: IruAccom,
      },
      {
        title: "Dining",
        para: `Sun Siyam Iru Veli offers a range of dining options to suit all tastes and preferences. The resort's main restaurant, Reflections, serves a variety of international dishes throughout the day, including breakfast, lunch, and dinner. You can enjoy your meals in the restaurant's open-air dining area, which offers stunning views of the ocean.

        For a more intimate dining experience, you can reserve a private beach or overwater dining experience. Imagine enjoying a candlelit dinner on the beach, with the sound of the waves lapping at your feet, or savouring a gourmet meal on your private deck overlooking the lagoon.
        
        If you're in the mood for a refreshing cocktail or a light snack, head to the resort's pool bar or a beach bar. The pool bar offers a variety of tropical cocktails and snacks, while the beach bar is the perfect spot to relax with a cold drink while watching the sunset.
        `,
        reverse: false,
        img: IruDining2,
      },
      {
        title: `Activities`,
        para: `Sun Siyam Iru Veli offers a range of activities and experiences to help you make the most of your island getaway. Whether you want to relax on the beach, explore the underwater world, or try your hand at a new sport, there's something for everyone.

            One of the highlights of a stay at Sun Siyam Iru Veli is the resort's PADI-certified dive centre. Here, you can take part in a variety of diving and snorkelling activities, from beginner courses to advanced excursions. The resort's location on the Noonu Atoll means you can explore some of the Maldives' most spectacular coral reefs and marine life.

            If you prefer to stay above water, you can take a sunset cruise around the atoll, go fishing for your dinner, or try your hand at windsurfing or kayaking. The resort also offers a range of land-based activities, such as yoga, tennis, and beach volleyball.

            For families travelling with children, the resort's Kids' Club offers a variety of activities and programs to keep little ones entertained. From arts and crafts to nature walks and treasure hunts, there's always something fun and engaging for kids to do.
            `,
        reverse: true,
        img: Activity3,
      },
      {
        title: `Spa
            `,
        para: `No island escape would be complete without a visit to the spa, and Sun Siyam Iru Veli's spa is one of the best in the Maldives. Here, you can indulge in a range of treatments and therapies designed to help you relax, unwind, and rejuvenate.

            The spa offers a range of massages, facials, body treatments, and beauty services, all of which use natural, organic products to nourish and soothe your skin. You can choose from traditional Maldivian treatments, such as the coconut and sandalwood massage, or opt for more Western-style therapies, such as the Swedish massage.

            The spa also features a hydrotherapy pool, a steam room, and a sauna, as well as a relaxation lounge where you can unwind before or after your treatment. For the ultimate indulgence, book one of the spa's private couples' suites, which come with their own plunge pools and ocean views.
            `,
        reverse: false,
        img: Spa4,
      },
    ],
  ],
  "unravelling-the-ultimate-maldivian-getaway-at-sun-siyam-olhuveli": [
    {
      title: `Unravelling the Ultimate Maldivian Getaway at Sun Siyam Olhuveli`,
      para: `Those looking for a tropical paradise experience frequently journey to the Maldives in style. It is not surprising that the Maldives has emerged as a top option for honeymooners, families, and adventurers equally given its immaculate beaches, clear waters, and picturesque views. Sun Siyam Olhuveli is a premier destination among the many opulent resorts dotted throughout the Maldives because it provides an unforgettable experience that will leave visitors with memories that will last a lifetime.`,
      cat: "sunsiyam",
      cont: "sunsiyam",

      bannerImage: OhleveliBanner,
      line: `Sun Siyam Olhuveli, a secluded island retreat that offers a distinctive mix of luxury, comfort, and adventure, is situated on the southern edge of the South Male Atoll. The resort provides a variety of lodging choices to suit the needs of every visitor. It is located on a sprawling 34-acre island surrounded by the clear waters of the Indian Ocean. Sun Siyam Olhuveli offers everything, from beachfront homes to overwater bungalows.
    `,
      summary: `Additionally, Sun Siyam Olhuveli provides a selection of excursions and encounters that let visitors discover the Maldives' native culture and breathtaking scenery. These consist of dolphin viewing, sunset cruises, and island hopping.

    Sun Siyam Olhuveli is a truly unique destination, offering guests the perfect blend of luxury, comfort, and adventure. With its stunning beaches, crystal-clear waters, and range of activities and amenities, the resort is the perfect choice for those seeking an unforgettable tropical paradise experience.
    
    Whether you are looking for a romantic getaway, a family vacation, or an adventure-filled holiday, Sun Siyam Olhuveli has something for everyone. So why wait? Book your stay today and start discovering paradise in the Maldives.
    `,
    },
    [
      {
        title: `Accommodation`,
        para: `There are several opulent lodging options at Sun Siyam Olhuveli, each with its special facilities and features. 164 rooms overall, including Deluxe Rooms, Deluxe Water Villas, Grand Water Villas, and Presidential Water Suites, are available at the resort.

  The beachfront Deluxe Rooms are encircled by lush tropical flora. Each room has a private veranda with breathtaking Indian Ocean vistas. The accommodations are roomy and have contemporary comforts like air conditioning, satellite TV, and a minibar.
  The unmatched vistas of the ocean offered by Deluxe Water Villas are situated over the water. Every villa has a private sundeck, which is ideal for relaxing in the sun or having a special supper under the stars. Modern conveniences like satellite TV, air conditioning, and minibars are available in the houses.

The Grand Water Villas have a separate living area and bedroom and are larger than the Deluxe Water Villas. The houses are ideal for swimming and snorkelling because they have a private sundeck with easy access to the lagoon.

The Presidential Water Suites, which have two bedrooms, a sitting area, and a private infinity pool overlooking the ocean, are the height of luxury. To provide the highest standard of service and attention to detail, the suites also include private butler service.

  `,
        img: Accomndation,
        reverse: false,
      },
      {
        title: "Dining",
        para: `Visitors to Sun Siyam Olhuveli can choose from a variety of dining options, each of which provides a distinctive culinary experience. The Four Spices, Lagoon, Sunset Restaurant, Island Pizza, Dhoni Bar, and Sunrise Bar are just a few of the resort's six eateries and nightclubs.

  The resort's signature eatery, The Four Spices, serves visitors a taste of genuine Asian cuisine. Due to the restaurant's beachfront location, customers can enjoy breathtaking ocean vistas. A variety of dishes from Thailand, China, Japan, and India are offered on the menu, with an emphasis on using fresh, regional products.
  
Lagoon is a beachfront eatery where patrons can enjoy a leisurely meal. A variety of foreign dishes are offered on the menu, with an emphasis on seafood and grilled meats. Additionally, the restaurant features a live cooking station where patrons can observe as their food is prepared to their preferences.

Visitors to the Sunset Restaurant can enjoy breathtaking sunset views because it is situated over the ocean. Mediterranean cuisine is highlighted on the menu, which offers a variety of foreign dishes. After a long day of activities, the restaurant is the ideal spot to unwind because it serves a variety of premium wines and cocktails.
Island Pizza is an informal restaurant that serves a variety of pizzas and other Italian specialties. Due to its beachfront location, the eatery is the ideal place for a quick lunch or snack.

Beachfront bar Dhoni Bar serves a variety of cool drinks and refreshments to patrons. After a long day of activities, the bar is the ideal spot to relax and unwind because it also serves light snacks and finger foods.

Visitors can enjoy breathtaking views of the sunrise from Sunrise Bar, which is situated over the ocean. The bar is the ideal location to start the day because it serves a variety of cocktails, mocktails, and other drinks.

  `,
        img: OhleveliDining2,
        reverse: true,
      },
      {
        title: "Activities and Amenities",
        para: `Sun Siyam Olhuveli offers guests a range of activities and amenities to keep them entertained throughout their stay.  The resort has a fully equipped gym, a tennis court, and a beach volleyball court. There is also a range of water sports available, including kayaking, windsurfing, stand-up paddleboarding, and snorkelling.
   The resort also has a diving school, giving visitors a chance to discover the Maldives' vibrant underwater world. The diving school provides a variety of classes for divers of all skill levels, as well as night dives and wreck dives.

   The resort's luxurious spa offers a variety of services, such as massages, facials, and body treatments, for visitors seeking a more unwinding experience. The spa is situated above the sea, giving visitors breathtaking views of the ocean as they unwind and relax.
   
   `,
        img: OhleveliActivity3,
        reverse: false,
      },
    ],
  ],
  "exploring-the-diverse-marine-life-at-sun-siyam-pasikudah-reef": [
    {
      title: `Exploring the Diverse Marine Life at Sun Siyam Pasikudah Reef`,
      para: `If you're looking for a luxurious and secluded retreat in Sri Lanka, Sun Siyam Pasikudah Resort should be at the top of your list. Located on the pristine Pasikudah Bay on the eastern coast of Sri Lanka, this stunning resort offers a serene atmosphere perfect for those seeking a peaceful getaway. Here's a closer look at what you can expect from a stay at Sun Siyam Pasikudah Resort.`,
      reverse: true,
      bannerImage: PasiBanner,
      cont: "sunsiyam",
      cat: "sunsiyam",
    },
    [
      {
        title: "Accommodation",
        para: `The resort provides 134 generously sized rooms and suites, each with upscale furnishings and modern conveniences. There are several choices to suit every preference and financial capacity, ranging from Deluxe Rooms to Beach Villas and even a Presidential Suite. A comfortable and enjoyable stay is guaranteed by the presence of air conditioning, flat-screen TVs, minibars, and complimentary Wi-Fi in every room.

  The Beach Villas are an ideal option for those seeking an added level of opulence. These villas provide the utmost in seclusion and relaxation with private pools and easy access to the ocean. The Presidential Suite, on the other hand, is the height of 
  luxury and features a private pool, breathtaking ocean vistas, and a personal butler service to tend to all of your needs.
  `,
        img: PasiAccom1,
        reverse: false,
      },
      {
        title: "Dining",
        para: `One of the highlights of any stay at Sun Siyam Pasikudah Resort is the exceptional dining experience. The resort offers a range of dining options, including two restaurants and two bars.
      In a laid-back and informal atmosphere, the main restaurant, Main, offers a large variety of international and Sri Lankan dishes. Visitors can visit Aqua, the resort's fine dining establishment, for a more upscale dining experience. Here, you'll find a variety of gourmet dishes made with the finest regional ingredients by the resort's skilled cooks.
      
      Go to the Beach Bar or the Pool Bar for a cool beverage or mocktail if you're in the mood for a drink. The Beach Bar is the ideal place to enjoy a drink and observe the sunset while taking in the magnificent ocean views.
      `,
        img: PasiDinings,
        reverse: true,
      },
      {
        title: "Activities",
        para: `There's no shortage of activities to keep you entertained during your stay at Sun Siyam Pasikudah Resort. The resort offers a range of water sports, including snorkeling, diving, and jet skiing, as well as a fully-equipped fitness center for those who want to stay active during their vacation.

      If you prefer a more relaxing experience, head to the resort's spa for a range of treatments designed to rejuvenate and refresh both body and mind. From traditional Ayurvedic treatments to modern spa therapies, there's something for everyone at the Sun Siyam Pasikudah Resort spa.
      
      For those looking to explore the local area, the resort offers a range of excursions and tours, including visits to nearby temples and historic sites, as well as nature walks and birdwatching tours.
      `,
        img: PasiActivity,
        reverse: false,
      },
    ],
  ],
};
