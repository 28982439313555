import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Link } from "react-router-dom";
import vid from "../../../Images/motion.mp4";
import img from "../../blogs/BlogImage/Luxury_Image1/cover.jpeg";
import { data } from "../../blogs/data";
import sunresortbannerimg from "../../../Images/sunshyam/sunresortbannerimg.jpg";
import siyamlogo from "../../../Images/sunshyam/siyamlogo.png";

const items = [
  <div className="item" data-value="1">
    1
  </div>,
  <div className="item" data-value="2">
    2
  </div>,
  <div className="item" data-value="3">
    3
  </div>,
  <div className="item" data-value="4">
    4
  </div>,
  <div className="item" data-value="5">
    5
  </div>,
];

const blogstoshow = [
  "sunsiyamresort",
  "dubai-a-dream-destination",
  "10-most-visited-places-in-maldives",
  "best-beaches-around-the-world-sun-sand-and-sea",
  "luxury-travel-top-destinations-for-a-splurge-worthy-experience",
];

let blogdata = [];

for (let i = 0; i < blogstoshow.length; i++) {
  blogdata.push(data[blogstoshow[i]]);
}

let imgs = [
  sunresortbannerimg,
  "https://images.unsplash.com/photo-1504556647341-3ce6ab157835?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  "https://images.pexels.com/photos/33622/fashion-model-beach-hat.jpg",
  "https://images.pexels.com/photos/21787/pexels-photo.jpg",
  "https://images.pexels.com/photos/9190/sea-boat-luxury-travel.jpg",
];

let homecarouseldata = blogdata.map((e, i) => {
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  return (
    <div className="itms">
      <img src={imgs[i]} alt="" />
      <div className="homecarousellinks">
        <h1>{i === 3 ? "Luxury Travel" : e[0].title}</h1>
        <p>{e[0].para.substring(0, 150)}...</p>
        <Link
          to={
            e[0].to ? `travelogue/${e[0].to}` : `travelogue/${blogstoshow[i]}`
          }
          onClick={() => {
            window.scrollTo(0, 0);
            scrolltotop();
          }}
        >
          <button>Read More</button>
        </Link>
        {i === 0 && (
          <div className="siyamlogo">{/* <img src={siyamlogo} /> */}</div>
        )}
      </div>
    </div>
  );
});

let itm = [
  // <div className="itms">{<video src={vid} autoPlay muted loop />}</div>,
  <div className="itms">
    <img
      src="https://images.unsplash.com/photo-1505881402582-c5bc11054f91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80"
      alt=""
    />
    <div className="homecarousellinks">
      <h1>something</h1>
      <p>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolor,
        architecto.
      </p>
      <button>Read More</button>
    </div>
  </div>,
];

let dotcontent = [1, 2, 3, 4];

let dotitems = dotcontent.map((e) => {
  return (
    <div style={{ height: "2vh", width: "2vh", backgroundColor: "red" }}></div>
  );
});

const HomeCarousel = () => (
  <div className="home">
    <AliceCarousel
      // renderDotsItem={dotcontent.forEach((e) => {
      //   <div style={{ height: "2vh", width: "2vh", backgroundColor: "red" }}>
      //     {e}
      //   </div>;
      // })}
      disableButtonsControls
      animationType="fadeout"
      autoPlay={6000}
      autoPlayInterval={6000}
      animationDuration={1000}
      infinite
      items={homecarouseldata}
      mouseTracking={true}
      autoPlayStrategy="none"
      stopAutoPlayOnHover={false}
      renderNextButton={(e) => {
        return <i class="ri-arrow-right-s-line"></i>;
      }}
      renderPrevButton={(e) => {
        return <i class="ri-arrow-left-s-line"></i>;
      }}
    />
  </div>
);

export default HomeCarousel;
