import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./country.css";
import CountryData from "./CountryData";
import packageData from "../Data/PackageData";
import Packages from "./Packages";
function Africa() {
  const { conts } = useParams();
  const [open, setOpen] = useState(false);
  const night5 = document.querySelector(".night5");
  const night6 = document.querySelector(".night6");
  const night3 = document.querySelector(".night3");
  const showNight3 = (index) => {
    const night31 = document.getElementById(`night3${index}`);
    const night51 = document.getElementById(`night5${index}`);
    const night61 = document.getElementById(`night6${index}`);
    night51.style.display = "none";
    night61.style.display = "none";
    if (night31.style.display === "block") {
      night31.style.display = "none";
    } else {
      night31.style.display = "block";
    }
  };
  const showNight5 = (index) => {
    const night51 = document.getElementById(`night5${index}`);
    const night61 = document.getElementById(`night6${index}`);
    const night31 = document.getElementById(`night3${index}`);
    night31.style.display = "none";

    night61.style.display = "none";
    if (night51.style.display === "block") {
      night51.style.display = "none";
    } else {
      night51.style.display = "block";
    }
  };
  const showNight6 = (index) => {
    const night51 = document.getElementById(`night5${index}`);
    const night61 = document.getElementById(`night6${index}`);
    const night31 = document.getElementById(`night3${index}`);
    night51.style.display = "none";
    night31.style.display = "none";
    if (night61.style.display === "block") {
      night61.style.display = "none";
    } else {
      night61.style.display = "block";
    }
  };

  return (
    <div className="country-container">
      <div
        className="banner-container"
        style={{
          backgroundImage: `url(${CountryData.images[0][`${conts}`]})`,
        }}>
        <h2> {CountryData.contsName[`${conts}`]} </h2>
      </div>
      {CountryData[`${conts}`].map((event, i) => {
        return (
          <>
            <div className={`countries-data ${event.rev && "reverse"}`}>
              <div
                className="country-img"
                style={{ backgroundImage: `url(${event.img})` }}></div>
              <div className="country-content">
                <h3> {event.name} </h3>
                <p>{event.content}</p>
                <div className="country-btn">
                  <Link className="night-3" onClick={() => showNight3(i)}>
                    3 Night
                  </Link>

                  <Link className="night-5" onClick={() => showNight5(i)}>
                    5 Night
                  </Link>

                  <Link className="night-6" onClick={() => showNight6(i)}>
                    6 Night
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="night3-content night3"
              style={{ ds: "sd" }}
              id={`night3${i}`}>
              {CountryData[conts][i].night3}
            </div>
            <div className="night3-content night5" id={`night5${i}`}>
              {CountryData[conts][i].night5}
            </div>
            <div className="night3-content night6" id={`night6${i}`}>
              {CountryData[conts][i].night6}
            </div>
          </>
        );
      })}
    </div>
  );
}

export default Africa;
