import React, { useState } from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import footerlogo from "../../Images/footerlogo.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Footer = () => {
  const [email, setEmail] = useState("");
  const toastOptions = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: true,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "dark",
  };

  let getEmail = async (e) => {
    fetch("https://getform.io/f/a681739a-c6e7-47a8-8304-500339c303a4", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((e) => {
        toast.success("data submitted successfully", toastOptions);
        setTimeout(() => {
          setEmail("");
        }, 1000);
      })
      .catch((err) => {
        toast.error("Failed to subscribe Newslatter ", toastOptions);
      });
  };
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  return (
    <>
      <div className="newFooter">
        <div className="foot-container">
          <div className="fsec left-sec">
            <Link
              to="/destination"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              <p>Destination</p>
            </Link>
            <Link
              to="/interest"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              <p>Interest</p>
            </Link>
            <Link
              to="/tripplanner"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              <p>Trip Planner</p>
            </Link>
            <Link
              to="/event"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              <p>Events</p>
            </Link>
            <Link
              to="/travelogue"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              <p>Travelogue</p>
            </Link>
          </div>
          <div className="fsec mid-sec">
            <div className="mid-sec-content">
              <span>
                <img src={footerlogo} />
              </span>
              <h6>Dream, Discover, Explore!</h6>
            </div>
            <div className="mid-sec-content">
           <a href="https://www.facebook.com/wanderchoices" target='_blank'>   <i class="ri-facebook-circle-fill"></i> </a>
            <a href="https://www.instagram.com/wanderchoice/" target='_blank'>  <i class="ri-instagram-fill"></i> </a>
              <i class="ri-linkedin-fill"></i>
              <i class="ri-youtube-fill"></i>
            </div>
            <div className="mid-sec-content">
              <div></div>
            </div>
            <div className="mid-sec-content">
              <h4 style={{ textTransform: "capitalize" }}>
                signup for our newsletter today
              </h4>
            </div>
            <div className="mid-sec-content">
              <div className="mid-sec-input">
                <input
                  type="email"
                  value={email}
                  id="newslatter"
                  placeholder="Drop Your E-mail"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button onClick={getEmail}>
                <h4>Subscribe</h4>
              </button>
            </div>
          </div>
          <div className="fsec right-sec">
            <Link
              to="/terms"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              <p>Terms & conditions</p>
            </Link>
            <Link
              to="/privacy"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              <p>privacy policy</p>
            </Link>
            <p>disclaimer</p>
            <Link
              to="/contactus"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              <p>contact us</p>
            </Link>
            <Link
              to="faq"
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              <p>
                faq’<small>s</small>
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="below-footer">
        <p>
          <b>Wanderspots</b> © 2016 - 2023
        </p>
        <p>
          designed by &nbsp;
          <b>
            <a href="http://webseeder.in/" target="_blank">
              WebSeeder technologies
            </a>
          </b>
        </p>
      </div>
    </>
  );
};

export default Footer;
