import React from "react";
import "./tourguide.css";
// import Carousel from "../simpleSlider/newSlider";
import Design from "../design/Design";
import { data } from "./guide-data";
import { useParams } from "react-router-dom";
import topimg from "../../Images/tourtop.png";
import Carousel from "../newCarousel/NewCarousel";
import commonbanner from "../../Images/tourguide/commonbanner.jpg";

// const TourCard = () => {
//   return (
//     <div className="tourCard">
//       <img
//         src="https://images.unsplash.com/photo-1516483638261-f4dbaf036963?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1372&q=80"
//         alt=""
//       />
//       <h1 className="tourCardText">Italy</h1>
//     </div>
//   );
// };

const cards = [
  <div className="tourCard">
    <img
      src="https://images.unsplash.com/photo-1516483638261-f4dbaf036963?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1372&q=80"
      alt=""
    />
    <h1 className="tourCardText">Italy</h1>
  </div>,
  <div className="tourCard">
    <img
      src="https://images.unsplash.com/photo-1516483638261-f4dbaf036963?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1372&q=80"
      alt=""
    />
    <h1 className="tourCardText">Italy</h1>
  </div>,
];

const TourGuide = () => {
  const { tour } = useParams();
  return (
    <>
      <div className="tour-top">
        <img
          src={data[tour][0].banner ? data[tour][0].banner : topimg}
          alt=""
        />
        <div className="tour-top-text">
          <h6>---- A Travel Guide ----</h6>
          {data && data[tour][0].heading}
          <button>Let's Go</button>
        </div>
      </div>
      {data[tour][1].map((section) => {
        return (
          <div className={`tour-mid ${section.rev && "tour-mid-rev"}`}>
            <div className="tour-mid-left">
              <h6>{section.title}</h6>
              <p>{section.para}</p>
            </div>
            <div className="tour-mid-right">
              <img
                src={
                  section.sectionimage
                    ? section.sectionimage
                    : "https://images.unsplash.com/photo-1586423669195-d9df98e5a20e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1408&q=80"
                }
                alt=""
              />
            </div>
          </div>
        );
      })}
      {/* <div className="tourExplore">
        <div className="tourExplore-left">
          <h1>
            Let's Explore The <br /> World
          </h1>
          <button>Explore</button>
        </div>
        <div className="tourExplore-right">
          <p>
            There's a whole wide world out there. and it contains so many
            unknowns - but we all only get one life. At WildVers. we believe
            that we should travel the world while we have the
          </p>
          <p>
            If you're just starting to think about why you should travel. or
            you're looking for travel inspiration. then we're here to help. Here
            are some of the best countries you should definitely visit.
          </p>
        </div>
      </div> */}
      {/* <div className="tourCardContainer">
        <Carousel
          Card={TourCard}
          setSlideNum={3}
          // setAutoPlay={false}
          // setEffect={"coverflow"}
        />
      </div> */}

      {/* <div className="tourCardContainer">
        <Carousel data={cards} />
      </div> */}
      <div className="travelExp">
        <img src={commonbanner} alt="" />
        <div className="travelExpText">
          <h3>Join us on our journey and explore the world!!!</h3>
          <p>
            Looking for travel inspiration, tips, and insider information?
            Follow our travel blog! Our team of experienced travelers and
            passionate writers share their personal stories and recommendations
            for the best destinations, activities, and accommodations around the
            world. From budget-friendly options to luxurious escapes, we cover
            it all with stunning photos, detailed descriptions, and helpful
            advice. Join our community of like-minded travelers and let us
            inspire you to embark on your next adventure. Subscribe to our blog
            today and start exploring the world with us!
          </p>
          <button>Start exploring today!</button>
        </div>
      </div>
      <div className="travelExpText2">
        <h3>Explore The Most Beautiful</h3>
        <h3>Places In The World</h3>
        <p>
          Join our community of avid travelers and explore the world with us!
          Follow our travel blog for insider tips, destination recommendations,
          and personal stories that will inspire your next adventure. Whether
          you're a seasoned traveler or planning your first trip, our blog has
          something for everyone. Subscribe now and let us help you make your
          travel dreams a reality!
        </p>
        <button>Pack your bags..</button>
      </div>
      <Design />
    </>
  );
};

export default TourGuide;
