import saticImage from "../../BlogImage/Dubai_Image/Palm3.jpg";
import AfricaImage from "../Images/AfricaImage.webp";
import AsiaImage from "../Images/AsiaImage.jpg"
import NorthImage from "../Images/NorthImage.jpg"
import MiddleImage from "../Images/MiddleEastImage.jpg"
import Banner from "../Images/BannerImage.jpg"
import Europe from "../Images/EuropeImage.jpg"

const discoverData = [
  {
    bannerImage: AfricaImage,
    heading: "Africa",
    url:'africa',
    content: `The second-largest continent in the world, Africa is home to over 50 countries and boasts an incredible diversity of cultures, languages, and landscapes. From the vast Sahara Desert to the lush rainforests of Central Africa, this continent is a haven for wildlife and natural wonders. Explore the vibrant cities, immerse yourself in the local culture, and discover the rich history of this fascinating continent., 
`,
    rev: false,
  },
  {
    bannerImage: AsiaImage,
    heading: "Asia",
    url:'asia',
    content: `The largest continent in the world, Asia is a land of contrasts, where ancient traditions meet modern technology. With a rich history that spans thousands of years, Asia is home to some of the world's oldest civilizations, including China, India, and Japan. From the snow-capped peaks of the Himalayas to the tropical beaches of Southeast Asia, this continent offers something for everyone., 
`,
    rev: true,
  },
  {
    bannerImage: Europe,
    heading: "Europe",
    url:'europe',
    content: `Renowned for its stunning architecture, rich history, and diverse culture, Europe is a must-visit destination for any traveler. From the romantic canals of Venice to the bustling streets of London, this continent is a treasure trove of iconic landmarks, breathtaking scenery, and mouth-watering cuisine. Whether you're interested in art, history, or adventure, Europe has something to offer., 
`,
    rev: false,
  },
  {
    bannerImage: MiddleImage,
    heading: "Middle East",
    url:'middleeast',
    content: `The Middle East is a region of immense cultural and historical significance, spanning across Western Asia and North Africa. Home to some of the world's oldest civilizations, the Middle East is a melting pot of cultures, religions, and traditions. Explore the ancient ruins of Petra, marvel at the skyscrapers of Dubai, and indulge in the delicious cuisine of this fascinating region. `,
    rev: true,
  },
  {
    bannerImage: NorthImage,
    heading: "North America",
    url:'northamerica',
    content: `From the Arctic tundra to the tropical beaches of the Caribbean, North America is a land of incredible diversity. Comprising three countries - the United States, Canada, and Mexico - this continent is home to some of the world's most iconic landmarks, including the Grand Canyon, Niagara Falls, and the Statue of Liberty. Whether you're looking for adventure, relaxation, or culture, North America has it all. 
    `,
    rev: false,
  },
];
export default discoverData;
