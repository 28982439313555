import React from "react";
import eventData from "./eventCardData";

const Ecard = eventData.map((e) => {
  return (
    <div className="ecard">
      <div className="ecard-top">
        <img src={e.cardimg} alt="imgg" />
      </div>
      <div className="ecard-mid">
        <h3>{e.title}</h3>
        <small>{e.para}</small>
      </div>
      <div className="ecard-bottom">
        <small>{e.date}</small>
        <i class="ri-arrow-right-fill"></i>
      </div>
    </div>
  );
});

export default Ecard;
