// MAP IMAGES

import Afriamap from "../../Images/Destination/Africa.png";
import Asiamap from "../../Images/Destination/Asia.png";
import Europemap from "../../Images/Destination/Europe.png";
import MiddleEastmap from "../../Images/Destination/MiddleEast.png";
import NorthAmericamap from "../../Images/Destination/NorthAmerica.png";

// COUNTRIES

// africa

import Kenya from "../../Images/Destination/Africa-Kenya.jpeg";
import Seychelles from "../../Images/Destination/Africa-Seychelles.jpeg";
import SouthAfrica from "../../Images/Destination/Africa-South-Africa.jpeg";
import Tanzania from "../../Images/Destination/Africa-Tanzania.jpeg";

// asia

import Armenia from "../../Images/Destination/Asia-Armenia.jpeg";
import Azerbaijan from "../../Images/Destination/Asia-Azerbaijan.jpeg";
import Indonesia from "../../Images/Destination/Asia-Indonesia.jpeg";
import Malaysia from "../../Images/Destination/Asia-Malaysia.jpeg";
import Maldives from "../../Images/Destination/Asia-Maldives.jpeg";
import Singapore from "../../Images/Destination/Asia-Singapore.jpeg";
import SriLanka from "../../Images/Destination/Asia-SriLanka.jpeg";
import Thailand from "../../Images/Destination/Asia-Thailand.jpeg";

// europe

import Austria from "../../Images/Destination/Europe-Austria.jpeg";
import Finland from "../../Images/Destination/Europe-Finland.jpeg";
import France from "../../Images/Destination/Europe-France.jpeg";
import Georgia from "../../Images/Destination/Europe-Georgia.jpeg";
import Germany from "../../Images/Destination/Europe-Germany.jpeg";
import Greece from "../../Images/Destination/Europe-Greece.jpeg";
import Hungary from "../../Images/Destination/Europe-Hungary.jpeg";
import Italy from "../../Images/Destination/Europe-Italy.jpeg";
import Netherlands from "../../Images/Destination/Europe-Netherlands.jpeg";
import Norway from "../../Images/Destination/Europe-Norway.jpeg";
import Spain from "../../Images/Destination/Europe-Spain.jpeg";
import Sweden from "../../Images/Destination/Europe-Sweden.jpeg";
import Switzerland from "../../Images/Destination/Europe-Switzerland.jpeg";
import UnitedKingdom from "../../Images/Destination/Europe-UnitedKingdom.jpeg";

// middleEast

import Dubai from "../../Images/Destination/MiddleEast-Dubai.jpeg";
import Jordan from "../../Images/Destination/MiddleEast-Jordan.jpeg";
import Turkey from "../../Images/Destination/MiddleEast-Turkey.jpeg";

// northAmerica

import UnitedStatesofAmerica from "../../Images/Destination/NorthAmerica-UnitedStatesofAmerica.jpeg";
import Chicago from "../../Images/Destination/NorthAmerica-Chicago.jpeg";
import LasVegas from "../../Images/Destination/NorthAmerica-LasVegas.jpeg";
import NewYork from "../../Images/Destination/NorthAmerica-NewYork.jpeg";

const destinationdata = [
  {
    mapimg: Afriamap,
    countries: [
      { img: Kenya, name: "Kenya" },
      { img: Seychelles, name: "Seychelles" },
      { img: SouthAfrica, name: "SouthAfrica" },
      { img: Tanzania, name: "Tanzania" },
    ],
    imghead: "AFRICA",
    imgsubhead:
      "Explore the Wonders of Africa: A Continent of Diversity, Wildlife, and Rich Heritage!",
  },
  {
    mapimg: Asiamap,
    countries: [
      { img: Armenia, name: "Armenia" },
      { img: Azerbaijan, name: "Azerbaijan" },
      { img: Indonesia, name: "Indonesia" },
      { img: Malaysia, name: "Malaysia" },
      { img: Maldives, name: "Maldives" },
      { img: Singapore, name: "Singapore" },
      { img: SriLanka, name: "SriLanka" },
      { img: Thailand, name: "Thailand" },
    ],
    imghead: "ASIA",
    imgsubhead:
      "Discover the Magic of Asia: A Journey of Culture, Cuisine, and Adventure!",
  },
  {
    mapimg: Europemap,
    countries: [
      { img: Austria, name: "Austria" },
      { img: Finland, name: "Finland" },
      { img: France, name: "France" },
      { img: Georgia, name: "Georgia" },
      { img: Germany, name: "Germany" },
      { img: Greece, name: "Greece" },
      { img: Hungary, name: "Hungary" },
      { img: Italy, name: "Italy" },
      { img: Netherlands, name: "Netherlands" },
      { img: Norway, name: "Norway" },
      { img: Spain, name: "Spain" },
      { img: Sweden, name: "Sweden" },
      { img: Switzerland, name: "Switzerland" },
      { img: UnitedKingdom, name: "United Kingdom" },
    ],
    imghead: "EUROPE",
    imgsubhead:
      "Experience the Charm of Europe: A Tapestry of History, Culture, and Unforgettable Adventures!",
  },
  {
    mapimg: MiddleEastmap,
    countries: [
      { img: Dubai, name: "Dubai" },
      { img: Jordan, name: "Jordan" },
      { img: Turkey, name: "Turkey" },
      // { img: Jordan, name: "Jordan" },
    ],
    imghead: "MIDDLE EAST",
    imgsubhead:
      "Journey to the Heart of the Middle East: A Fusion of Ancient Traditions, Modern Marvels, and Endless Hospitality!",
  },
  {
    mapimg: NorthAmericamap,
    countries: [
      { img: UnitedStatesofAmerica, name: "United States of America" },
      { img: NewYork, name: "NewYork" },
      { img: LasVegas, name: "Las Vegas" },
      { img: Chicago, name: "Chicago" },
    ],
    imghead: "NORTH AMERICA",
    imgsubhead:
      "Discover the Diversity of North America: A Continent of Scenic Landscapes, Vibrant Cities, and Endless Exploration!",
  },
];

export default destinationdata;
