// winter imports

import winter from "../../Images/Interest/Winter/Winter.jpeg";
import winterArticle from "../../Images/Interest/Winter/Winter-article.jpeg";
import winterFinder from "../../Images/Interest/Winter/Winter-trip-finder.jpeg";
import winterGuide from "../../Images/Interest/Winter/Winter-trip-guide.jpeg";

// summer imports

import summer from "../../Images/Interest/Summer/Summer.jpeg";
import summerArticle from "../../Images/Interest/Summer/Summer-article.jpeg";
import summerFinder from "../../Images/Interest/Summer/Summer-trip-finder.jpeg";
import summerGuide from "../../Images/Interest/Summer/Summer-trip-guide.jpeg";

// spring imports

import spring from "../../Images/Interest/Spring/Spring.jpeg";
import springArticle from "../../Images/Interest/Spring/Spring-article.jpeg";
import springFinder from "../../Images/Interest/Spring/Spring-trip-finder.jpeg";
import springGuide from "../../Images/Interest/Spring/Spring-trip-guide.jpeg";

// Snow imports

import snow from "../../Images/Interest/Snow/Snow.jpeg";
import snowArticle from "../../Images/Interest/Snow/Snow-article.jpeg";
import snowFinder from "../../Images/Interest/Snow/Snow-trip-finder.jpeg";
import snowGuide from "../../Images/Interest/Snow/Snow-trip-guide.jpeg";

// Beaches imports

import beaches from "../../Images/Interest/Beaches/Beaches.jpeg";
import beachesArticle from "../../Images/Interest/Beaches/Beaches-article.jpeg";
import beachesFinder from "../../Images/Interest/Beaches/Beaches-trip-finder.jpeg";
import beachesGuide from "../../Images/Interest/Beaches/Beaches-trip-guide.jpeg";

// FoodnDrink imports

import FoodnDrink from "../../Images/Interest/FoodnDrink/FoodnDrink.jpeg";
import FoodnDrinkArticle from "../../Images/Interest/FoodnDrink/FoodnDrink-article.jpeg";
import FoodnDrinkFinder from "../../Images/Interest/FoodnDrink/FoodnDrink-trip-finder.jpeg";
import FoodnDrinkGuide from "../../Images/Interest/FoodnDrink/FoodnDrink-trip-guide.jpeg";

// HistoryandHeritage imports

import historyandHeritage from "../../Images/Interest/HistoryandHeritage/HistoryandHeritage.jpeg";
import historyandHeritageArticle from "../../Images/Interest/HistoryandHeritage/HistoryandHeritage-article.jpeg";
import historyandHeritageFinder from "../../Images/Interest/HistoryandHeritage/HistoryandHeritage-trip-finder.jpeg";
import historyandHeritageGuide from "../../Images/Interest/HistoryandHeritage/HistoryandHeritage-trip-guide.jpeg";

// SoloTravel imports

import soloTravel from "../../Images/Interest/SoloTravel/SoloTravel.jpeg";
import soloTravelArticle from "../../Images/Interest/SoloTravel/SoloTravel-article.jpeg";
import soloTravelFinder from "../../Images/Interest/SoloTravel/SoloTravel-trip-finder.jpeg";
import soloTravelGuide from "../../Images/Interest/SoloTravel/SoloTravel-trip-guide.jpeg";

export const data = [
  {
    Name: "Winter",
    Main: winter,
    Article: winterArticle,
    Finder: winterFinder,
    Guide: winterGuide,
    para: (
      <>
        Winter travel can be a magical experience, with snow-covered landscapes,
        cozy accommodations, and festive holiday events. Whether you're looking
        for a skiing adventure or a relaxing escape, winter offers a unique and
        unforgettable travel experience.<br></br>
        <br></br>
        One of the best parts of winter travel is the opportunity to enjoy
        outdoor activities like skiing, snowboarding, snowshoeing, ice skating,
        and sledding. You can also take a scenic winter hike, soak in hot
        springs, or visit a winter festival.
        <br></br>
        <br></br>
        When packing for your winter trip, be sure to bring warm clothing, hats,
        gloves, and waterproof boots. Don't let the colder temperatures deter
        you from exploring new destinations and creating unforgettable memories.
      </>
    ),
  },
  {
    Name: "Summer",
    Main: summer,
    Article: summerArticle,
    Finder: summerFinder,
    Guide: summerGuide,
    para: (
      <>
        Summer is the perfect time for travel, with longer days, warmer weather,
        and endless possibilities for outdoor adventures. Whether you're looking
        for a beach vacation, a road trip, or a cultural escape, summer offers a
        variety of travel options.<br></br>
        <br></br>
        One of the best parts of summer travel is the chance to enjoy outdoor
        activities like hiking, biking, swimming, kayaking, and surfing. You can
        also explore national parks, attend outdoor concerts and festivals, or
        simply relax on a beautiful beach.<br></br>
        <br></br>
        When packing for your summer trip, be sure to bring sunscreen,
        sunglasses, comfortable shoes, and light, breathable clothing. Embrace
        the warmth and sunshine of summer and make memories that will last a
        lifetime.
      </>
    ),
  },
  {
    Name: "Spring",
    Main: spring,
    Article: springArticle,
    Finder: springFinder,
    Guide: springGuide,
    para: (
      <>
        Spring travel offers a refreshing break from the chill of winter and the
        crowds of summer. With blooming flowers, pleasant temperatures, and a
        variety of outdoor activities, spring is an excellent time to explore
        new destinations.<br></br>
        <br></br>
        One of the best parts of spring travel is the opportunity to enjoy
        outdoor activities like hiking, biking, picnicking, and exploring
        botanical gardens. You can also attend festivals and events celebrating
        the season, such as cherry blossom festivals and Easter parades.
        <br></br>
        <br></br>
        When packing for your spring trip, be sure to bring comfortable layers,
        rain gear, and sunscreen. Embrace the renewal of the season and discover
        new destinations that are perfect for springtime exploration.
      </>
    ),
  },
  {
    Name: "Snow",
    Main: snow,
    Article: snowArticle,
    Finder: snowFinder,
    Guide: snowGuide,
    para: (
      <>
        Traveling in the snow can be an exhilarating experience, with
        breathtaking views, peaceful surroundings, and a variety of winter
        activities. Whether you're looking for a skiing adventure, a cozy cabin
        retreat, or a snowshoeing excursion, there are many destinations perfect
        for a snowy getaway.<br></br>
        <br></br>
        One of the best parts of traveling in the snow is the chance to enjoy
        outdoor activities like skiing, snowboarding, ice skating, snowshoeing,
        and even dog sledding. You can also warm up with hot cocoa by the fire
        or soak in a hot tub with snowflakes falling around you.<br></br>
        <br></br>
        When packing for your snowy trip, be sure to bring warm clothing,
        waterproof boots, and accessories like hats and gloves. Embrace the
        winter wonderland and make memories that will last a lifetime.
      </>
    ),
  },
  {
    Name: "Beaches",
    Main: beaches,
    Article: beachesArticle,
    Finder: beachesFinder,
    Guide: beachesGuide,
    para: (
      <>
        Traveling to the beach can be a rejuvenating experience, with the
        soothing sound of the waves, the warm sun on your skin, and endless
        possibilities for relaxation and fun. Whether you're looking for a
        romantic getaway, a family vacation, or a solo retreat, there are many
        beaches around the world that are perfect for any type of traveler.
        <br></br>
        <br></br>
        One of the best parts of beach travel is the opportunity to enjoy water
        activities like swimming, surfing, kayaking, and snorkeling. You can
        also lounge on the beach, take a sunset walk, or indulge in delicious
        seafood.<br></br>
        <br></br>
        When packing for your beach trip, be sure to bring sunscreen, a hat, a
        beach bag, and comfortable sandals. Soak up the sun, feel the sand
        between your toes, and make memories that will last a lifetime.
      </>
    ),
  },
  {
    Name: "Food & Drinks",
    Main: FoodnDrink,
    Article: FoodnDrinkArticle,
    Finder: FoodnDrinkFinder,
    Guide: FoodnDrinkGuide,
    para: (
      <>
        Traveling for food and drink can be a deliciously rewarding experience,
        with the opportunity to savor unique flavors, discover new ingredients,
        and explore local culture through cuisine. Whether you're a foodie, a
        wine connoisseur, or a lover of craft beer, there are destinations
        around the world that offer incredible culinary experiences.<br></br>
        <br></br>
        One of the best parts of food and drink travel is the chance to sample
        local specialties, dine at top-rated restaurants, and participate in
        cooking classes or food tours. You can also explore vineyards,
        breweries, and distilleries to learn about the production process and
        taste the final product.<br></br>
        <br></br>
        When packing for your food and drink trip, be sure to bring an open
        mind, a healthy appetite, and comfortable walking shoes. Satisfy your
        taste buds, expand your culinary horizons, and make memories through the
        delicious flavors of your destination.<br></br>
        <br></br>
      </>
    ),
  },
  {
    Name: "History & Heritage",
    Main: historyandHeritage,
    Article: historyandHeritageArticle,
    Finder: historyandHeritageFinder,
    Guide: historyandHeritageGuide,
    para: (
      <>
        Traveling for history and heritage can be a fascinating and enriching
        experience, with the opportunity to explore the stories, traditions, and
        legacies of different cultures and civilizations. Whether you're
        interested in ancient ruins, historic landmarks, or traditional arts and
        crafts, there are destinations around the world that offer incredible
        insights into the past. One of the best parts of history and heritage
        travel is the chance to visit museums, historical sites, and cultural
        festivals to learn about the people and events that shaped the local
        history.<br></br>
        <br></br>
        When packing for your history and heritage trip, be sure to bring
        comfortable walking shoes, sunscreen, and a camera to capture the
        moments and artifacts that bring history to life. Immerse yourself in
        the rich history and culture of your destination, and make memories that
        will last a lifetime.<br></br>
        <br></br>
      </>
    ),
  },
  {
    Name: "Solo Travel",
    Main: soloTravel,
    Article: soloTravelArticle,
    Finder: soloTravelFinder,
    Guide: soloTravelGuide,
    para: (
      <>
        Traveling solo can be a liberating and empowering experience, with the
        opportunity to explore new destinations on your own terms, challenge
        yourself, and discover new passions. Whether you're seeking adventure,
        relaxation, or personal growth, there are destinations around the world
        that are perfect for solo travelers.<br></br>
        <br></br>
        One of the best parts of solo travel is the chance to connect with
        locals and other travelers, and to immerse yourself in new cultures and
        ways of life. You can also enjoy the freedom to set your own itinerary,
        try new experiences, and learn about yourself in the process.<br></br>
        <br></br>
        When packing for your solo trip, be sure to bring a positive attitude,
        comfortable shoes, and a sense of adventure. Embrace the journey, trust
        your instincts, and make memories that will last a lifetime.<br></br>
        <br></br>
      </>
    ),
  },
];
