export const lowersecsliderdata = [
  {
    img: "https://images.unsplash.com/photo-1492684223066-81342ee5ff30?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
  {
    img: "https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80,",
  },
  {
    img: "https://images.unsplash.com/photo-1508997449629-303059a039c0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
];

const eventData = [
  {
    title: "Coachella - California, USA",
    date: "April 15-17, 2023 & April 22-24, 2023",
    para: `Get ready to rock out in the desert with some of the biggest names in music. Coachella is one of the most popular music festivals in the world, and it's not hard to see why.`,
    cardimg:
      "https://images.unsplash.com/photo-1612443016610-00c5fa0ec439?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
  {
    title: "Songkran - Thailand ",
    date: "April 13-15, 2023",
    para: `Experience the Thai New Year like a local with Songkran, a water festival that takes place across the country. Get drenched in water as part of the traditional celebrations and enjoy the festive atmosphere.`,
    cardimg:
      "https://images.unsplash.com/photo-1508009603885-50cf7c579365?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80",
  },
  {
    title: "La Tomatina - Spain ",
    date: "August 30, 2023",
    para: `Join the tomato fight in the streets of Buñol, Valencia, as part of the annual La Tomatina festival. This unique event is all about throwing tomatoes at your friends and strangers - it's messy, but a lot of fun!`,
    cardimg:
      "https://images.unsplash.com/photo-1476786516785-c3ff2dac9bbf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
  {
    title: "Oktoberfest - Germany ",
    date: "September 16-October 3, 2023",
    para: `Raise a stein and celebrate all things beer at Oktoberfest in Munich. With traditional food, music, and, of course, plenty of beer, this is a bucket list event for any beer lover.`,
    cardimg:
      "https://images.unsplash.com/photo-1467269204594-9661b134dd2b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
  },
];

export default eventData;
