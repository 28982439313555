import React from "react";
import "./error.css";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="error">
      <h1>404</h1>
      <small>OOPS ! PAGE NOT FOUND</small>
      <Link to="/">
        <p>go back to home page</p>
      </Link>
    </div>
  );
};

export default Error;
