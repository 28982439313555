import React, { useState, useEffect, useRef } from "react";
import "./newForm.css";
// import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import axios from "axios";

const toastOptions = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "dark",
};
const NewForm = () => {
  let initialdata = {
    name: "",
    email: "",
    contact: "",
    message: "",
  };

  let data = initialdata;

  let handleSubmit = (e) => {
    var URL = "https://getform.io/f/a681739a-c6e7-47a8-8304-500339c303a4";
    axios
      .post("http://localhost:4000/register", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((e) => {
        console.log(data.contact);
      })
      .then(async (response) => {
        await axios.post(URL, data, {
          headers: { Accept: "application/json" },
        });
        data = initialdata;
        toast.success("data submitted successfully", toastOptions);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("something went wrong", toastOptions);
      });
  };

  let handleValidation = (e) => {
    e.preventDefault();
    const { name, email, contact, message } = data;
    if (name.length < 3) {
      toast.error("Name length should be greater than 3", toastOptions);
      return;
    }
    if (email === "") {
      toast.error("Email cannot be empty", toastOptions);
      return;
    }
    if (contact === "") {
      toast.error("Contact cannot be empty", toastOptions);
      return;
    }
    if (message.length < 10) {
      toast.error(" Message length should be greater than 3", toastOptions);
      return;
    }

    handleSubmit();
  };
  let handleChange = (e) => {
    let setName = e.target.name;
    let val = e.target.value;
    data = { ...data, [setName]: val };
  };
  const PhoneInp = () => {
    return (
      <div className="inp-tel">
        <PhoneInput
          country={"ae"}
          onChange={(e) => {
            data = { ...data, contact: e };
          }}
        />
        {/* <PhoneInput
          country={"in"}
          placeholder="Enter phone number"
          value={data.contact}
          onChange={(e) => (data = { ...data, contact: e })}
        /> */}
      </div>
    );
  };
  return (
    <div className="newForm">
      <form onSubmit={handleValidation} autoComplete="off">
        <h1>Contact Us!</h1>
        <input
          name="name"
          placeholder="name"
          type="text"
          // value={data.name}
          onFocus={(e) => {
            e.target.select();
          }}
          onChange={(e) => handleChange(e)}
        />
        <PhoneInp />
        <input
          name="email"
          placeholder="email"
          type="email"
          // value={data.email}
          onFocus={(e) => {
            e.target.select();
          }}
          onChange={(e) => handleChange(e)}
        />
        <input
          name="message"
          placeholder="message"
          // value={data.message}
          type="text"
          onFocus={(e) => {
            e.target.select();
          }}
          onChange={(e) => handleChange(e)}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};
export default NewForm;
