import React from "react";
import "./design.css";
import image1 from "../../Images/tourguide/tourguidebottom/tourguide1.jpeg";
import image2 from "../../Images/tourguide/tourguidebottom/tourguide2.jpeg";
import image3 from "../../Images/tourguide/tourguidebottom/tourguide3.jpeg";
import image4 from "../../Images/tourguide/tourguidebottom/tourguide4.jpeg";
import image5 from "../../Images/tourguide/tourguidebottom/tourguide5.jpeg";
import image6 from "../../Images/tourguide/tourguidebottom/tourguide6.jpeg";
import image7 from "../../Images/tourguide/tourguidebottom/tourguide7.jpeg";
import image8 from "../../Images/tourguide/tourguidebottom/tourguide8.jpeg";

const Design = () => {
  return (
    <div className="design">
      <div className="design-top">
        <div className="imgCont">
          <img src={image1} alt="" />
        </div>
        <div className="imgCont">
          <img src={image2} alt="" />
        </div>
        <div className="imgCont">
          <img src={image3} alt="" />
        </div>
      </div>
      <div className="design-bot">
        <div className="imgCont">
          <img src={image4} alt="" />
        </div>
        <div className="imgCont">
          <img src={image5} alt="" />
        </div>
        <div className="imgCont">
          <img src={image6} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Design;
