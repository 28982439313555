const packageData = [
  {
    kenya: {
      night3: (
        <>
          <h3>
            Here is a suggested itinerary for a 3-night 4-day trip to Kenya
          </h3>
          <h4> Arrival in Nairobi</h4>
          <ul>
            <li> Arrive at Jomo Kenyatta International Airport. </li>
            <li> Check into your hotel and take some time to settle in </li>
            <li>
              Visit the Nairobi National Museum, where you can learn about the
              history and culture of Kenya.
            </li>
            <li>
              Take a stroll through the nearby Uhuru Park and enjoy the tranquil
              surroundings.
            </li>
          </ul>
          <h4> Visit the Maasai Mara National Reserve</h4>
          <ul>
            <li> Take a scenic flight to the Maasai Mara National Reserve. </li>
            <li>
              Spend the day on a game drive, exploring the vast savannas and
              observing the diverse wildlife, including lions, elephants,
              giraffes, and zebras.
            </li>
            <li>
              In the evening, relax at your lodge and take in the peaceful
              sounds of the African wilderness.
            </li>
          </ul>
          <h4> Maasai Village Visi</h4>
          <ul>
            <li>
              Visit a Maasai village and learn about the traditional way of life
              of this indigenous people.
            </li>
            <li>
              Watch a traditional dance performance and purchase souvenirs from
              the local market
            </li>
            <li> Return to your lodge for the evening </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li> Spend the morning at leisure before departing Kenya. </li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to go on a hot air
            balloon ride, visit the Amboseli National Park, or go on a beach
            holiday, Kenya offers a wide range of options for travelers.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>
            Here is a suggested itinerary for a 5-night 6-day trip to Kenya
          </h3>
          <h4>Arrival in Nairobi</h4>
          <ul>
            <li>Arrive at Jomo Kenyatta International Airport </li>

            <li>Check into your hotel and take some time to settle in </li>
            <li>
              Visit the Nairobi National Museum, where you can learn about the
              history and culture of Kenya
            </li>
            <li>
              Take a stroll through the nearby Uhuru Park and enjoy the tranquil
              surroundings
            </li>
          </ul>
          <h4> Visit the Maasai Mara National Reserve</h4>
          <ul>
            {" "}
            <li>Take a scenic flight to the Maasai Mara National Reserve</li>
            <li>
              Spend the day on a game drive, exploring the vast savannas and
              observing the diverse wildlife, including lions, elephants,
              giraffes, and zebras.
            </li>
            <li>
              In the evening, relax at your lodge and take in the peaceful
              sounds of the African wilderness.
            </li>
          </ul>
          <h4> Maasai Village Visit</h4>
          <ul>
            <li>
              Visit a Maasai village and learn about the traditional way of life
              of these indigenous people.
            </li>
            <li>
              Watch a traditional dance performance and purchase souvenirs from
              the local market
            </li>
            <li> Return to your lodge for the evening</li>
          </ul>
          <h4> Amboseli National Park</h4>
          <ul>
            <li>
              Take a scenic drive to Amboseli National Park, located at the foot
              of Mount Kilimanjaro.
            </li>
            <li>
              Spend the day on a game drive, observing the elephants and other
              wildlife that call this park home.
            </li>
            <li>
              In the evening, relax at your lodge and enjoy the peaceful
              surroundings.
            </li>
          </ul>
          <h3>Lake Nakuru National Park</h3>
          <ul>
            <li>
              Take a scenic drive to Lake Nakuru National Park, known for its
              stunning pink flamingos and diverse wildlife.
            </li>
            <li>
              Spend the day on a game drive, observing the abundant birdlife and
              other wildlife that call this park home.
            </li>
            <li>
              In the evening, relax at your lodge and take in the peaceful
              sounds of the African wilderness.
            </li>
          </ul>
          <h3> Departure</h3>
          <ul>
            <li>Spend the morning at leisure before departing Kenya.</li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to go on a hot air
            balloon ride, visit the Tsavo National Park, or go on a beach
            holiday, Kenya offers travelers a wide range of options.
          </p>
        </>
      ),
      night6: (
        <>
          <h3>
            Here is a suggested itinerary for a 6-night 7-day trip to Kenya:
          </h3>
          <h4> Arrival in Nairobi</h4>
          <ul>
            <li>Arrive at Jomo Kenyatta International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Visit the Nairobi National Museum, where you can learn about the
              history and culture of Kenya
            </li>
            <li>
              Take a stroll through the nearby Uhuru Park and enjoy the tranquil
              surroundings
            </li>
          </ul>
          <h4>Visit the Maasai Mara National Reserve</h4>
          <ul>
            <li>Take a scenic flight to the Maasai Mara National Reserve</li>
            <li>
              Spend the day on a game drive, exploring the vast savannas and
              observing the diverse wildlife, including lions, elephants,
              giraffes, and zebras.
            </li>
            <li>
              In the evening, relax at your lodge and take in the peaceful
              sounds of the African wilderness.
            </li>
          </ul>
          <h4>Maasai Village Visit</h4>
          <ul>
            <li>
              Visit a Maasai village and learn about the traditional way of life
              of this indigenous people.
            </li>
            <li>
              Watch a traditional dance performance and purchase souvenirs from
              the local market
            </li>
            <li>Return to your lodge for the evening</li>
          </ul>
          <h4>Amboseli National Park</h4>
          <ul>
            <li>
              Take a scenic drive to Amboseli National Park, located at the foot
              of Mount Kilimanjaro.
            </li>
            <li>
              Spend the day on a game drive, observing the elephants and other
              wildlife that call this park home.
            </li>
            <li>
              In the evening, relax at your lodge and enjoy the peaceful
              surroundings.
            </li>
          </ul>
          <h4>Lake Nakuru National Park</h4>
          <ul>
            <li>
              Take a scenic drive to Lake Nakuru National Park, known for its
              stunning pink flamingos and diverse wildlife.
            </li>
            <li>
              Spend the day on a game drive, observing the abundant birdlife and
              other wildlife that call this park home.
            </li>
            <li>
              In the evening, relax at your lodge and take in the peaceful
              sounds of the African wilderness.
            </li>
          </ul>
          <h4>Tsavo West National Park</h4>
          <ul>
            <li>
              Take a scenic drive to Tsavo West National Park, known for its
              diverse landscapes and abundant wildlife.
            </li>
            <li>
              Spend the day on a game drive, observing the diverse wildlife that
              call this park home.
            </li>
            <li>
              In the evening, relax at your lodge and take in the peaceful
              sounds of the African wilderness.
            </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>Spend the morning at leisure before departing Kenya.</li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to go on a hot air
            balloon ride, visit the Samburu National Reserve, or go on a beach
            holiday, Kenya offers a wide range of options for travelers.
          </p>
        </>
      ),
    },
    seychelles: {
      night3: (
        <>
          <p>
            Seychelles is a beautiful island nation located in the Indian Ocean.
            With its pristine beaches, lush tropical forests, and abundant
            marine life, Seychelles is the perfect destination for a relaxing
            and rejuvenating getaway. Here is a suggested itinerary for your
            3-night 4-day trip to Seychelles:
          </p>
          <h4>Arrival and Exploring Victoria</h4>
          <ul>
            <li>Arrive at the Seychelles International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Visit Victoria, the capital of Seychelles and one of the smallest
              capital cities in the world. Take a stroll through the city and
              admire its colonial architecture, visit the Natural History
              Museum, and stop by the bustling markets to pick up some
              souvenirs.
            </li>
          </ul>
          <h4>Beach Day at Beau Vallon</h4>
          <ul>
            <li>
              Spend the day at Beau Vallon, one of the most popular beaches in
              Seychelles. Relax on the soft white sands, swim in the
              crystal-clear waters, and enjoy the vibrant atmosphere of this
              bustling beach.
            </li>
            <li>
              In the evening, take a sunset cruise and watch the sky turn a
              brilliant shade of orange and red as the sun dips below the
              horizon.
            </li>
          </ul>
          <h4> Discover the Island of La Digue</h4>
          <ul>
            <li>
              Take a ferry to La Digue, one of the most picturesque islands in
              Seychelles.
            </li>
            <li>
              Rent a bicycle and explore the island, visiting its charming
              villages, lush coconut groves, and pristine beaches. Don't miss
              the famous Anse Source d'Argent, considered one of the most
              beautiful beaches in the world.
            </li>
          </ul>
          <h4> Departure</h4>
          <ul>
            <li>
              Spend the morning relaxing at your hotel or take one last stroll
              along the beach before departing Seychelles.
            </li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to relax on the beach,
            go snorkeling, or explore the island's rich cultural heritage,
            Seychelles has something for everyone.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>
            Here is a suggested itinerary for a 5-night 6-day trip to
            Seychelles:
          </h3>
          <h4> Arrival and Exploring Victoria</h4>
          <ul>
            <li>Arrive at the Seychelles International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Visit Victoria, the capital of Seychelles and one of the smallest
              capital cities in the world. Take a stroll through the city and
              admire its colonial architecture, visit the Natural History
              Museum, and stop by the bustling markets to pick up some
              souvenirs.
            </li>
          </ul>
          <h4>Praslin Island</h4>
          <ul>
            <li>
              Take a ferry to Praslin Island, one of the largest islands in
              Seychelles.
            </li>
            <li>
              Visit the Vallee de Mai Nature Reserve, a UNESCO World Heritage
              Site and home to the endemic Coco de Mer palm, the largest seed in
              the world.
            </li>
            <li>
              Spend the rest of the day relaxing on the beautiful Anse Lazio
              Beach, known for its turquoise waters and stunning scenery.
            </li>
          </ul>
          <h4>Beach Day at Beau Vallon</h4>
          <ul>
            <li>
              Return to Mahé Island and spend the day at Beau Vallon, one of the
              most popular beaches in Seychelles. Relax on the soft white sands,
              swim in the crystal-clear waters, and enjoy the vibrant atmosphere
              of this bustling beach.
            </li>
            <li>
              In the evening, take a sunset cruise and watch the sky turn a
              brilliant shade of orange and red as the sun dips below the
              horizon.
            </li>
          </ul>
          <h4>Discover the Island of La Digue</h4>
          <ul>
            <li>
              Take a ferry to La Digue, one of the most picturesque islands in
              Seychelles.
            </li>
            <li>
              Rent a bicycle and explore the island, visiting its charming
              villages, lush coconut groves, and pristine beaches. Don't miss
              the famous Anse Source d'Argent, considered one of the most
              beautiful beaches in the world.
            </li>
          </ul>
          <h4> Marine Activities and Relaxation</h4>
          <ul>
            <li>
              Spend the day exploring Seychelles' underwater world by going
              snorkeling or diving. With its vibrant coral reefs and abundant
              marine life, Seychelles is a haven for marine enthusiasts.
            </li>
            <li>
              In the evening, relax at your hotel or take a walk along the
              beach.
            </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Spend the morning relaxing at your hotel or take one last stroll
              along the beach before departing Seychelles.
            </li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to relax on the beach,
            go snorkeling, or explore the island's rich cultural heritage,
            Seychelles has something for everyone.
          </p>
        </>
      ),
      night6: (
        <>
          <h3>
            Here is a suggested itinerary for a 6-night 7-day trip to
            Seychelles:
          </h3>
          <h4> Arrival and Exploring Victoria</h4>
          <ul>
            <li>Arrive at the Seychelles International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Visit Victoria, the capital of Seychelles and one of the smallest
              capital cities in the world. Take a stroll through the city and
              admire its colonial architecture, visit the Natural History
              Museum, and stop by the bustling markets to pick up some
              souvenirs.
            </li>
          </ul>
          <h4> Praslin Island</h4>
          <ul>
            <li>
              Take a ferry to Praslin Island, one of the largest islands in
              Seychelles.
            </li>
            <li>
              Visit the Vallee de Mai Nature Reserve, a UNESCO World Heritage
              Site and home to the endemic Coco de Mer palm, the largest seed in
              the world.
            </li>
            <li>
              Spend the rest of the day relaxing on the beautiful Anse Lazio
              Beach, known for its turquoise waters and stunning scenery.
            </li>
          </ul>
          <h4>Beach Day at Beau Vallon</h4>
          <ul>
            <li>
              Return to Mahé Island and spend the day at Beau Vallon, one of the
              most popular beaches in Seychelles. Relax on the soft white sands,
              swim in the crystal-clear waters, and enjoy the vibrant atmosphere
              of this bustling beach.
            </li>
            <li>
              In the evening, take a sunset cruise and watch the sky turn a
              brilliant shade of orange and red as the sun dips below the
              horizon.
            </li>
          </ul>
          <h4>Discover the Island of La Digue</h4>
          <ul>
            <li>
              Take a ferry to La Digue, one of the most picturesque islands in
              Seychelles.
            </li>
            <li>
              Rent a bicycle and explore the island, visiting its charming
              villages, lush coconut groves, and pristine beaches. Don't miss
              the famous Anse Source d'Argent, considered one of the most
              beautiful beaches in the world.
            </li>
          </ul>
          <h4>Marine Activities and Relaxation</h4>
          <ul>
            <li>
              Spend the day exploring Seychelles' underwater world by going
              snorkeling or diving. With its vibrant coral reefs and abundant
              marine life, Seychelles is a haven for marine enthusiasts.
            </li>
            <li>
              In the evening, relax at your hotel or take a walk along the
              beach.
            </li>
          </ul>
          <h4>Visit Sainte Anne Marine National Park</h4>
          <ul>
            <li>
              Take a boat trip to Sainte Anne Marine National Park, located just
              off the coast of Mahé Island.
            </li>
            <li>
              Enjoy a day of snorkeling, swimming, and exploring the park's
              coral reefs and marine life.
            </li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>
              Spend the morning relaxing at your hotel or take one last stroll
              along the beach before departing Seychelles.
            </li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to relax on the beach,
            go snorkeling, or explore the island's rich cultural heritage,
            Seychelles has something for everyone.
          </p>
        </>
      ),
    },
    southafrica: {
      night3: (
        <>
          <h3>
            Here is a suggested itinerary for a 3-night 4-day trip to South
            Africa:
          </h3>
          <h4> Arrival in Cape Town</h4>
          <ul>
            <li>Arrive at Cape Town International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Explore the city center, visit the vibrant Green Market Square,
              and take a stroll through the nearby Company's Garden.
            </li>
            <li>
              In the evening, enjoy a scenic cable car ride up Table Mountain
              for stunning views of the city.
            </li>
          </ul>
          <h4>Cape Town Tour</h4>
          <ul>
            <li>
              Take a full-day tour of Cape Town, including visits to the famous
              landmarks such as the Table Mountain, the Cape of Good Hope, and
              Boulders Beach to observe the penguin colony.
            </li>
            <li>
              In the evening, enjoy a delicious seafood dinner in one of the
              many restaurants in the harbor.
            </li>
          </ul>
          <h4> Winelands Tour</h4>
          <ul>
            <li>
              Take a half-day tour of the nearby Winelands, including visits to
              the famous Stellenbosch and Franschhoek valleys.
            </li>
            <li>
              Sample some of South Africa's finest wines, and enjoy a delicious
              lunch at one of the wineries.
            </li>
            <li>
              In the evening, return to Cape Town and relax at your hotel.
            </li>
          </ul>
          <h4> Departure</h4>
          <ul>
            <li>Spend the morning at leisure before departing South Africa.</li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. If you have more time, consider visiting
            nearby attractions such as the Kirstenbosch National Botanical
            Garden, Robben Island, or the Victoria & Alfred Waterfront.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>
            Here is a suggested itinerary for a 5-night 6-day trip to South
            Africa:
          </h3>
          <h4> Arrival in Cape Town</h4>
          <ul>
            <li>Arrive at Cape Town International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Explore the city center, visit the vibrant Green Market Square,
              and take a stroll through the nearby Company's Garden.
            </li>
            <li>
              In the evening, enjoy a scenic cable car ride up Table Mountain
              for stunning views of the city.
            </li>
          </ul>
          <h4>Cape Peninsula Tour</h4>
          <ul>
            <li>
              Take a full-day tour of the Cape Peninsula region, including a
              visit to the Cape of Good Hope, where the Atlantic and Indian
              Oceans meet.
            </li>
            <li>Stop at Boulders Beach to observe the penguin colony</li>
            <li>
              Visit Simon's Town and the naval museum, and admire the colonial
              architecture.
            </li>
            <li>
              In the evening, enjoy a delicious seafood dinner in one of the
              many restaurants in the harbor.
            </li>
          </ul>
          <h4>Winelands Tour</h4>
          <ul>
            <li>
              Take a full-day tour of the nearby winelands, including visits to
              the famous Stellenbosch and Franschhoek valleys.
            </li>
            <li>
              Sample some of South Africa's finest wines, and enjoy a delicious
              lunch at one of the wineries.
            </li>
            <li>
              In the evening, return to Cape Town and relax at your hotel.
            </li>
          </ul>
          <h4>Cape Town to Kruger National Park</h4>
          <ul>
            <li>
              Take a flight to Kruger National Park, one of the largest game
              reserves in Africa.
            </li>
            <li>Check into your lodge and take some time to settle in</li>
            <li>
              In the evening, go on a night game drive to observe the nocturnal
              wildlife.
            </li>
          </ul>
          <h4> Kruger National Park</h4>
          <ul>
            <li>
              Spend the day on game drives, observing the diverse wildlife that
              call this park home.
            </li>
            <li>In the evening, return to your lodge and relax.</li>
          </ul>
          <h4> Departure</h4>
          <ul>
            <li>Spend the morning at leisure before departing South Africa.</li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to go on a shark-cage
            diving excursion, visit the nearby Victoria Falls, or explore the
            vibrant culture and history of Johannesburg, South Africa offers a
            wide range of options for travelers.{" "}
          </p>
        </>
      ),
      night6: (
        <>
          <h3>
            Here is a suggested itinerary for a 6-night 7-day trip to South
            Africa:
          </h3>
          <h4> Arrival in Cape Town</h4>
          <ul>
            <li>Arrive at Cape Town International Airport</li>
            <li>Check into your hotel and take some time to settle in</li>
            <li>
              Explore the city center, visit the vibrant Green Market Square,
              and take a stroll through the nearby Company's Garden.
            </li>
            <li>
              In the evening, enjoy a scenic cable car ride up Table Mountain
              for stunning views of the city.
            </li>
          </ul>
          <h4>Cape Peninsula Tour</h4>
          <ul>
            <li>
              Take a full-day tour of the Cape Peninsula Peninsula region,
              including a visit to the Cape of Good Hope, where the Atlantic and
              Indian Oceans meet.
            </li>
            <li>Stop at Boulders Beach to observe the penguin colony</li>
            <li>
              Visit Simon's Town and the naval museum, and admire the colonial
              architecture.
            </li>
            <li>
              In the evening, enjoy a delicious seafood dinner in one of the
              many restaurants in the harbor.
            </li>
          </ul>
          <h4> Winelands Tour</h4>
          <ul>
            <li>
              Take a full-day tour of the nearby winelands, including visits to
              the famous Stellenbosch and Franschhoek valleys.
            </li>
            <li>
              Sample some of South Africa's finest wines, and enjoy a delicious
              lunch at one of the wineries.
            </li>
            <li>
              In the evening, return to Cape Town and relax at your hotel.
            </li>
          </ul>
          <h4> Cape Town to Kruger National Park</h4>
          <ul>
            <li>
              Take a flight to Kruger National Park, one of the largest game
              reserves in Africa.
            </li>
            <li>Check into your lodge and take some time to settle in</li>
            <li>
              In the evening, go on a night game drive to observe the nocturnal
              wildlife.
            </li>
          </ul>
          <h4>Kruger National Park</h4>
          <ul>
            <li>
              Spend the day on game drives, observing the diverse wildlife that
              call this park home.
            </li>
            <li>In the evening, return to your lodge and relax.</li>
          </ul>
          <h4>Panorama Route</h4>
          <ul>
            <li>
              Take a scenic drive along the Panorama Route, passing by the Blyde
              River Canyon, the Three Rondavels, and other stunning landscapes.
            </li>
            <li>In the evening, return to your lodge and relax.</li>
          </ul>
          <h4>Departure</h4>
          <ul>
            <li>Spend the morning at leisure before departing South Africa.</li>
          </ul>
          <p>
            This itinerary is just a suggestion and can be modified to suit your
            interests and preferences. Whether you want to go on a shark-cage
            diving excursion, visit the nearby Victoria Falls, or explore the
            vibrant culture and history of Johannesburg, South Africa offers a
            wide range of options for travelers.
          </p>
        </>
      ),
    },
    tanzania: {
      night3: (
        <>
          <h3>
            Here is a sample 3 nights, 4 days itinerary for a trip to Tanzania:
          </h3>
          <h4> Arrival in Tanzania</h4>
          <ul>
            <li>Arrive in Tanzania, meet and greet at the airport</li>
            <li>Transfer to your hotel in Arusha</li>
            <li>Relax and settle into your hotel</li>
            <li>Overnight stay in Arusha</li>
          </ul>
          <h4>: Arusha National Park</h4>
          <ul>
            <li>
              After breakfast, set out on a morning game drive in Arusha
              National Park
            </li>
            <li>
              Explore the park's diverse habitats including forests, savanna,
              and hot springs
            </li>
            <li>Enjoy a picnic lunch in the park</li>
            <li>Return to the hotel for dinner and overnight stay in Arusha</li>
          </ul>
          <h4>Serengeti National Park</h4>
          <ul>
            <li>After breakfast, depart for Serengeti National Park</li>
            <li>
              On the way, stop at Olduvai Gorge, a significant archaeological
              site where human evolution has been traced back to the earliest
              human ancestors
            </li>
            <li>
              Arrive at the park in the late afternoon, check into your lodge
            </li>
            <li>Overnight stay in Serengeti National Park</li>
          </ul>
          <h4> Serengeti National Park</h4>
          <ul>
            <li>Wake up early for a full-day game drive in the Serengeti</li>
            <li>
              Search for the famous Big Five (lion, elephant, leopard, buffalo,
              and rhino) and other wildlife
            </li>
            <li>
              Return to the lodge for dinner and overnight stay in Serengeti
              National Park
            </li>
          </ul>
        </>
      ),
      night5: (
        <>
          <h3>
            Here's a sample itinerary for a 5-night, 6-day trip to Tanzania:
          </h3>
          <h4>Arrival in Dar es Salaam</h4>
          <ul>
            <li>
              Arrive at Julius Nyerere International Airport in Dar es Salaam
            </li>
            <li>Transfer to your hotel</li>
            <li>Overnight in Dar es Salaam</li>
          </ul>
          <h4>Dar es Salaam to Serengeti National Park</h4>
          <ul>
            <li>After breakfast, depart for Serengeti National Park</li>
            <li>Stop en route at the Olduvai Gorge Museum</li>
            <li>Arrive in the Serengeti in the late afternoon</li>
            <li>Overnight in the Serengeti</li>
          </ul>
          <h4>Full day in Serengeti National Park</h4>
          <ul>
            <li>Early morning game drive in the park</li>
            <li>Return to the lodge for breakfast</li>
            <li>Mid-morning game drive in the park</li>
            <li>Lunch at the lodge</li>
            <li>Afternoon game drive in the park</li>
            <li>Return to the lodge for dinner and overnight</li>
          </ul>
          <h4>Serengeti to Ngorongoro Conservation Area</h4>
          <ul>
            <li>After breakfast, depart for Ngorongoro Conservation Area</li>
            <li>Arrive in Ngorongoro in the late afternoon</li>
            <li>Overnight in Ngorongoro</li>
          </ul>
          <h4> Full day in Ngorongoro Conservation Area</h4>
          <ul>
            <li>Early morning game drive in the Ngorongoro Crater</li>
            <li>Return to the lodge for breakfast</li>
            <li>Mid-morning game drive in the crater</li>
            <li>Lunch at the lodge</li>
            <li>Afternoon game drive in the crater</li>
            <li>Return to the lodge for dinner and overnight</li>
          </ul>
          <h4>Ngorongoro to Dar es Salaam</h4>
          <ul>
            <li>After breakfast, depart for Dar es Salaam</li>
            <li>Stop en route for a visit to a cultural village</li>
            <li>Arrive in Dar es Salaam in the late afternoon</li>
            <li>
              Transfer to Julius Nyerere International Airport for your flight
              back home
            </li>
          </ul>
          <p>
            <b>Note:</b> This itinerary is just a sample and can be customized
            based on your preferences and interests. Also, wildlife sightings
            cannot be guaranteed.
          </p>
        </>
      ),
      night6: (
        <>
          <h3>
            Here's a sample itinerary for a 6-night, 7-day trip to Tanzania:
          </h3>
          <h4>Arrival in Dar es Salaam</h4>
          <ul>
            <li>
              Arrive at Julius Nyerere International Airport in Dar es Salaam
            </li>
            <li>Transfer to your hotel</li>
            <li>Overnight in Dar es Salaam</li>
          </ul>
          <h4>Dar es Salaam to Selous Game Reserve</h4>
          <ul>
            <li>After breakfast, depart for Selous Game Reserve</li>
            <li>Arrive in the Selous in the late afternoon</li>
            <li>Overnight in the Selous</li>
          </ul>
          <h4> Full day in Selous Game Reserve</h4>
          <ul>
            <li>Early morning game drive in the reserve</li>
            <li>Return to the lodge for breakfast</li>
            <li>Mid-morning boat safari on the Rufiji River</li>
            <li>Lunch at the lodge</li>
            <li>Afternoon game drive in the reserve</li>
            <li>Return to the lodge for dinner and overnight</li>
          </ul>
          <h4> Selous to Mikumi National Park</h4>
          <ul>
            <li>After breakfast, depart for Mikumi National Park</li>
            <li>Arrive in Mikumi in the late afternoon</li>
            <li>Overnight in Mikumi</li>
          </ul>
          <h4> Full day in Mikumi National Park</h4>
          <ul>
            <li>Early morning game drive in the park</li>
            <li>Return to the lodge for breakfast</li>
            <li>Mid-morning game drive in the park</li>
            <li>Lunch at the lodge</li>
            <li>Afternoon game drive in the park</li>
            <li>Return to the lodge for dinner and overnight</li>
          </ul>
          <h4>Mikumi to Tarangire National Park</h4>
          <ul>
            <li>After breakfast, depart for Tarangire National Park</li>
            <li>Arrive in Tarangire in the late afternoon</li>
            <li>Overnight in Tarangire</li>
          </ul>
          <h4>Full day in Tarangire National Park</h4>
          <ul>
            <li>Early morning game drive in the park</li>
            <li>Return to the lodge for breakfast</li>
            <li>Mid-morning game drive in the park</li>
            <li>Lunch at the lodge</li>
            <li>Afternoon game drive in the park</li>
            <li>Return to the lodge for dinner and overnight</li>
          </ul>
          <h4>Tarangire to Dar es Salaam</h4>
          <ul>
            <li>After breakfast, depart for Dar es Salaam</li>
            <li>Stop en route for a visit to a cultural village</li>
            <li>Arrive in Dar es Salaam in the late afternoon</li>
            <li>
              Transfer to Julius Nyerere International Airport for your flight
              back home
            </li>
          </ul>
          <p>
            <b>Note:</b> This itinerary is just a sample and can be customized
            based on your preferences and interests. Also, wildlife sightings
            cannot be guaranteed.
          </p>
        </>
      ),
    },
    armernia: {
      night3: (
        <>
          <h3>Here is a sample 3-night, 4-day trip plan to Armenia:</h3>
          <h4>Arrival in Yerevan</h4>
          <ul>
            <li>
              Upon arrival at the airport, transfer to your hotel in the city
              center.
            </li>
            <li>
              Explore the city and visit some of its landmarks such as the
              Republic Square, the Opera House, and the Cascade Complex.
            </li>
            <li>
              In the evening, visit a traditional Armenian restaurant for dinner
              and experience the local cuisine.
            </li>
          </ul>
          <h4>Yerevan - Garni - Geghard - Yerevan</h4>
          <ul>
            <li>
              Drive to the ancient pagan temple of Garni, the only standing
              temple of pre-Christian Armenia.
            </li>
            <li>
              Next, visit the Geghard Monastery, a UNESCO World Heritage Site
              carved out of a mountain and surrounded by breathtaking scenery.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4> Yerevan - Lake Sevan - Dilijan - Yerevan</h4>
          <ul>
            <li>
              Drive to the picturesque Lake Sevan, the largest lake in the
              Caucasus region.
            </li>
            <li>
              Continue to the forested spa town of Dilijan, known for its
              healing mineral waters and charming architecture.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4> Departure from Yerevan</h4>
          <ul>
            <li>Transfer to the airport for your departure flight.</li>
          </ul>
          <p>
            <b>Note:</b> This is a basic itinerary and can be adjusted to suit
            your personal preferences and schedule.
          </p>
        </>
      ),
      night5: (
        <>
          <h3>Here is a sample 5-night, 6-day trip plan to Armenia:</h3>
          <h4>Arrival in Yerevan</h4>
          <ul>
            <li>
              Upon arrival at the airport, transfer to your hotel in the city
              center.
            </li>
            <li>
              Explore the city and visit some of its landmarks such as the
              Republic Square, the Opera House, and the Cascade Complex.
            </li>
            <li>
              In the evening, visit a traditional Armenian restaurant for dinner
              and experience the local cuisine.
            </li>
          </ul>
          <h4>Yerevan - Garni - Geghard - Yerevan</h4>
          <ul>
            <li>
              Drive to the ancient pagan temple of Garni, the only standing
              temple of pre-Christian Armenia.
            </li>
            <li>
              Next, visit the Geghard Monastery, a UNESCO World Heritage Site
              carved out of a mountain and surrounded by breathtaking scenery.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Lake Sevan - Dilijan - Yerevan</h4>
          <ul>
            <li>
              Drive to the picturesque Lake Sevan, the largest lake in the
              Caucasus region.
            </li>
            <li>
              Continue to the forested spa town of Dilijan, known for its
              healing mineral waters and charming architecture.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Khor Virap - Noravank - Yerevan</h4>
          <ul>
            <li>
              Drive to the Khor Virap Monastery, one of the most important
              pilgrimage sites in Armenia.
            </li>
            <li>
              Continue to the stunning Noravank Monastery, nestled in a red-rock
              canyon.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Echmiadzin - Zvartnots - Yerevan</h4>
          <ul>
            <li>
              Drive to the religious capital of Armenia, Echmiadzin, to visit
              the Mother Cathedral of Holy Etchmiadzin, the oldest state-built
              church in the world.
            </li>
            <li>
              Next, visit the ruins of the 7th-century Zvartnots Temple, a
              UNESCO World Heritage Site. Return to Yerevan for overnight.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Departure from Yerevan</h4>
          <ul>
            <li>Transfer to the airport for your departure flight.</li>
          </ul>
          <p>
            <b>Note:</b> This is a basic itinerary and can be adjusted to suit
            your personal preferences and schedule.
          </p>
        </>
      ),
      night6: (
        <>
          <h3>Here is a sample 6-night, 7-day trip plan to Armenia:</h3>
          <h4>Arrival in Yerevan</h4>
          <ul>
            <li>
              Upon arrival at the airport, transfer to your hotel in the city
              center.
            </li>
            <li>
              Explore the city and visit some of its landmarks such as the
              Republic Square, the Opera House, and the Cascade Complex.
            </li>
            <li>
              In the evening, visit a traditional Armenian restaurant for dinner
              and experience the local cuisine.
            </li>
          </ul>
          <h4>Yerevan - Garni - Geghard - Yerevan</h4>
          <ul>
            <li>
              Drive to the ancient pagan temple of Garni, the only standing
              temple of pre-Christian Armenia.
            </li>
            <li>
              Next, visit the Geghard Monastery, a UNESCO World Heritage Site
              carved out of a mountain and surrounded by breathtaking scenery.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Lake Sevan - Dilijan - Yerevan</h4>
          <ul>
            <li>
              Drive to the picturesque Lake Sevan, the largest lake in the
              Caucasus region.
            </li>
            <li>
              Continue to the forested spa town of Dilijan, known for its
              healing mineral waters and charming architecture.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Khor Virap - Noravank - Yerevan</h4>
          <ul>
            <li>
              Drive to the Khor Virap Monastery, one of the most important
              pilgrimage sites in Armenia.
            </li>
            <li>
              Continue to the stunning Noravank Monastery, nestled in a red-rock
              canyon.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Echmiadzin - Zvartnots - Yerevan</h4>
          <ul>
            <li>
              Drive to the religious capital of Armenia, Echmiadzin, to visit
              the Mother Cathedral of Holy Etchmiadzin, the oldest state-built
              church in the world.
            </li>
            <li>
              {" "}
              Next, visit the ruins of the 7th-century Zvartnots Temple, a
              UNESCO World Heritage Site.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Yerevan - Saghmosavank - Haghpat - Sanahin</h4>
          <ul>
            <li>
              Drive to the Saghmosavank Monastery, perched on the edge of a deep
              gorge.
            </li>
            <li>
              Continue to the Haghpat Monastery, another UNESCO World Heritage
              Site known for its intricate stone carvings.
            </li>
            <li>
              Visit the Sanahin Monastery, a complex of monastic buildings from
              the 10th to 13th centuries.
            </li>
            <li>Return to Yerevan for overnight.</li>
          </ul>
          <h4>Departure from Yerevan</h4>
          <ul>
            <li>Transfer to the airport for your departure flight.</li>
          </ul>
          <p>
            <b>Note:</b>This is a basic itinerary and can be adjusted to suit
            your personal preferences and schedule.
          </p>
        </>
      ),
    },
  },
];
export default packageData;