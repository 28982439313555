export const Data = [
  {
    question: `What are some of the best places to visit in Paris?`,
    answer: `Some of the best places to visit in Paris include the Eiffel Tower, the Louvre Museum, Notre-Dame Cathedral, and the Champs-Élysées.`,
    open: true,
  },
  {
    question: "What is the best time of year to visit Bali?",
    answer: `
        The best time to visit Bali is during the dry season, from May to September when the weather is sunny and warm.
        `,
    open: false,
  },
  {
    question: "What are some recommended activities in New York City?",
    answer: `Some recommended activities in New York City include visiting the Statue of Liberty, Central Park, the Empire State Building, and Times Square.`,
    open: false,
  },
  {
    question: "How can I find affordable accommodation in Tokyo?",
    answer: `You can find affordable accommodation in Tokyo by searching for budget-friendly options like capsule hotels, hostels, or Airbnb. It's also recommended to book in advance and check for discounts.`,
    open: false,
  },
  {
    question: "What are some must-try foods in Italy?",
    answer: `Some must-try foods in Italy include pizza, pasta, gelato, and risotto. Additionally, each region of Italy has its own unique cuisine worth trying.`,
    open: false,
  },
  {
    question: "How can I avoid crowds at the Grand Canyon?",
    answer: `To avoid crowds at the Grand Canyon, it's recommended to visit during the offseason, such as fall or winter, or take a less popular trail to explore the canyon.`,
    open: false,
  },
  {
    question: "What are some top attractions in Dubai?",
    answer: `Some top attractions in Dubai include the Burj Khalifa, the Dubai Mall, the Palm Jumeirah, and the Dubai Fountain.`,
    open: false,
  },
  {
    question: "What is the best way to get around Bangkok?",
    answer: `The best way to get around Bangkok is by using public transportation like the BTS Skytrain or the MRT subway. Taxis and tuk-tuks are also available but can be more expensive.`,
    open: false,
  },
  {
    question: "What are some hidden gems in Barcelona?",
    answer: `Some hidden gems in Barcelona include the Gothic Quarter, Park Güell, the Sant Pau Art Nouveau Site, and the Gracia neighborhood.`,
    open: false,
  },
  {
    question: "How can I stay safe while traveling in Rio de Janeiro?",
    answer: `To stay safe while traveling in Rio de Janeiro, it's recommended to avoid walking alone at night, keep your belongings safe, and be aware of your surroundings. It's also best to stick to well-lit areas and avoid favelas.
        `,
    open: false,
  },
  {
    question: "What are some top activities to do in Sydney?    ",
    answer: `Some top activities to do in Sydney include visiting the Sydney Opera House, climbing the Sydney Harbour Bridge, exploring Bondi Beach, and taking a ferry ride in the harbor.`,
    open: false,
  },
  {
    question: "What is the best time of year to visit Iceland?",
    answer: `The best time to visit Iceland is during the summer months of June to August when the weather is mild and the days are long. However, winter months offer opportunities to see the Northern Lights.
        `,
    open: false,
  },
  {
    question: "What are some recommended hikes in the Swiss Alps?",
    answer: `Some recommended hikes in the Swiss Alps include the Matterhorn Trail, the Eiger Trail, and the Haute Route. Each hike offers stunning views of the surrounding mountains and landscapes.`,
    open: false,
  },
  {
    question: "What are some top attractions in Cape Town?",
    answer: `Some top attractions in Cape Town include Table Mountain, the V&A Waterfront, Robben Island, and the Kirstenbosch National Botanical Garden.`,
    open: false,
  },
  {
    question: "How can I save money while traveling in London?",
    answer: `You can save money while traveling in London by using public transportation, eating at local markets, and looking for free or discounted activities like museums or parks. It's also recommended to book accommodations in advance and search for deals.`,
    open: false,
  },
];
export var focusTips = [
  {
    question: "How can I stay safe while traveling alone?",
    answer: `To stay safe while traveling alone, it's recommended to research your destination in advance stick to well-lit and populated areas and avoid sharing too much personal information with strangers. It's also a good idea to let someone know your itinerary and check in with them regularly.`,
    open: false,
  },
  {
    question: "What should I pack for a long-term backpacking trip?",
    answer: `When packing for a long-term backpacking trip, it's important to pack light and only bring essential items. Some recommended items include a sturdy backpack, comfortable shoes, versatile clothing, a water bottle, and a first-aid kit.`,
    open: false,
  },
  {
    question: "What are some tips for avoiding travel scams? ",
    answer: `
        To avoid travel scams, it's recommended to be wary of unsolicited offers, always research prices and options in advance, and avoid carrying large amounts of cash. It's also a good idea to stay alert and trust your instincts.
        `,
    open: false,
  },
  {
    question: "How can I ensure the safety of my belongings while traveling?",
    answer: `To ensure the safety of your belongings while traveling, it's recommended to keep important items like passports, cash, and credit cards in a secure place like a hotel safe or hidden money belt. It's also a good idea to keep an eye on your belongings in crowded areas or when using public transportation.
        `,
    open: false,
  },
  {
    question:
      "What should I do if I experience a medical emergency while traveling?",
    answer: `If you experience a medical emergency while traveling, it's important to seek medical help immediately. Make sure you have travel insurance that covers medical expenses and keep a copy of your insurance card and emergency contact information with you at all times. It's also recommended to research the healthcare system and emergency services in your destination before you leave.
        `,
    open: false,
  },
];
