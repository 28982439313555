import React, { useState, useEffect } from "react";
import BlogCard from "./BlogCard";
import { data } from "../blogs/data";
import "./Categories.css";
import Dolphinarium10 from "../blogs/BlogImage/Dubai_Image/Dolphinarium10.jpg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CategoryCard from "./CategoryCard";
import { Link } from "react-router-dom";
import { blogstoshow } from "../trip-finder/NewTripFinder";
import DubaiImage from "../blogs/BlogImage/Dubai_Image/Cover.jpeg";
import ThailandImage from "../blogs/BlogImage/Thailand_Image/Cover.jpeg";
import MaldivesImage from "../blogs/BlogImage/Maldives_Image/Cover.jpeg";

function Categories() {
  const [drop, setDrop] = useState(true);
  const [drop2, setDrop2] = useState(true);
  const [category, setCategory] = useState("All");
  var handleClick = (e) => {
    e.preventDefault();

    setCategory("All");
  };
  var Africa = (e) => {
    e.preventDefault();
    setCategory("africa");
  };
  var Asia = (e) => {
    e.preventDefault();
    setCategory("asia");
  };
  var Europe = (e) => {
    e.preventDefault();
    setCategory("europe");
  };
  var MiddleEast = (e) => {
    e.preventDefault();
    setCategory("middleeast");
  };
  var northAmerica = (e) => {
    e.preventDefault();

    setCategory("northamerica");
  };
  var Beach = (e) => {
    e.preventDefault();
    setCategory("beach");
  };
  var soloTravel = (e) => {
    e.preventDefault();
    setCategory("solo-travel");
  };
  var Luxury = (e) => {
    e.preventDefault();
    setCategory("luxury");
  };
  var Sunsiyam = (e) => {
    e.preventDefault();
    setCategory("sunsiyam");
  };
  let blogdata = [];
  var myKey = Object.keys(data);
  for (let i = 0; i < myKey.length; i++) {
    blogdata.push(data[myKey[i]][0]);
  }
  var cat = ["asia", "europe", "middleeast", "northamerica", "africa"];
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  return (
    <>
      <div className="category">
        <div className="category-image">
          <h1>
            TRAVELLING TURNS YOU INTO A <br /> GREAT STORYTELLER
          </h1>
        
        </div>

        <div className="cat-article" id="art">
          <div className="cat-text">
            <h1 className="about">Welcome to our travel blog! </h1>
            <p>
              Here, we share our passion for exploring the world and discovering
              new adventures. Our blog is packed with inspiring stories, useful
              travel tips, and insider information on some of the world's most
              amazing destinations.
            </p>
            <p>
              Stay up-to-date with the latest travel trends and get inspired for
              your next trip by browsing our blog. We update our content
              regularly, so be sure to check back often for new and exciting
              travel ideas.
            </p>
            <h2>
              Join us on our adventures, and let's explore the world together!!
            </h2>
          </div>
          <div className="cat-image">
            <Link
              to={`/travelogue/best-places-to-visit-in-2023`}
              onClick={() => {
                window.scrollTo(0, 0);
                scrolltotop();
              }}
            >
              <button>Read More</button>
            </Link>
          </div>
        </div>
        <div className="cards">
          <Link
            to="/travelogue/dubai-a-dream-destination"
            onClick={() => {
              window.scrollTo(0, 0);
              scrolltotop();
            }}
          >
            <BlogCard img={DubaiImage} title="Dubai- A Dream Destination" />
          </Link>
          <Link to="/travelogue/10-most-visited-places-in-maldives">
            <BlogCard
              img={MaldivesImage}
              title="Most visited places in Maldives"
            />
          </Link>
          <Link
            to="/travelogue/best-attractive-beaches-in-thailand"
            onClick={() => {
              window.scrollTo(0, 0);
              scrolltotop();
            }}
          >
            <BlogCard
              title="Best attractive beach in Thailand"
              img={ThailandImage}
            />
          </Link>
        </div>
        <div className="destination-place">
          <div className="place-heading">
            <h2>Popular Destination</h2>
            <p>
              From tropical beaches to snowy mountain peaks, we cover it all.
              Along with practical advice to help you plan your unforgettable
              journeys. Whether you're a solo traveler, a family on a budget, or
              a luxury seeker, we've got something for everyone.
            </p>
          </div>
          <div className="cat">
            <div className="two-row">
              <div className="cat-2 cat-dubai">
                <h5>Dubai</h5>
              </div>
              <div className="cat-2 cat-malasiya">
                <h5>Malasiya</h5>
              </div>
            </div>
            <div className="three-row">
              <div className="cat-3 cat-maldives">
                <h5>Maldives</h5>
              </div>
              <div className="cat-3 cat-singapore">
                <h5>Singapore</h5>
              </div>
              <div className="cat-3 cat-thailand">
                <h5>Thailand</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-cat" id="blog">
          <div className="blog-header">
            <h1>Travel Categories</h1>
            <p>
              Explore the world through our eyes. Join us on a journey of
              discovery and adventure as we share our travel experiences and
              tips for exploring countries around the globe.
            </p>
          </div>
          {/* <div className="category-option">
            <ul>
              <li>
                <a href="" value="All" onClick={handleClick}>
                  All
                </a>
              </li>
              <li>
                <a href="" onClick={Beach}>
                  Beaches
                </a>
              </li>
              <li>
                <a href="" onClick={soloTravel}>
                  Solo Travel
                </a>
              </li>
              <li>
                <a href="" onClick={Luxury}>
                  Luxury Travel
                </a>
              </li>
              <li>
                <a href="" onClick={Africa}>
                  Africa
                </a>
              </li>
              <li>
                <a href="" onClick={Asia}>
                  Asia
                </a>
              </li>
              <li>
                <a href="" onClick={Europe}>
                  Europe
                </a>
              </li>
              <li>
                <a href="" onClick={MiddleEast}>
                  Middle East
                </a>
              </li>
              <li>
                <a href="" onClick={northAmerica}>
                  North America
                </a>
              </li>
              <li>
                <a href="" onClick={northAmerica}>
                  Sun Siyam Resorts
                </a>
              </li>
            </ul>
          </div> */}
          <div className="dropdowncategories">
            <ul>
              <li onClick={handleClick}>All</li>
              <li onClick={() => setDrop(!drop)}>
                Interest
                <i
                  class="ri-arrow-down-s-line"
                  style={{
                    transform: drop
                      ? "translate(-50%, -50%) rotate(0deg)"
                      : "translate(-50%, -50%) rotate(180deg)",
                  }}
                ></i>
                <ul
                  style={{
                    transform: drop ? "scaleY(0)" : "scaleY(1)",
                    opacity: drop ? "0" : "1",
                  }}
                >
                  <li onClick={Beach}>Beaches</li>
                  <li onClick={soloTravel}>Solo Travel</li>
                  <li onClick={Luxury}>Luxury Travel</li>
                </ul>
              </li>
              <li
                onClick={() => {
                  setDrop2(!drop2);
                }}
              >
                Continents
                <i
                  class="ri-arrow-down-s-line"
                  style={{
                    transform: drop2
                      ? "translate(-50%, -50%) rotate(0deg)"
                      : "translate(-50%, -50%) rotate(180deg)",
                  }}
                ></i>
                <ul
                  style={{
                    transform: drop2 ? "scaleY(0)" : "scaleY(1)",
                    opacity: drop2 ? "0" : "1",
                  }}
                >
                  <li onClick={Africa}>Africa</li>
                  <li onClick={Asia}>Asia</li>
                  <li onClick={Europe}>Europe</li>
                  <li onClick={MiddleEast}>Middle East</li>
                  <li onClick={northAmerica}>North America</li>
                </ul>
              </li>
              <li onClick={Sunsiyam}>Sun Siyam Resorts</li>
            </ul>
          </div>
        </div>
        <div className="blogs-option">
          {category === "All"
            ? blogdata.map((event, k) => {
                return (
                  <>
                    <CategoryCard
                      title={event.title}
                      category={myKey.at(k)}
                      image={
                        event.bannerImage ? event.bannerImage : Dolphinarium10
                      }
                    />
                  </>
                );
              })
            : blogdata
                .filter((e, k) => {
                  return e.cont === category;
                })
                .map((e, k) => {
                  return (
                    <>
                      <CategoryCard
                        title={e.title}
                        category={myKey.at(k)}
                        image={e.bannerImage ? e.bannerImage : Dolphinarium10}
                      />
                    </>
                  );
                })}
        </div>
      </div>
    </>
  );
}

export default Categories;
