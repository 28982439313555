import React from "react";
import "./newTripFinder.css";
import img from "../../Images/tripplanner/Trip-planner-cover.jpeg";
import img2 from "../../Images/tripplanner/Section-2.jpeg";
import { data } from "../blogs/data";
import { Link } from "react-router-dom";
import NewCarousel from "../newCarousel/NewCarousel";
import Form from "./Form/Form";

let blogdata = [];

export let blogstoshow = Object.keys(data);

for (let i = 0; i < blogstoshow.length; i++) {
  blogdata.push(data[blogstoshow[i]][0]);
}

let TripCard = blogdata.map((e, i) => {
  function scrolltotop() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  return (
    <Link to={`/travelogue/${blogstoshow[i]}`}>
      <div
        className="trip-card"
        onClick={() => {
          window.scrollTo(0, 0);
          scrolltotop();
        }}
      >
        <div className="trip-card-img">
          <img src={e.bannerImage ? e.bannerImage : img2} alt="" />
        </div>
        <h4>{e.title}</h4>
        <div className="trip-card-film">
          <h1>
            <i className="ri-external-link-fill"></i>
          </h1>
        </div>
      </div>
    </Link>
  );
});

const NewTripFinder = () => {
  return (
    <>
      <div className="trip-top">
        <img src={img}></img>
        <div className="trip-top-text">
          <h1>TRIP PLANNER</h1>
          <p>
            Traveling is not just about seeing new places. It's about
            discovering new perspectives.
          </p>
        </div>
      </div>
      <Form />
      <div className="trip-mid">
        <div className="trip-mid-left">
          <h1>Life is short, and the world is wide. Explore it!!</h1>
          <p>
            Traveling is a wonderful opportunity to explore new cultures, meet
            interesting people, and see some of the most stunning sights the
            planet has to offer. Whether you're a seasoned traveler or a
            first-timer, there are countless destinations around the world that
            are worth exploring.
          </p>
          <button> Incredible Destinations</button>
        </div>
        <div className="trip-mid-right">
          <img
            src="https://images.unsplash.com/photo-1601613107757-fd603f3c38ed?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1376&q=80"
            alt=""
          />
        </div>
      </div>
      <div className="trip-bottom">
        <NewCarousel data={TripCard} />
      </div>
      {/* <NewForm /> */}
    </>
  );
};
// src\Images\landscape.mp4
export default NewTripFinder;
